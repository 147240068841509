import { useMutation, useQuery } from "@apollo/client";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { GET_USER } from "../../graphql/users";
import * as Yup from "yup";
import { STAFF_BOOKING_ADD } from "../../graphql/jobs";
import { Modal } from "react-bootstrap";

const EmployeeRevenueForm = (props) => {
  const {
    user,
    touched,
    handleSubmit,
    handleChange,
    errors,
    handleBlur,
    values,
    setAddRevenueView,
  } = props;
  console.log(errors, touched);
  return (
    <div className="section-body mt-3">
      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <form action="">
              <div className="form-group d-flex">
                <label htmlFor="" className="col-form-label col-lg-3">
                  Cluster Name
                </label>
                <div className="col-lg-5">
                  <input
                    type="text"
                    value={user?.user?.cluster?.name}
                    className="form-control"
                    disabled
                  />
                </div>
              </div>
              <div className="form-group d-flex">
                <label htmlFor="" className="col-form-label col-lg-3">
                  Transaction Type
                </label>
                <div className="col-lg-5">
                  <select
                    type="text"
                    id="transactionType"
                    className="form-control"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <option disabled selected>
                      {" "}
                      -- Select Transaction Type* --{" "}
                    </option>
                    <option value="STAFFING_REVENUE">Staffing</option>
                    <option value="STAFFING_RECURRING_GP">
                      Staffing Recurring GP
                    </option>
                    <option value="PLACEMENT_TRACKING">
                      Placement Tracking
                    </option>
                  </select>
                  {touched.transactionType && (
                    <span style={{ fontSize: 12, color: "red" }}>
                      {errors.transactionType}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group d-flex">
                <label htmlFor="" className="col-form-label col-lg-3">
                  Month and Year
                </label>
                <div className="col-lg-5">
                  <input
                    type="month"
                    id="commissionDate"
                    className="form-control"
                    placeholder="mm/yyyy"
                    value={values.commissionDate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.commissionDate && (
                    <span style={{ fontSize: 12, color: "red" }}>
                      {errors.commissionDate}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group d-flex">
                <label htmlFor="" className="col-form-label col-lg-3">
                  Number of Deputees
                </label>
                <div className="col-lg-5">
                  <input
                    type="number"
                    id="numberOfDeputees"
                    className="form-control"
                    placeholder="No.of.Deputees"
                    value={values.numberOfDeputees}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.numberOfDeputees && (
                    <span style={{ fontSize: 12, color: "red" }}>
                      {errors.numberOfDeputees}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group d-flex">
                <label htmlFor="" className="col-form-label col-lg-3">
                  Revenue for the month
                </label>
                <div className="col-lg-5">
                  <input
                    type="number"
                    id="commission"
                    className="form-control"
                    placeholder="Revenue for the Month"
                    value={values.commission}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.commission && (
                    <span style={{ fontSize: 12, color: "red" }}>
                      {errors.commission}
                    </span>
                  )}
                </div>
              </div>
            </form>
            <div className="mb-10">
              <button
                className="btn btn-primary"
                type="sumbit"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                style={{ marginLeft: 5 }}
                onClick={() => setAddRevenueView(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EmployeeRevenue = (props) => {
  const { setAddRevenueView, refetch } = props;
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    if (!confirmationModal && buttonClicked) {
      setAddRevenueView(false);
    }
  }, [buttonClicked, confirmationModal, setAddRevenueView]);
  const [addRevenue] = useMutation(STAFF_BOOKING_ADD, {
    onCompleted: (data) => {
      const { addStaffingRevenue } = data || {};
      const { status, error } = addStaffingRevenue;
      if (status) {
        console.log("Status", status);
        setConfirmationModal(true);
        refetch();
      } else {
        console.log("Error", error);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    const userMe = JSON.parse(localStorage.getItem("me"));
    console.log(userMe.id);
    setUserId(userMe.id);
  }, []);

  const { data: user } = useQuery(GET_USER, {
    variables: {
      id: userId,
    },
    skip: !userId,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  console.log(user?.user);
  const EmployeeRevenueWrapped = withFormik({
    mapPropsToValues: () => {
      return {
        clusterId: user?.user?.cluster?.id || "",
        transactionType: "",
        commission: "",
        commissionDate: "",
        numberOfDeputees: "",
      };
    },
    validationSchema: Yup.object().shape({
      transactionType: Yup.string().required("Please Select Transaction type"),
      commission: Yup.number().required("Enter the Revenue"),
      commissionDate: Yup.date().required("Please Enter Month and Year"),
      numberOfDeputees: Yup.number().required("Please Enter No of Deputees"),
    }),
    handleSubmit(values, { props }) {
      console.log(">>>>>>>Values123", values, props);
      addRevenue({
        variables: {
          payload: {
            commissionDate: values.commissionDate + "-01",
            transactionType: values.transactionType,
            commission: values.commission,
            clusterId: values?.clusterId,
            numberOfDeputees: values.numberOfDeputees,
          },
        },
      });
    },
  })(EmployeeRevenueForm);

  return (
    <>
      <EmployeeRevenueWrapped
        user={user}
        setAddRevenueView={setAddRevenueView}
      />
      <Modal show={confirmationModal} hide={() => setConfirmationModal(false)}>
        <Modal.Header>Revenue Captured</Modal.Header>
        <Modal.Body>Revenue has been added</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={() => {
              setButtonClicked(true);
              setConfirmationModal(false);
            }}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmployeeRevenue;
