import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  TRANSACTION_TABLE_DATA,
  SUMMARY_TABLE_DATA,
  NEW_ENROLLED_TABLE_DATA,
  EMPLOYER_JOB_REPORT,
  DOWNLOAD_REPORT,
  STAFFING_REVENUE_REPORT,
} from "../../graphql/reports";
import { downloadExcel } from "../../utils";
import moment from "moment";
import { isJwtTokenValid } from "../../utils";
import MCCReport from "./MCC_Report";
import PIAReport from "./PIA_Report";
import EmployerReport from "./Employer_Report";
import { Redirect } from "react-router-dom";

const Reports = () => {
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "days").format("YYYY-MM-DD").concat(" 00:00")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD HH:mm"));
  const [staffingStartDate, setStaffingStartDate] = useState(
    moment().format("YYYY-MM")
  );
  const [staffingEndDate, setStaffingEndDate] = useState(
    moment().add(1, "M").format("YYYY-MM")
  );
  const [isStaffing, setIsStaffing] = useState(false);
  const { roles, hasExpired } = isJwtTokenValid();
  const [loading, setLoading] = useState(true)

  const { data: summaryTableData,loading:loadingTableData } = useQuery(SUMMARY_TABLE_DATA, {
    variables: {
      filter: {
        createdDate__gte: moment(startDate)
          .format("YYYY-MM-DD")
          .concat(" 00:00"),
        createdDate__lt:
          endDate === moment().format("YYYY-MM-DD")
            ? moment(endDate).format("YYYY-MM-DD HH:mm")
            : moment(endDate).format("YYYY-MM-DD").concat(" 23:59"),
      },
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: transactionData,loading:loadingTransactionData} = useQuery(TRANSACTION_TABLE_DATA, {
    variables: {
      filter: {
        createdDate__gte: moment(startDate)
          .format("YYYY-MM-DD")
          .concat(" 00:00"),
        createdDate__lt:
          endDate === moment().format("YYYY-MM-DD")
            ? moment(endDate).format("YYYY-MM-DD HH:mm")
            : moment(endDate).format("YYYY-MM-DD").concat(" 23:59"),
      },
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: enrolledUsers,loading:loadingEnrolledUsers } = useQuery(NEW_ENROLLED_TABLE_DATA, {
    variables: {
      filter: {
        createdDate__gte: moment(startDate)
          .format("YYYY-MM-DD")
          .concat(" 00:00"),
        createdDate__lt:
          endDate === moment().format("YYYY-MM-DD")
            ? moment(endDate).format("YYYY-MM-DD HH:mm")
            : moment(endDate).format("YYYY-MM-DD").concat(" 23:59"),
      },
    },
    skip: roles[0] === "employer_user",
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: jobReport,loading:loadingJobReport } = useQuery(EMPLOYER_JOB_REPORT, {
    variables: {
      filter: {
        createdDate__gte: moment(startDate)
          .format("YYYY-MM-DD")
          .concat(" 00:00"),
        createdDate__lt:
          endDate === moment().format("YYYY-MM-DD")
            ? moment(endDate).format("YYYY-MM-DD HH:mm")
            : moment(endDate).format("YYYY-MM-DD").concat(" 23:59"),
      },
    },
    skip: roles[0] !== "employer_user",
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: reportData,loading:loadingReportData } = useQuery(DOWNLOAD_REPORT, {
    variables: {
      filter: {
        createdDate__gte: moment(startDate)
          .format("YYYY-MM-DD")
          .concat(" 00:00"),
        createdDate__lt:
          endDate === moment().format("YYYY-MM-DD")
            ? moment(endDate).format("YYYY-MM-DD HH:mm")
            : moment(endDate).format("YYYY-MM-DD").concat(" 23:59"),
      },
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: staffingRevenueData,loading:loadingStaffingRevenueData } = useQuery(STAFFING_REVENUE_REPORT, {
    variables: {
      filter: {
        createdDate__gte: moment(staffingStartDate)
          .format("YYYY-MM-DD")
          .concat(" 00:00"),
        createdDate__lt: moment(staffingEndDate)
          .format("YYYY-MM-DD")
          .concat(" 23:59"),
      },
    },
    skip: !(
      roles[0] === "mcc_coordinator" ||
      roles[0] === "mcc_ho_user" ||
      roles[0] === "mcc_state_head" ||
      roles[0] === "mcc_cluster_manager"
    ),
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    console.log("hello", {
      transactionData,
      summaryTableData,
      enrolledUsers,
      jobReport,
    });

  }, [transactionData, summaryTableData, enrolledUsers, jobReport, staffingRevenueData]);

  return (
    <>
      {hasExpired ? (
        <Redirect to="/login" />
      ) : (
        <div>
          <div className="container-fluid">
            <div className="float-right d-flex justify-content-between align-items-center mr-20 mb-3">
              {!isStaffing && (
                <>
                  <span
                    style={{
                      color: "#A8A8A8",
                      cursor: "default",
                      marginRight: 5,
                      fontSize: 14,
                    }}
                  >
                    Start Date
                  </span>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Date of Birth"
                    style={{
                      border: "1PX #7C7B7B solid",
                      marginRight: 10,
                      width: 170,
                    }}
                    // id="dob"
                    onChange={(e) => setStartDate(e.target.value)}
                    value={moment(startDate).format("YYYY-MM-DD")}
                    // onBlur={handleBlur}
                  />
                  <span
                    style={{
                      color: "#A8A8A8",
                      cursor: "default",
                      marginRight: 5,
                      fontSize: 14,
                    }}
                  >
                    End Date
                  </span>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Date of Birth"
                    style={{
                      border: "1PX #7C7B7B solid",
                      marginRight: 10,
                      width: 170,
                    }}
                    // id="dob"
                    onChange={(e) => setEndDate(e.target.value)}
                    value={moment(endDate).format("YYYY-MM-DD")}
                    // onBlur={handleBlur}
                  />{" "}
                </>
              )}
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  if (reportData?.report && reportData?.report?.report) {
                    downloadExcel(reportData.report.report, "Report.xlsx");
                  }
                }}
              >
                Export
              </button>
            </div>
            <div className="d-flex justify-content-between align-items-center w-100">
              <ul className="nav nav-tabs page-header-tab">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="user-tab"
                    data-toggle="tab"
                    href="#summary"
                    onClick={() => setIsStaffing(false)}
                  >
                    Summary
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="user-tab"
                    data-toggle="tab"
                    href="#transaction"
                    onClick={() => setIsStaffing(false)}
                  >
                    Transaction
                  </a>
                </li>
                {roles[0] === "employer_user" ? (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="user-tab"
                      data-toggle="tab"
                      href="#newCandidates"
                      onClick={() => setIsStaffing(false)}
                    >
                      Job-wise Shortlisted Candidates
                    </a>
                  </li>
                ) : (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="user-tab"
                      data-toggle="tab"
                      href="#newCandidates"
                      onClick={() => setIsStaffing(false)}
                    >
                      New Candidates Enrolled
                    </a>
                  </li>
                )}
                {(roles[0] === "mcc_coordinator" ||
                  roles[0] === "mcc_ho_user" ||
                  roles[0] === "mcc_state_head" ||
                  roles[0] === "mcc_cluster_manager") && (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="user-tab"
                      data-toggle="tab"
                      href="#staffingRevenue"
                      onClick={() => setIsStaffing(true)}
                    >
                      Staffing Revenue
                    </a>
                  </li>
                )}
                {/* <li className="nav-item">
                            <span style={{ color: '3F3F3F', cursor: 'default' }}>Start Date</span>
                            <input
                                type="date"
                                className="form-control"
                                placeholder="Date of Birth"
                                style={{ border: '1PX #7C7B7B solid' }}
                                // id="dob"
                                onChange={(e) => setStartDate(e.target.value)}
                                value={moment(startDate).format('YYYY-MM-DD')}
                            // onBlur={handleBlur}
                            />
                        </li>
                        <li className="nav-item">
                            <span style={{ color: '3F3F3F', cursor: 'default' }}>End Date</span>
                            <input
                                type="date"
                                className="form-control"
                                placeholder="Date of Birth"
                                style={{ border: '1PX #7C7B7B solid' }}
                                // id="dob"
                                onChange={(e) => setEndDate(e.target.value)}
                                value={moment(endDate).format('YYYY-MM-DD')}
                            // onBlur={handleBlur}
                            />
                        </li>
                        <li className="nav-item">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    if (reportData?.report && reportData?.report?.report) {
                                        downloadExcel(reportData.report.report, "Report.xlsx")
                                    }
                                }}
                            >
                                Export
                            </button>
                        </li> */}
              </ul>
            </div>
          </div>
          {loading && roles[0] === "pia_user" ? (
            <PIAReport
              summaryTableData={summaryTableData}
              transactionData={transactionData}
              enrolledUsers={enrolledUsers}
              startDate={startDate}
              endDate={endDate}
              loading={loading}
              loadingTableData={loadingTableData}
              loadingTransactionData={loadingTransactionData}
              loadingEnrolledUsers={loadingEnrolledUsers}
            />
          ) : roles[0] === "employer_user" ? (
            <EmployerReport
              summaryTableData={summaryTableData}
              transactionData={transactionData}
              jobReport={jobReport}
              startDate={startDate}
              endDate={endDate}
              loading={loading}
              loadingTableData={loadingTableData}
              loadingTransactionData={loadingTransactionData}
              loadingJobReport={loadingJobReport}
            />
          ) : (
            <MCCReport
              summaryTableData={summaryTableData}
              transactionData={transactionData}
              enrolledUsers={enrolledUsers}
              staffingRevenueData={staffingRevenueData}
              startDate={startDate}
              endDate={endDate}
              staffingStartDate={staffingStartDate}
              setStaffingStartDate={setStaffingStartDate}
              staffingEndDate={staffingEndDate}
              setStaffingEndDate={setStaffingEndDate}
              loadingTableData={loadingTableData}
              loadingTransactionData={loadingTransactionData}
              loadingEnrolledUsers={loadingEnrolledUsers}
              loadingStaffingRevenueData={loadingStaffingRevenueData}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Reports;
