import React from "react";
import { Box } from "@mui/material";
import ResultCard from "../../common/CandidateResultCard";
import Pagination from "../../common/PaginationCommon";
import { useHistory } from "react-router-dom";

const Results = (props) => {
  const {
    setJobSelectionModal,
    count,
    candidates,
    page,
    setPage,
    pageSize,
    setPageSize,
  } = props;

  const history = useHistory();

  return (
    <Box>
      {candidates &&
        candidates.map((res, index) => (
          <div key={res.id} style={{ marginTop: index === 0 ? 0 : 15 }}>
            <ResultCard
              mask={false}
              candidateData={res}
              fullWidth={false}
              recommended={false}
              addBtnAction={() => setJobSelectionModal(true, res.id)}
              avatarClickAction={() => {
                history.push(`/candidate-details/${res.id}`);
              }}
            />
          </div>
        ))}
      <Pagination
        page={page}
        pageSize={pageSize}
        rowCount={count}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </Box>
  );
};

export default Results;
