import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import colors from "./colors";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: colors.gray,
    },
  },
  typography: {
    fontFamily: "DM Sans",
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "16px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "16px",
          fontWeight: 500,
          borderRadius: "27px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: { fontSize: "14px" },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: colors.secondary,
          borderRadius: "4px",
          "& .Mui-disabled": { backgroundColor: "#F4F7FC" },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
