import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { ADD_USER, UPDATE_USER } from "../../graphql/users";
import { GET_ORGANIZATIONS } from "../../graphql/organizations";
import { GET_CLUSTERS } from "../../graphql/clusters";
import { useQuery, useMutation } from "@apollo/client";
import { Modal } from "react-bootstrap";

const AddNewCandidateForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setAddCandidateView,
    selectedCandidateData,
    handleBlur,
    // setError
  } = props;

  const [errorModal, setErrorModal] = useState(false);

  const { data: clustersDropDown } = useQuery(GET_CLUSTERS, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: organizations } = useQuery(GET_ORGANIZATIONS, {
    variables: {
      filter: {
        organizationType: values.roles
          ? values.roles === "pia_user"
            ? 1
            : values.roles === "employer_user"
            ? 2
            : 0
          : 0,
      },
    },
  });

  useEffect(() => {
    console.log({ values });
  }, [values]);

  useEffect(() => {
    if (values?.error !== "") {
      setErrorModal(true);
    }
  }, [values]);

  return (
    <>
      <div>
        <Modal show={errorModal} onHide={() => setErrorModal(false)}>
          <Modal.Header>
            <Modal.Title>Add Fail</Modal.Title>
            <button
              type="button"
              class="close"
              aria-label="Close"
              onClick={() => setErrorModal(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>{values.error}</Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              class="btn btn-secondary"
              onClick={() => {
                setErrorModal(false);
                setFieldValue("error", "");
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <div className="container-fluid" style={{ marginLeft: 20 }}>
          <div className="d-flex justify-content-between align-items-center">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => setAddCandidateView(false)}>
                    <span style={{ fontSize: 14, cursor: "pointer" }}>
                      Users
                    </span>
                  </a>
                </li>
                <li class="breadcrumb-item">
                  <a>
                    <span style={{ fontSize: 14 }}>
                      {selectedCandidateData?.id
                        ? `${selectedCandidateData.firstName || ""} ${
                            selectedCandidateData.lastName || ""
                          }`
                        : "New"}
                    </span>
                  </a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="section-body">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="form-group">
                    <label>User Details</label>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: -20 }}
                >
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name *"
                        style={{ border: "1px #7C7B7B solid" }}
                        id="firstName"
                        onChange={handleChange}
                        value={values.firstName}
                        onBlur={handleBlur}
                      />
                      {touched.firstName && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.firstName}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="lastName"
                        onChange={handleChange}
                        value={values.lastName}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Mobile No *"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="mobile"
                        onChange={(event) => {
                          let mob = event.target.value.toString();
                          setFieldValue("mobile", mob);
                        }}
                        value={values.mobile}
                        onBlur={handleBlur}
                      />
                      {touched.mobile && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.mobile}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email ID"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="email"
                        onChange={handleChange}
                        value={values.email}
                        onBlur={handleBlur}
                        disabled={selectedCandidateData?.id ? true : false}
                      />
                      {touched.email && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.email}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-flex-start">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <select
                      className="form-control"
                      style={{ border: "1PX #7C7B7B solid" }}
                      id={"roles"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option
                        disabled
                        selected={
                          values.roles === "" || values.roles === "candidate"
                            ? true
                            : false
                        }
                      >
                        {" "}
                        -- Select a Role* --{" "}
                      </option>
                      <option
                        value="mcc_ho_user"
                        selected={values.roles === "mcc_ho_user" ? true : false}
                      >
                        MCC HO User
                      </option>
                      <option
                        value="mcc_state_head"
                        selected={
                          values.roles === "mcc_state_head" ? true : false
                        }
                      >
                        MCC State Head
                      </option>
                      <option
                        value="mcc_cluster_manager"
                        selected={
                          values.roles === "mcc_cluster_manager" ? true : false
                        }
                      >
                        MCC Cluster Manager
                      </option>
                      <option
                        value="mcc_coordinator"
                        selected={
                          values.roles === "mcc_coordinator" ? true : false
                        }
                      >
                        MCC User
                      </option>
                      <option
                        value="pia_user"
                        selected={values.roles === "pia_user" ? true : false}
                      >
                        PIA User
                      </option>
                      <option
                        value="employer_user"
                        selected={
                          values.roles === "employer_user" ? true : false
                        }
                      >
                        Employer User
                      </option>
                    </select>
                    {touched.roles && (
                      <span style={{ fontSize: 12, color: "red" }}>
                        {errors.roles}
                      </span>
                    )}
                  </div>
                  {values.roles &&
                    (values.roles === "mcc_ho_user" ||
                      values.roles === "mcc_state_head" ||
                      values.roles === "mcc_cluster_manager" ||
                      values.roles === "mcc_coordinator") && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id={"clusterId"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option
                              disabled
                              selected={!values.centreId ? true : false}
                            >
                              {" "}
                              -- Select MCC Cluster* --{" "}
                            </option>
                            {/* {centresDropDown && centresDropDown.centres && centresDropDown?.centres.map((centre) => (
                                                    <option value={centre.id} selected={values.centreId === centre.id ? true : false}>{centre.name}</option>
                                                ))} */}
                            {clustersDropDown &&
                              clustersDropDown?.clusters &&
                              clustersDropDown?.clusters?.clusters &&
                              clustersDropDown?.clusters?.clusters.map(
                                (cluster) => (
                                  <option
                                    value={cluster.id}
                                    selected={
                                      values.clusterId === cluster.id
                                        ? true
                                        : false
                                    }
                                  >
                                    {cluster.name}
                                  </option>
                                )
                              )}
                          </select>
                          {touched.clusterId && (
                            <span style={{ fontSize: 12, color: "red" }}>
                              {errors.clusterId}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  {values.roles && values.roles === "pia_user" && (
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <select
                          className="form-control"
                          style={{ border: "1PX #7C7B7B solid" }}
                          id={"organizationId"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option
                            disabled
                            selected={!values.organizationId ? true : false}
                          >
                            {" "}
                            -- Select PIA* --{" "}
                          </option>
                          {/* {centresDropDown && centresDropDown.centres && centresDropDown?.centres.map((centre) => (
                                                    <option value={centre.id} selected={values.centreId === centre.id ? true : false}>{centre.name}</option>
                                                ))} */}
                          {organizations &&
                            organizations?.organizations &&
                            organizations?.organizations?.map((org) => (
                              <option
                                value={org.id}
                                selected={
                                  values.organizationId === org.id
                                    ? true
                                    : false
                                }
                              >
                                {org.name}
                              </option>
                            ))}
                        </select>
                        {touched.organizationId && (
                          <span style={{ fontSize: 12, color: "red" }}>
                            {errors.organizationId}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  {values.roles && values.roles === "employer_user" && (
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <select
                          className="form-control"
                          style={{ border: "1PX #7C7B7B solid" }}
                          id={"organizationId"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option
                            disabled
                            selected={!values.organizationId ? true : false}
                          >
                            {" "}
                            -- Select Employer* --{" "}
                          </option>
                          {/* {centresDropDown && centresDropDown.centres && centresDropDown?.centres.map((centre) => (
                                                    <option value={centre.id} selected={values.centreId === centre.id ? true : false}>{centre.name}</option>
                                                ))} */}
                          {organizations &&
                            organizations?.organizations &&
                            organizations?.organizations?.map((org) => (
                              <option
                                value={org.id}
                                selected={
                                  values.organizationId === org.id
                                    ? true
                                    : false
                                }
                              >
                                {org.name}
                              </option>
                            ))}
                        </select>
                        {touched.organizationId && (
                          <span style={{ fontSize: 12, color: "red" }}>
                            {errors.organizationId}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {values.roles === "pia_user" && (
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id={"clusterId"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.centreId ? true : false}
                        >
                          {" "}
                          -- Select MCC Cluster* --{" "}
                        </option>
                        {clustersDropDown &&
                          clustersDropDown?.clusters &&
                          clustersDropDown?.clusters?.clusters &&
                          clustersDropDown?.clusters?.clusters.map(
                            (cluster) => (
                              <option
                                value={cluster.id}
                                selected={
                                  values.clusterId === cluster.id ? true : false
                                }
                              >
                                {cluster.name}
                              </option>
                            )
                          )}
                      </select>
                      {touched.clusterId && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.clusterId}
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {values?.roles && values.roles !== 'pia_user' && values.roles !== 'employer_user' && <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="isAccountManager"
                        name="isAccountManager"
                        // value={true}
                        checked={values.isAccountManager}
                        onChange={(e) =>
                          setFieldValue("isAccountManager", e.target.checked)
                        }
                      />
                      <label for="confidential" style={{ marginLeft: 5 }}>
                        Is Account Manager?
                      </label>
                    </div>
                  </div>}
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ marginTop: 15 }}
                    onClick={handleSubmit}
                  >
                    {selectedCandidateData?.id ? "Save" : "Add"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ marginTop: 15, marginLeft: 5 }}
                    onClick={() => setAddCandidateView(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddNewCandidate = (props) => {
  const { setAddCandidateView, selectedCandidateData } = props;
  const [error, setError] = useState("");

  const [addUser] = useMutation(ADD_USER, {
    onCompleted: (data) => {
      const { createUser } = data || {};
      const { status, error } = createUser;
      console.log({ status, error });
      if (status) {
        setAddCandidateView(false);
        setError("");
      } else {
        setError(error);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      const { updateUser } = data || {};
      const { status, error } = updateUser;
      console.log({ status, error });
      if (status) {
        setAddCandidateView(false);
        setError("");
      } else {
        setError(error);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const AddNewCandidateWrapped = withFormik({
    mapPropsToValues: (props) => {
      const { selectedCandidateData } = props;

      return {
        firstName: selectedCandidateData?.firstName || "",
        lastName: selectedCandidateData?.lastName || "",
        mobile: selectedCandidateData?.mobile || "",
        email: selectedCandidateData?.email || "",
        clusterId: selectedCandidateData.cluster?.id || "",
        roles: selectedCandidateData?.roles
          ? selectedCandidateData?.roles[0]
          : "",
        organizationId: selectedCandidateData?.organization?.id || "",
        isAccountManager: selectedCandidateData?.isAccountManager || false,
        error: error,
      };
    },

    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Please enter first name"),
      mobile: Yup.string()
        .required("Please enter mobile number")
        .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
      email: Yup.string()
        .required("Please enter email id")
        .email("Enter a valid email ID"),
      clusterId: Yup.string().when("roles", {
        is: (role) => role === "pia_user",
        then: Yup.string().required("Please select the cluster"),
      }),
      roles: Yup.string().required("Please select a role"),
      organizationId: Yup.string().when("roles", {
        is: (role) => role === "pia_user" || role === "employer_user",
        then: Yup.string().required("Please select the organization"),
      }),
    }),

    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);

      const { selectedCandidateData } = props;
      delete values["error"];

      if (selectedCandidateData?.id) {
        updateUser({
          variables: {
            payload: {
              ...values,
              profile: JSON.stringify(values.profile),
              id: selectedCandidateData?.id,
              firstName: values.firstName.trim(),
              lastName: values.lastName.trim(),
              email: values.email.trim()
            },
          },
        });
      } else {
        addUser({
          variables: {
            payload: {
              ...values,
              roles: [values.roles],
              profile: JSON.stringify(values.profile),
              // centreId: '1'
            },
          },
        });
      }
    },
  })(AddNewCandidateForm);

  return (
    <AddNewCandidateWrapped
      setAddCandidateView={setAddCandidateView}
      selectedCandidateData={selectedCandidateData}
    />
  );
};

export default AddNewCandidate;
