import React, { useState, useEffect } from "react";
import { Grid, Typography, Avatar } from "@mui/material";
import Modal from "./CommonModal";
import HorizontalDivider from "./HorizontalDivider";
import help_icon from "../assets/imagesNew/help-icon.svg";
import colors from "../styles/colors";

const ScreeningQuestionsModal = (props) => {
  const { open, setOpen, data, name, setData } = props;
  const [questionAnswers, setQuestionAnswers] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      setQuestionAnswers(data);
    } else {
      setQuestionAnswers([]);
    }
  }, [data]);

  useEffect(() => {
    if (!open) {
      setData([]);
    }
  }, [open, setData]);
  return (
    <Modal open={open} setOpen={setOpen}>
      <Grid container spacing={2} alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <img src={help_icon} alt="help" width={50} />
        </Grid>
        <Grid item lg={9} md={9} sm={9} xs={9}>
          <Typography fontSize="24px" fontWeight={500} color={colors.dark}>
            Screening Questions &amp; Answer
          </Typography>
          <Typography fontSize="16px">Answers from {name}</Typography>
        </Grid>
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          xs={3}
          display="flex"
          justifyContent="flex-end"
        >
          <Avatar sx={{ width: 100, height: 100 }}>{name.charAt(0)}</Avatar>
        </Grid>
      </Grid>
      <div style={{ marginTop: 15, marginBottom: 15 }}>
        <HorizontalDivider />
      </div>
      <Grid container spacing={2}>
        {questionAnswers &&
          questionAnswers.length > 0 &&
          questionAnswers.map((item, index) => (
            <>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography fontWeight={500} fontSize="16px">{`${index + 1}. ${
                  item.question
                }`}</Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
                <Typography fontSize="14px">{item.answer}</Typography>
              </Grid>
            </>
          ))}
        {questionAnswers.length === 0 && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              color={colors.dark}
              fontSize="20px"
              fontWeight={500}
              textAlign="center"
            >
              No questions were answered.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};

export default ScreeningQuestionsModal;
