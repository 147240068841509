import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ceil_logo from "../../assets/images/CIELlogo-1.png";
import { withFormik } from "formik";
import * as Yup from "yup";
import { LOGIN } from "../../graphql/users";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { getDefaultRoute, isJwtTokenValid } from "../../utils";

const SignInForm = (props) => {
  const { values, errors, touched, handleChange, handleSubmit } = props;

  return (
    <div className="auth">
      <div className="auth_left">
        <div className="card">
          <div className="text-center mb-10">
            <Link className="header-brand" to="/">
              {/* <img src={mafoi_logo} style={{ width: 90, height: 90 }} /> */}
              <img src={ceil_logo} alt="LOGO" />
            </Link>
          </div>
          <div className="card-body">
            <div className="card-title">Login to your account</div>
            {values.loginError && (
              <span style={{ fontSize: 13, color: "red" }}>
                {values.loginError}
              </span>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="username"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  onChange={handleChange}
                />
                {touched.username && (
                  <span style={{ fontSize: 13, color: "red" }}>
                    {errors.username}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">
                  Password
                  <Link className="float-right small" to="/forgotpassword">
                    I forgot password
                  </Link>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  onChange={handleChange}
                />
                {touched.password && (
                  <span style={{ fontSize: 13, color: "red" }}>
                    {errors.password}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" />
                  <span className="custom-control-label">Remember me</span>
                </label>
              </div>
              <div className="form-footer">
                <button className="btn btn-primary btn-block" type="submit">
                  Click to login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="auth_right">
        <div
          className="carousel slide"
          data-ride="carousel"
          data-interval={3000}
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="https://www.incimages.com/uploaded_files/image/1920x1080/getty_475352876_301971.jpg"
                className="img-fluid"
                style={{ width: "100%", height: 790 }}
                alt="login page"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Login = (props) => {
  const [loginError, setLoginError] = React.useState("");
  const history = useHistory();

  useEffect(() => {
    const defaultRoute = getDefaultRoute();

    if (defaultRoute !== "/") {
      history.push(defaultRoute);
    }
  }, [history]);

  const [login] = useMutation(LOGIN, {
    onCompleted: (data) => {
      const { loginUser } = data || {};
      const { token, status, me } = loginUser;

      if (!Boolean(status)) {
        setLoginError("Invalid username or password.");
        localStorage.removeItem("token");
        localStorage.removeItem("me");
      } else {
        console.log({ me });
        localStorage.setItem("token", token);
        localStorage.setItem("me", JSON.stringify(me));
        setLoginError("");
        const { roles } = isJwtTokenValid();
        if (roles[0] === "pia_user") {
          history.push("/candidates");
        } else if (roles[0] === "employer_user") {
          history.push("/employer-dashboard");
        } else {
          history.push("/dashboard");
        }
      }
    },
  });

  const handleLogin = (formValues) => {
    console.log(formValues);
    const { username, password } = formValues;
    login({
      variables: {
        username,
        password,
      },
    });
  };

  const LoginWrapped = withFormik({
    mapPropsToValues: () => ({
      username: "",
      password: "",
      loginError: loginError,
    }),

    validationSchema: Yup.object().shape({
      username: Yup.string()
        .required("*Email ID Required")
        .email("Enter a Valid Email"),
      password: Yup.string().required("*Password Required"),
    }),

    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values);
      const { handleLogin } = props;

      handleLogin(values);
    },
  })(SignInForm);

  return <LoginWrapped handleLogin={handleLogin}></LoginWrapped>;
};

export default Login;
