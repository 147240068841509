import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import ProfileSections from "./ProfileSections";
import CompanyDetails from "./CompanyDetails";
import ContactDetails from "./ContactDetails";
import LogoutModal from "./LogoutModal";
import EmployerNavbar from "../../common/EmployerNavBar";
import colors from "../../styles/colors";

function Profile() {
  const [selectedSection, setSelectedSection] = useState("company");
  const [logoutModal, setLogoutModal] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item lg={3} md={3} sm={4} xs={12}>
        <ProfileSections
          setSelectedSection={setSelectedSection}
          selectedSection={selectedSection}
          setLogoutModal={setLogoutModal}
        />
      </Grid>
      <Grid item lg={9} md={9} sm={8} xs={12}>
        <Box
          sx={{
            border: `1px solid ${colors.strokeGray}`,
            borderRadius: "4px",
            p: { xs: 2, sm: 3.5 },
          }}
        >
          {selectedSection === "company" && <CompanyDetails />}
          {selectedSection === "contact" && <ContactDetails />}
          {logoutModal && (
            <LogoutModal open={logoutModal} setOpen={setLogoutModal} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

const EmployerProfile = (props) => {
  return <EmployerNavbar mainComponent={<Profile />} />;
};

export default EmployerProfile;
