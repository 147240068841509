import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  TextField,
  Autocomplete,
  Box,
  Typography,
} from "@mui/material";
import DynamicDateRangePicker from "./DynamicDateRangePicker";
import { setColumnLineChartData } from "./utils";
import StackedColumPlusLineChart from "../../common/StackedColumPlusLineChart";
import NoBorderDropdown from "../../common/NoBorderDropdown";
import colors from "../../styles/colors";

const spanOfDataArr = [
  { id: "WEEKLY", name: "Weekly" },
  { id: "MONTHLY", name: "Monthly" },
  { id: "custom", name: "Custom" },
];

const StackedGraphSection = (props) => {
  const {
    selectedJob,
    setSelectedJob,
    chartsData,
    spanOfData,
    setSpanOfData,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    jobsDropdownArr,
  } = props;

  const [xAxis, setxAxis] = useState([]);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    setColumnLineChartData(chartsData, setxAxis, setGraphData, spanOfData);
  }, [chartsData, spanOfData]);
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item lg={5} md={5} sm={12} xs={12}>
        <Autocomplete
          id="combo-box-demo"
          autoComplete
          options={jobsDropdownArr}
          getOptionLabel={(option) => option.name}
          value={
            selectedJob &&
            jobsDropdownArr.find((job) => job.id === selectedJob.id)
          }
          onChange={(event, value) => setSelectedJob(value)}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder="Choose a Job"
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                backgroundColor: "#F5F5F5",
                width: { xs: "100%", md: "55%" },
              }}
            />
          )}
        />
      </Grid>
      <Grid
        item
        lg={7}
        md={7}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="flex-end"
      >
        <div style={{ marginRight: 10 }}>
          <NoBorderDropdown
            value={spanOfData}
            onChange={(e) => setSpanOfData(e.target.value)}
            bgColor={colors.primary}
            textColor={colors.secondary}
            fontSize="14px"
            dropdownArr={spanOfDataArr}
          />
        </div>
        <DynamicDateRangePicker
          spanOfData={spanOfData}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Paper elevation={1} sx={{ p: 2 }}>
          {graphData.length > 0 ? (
            <StackedColumPlusLineChart
              xAxisLabels={xAxis}
              data={graphData}
              partColors={[
                colors.warning,
                colors.success,
                colors.danger,
                "#0E3D83",
                colors.primary,
              ]}
            />
          ) : (
            <Box
              sx={{
                height: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>No data available for this date range</Typography>
            </Box>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default StackedGraphSection;
