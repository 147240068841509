import moment from "moment";
import colors from "./styles/colors";

export function getEmployerNavbarSelected(location, currentIteration) {
  if (
    location.pathname === "/employer-dashboard" &&
    currentIteration.id === 0
  ) {
    return true;
  }
  if (location.pathname === "/employer-jobs" && currentIteration.id === 1) {
    return true;
  }
  if (
    location.pathname.includes("/employer-job-details") &&
    currentIteration.id === 1
  ) {
    return true;
  }
  if (
    location.pathname.includes("/employer-job-applicants") &&
    currentIteration.id === 1
  ) {
    return true;
  }
  if (
    location.pathname.includes("/employer-add-job") &&
    currentIteration.id === 1
  ) {
    return true;
  }
  if (
    location.pathname === "/employer-add-screeningQuestions" &&
    currentIteration.id === 1
  ) {
    return true;
  }
  if (
    location.pathname.includes("/recommended-candidates") &&
    currentIteration.id === 1
  ) {
    return true;
  }
  if (
    location.pathname === "/employer-applied-candidates" &&
    currentIteration.id === 2
  ) {
    return true;
  }
  if (
    location.pathname === "/employer-search-candidate" &&
    currentIteration.id === 3
  ) {
    return true;
  }
  return false;
}

export function getEmployerBreadcrumbs(location, jobData) {
  if (location.pathname === "/employer-dashboard") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/employer-jobs") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: colors.lightGray,
      },
      {
        id: 2,
        name: "Jobs",
        path: "/employer-jobs",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname.includes("/employer-job-details")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: colors.lightGray,
      },
      {
        id: 2,
        name: "Jobs",
        path: "/employer-jobs",
        color: colors.lightGray,
      },
      {
        id: 3,
        name: jobData.title,
        path: `/employer-job-details/${jobData.id}`,
        color: colors.primary,
      },
    ];
  }
  if (location.pathname.includes("/employer-job-applicants")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: colors.lightGray,
      },
      {
        id: 2,
        name: "Jobs",
        path: "/employer-jobs",
        color: colors.lightGray,
      },
      {
        id: 3,
        name: jobData.title,
        path: `/employer-job-details/${jobData.id}`,
        color: colors.lightGray,
      },
      {
        id: 4,
        name: "View Applicants",
        path: `/employer-job-applicants/${jobData.id}`,
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/employer-add-job/new") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: colors.lightGray,
      },
      {
        id: 2,
        name: "Jobs",
        path: "/employer-jobs",
        color: colors.lightGray,
      },
      {
        id: 3,
        name: "Post Job",
        path: "/employer-add-job/new",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/employer-add-screeningQuestionss") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: colors.lightGray,
      },
      {
        id: 2,
        name: "Jobs",
        path: "/employer-jobs",
        color: colors.lightGray,
      },
      {
        id: 3,
        name: "Post Job",
        path: `/employer-add-job`,
        color: colors.lightGray,
      },
      {
        id: 4,
        name: "Screening Questions",
        path: "/employer-add-screeningQuestions",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname.includes("/recommended-candidates")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: colors.lightGray,
      },
      {
        id: 2,
        name: "Jobs",
        path: "/employer-jobs",
        color: colors.lightGray,
      },
      {
        id: 3,
        name: jobData.title,
        path: `/employer-job-details/${jobData.id}`,
        color: colors.lightGray,
      },
      {
        id: 4,
        name: "Recommended Candidates",
        path: `/recommended-candidates/${jobData.id}`,
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/employer-applied-candidates") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: colors.lightGray,
      },
      {
        id: 2,
        name: "Applied Candidates",
        path: "/employer-applied-candidates",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/employer-search-candidate") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: colors.lightGray,
      },
      {
        id: 2,
        name: "Search Candidate",
        path: "/employer-search-candidate",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/employer-profile") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: colors.lightGray,
      },
      {
        id: 2,
        name: "My Profile",
        path: "/employer-profile",
        color: colors.primary,
      },
    ];
  }
  return [];
}

export function prepareAutoCompleteOptions(
  arr,
  idField,
  titleField,
  jobsDashboard
) {
  let autoCompleteArr = arr.map((item) => ({
    id: item[idField],
    name: item[titleField],
  }));
  if (jobsDashboard) {
    autoCompleteArr = arr.map((item, index) => ({
      ...autoCompleteArr[index],
      jobId: item.jobId,
    }));
  }
  return autoCompleteArr;
}

export function getDateInFormat(date, format) {
  return moment(date).format(format);
}

export function commaFormatterNumber(num) {
  return num.toLocaleString("en-IN");
}

export function kFormatterNumber(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}

export function formArrayFromStringSkills(skills) {
  return skills.split(",");
}

export const loadScreeningAnswers = (data, setScreeningQuestionsResponses) => {
  if (data && data.length > 0) {
    const temp = data.map((item) => {
      const answerTemp = JSON.parse(item.answer);
      const answer = answerTemp.answer;
      return {
        id: item.id,
        question: item.question.question,
        answer: answer,
      };
    });
    setScreeningQuestionsResponses([...temp]);
  } else {
    setScreeningQuestionsResponses([]);
  }
};

export const getLoggedInUserId = () => {
  const temp = localStorage.getItem("me");
  return temp;
};

const MIMETypesMapping = {
  ".apng": "apng",
  ".avif": "avif",
  ".gif": "gif",
  ".jpg": "jpeg",
  ".jpeg": "jpeg",
  ".jfif": "jpeg",
  ".pjpeg": "jpeg",
  ".pjp": "jpeg",
  ".png": "png",
  ".svg": "svg+xml",
  ".webp": "webp",
};

export const getMIMETypeOfFile = (fileType) => {
  return MIMETypesMapping[fileType];
};

export const acceptImageTypes =
  ".apng, .avif, .gif, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .svg, .webp";

export const fitmentRatioMap = (score) => {
  let obj = {};
  if (score <= 100 && score > 90) {
    obj = {
      color: colors.success,
      fillColor: `${colors.success}15`,
      label: "EXCELLENT FIT",
    };
  }
  if (score <= 90 && score > 70) {
    obj = {
      color: colors.primary,
      fillColor: `${colors.primary}15`,
      label: "GOOD FIT",
    };
  }
  if (score <= 70 && score > 50) {
    obj = {
      color: colors.warning,
      fillColor: `${colors.warning}15`,
      label: "AVERAGE FIT",
    };
  }
  if (score <= 50 && score > 40) {
    obj = {
      color: colors.warning,
      fillColor: colors.yellowFill,
      label: "POOR FIT",
    };
  }
  if (score <= 40) {
    obj = {
      color: colors.danger,
      fillColor: `${colors.danger}20`,
      label: "NOT A FIT",
    };
  }
  return obj;
};
