import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import Chip from "../../common/Chip";
import { formArrayFromStringSkills } from "../../helpers";
import { FiberManualRecord } from "@mui/icons-material";

const ExpAndSkills = (props) => {
  const { user } = props;

  const [skills, setSkills] = useState([]);
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    let tempSkills = [];
    if (user && user?.skills) {
      tempSkills = formArrayFromStringSkills(user?.skills);
      setSkills(tempSkills);
    }
    if (user && user?.profile) {
      const tempJSON = JSON.parse(user?.profile);
      if (tempJSON && tempJSON?.skillset && tempJSON?.skillset.length > 0) {
        const newSkills = tempJSON?.skillset.map((x) => x.skill_name);
        setSkills([...tempSkills, ...newSkills]);
      }
      if (
        tempJSON &&
        tempJSON?.experiences &&
        tempJSON?.experiences?.length > 0
      ) {
        setExperiences(tempJSON?.experiences);
      }
    }
  }, [user]);

  return (
    <Paper elevation={2} sx={{ px: 2, py: 3 }}>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography fontWeight={600} fontSize="18px" sx={{ mb: 0.5 }}>
            Job Experience
          </Typography>
          {experiences.length > 0 ? (
            <Grid container spacing={0.5}>
              {experiences.map((x) => (
                <>
                  <Grid item lg={0.5} md={1} sm={1.5} xs={2}>
                    <FiberManualRecord color="primary" fontSize="small" />
                  </Grid>
                  <Grid item lg={11.5} md={11} sm={10.5} xs={10}>
                    <Typography fontSize="14px" fontWeight={600}>
                      {x?.comp_name || "-"}
                    </Typography>
                    <Typography fontSize="14px">
                      {x?.job_title || ""}
                    </Typography>
                    <Typography fontSize="14px" color="primary">
                      {x.hasOwnProperty("tot_exp")
                        ? `${x.tot_exp} year(s)`
                        : "-"}
                    </Typography>
                  </Grid>
                </>
              ))}
            </Grid>
          ) : (
            <Typography fontWeight={600} fontSize="16px">
              Not specified
            </Typography>
          )}
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography fontWeight={600} fontSize="18px" sx={{ mb: 0.5 }}>
            Key Skills
          </Typography>
          {skills.length > 0 ? (
            <Grid container spacing={0.5}>
              {skills.map((x) => (
                <Grid item>
                  <Chip label={x} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography fontWeight={600} fontSize="16px">
              Not specified
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ExpAndSkills;
