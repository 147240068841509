import { useQuery, useMutation } from '@apollo/client';
import React, { Component, useEffect, useState } from 'react';
import { GET_THREADS, ADD_POST, DELETE_THREAD } from '../../graphql/community';
import AddPostForm from './AddPost';
import { getPrettyDate } from '../../utils';
import { Modal } from 'react-bootstrap';
// import Thread from './Thread';

const CommunityPost = (props) => {

	const { fixNavbar, communities, refetchCommunities } = props;
	const [threads, setThreads] = useState([]);
	const [addPostView, setAddPostView] = useState(false)
	const [editPostData, setEditPostData] = useState({});

	const { data, refetch } = useQuery(GET_THREADS, {
		variables: {
			// page: page + 1,
			// limit: pageSize,
			// filter: filter
		},
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (data?.allThreads?.threads) {
			setThreads(data.allThreads.threads);
		}
	}, [data])

	console.log('>>>>>>>>>>>>>>threads', threads);

	const Comment = (commentProps) => {

		const { comment } = commentProps;

		return (
			<li>
				<div className="comment_body">
					<div className="avatar_img">
						<span
							className="tl_avatar avatar avatar-cyan"
							data-toggle="tooltip"
							data-placement="top"
							data-original-title="Avatar Name"
						>
							{comment.author?.firstName ? comment.author.firstName.substring(0, 1) : '-'}
						</span>
					</div>
					<h6>
						<a>{comment.author?.firstName || ''} {comment.author?.lastName}</a>{' '}
						<small className="float-right font-14">
							{getPrettyDate(comment.time)}
						</small>
					</h6>
					<p dangerouslySetInnerHTML={{ __html: comment?.body ? comment.body : '<p></p>' }}></p>
				</div>
			</li>
		)
	};
	const Thread = (props) => {

		const { thread, fetchThreads, refetchCommunities, communities } = props;
		const [commentText, setCommentText] = useState('');
		const [confirmModal, setConfirmModal] = useState(false);

		const [addPost] = useMutation(ADD_POST, {
			onCompleted: (data) => {
				const { createPost } = data || {};
				const { status, error } = createPost;
				console.log({ status, error });
				if (status) {
					// handleClose()
					fetchThreads();
					setCommentText('');
					refetchCommunities()
				}
			},
			fetchPolicy: 'network-only',
			nextFetchPolicy: 'network-only',
		});

		const [deleteThread] = useMutation(DELETE_THREAD, {
			onCompleted: (data) => {
				const { deleteThread } = data || {};
				const { status, error } = deleteThread;
				console.log({ status, error });
				if (status) {
					// handleClose()
					fetchThreads();
					setCommentText('');
					setConfirmModal(false)
					refetchCommunities()
				}
			},
			fetchPolicy: 'network-only',
			nextFetchPolicy: 'network-only',
		});
	
		const { community, posts, postCount, id, title } = thread;
		console.log(posts)
		const [firstPost, ...restOfPosts] = posts||[];
		console.log("firstPost",firstPost)
		const [selectedThread, setSelectedThread] = useState('')
		console.log({ editPostData })

		return (
			<>
				{firstPost &&
				<div className="timeline_item ">
					<span
						className="tl_avatar avatar avatar-cyan"
						data-toggle="tooltip"
						data-placement="top"
						data-original-title="Avatar Name"

					>
						{firstPost?.author?.firstName ? firstPost?.author.firstName.substring(0, 1) : '-'}
					</span>
					<span>
						<a>{firstPost.author?.firstName || ''} {firstPost.author?.lastName}</a>{' '}
						<small className="float-right text-right">
							{getPrettyDate(firstPost.time)}
							<button
								type="button"
								className="btn btn-icon"
								title="Edit"
								// data-type="confirm"
								// data-toggle="modal"
								// data-target="#deleteThread"
								onClick={() => {
									setEditPostData({
										postData: firstPost,
										title: title,
										community: community
									})
									setAddPostView(true)
									// setConfirmModal(true)
								}}
								style={{ marginRight: -10 }}
							>
								<i className="fa fa-edit" />
							</button>
							<button
								type="button"
								className="btn btn-icon"
								title="Delete"
								// data-type="confirm"
								// data-toggle="modal"
								// data-target="#deleteThread"
								onClick={() => {
									setSelectedThread(id)
									setConfirmModal(true)
								}}
							>
								<i className="fa fa-trash" />
							</button>
						</small>
					</span>
					<h6 className="font600" style={{ color: 'blue' }}>
						{community?.title || '-'}
					</h6>
					<div className="msg">
						<p dangerouslySetInnerHTML={{ __html: firstPost?.body ? firstPost.body : '<p></p>' }}></p>
						{/* <a className="mr-20 text-muted">
						<i className="fa fa-heart text-pink" /> {firstPost?.likesCount || 0} Likes
					</a> */}
						<span className='text-muted'>
							<i className="fa fa-heart text-pink" style={{ marginRight: 5 }} />
							{firstPost?.likesCount || 0} {firstPost?.likesCount === 1 ? "Like" : "Likes"}
						</span>
						<a
							className="text-muted"
							role="button"
							data-toggle="collapse"
							href={`#collapse${thread.id}`}
							aria-expanded="false"
							aria-controls={`collapse${thread.id}`}
							style={{ marginLeft: 10 }}
						>
							<i className="fa fa-comments" /> {postCount - 1} Comments
						</a>
						<div
							className="collapse p-4 section-gray mt-2"
							id={`collapse${thread.id}`}
						>
							<form className="well">
								<div className="form-group">
									<textarea
										rows={2}
										className="form-control no-resize"
										placeholder="Enter here for comment..."
										value={commentText}
										onChange={(e) => {
											setCommentText(e.target.value);
										}}
									/>
								</div>
								<button className="btn btn-primary"
									onClick={() => {
										if (commentText) {
											addPost({
												variables: {
													payload: {
														threadId: thread.id,
														communityId: community.id,
														title: thread.title,
														body: commentText
													}
												}
											});
										}
									}}
								>
									Submit
								</button>
							</form>
							<ul className="recent_comments list-unstyled mt-4 mb-0">
								{restOfPosts.map((r) => <Comment comment={r} key={r.id} />)}
							</ul>
						</div>
					</div>
					<Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
						<Modal.Header>
							<Modal.Title>Confirm</Modal.Title>
							<button type="button" class="close" aria-label="Close" onClick={() => setConfirmModal(false)}>
								<span aria-hidden="true">&times;</span>
							</button>
						</Modal.Header>
						<Modal.Body>Are you sure you want to delete the thread?</Modal.Body>
						<Modal.Footer>
							<button type="button" className="btn btn-outline-success" onClick={() =>
								deleteThread({
									variables: {
										id: selectedThread,
									},
								})
							}>
								Yes
							</button>
							<button type="button" class="btn btn-outline-danger" onClick={() => setConfirmModal(false)}>No</button>
						</Modal.Footer>
					</Modal>
				</div>
				}
				{/* {addPostView && <AddPost communities={communities} fetchThreads={fetchThreads} addPostView={addPostView} setAddPostView={setAddPostView} refetchCommunities={refetchCommunities} />} */}
			</>
		);
						
	}

	return (
		<>
			<link rel="stylesheet" href="../assets/plugins/summernote/dist/summernote.css" />
			{!addPostView && <div>
				<div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
					<div className="container-fluid">
						<div className="row clearfix">
							<div className="col-md-12">
								<button
									type="button"
									className="btn btn-primary"
									style={{ height: 30, marginBottom: 10 }}
									onClick={() => setAddPostView(true)}
								>
									<i className="fe fe-plus mr-2" />
									Add Post
								</button>
								<div className="card">
									<div className="card-body">
										{threads.map((t) => <Thread key={t.id} thread={t} fetchThreads={refetch} refetchCommunities={refetchCommunities} communities={communities} />)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>}
			{addPostView && <AddPostForm communities={communities} fetchThreads={refetch} addPostView={addPostView} setAddPostView={setAddPostView} refetchCommunities={refetchCommunities} selectedPostData={editPostData} />}
		</>
	);
}

export default CommunityPost;