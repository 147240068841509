import React from "react";
import { Typography } from "@mui/material";
import colors from "../styles/colors";

const CommonFooter = (props) => {
  return (
    <Typography
      color={`${colors.fontPrimary}50`}
      fontSize="14px"
      sx={{ py: 1.5, backgroundColor: "#F9F9F9", mt: 5 }}
      textAlign="center"
    >
      © 2022 CIEL Jobs. All rights reserved
    </Typography>
  );
};

export default CommonFooter;
