import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { ADD_EVENT } from "../../graphql/users";
import { GET_STAGES } from "../../graphql/meta";
import { useQuery, useMutation } from "@apollo/client";
import { Modal } from "react-bootstrap";

const ActivityForm = (props) => {
  const {
    setAddCandidateView,
    timeLineData,
    handleChange,
    handleSubmit,
    touched,
    errors,
    values,
    addActivityModal,
    setAddActivityModal,
    setFieldValue,
    editTimeline,
    selectedCandidateData,
  } = props;

  const { data: stages } = useQuery(GET_STAGES, {
    variables: {},
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (addActivityModal === false) {
      setFieldValue("eventDate", "");
      setFieldValue("eventType", "");
      setFieldValue("eventComments", "");
    }
  }, [addActivityModal, setFieldValue]);

  return (
    <div className="card">
      <div className="card-body">
        <div
          className="d-flex justify-content-between"
          style={{ marginLeft: 30 }}
        >
          {timeLineData &&
          timeLineData.userTimeline &&
          timeLineData.userTimeline.eventstimeline.length > 0 ? (
            <ul className="new_timeline mt-3">
              {timeLineData.userTimeline.eventstimeline.map((timeline) => (
                <li>
                  <div className="bullet orange" />
                  <div className="time">{timeline.eventDate}</div>
                  <div className="desc">
                    <h3>{timeline.event && timeline.event.name}</h3>
                    <div className="msg">
                      {timeline.comments !== "" && (
                        <ul style={{ marginBottom: -20 }}>
                          <li>
                            <p style={{ fontSize: 13 }}>{timeline.comments}</p>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : editTimeline ? (
            (selectedCandidateData?.source === 1 ||
              selectedCandidateData?.source === 2) &&
            !selectedCandidateData?.organization ? (
              <div className="alert alert-warning" role="alert">
                No PIA has been selected for this candidate, kindly select a PIA
                to update stage of the candidate.
              </div>
            ) : (
              <div className="alert alert-warning" role="alert">
                No activity added, please add an activity.
              </div>
            )
          ) : (
            <div className="alert alert-warning" role="alert">
              No activity added
            </div>
          )}
          {editTimeline && (
            <button
              type="button"
              className="btn btn-primary"
              style={{ height: 30 }}
              onClick={() => setAddActivityModal(true)}
              disabled={
                (selectedCandidateData?.source === 1 ||
                  selectedCandidateData?.source === 2) &&
                !selectedCandidateData?.organization
                  ? true
                  : false
              }
            >
              <i className="fe fe-plus mr-2" />
              Add
            </button>
          )}
          <Modal
            show={addActivityModal}
            onHide={() => setAddActivityModal(false)}
          >
            <Modal.Header>
              <Modal.Title>Add Activity</Modal.Title>
              <button
                type="button"
                class="close"
                aria-label="Close"
                onClick={() => setAddActivityModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex justify-content-between">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <span>Date</span>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className="form-group">
                    <input
                      id="eventDate"
                      type="date"
                      className="form-control"
                      style={{ border: "1PX #7C7B7B solid" }}
                      onChange={handleChange}
                      value={values.eventDate}
                    />
                    {touched.eventDate && (
                      <span style={{ color: "red", fontSize: 13 }}>
                        {errors.eventDate}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <span>Stage</span>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className="form-group">
                    <select
                      className="custom-select"
                      style={{ border: "1PX #7C7B7B solid" }}
                      id="eventType"
                      onChange={handleChange}
                    >
                      <option disabled selected value>
                        {" "}
                        -- Select a Stage --{" "}
                      </option>
                      {stages &&
                        stages?.events &&
                        stages?.events?.map((e) => (
                          <option value={e.id}>{e.name}</option>
                        ))}
                    </select>
                    {touched.eventType && (
                      <span style={{ color: "red", fontSize: 13 }}>
                        {errors.eventType}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <span>Comments</span>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      style={{ border: "1PX #7C7B7B solid" }}
                      onChange={handleChange}
                      id="eventComments"
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleSubmit}
              >
                Add
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                onClick={() => setAddActivityModal(false)}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        {editTimeline &&
          timeLineData &&
          timeLineData.userTimeline &&
          timeLineData.userTimeline.eventstimeline.length > 0 &&
          (selectedCandidateData?.source === 1 ||
            selectedCandidateData?.source === 2) &&
          !selectedCandidateData?.organization && (
            <div
              className="alert alert-warning"
              role="alert"
              style={{ marginTop: 30 }}
            >
              No PIA has been selected for this candidate, kindly select a PIA
              to update stage of the candidate.
            </div>
          )}
        <div className="col-12" style={{ marginTop: 30 }}>
          <button
            type="button"
            className="btn btn-secondary"
            style={{ marginTop: 15 }}
            onClick={() => setAddCandidateView(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const Activity = (props) => {
  const {
    setAddCandidateView,
    timeLineData,
    id,
    refetchTimeline,
    editTimeline,
    selectedCandidateData,
  } = props;
  const [addActivityModal, setAddActivityModal] = useState(false);

  const [addEvent] = useMutation(ADD_EVENT, {
    onCompleted: (data) => {
      const { createTimeline } = data;
      const { status, error } = createTimeline;
      console.log({ status, error });
      setAddActivityModal(false);
      refetchTimeline();
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const AddNewActivityWrapped = withFormik({
    mapPropsToValues: () => {
      return {
        eventDate: "",
        eventType: "",
        eventComments: "",
      };
    },

    validationSchema: Yup.object().shape({
      eventDate: Yup.date()
        .required("Please enter the activity date")
        .max(moment().format("YYYY-MM-DD")),
      eventType: Yup.string().required("Please select a stage"),
    }),

    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);

      addEvent({
        variables: {
          payload: {
            userId: [id],
            eventId: values.eventType,
            eventDate: values.eventDate,
            comments: values.eventComments,
          },
        },
      });
    },
  })(ActivityForm);

  return (
    <AddNewActivityWrapped
      setAddCandidateView={setAddCandidateView}
      timeLineData={timeLineData}
      addActivityModal={addActivityModal}
      setAddActivityModal={setAddActivityModal}
      editTimeline={editTimeline}
      selectedCandidateData={selectedCandidateData}
    />
  );
};

export default Activity;
