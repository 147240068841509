import React, { Component } from 'react'
import { connect } from 'react-redux';

class Clients extends Component {
    render() {
        const { fixNavbar } = this.props;
        return (
            <>
                <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Community Name" />
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-4 col-sm-6">
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Project" />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-12">
                                                <a href="fake_url;" className="btn btn-sm btn-primary" style={{marginRight: 5}} >Search</a>
                                                <a href="fake_url;" className="btn btn-sm btn-success" >Add New</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body text-center ribbon">
                                        {/* <div className="ribbon-box green">New</div>
                                        <img 
                                            className="rounded-circle img-thumbnail w100" 
                                            src="https://lh3.googleusercontent.com/proxy/0UWMdqEJo1LwIZCHEzJMKpdJaGVXubBjbffFAafC4FKKfgvWbwywWIvwqgNVle2PBmAKHTIBL5lbWrsSZcA_zbaNI2Y8KIQ4NHUQRk1Xt10t71N2AOhiOWuJ" 
                                            alt="fake_url" 
                                            style={{width: '30%', height: '70%'}}
                                        /> */}
                                        <img 
                                            // className="rounded-circle img-thumbnail w100" 
                                            src="https://lh3.googleusercontent.com/proxy/0UWMdqEJo1LwIZCHEzJMKpdJaGVXubBjbffFAafC4FKKfgvWbwywWIvwqgNVle2PBmAKHTIBL5lbWrsSZcA_zbaNI2Y8KIQ4NHUQRk1Xt10t71N2AOhiOWuJ" 
                                            alt="fake_url" 
                                            // style={{width: '30%', height: '70%'}}
                                            style={{height: 90, width: 130}}
                                        />
                                        <h6 className="mt-3 mb-0">Adobe</h6>
                                        <span>jason-porter@info.com</span>
                                        <ul className="mt-3 list-unstyled d-flex justify-content-center">
                                        <li><a className="p-3" target="_blank" href="/#"><i className="fa fa-linkedin" /></a></li>
                                            <li><a className="p-3" target="_blank" href="/#"><i className="fa fa-facebook" /></a></li>
                                            <li><a className="p-3" target="_blank" href="/#"><i className="fa fa-twitter" /></a></li>
                                            {/* <li><a className="p-3" target="_blank" href="/#"><i className="fa fa-slack" /></a></li> */}
                                        </ul>
                                        <button className="btn btn-default btn-sm" style={{marginRight: 3}}>View Community</button>
                                        <button className="btn btn-default btn-sm">Message</button>
                                        <div className="row text-center mt-4">
                                            <div className="col-lg-6 border-right">
                                                <label className="mb-0">Project</label>
                                                <h4 className="font-18">07</h4>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="mb-0">Deal</label>
                                                <h4 className="font-18">$2,510</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body text-center ribbon">
                                        {/* <div className="ribbon-box indigo">Bengaluru</div> */}
                                        <img 
                                            // className="rounded-circle img-thumbnail w100" 
                                            src="https://1000logos.net/wp-content/uploads/2021/05/Wipro-logo.png" 
                                            alt="fake_url" 
                                            style={{height: 90, width: 140}}
                                        />
                                        <h6 className="mt-3 mb-0">Wipro</h6>
                                        <span>Michelle@info.com</span>
                                        <ul className="mt-3 list-unstyled d-flex justify-content-center">
                                            <li><a className="p-3" target="_blank" href="/#"><i className="fa fa-linkedin" /></a></li>
                                            <li><a className="p-3" target="_blank" href="/#"><i className="fa fa-facebook" /></a></li>
                                            <li><a className="p-3" target="_blank" href="/#"><i className="fa fa-twitter" /></a></li>
                                            {/* <li><a className="p-3" target="_blank" href="/#"><i className="fa fa-slack" /></a></li> */}
                                        </ul>
                                        <button className="btn btn-default btn-sm" style={{marginRight: 3}}>View Community</button>
                                        <button className="btn btn-default btn-sm">Message</button>
                                        <div className="row text-center mt-4">
                                            <div className="col-lg-6 border-right">
                                                <label className="mb-0">Project</label>
                                                <h4 className="font-18">14</h4>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="mb-0">Deal</label>
                                                <h4 className="font-18">$7,510</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <img 
                                            // className="rounded-circle img-thumbnail w100" 
                                            src="https://i.pinimg.com/originals/d3/d1/75/d3d175e560ae133f1ed5cd4ec173751a.png" 
                                            alt="fake_url" 
                                            style={{height: 90, width: 100}}
                                        />
                                        <h6 className="mt-3 mb-0">Pinterest</h6>
                                        <span>jason-porter@info.com</span>
                                        <ul className="mt-3 list-unstyled d-flex justify-content-center">
                                            <li><a className="p-3" target="_blank" href="/#"><i className="fa fa-linkedin" /></a></li>
                                            <li><a className="p-3" target="_blank" href="/#"><i className="fa fa-facebook" /></a></li>
                                            <li><a className="p-3" target="_blank" href="/#"><i className="fa fa-twitter" /></a></li>
                                        </ul>
                                        <button className="btn btn-default btn-sm" style={{marginRight: 3}}>View Community</button>
                                        <button className="btn btn-default btn-sm">Message</button>
                                        <div className="row text-center mt-4">
                                            <div className="col-lg-6 border-right">
                                                <label className="mb-0">Project</label>
                                                <h4 className="font-18">08</h4>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="mb-0">Deal</label>
                                                <h4 className="font-18">$5,510</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(Clients);