import React, { useEffect, useRef, useState } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { useQuery, useMutation } from "@apollo/client";
import { ADD_POST, CREATE_IMAGE, UPDATE_POST } from "../../graphql/community";
import CKEditor from "../common/ckeditor";
import { Modal } from "react-bootstrap";

const AddPostForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    onSubmit,
    handleBlur,
    selectedPostData,
    closeModal,
    communities,
    setFieldValue,
    setAddPostView,
    addPostView,
    setImage
  } = props;

  const [file, setFile] = useState(null);
  const [base64, setBase64] = useState("");
  useEffect(() => {
    handleFileUpload();
  }, [file]);

  const handleFileUpload = async () => {
    console.log("useEffect 119");
    if (file) {
      setBase64(await convertToBase64(file));
      console.log("Convert12345",base64)
      setImage(base64)
    }
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <div>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-flex-start">
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <span>
                    Community <span style={{ color: "red" }}>*</span>
                  </span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="form-group">
                    <select
                      id="communityId"
                      className="custom-select"
                      style={{ border: "1PX #7C7B7B solid" }}
                      onChange={handleChange}
                      value={values.communityId}
                      disabled={selectedPostData?.postData?.id ? true : false}
                    >
                      <option value={"null"}> -- Select a Community -- </option>
                      {communities &&
                        communities.map((c) => (
                          <option key={c.id} value={c.id}>
                            {c.title}
                          </option>
                        ))}
                    </select>
                    {touched.communityId && (
                      <span style={{ fontSize: 12, color: "red" }}>
                        {errors.communityId}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-flex-start">
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <span>
                    Title <span style={{ color: "red" }}>*</span>
                  </span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="form-group">
                    <input
                      type="text"
                      id="title"
                      className="form-control"
                      placeholder="Title"
                      style={{ border: "1PX #7C7B7B solid" }}
                      onChange={handleChange}
                      value={values.title}
                      disabled={selectedPostData?.postData?.id ? true : false}
                    />
                    {touched.title && (
                      <span style={{ fontSize: 12, color: "red" }}>
                        {errors.title}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-flex-start">
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <span>
                    Post <span style={{ color: "red" }}>*</span>
                  </span>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className="form-group summernote">
                    <CKEditor
                      id="body"
                      content={values.body}
                      onChange={(e) =>
                        setFieldValue("body", e.editor.getData())
                      }
                    />
                    {touched.body && (
                      <span style={{ fontSize: 12, color: "red" }}>
                        {errors.body}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="d-flex justify-content-flex-start">
                                <div className="col-lg-2 col-md-2 col-sm-12">
                                    <span>Add Image </span>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <div className="form-group summernote">
                                        <input type="file"
                                        onChange={(e)=>setFile(e.target.files[0])}
                                        />
                                    </div>
                                </div>
                </div>
                <div>
                  {base64 && 
                  <img src={base64} alt="" />
                  }
                </div> */}
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  style={{ marginTop: 15 }}
                >
                  {selectedPostData?.postData?.id ? "Save" : "Add"}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setAddPostView(false)}
                  style={{ marginTop: 15, marginLeft: 5 }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddPost = (props) => {
  const {
    selectedPostData,
    communities,
    fetchThreads,
    setAddPostView,
    addPostView,
    refetchCommunities,
  } = props;
  // const [closeModal, setCloseModal] = useState(false);

  const [image, setImage] = useState("")

  console.log({ selectedPostData });

  const [addPost] = useMutation(ADD_POST, {
    onCompleted: (data) => {
      const { createPost } = data || {};
      const { status, error } = createPost;
      console.log({ status, error });
      if (status) {
        fetchThreads();
        setAddPostView(false);
        refetchCommunities();
        console.log("SUccess on ADDING")
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [updatePost] = useMutation(UPDATE_POST, {
    onCompleted: (data) => {
      const { updatePost } = data || {};
      const { status, error } = updatePost;
      console.log({ status, error });
      if (status) {
        fetchThreads();
        setAddPostView(false);
        refetchCommunities();
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const AddPostWrapped = withFormik({
    mapPropsToValues: (props) => {
      const { selectedPostData } = props;

      return {
        title: selectedPostData?.title || "",
        body: selectedPostData?.postData?.body || "",
        communityId: selectedPostData?.community?.id || "",
        // filesId: selectedPostData?.postImages?.id || "",
        // image:selectedPostData?.postImages?.file||[]
      };
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Please enter a title"),
      body: Yup.string().required("Please enter what this post is about"),
      communityId: Yup.string().required("Please select a community."),
    }),

    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);

      const { selectedPostData } = props;
      if (selectedPostData?.postData?.id) {
        updatePost({
          variables: {
            payload: {
              id: selectedPostData.postData.id,
              body: values.body,
            },
            files: {
              //id: selectedPostData.postImages.id,
            },
          },
        });
      } else {
        addPost({
          variables: {
            payload: {
              ...values,
            },
            files:{
              image:Image
            }
          },
        });
      }
    },
  })(AddPostForm);

  return (
    <AddPostWrapped
      communities={communities}
      selectedPostData={selectedPostData}
      setAddPostView={setAddPostView}
      addPostView={addPostView}
      setImage={setImage}
      // convertToBase64={convertToBase64}
      // setFile={setFile}
    />
  );
};

export default AddPost;
