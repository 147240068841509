import { gql } from "@apollo/client";

export const GET_COUNTRY = gql`
  query Country {
    countries {
      id
      name
    }
  }
`;

export const GET_STATES = gql`
  query States($country: String!) {
    states(country: $country) {
      id
      name
    }
  }
`;

export const GET_CITIES = gql`
  query Cities($state: String!) {
    cities(state: $state) {
      id
      name
    }
  }
`;

export const GET_QUALIFICATIONS = gql`
  query Qualifications {
    qualifications {
      id
      name
    }
  }
`;

export const GET_JOB_CATEGORIES = gql`
  query jobTypes {
    jobTypes {
      id
      name
    }
  }
`;
