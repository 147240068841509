import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import { KeyboardArrowRightOutlined } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import getStore from "../../zustandStore";
import colors from "../../styles/colors";

const Footer = (props) => {
  const setJobPayload = getStore((state) => state.setJobPayload);
  const setScreeningQuestions = getStore(
    (state) => state.setScreeningQuestions
  );
  const { handleSubmit, values, edit } = props;

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Grid container spacing={2}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          size="small"
          onClick={() => {
            if (values?.jobTypeId) {
              setJobPayload(values);
              history.push("/employer-add-screeningQuestions");
            } else {
              enqueueSnackbar("Please select the job category", {
                variant: "error",
              });
            }
          }}
          endIcon={
            <KeyboardArrowRightOutlined fontSize="small" color="primary" />
          }
        >
          {edit !== "new"
            ? " View/Edit Screening Questions"
            : "Add Screening Questions"}
        </Button>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              size="small"
              sx={{ color: colors.lightGray }}
              onClick={() => {
                setJobPayload({});
                setScreeningQuestions([]);
                history.push("/employer-jobs");
              }}
            >
              Cancel
            </Button>
          </Grid>
          {/* <Grid item>
            <Button size="small" variant="outlined">
              Save Draft
            </Button>
          </Grid> */}
          <Grid item>
            <Button size="small" variant="contained" onClick={handleSubmit}>
              {edit !== "new" ? "Update Job" : "Post Job"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
