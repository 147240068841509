import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Checkbox,
  Button,
} from "@mui/material";
import { WhatsApp } from "@mui/icons-material";
import india from "../../assets/imagesNew/india.svg";
import colors from "../../styles/colors";

import { withFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { REGISTER_USER } from "../../graphql/SelfRegistration";
import getStore from "../../zustandStore";

const RegistrationForm = (props) => {
  const {
    values,
    touched,
    handleChange,
    handleBlur,
    errors,
    handleSubmit,
    setFieldValue,
  } = props;

  return (
    <Grid container spacing={3} sx={{ p: { xs: 3, sm: 0 } }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography color={colors.dark} fontSize="24px" fontWeight={500}>
          Registration
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Typography fontSize="14px">Company Name</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
        <TextField
          id="companyName"
          value={values.companyName}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
        />
        {touched.companyName && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.companyName}
          </Typography>
        )}
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Typography fontSize="14px">First Name</Typography>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <Typography fontSize="14px">Last Name</Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -2 }}>
        <TextField
          id="firstName"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
        />
        {touched.firstName && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.firstName}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        <Typography fontSize="14px">Last Name</Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -2 }}>
        <TextField
          id="lastName"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px">Contact Number</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
        <TextField
          id="mobile"
          value={values.mobile}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          type="number"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={india} alt="india" />
              </InputAdornment>
            ),
          }}
        />
        {touched.mobile && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.mobile}
          </Typography>
        )}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px">Official Email</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
        <TextField
          id="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
        />
        {touched.email && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.email}
          </Typography>
        )}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px">Designation</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
        <TextField
          id="designation"
          value={values.designation}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Checkbox size="small" sx={{ mr: 1 }} defaultChecked />
        <Typography fontSize="12px" color={colors.dark}>
          I Agree to{" "}
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: colors.primary,
            }}
          >
            Terms &amp; Conditions
          </span>
        </Typography>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Checkbox
          id="otpInWhatsapp"
          size="small"
          sx={{ mr: 1 }}
          checked={values.otpInWhatsapp}
          onChange={(e) => setFieldValue("otpInWhatsapp", e.target.checked)}
        />
        <Typography fontSize="12px" color={colors.dark}>
          Receive updates via{" "}
          <WhatsApp fontSize="small" sx={{ color: "#57BB63" }} />
          <span style={{ color: "#57BB63", marginLeft: 2 }}>Whatsapp</span>
        </Typography>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
      >
        <Button variant="contained" onClick={handleSubmit}>
          Send OTP
        </Button>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
      >
        <Typography color={colors.dark} fontSize="14px">
          Already have an account?{" "}
          <NavLink
            to="/"
            style={{
              color: colors.primary,
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Login
          </NavLink>
        </Typography>
      </Grid>
    </Grid>
  );
};

const RegistrationFormFormik = (props) => {
  const {
    setViewType,
    setRegisteredUser,
    setEnteredMobileNumber,
    setEnteredCompanyName,
    setEnteredValues,
  } = props;

  const setLoading = getStore((state) => state.setLoading);
  const { enqueueSnackbar } = useSnackbar();

  const [registerEmployer, { loading }] = useMutation(REGISTER_USER, {
    onCompleted: (data) => {
      const { upsertRegisterUser } = data;
      const { status, error } = upsertRegisterUser;
      console.log({ status, error });
      if (status) {
        setViewType("otp");
      } else {
        enqueueSnackbar(error, {
          variant: "error",
          sx: { "& .SnackbarContent-root": { backgroundColor: colors.danger } },
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleRegister = (values) => {
    // let profileObj = {};
    // if (values && values.designation) {
    //   profileObj = { designation: values.designation };
    // }
    registerEmployer({
      variables: {
        payload: {
          mobile: values.mobile.toString(),
          role: "employer_user",
          email: values.email,
        },
      },
    });
    setEnteredMobileNumber(values.mobile.toString());
    setEnteredCompanyName(values.companyName);
    setRegisteredUser(values.email);
    setEnteredValues(values);
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const RegistrationFormWrapped = withFormik({
    mapPropsToValues: () => ({
      companyName: "",
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      designation: "",
      otpInWhatsapp: false,
    }),

    validationSchema: Yup.object().shape({
      companyName: Yup.string().required("Please enter the company name"),
      firstName: Yup.string().required("Please enter the first name"),
      mobile: Yup.string()
        .required("Please enter the phone number")
        .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),
      email: Yup.string()
        .required("Please enter the email id")
        .email("Enter a valid email id"),
    }),

    handleSubmit(values, { props }) {
      const { handleReset } = props;
      handleRegister(values);
      handleReset(values);
    },
  })(RegistrationForm);

  return <RegistrationFormWrapped />;
};

export default RegistrationFormFormik;
