import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import {
  GET_COUNTRY,
  GET_STATES,
  GET_CITIES,
  GET_LOCATIONS,
  GET_INDUSTRIES,
} from "../../graphql/meta";
import { useQuery, useMutation } from "@apollo/client";
import {
  ADD_ORGANIZATION,
  UPDATE_ORGANIZATION,
} from "../../graphql/organizations";

const AddNewOrganizationForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setAddOrganizationView,
    selectedOrganizationPayload,
    setFieldValue,
  } = props;

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const { data: countries } = useQuery(GET_COUNTRY, {
    variables: {},
  });

  const { data: industries } = useQuery(GET_INDUSTRIES, {
    variables: {},
  });

  const { data: states } = useQuery(GET_STATES, {
    variables: {
      country: selectedCountry,
    },
    skip: !selectedCountry,
  });

  const { data: cities } = useQuery(GET_CITIES, {
    variables: {
      state: selectedState,
    },
    skip: !selectedState,
  });

  const { data: locations } = useQuery(GET_LOCATIONS, {
    variables: {
      city: selectedCity,
    },
    skip: !selectedCity,
  });

  const { cityId, stateId, countryId } = values;

  useEffect(() => {
    if (cityId) {
      setSelectedCity(cityId);
    }
  }, [cityId]);

  useEffect(() => {
    if (countryId) {
      setSelectedCountry(countryId);
    }
  }, [countryId]);

  useEffect(() => {
    if (stateId) {
      setSelectedState(stateId);
    }
  }, [stateId]);

  useEffect(() => {
    console.log({ values });
  }, [values]);

  return (
    <>
      <div>
        <div className="section-body mt-3">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a onClick={() => setAddOrganizationView(false)}>
                      <span style={{ fontSize: 14, cursor: "pointer" }}>
                        Organizations
                      </span>
                    </a>
                  </li>
                  <li class="breadcrumb-item">
                    <span style={{ fontSize: 14 }}>
                      {values.name ? values.name : "New"}
                    </span>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="form-group">
                    <label>Organization Details</label>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: -20 }}
                >
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        id="name"
                        type="text"
                        className="form-control"
                        placeholder="Name *"
                        style={{ border: "1px #7C7B7B solid" }}
                        onChange={handleChange}
                        value={values?.name}
                        onBlur={handleBlur}
                      />
                      {touched.name && (
                        <span style={{ fontSize: 13, color: "red" }}>
                          {errors.name}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        id="description"
                        type="text"
                        className="form-control"
                        placeholder="Description"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        value={values?.description}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Website"
                        id="website"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        value={values?.website}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Address"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="address"
                        onChange={handleChange}
                        value={values?.address}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        id="countryId"
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.countryId ? true : false}
                        >
                          {" "}
                          -- Select Country* --{" "}
                        </option>
                        {countries?.countries?.map((c) => (
                          <option
                            key={c.id}
                            value={c.id}
                            selected={values?.countryId === c.id ? true : false}
                          >
                            {" "}
                            {c.name}{" "}
                          </option>
                        ))}
                      </select>
                      {touched.countryId && (
                        <span style={{ fontSize: 13, color: "red" }}>
                          {errors.countryId}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        id="stateId"
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.stateId ? true : false}
                        >
                          {" "}
                          -- Select State* --{" "}
                        </option>
                        {states?.states?.map((c) => (
                          <option
                            key={c.id}
                            value={c.id}
                            selected={values?.stateId === c.id ? true : false}
                          >
                            {" "}
                            {c.name}{" "}
                          </option>
                        ))}
                      </select>
                      {touched.stateId && (
                        <span style={{ fontSize: 13, color: "red" }}>
                          {errors.stateId}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        id="cityId"
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.cityId ? true : false}
                        >
                          {" "}
                          -- Select City* --{" "}
                        </option>
                        {cities?.cities?.map((c) => (
                          <option
                            key={c.id}
                            value={c.id}
                            selected={values?.cityId === c.id ? true : false}
                          >
                            {" "}
                            {c.name}{" "}
                          </option>
                        ))}
                      </select>
                      {touched.cityId && (
                        <span style={{ fontSize: 13, color: "red" }}>
                          {errors.cityId}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        id="locationId"
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.locationId ? true : false}
                        >
                          {" "}
                          -- Select Location* --{" "}
                        </option>
                        {locations?.locations?.map((c) => (
                          <option
                            key={c.id}
                            value={c.id}
                            selected={
                              values?.locationId === c.id ? true : false
                            }
                          >
                            {" "}
                            {c.name}{" "}
                          </option>
                        ))}
                      </select>
                      {touched.locationId && (
                        <span style={{ fontSize: 13, color: "red" }}>
                          {errors.locationId}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Pincode"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="pincode"
                        onChange={handleChange}
                        value={values?.pincode}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        id="industryId"
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                      >
                        <option
                          disabled
                          selected={!values.industryId ? true : false}
                        >
                          {" "}
                          -- Select Industry --{" "}
                        </option>
                        {industries?.industries?.map((c) => (
                          <option
                            key={c.id}
                            value={c.id}
                            selected={
                              values?.industryId === c.id ? true : false
                            }
                          >
                            {" "}
                            {c.name}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        id="organizationType"
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={(e) => {
                          let v = e.target?.value
                            ? parseInt(e.target.value)
                            : null;
                          setFieldValue("organizationType", v);
                        }}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.organizationType ? true : false}
                        >
                          {" "}
                          -- Select Organization Type* --{" "}
                        </option>
                        <option
                          value="1"
                          selected={
                            values.organizationType === 1 ? true : false
                          }
                        >
                          {" "}
                          PIA{" "}
                        </option>
                        <option
                          value="2"
                          selected={
                            values.organizationType === 2 ? true : false
                          }
                        >
                          {" "}
                          Employer{" "}
                        </option>
                        <option
                          value="3"
                          selected={
                            values.organizationType === 3 ? true : false
                          }
                        >
                          {" "}
                          Placement Partner{" "}
                        </option>
                      </select>
                      {touched.organizationType && (
                        <span style={{ fontSize: 13, color: "red" }}>
                          {errors.organizationType}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        id="lob"
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                      >
                        <option disabled selected={!values.lob ? true : false}>
                          {" "}
                          -- Select Line of Business --{" "}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Billing Address"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="billing_address"
                        onChange={handleChange}
                        value={values?.billing_address}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Shipping Address"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="shipping_address"
                        onChange={handleChange}
                        value={values?.shipping_address}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-flex-start">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Employees"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="employees"
                        onChange={handleChange}
                        value={values?.employees === 0 ? "" : values?.employees}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="GST IN"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="gst_number"
                        onChange={handleChange}
                        value={values?.gst_number}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>Client Contact Details 1</label>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>Client Contact Details 2</label>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-flex-start"
                  style={{ marginTop: -20 }}
                >
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="profile.name1"
                        onChange={handleChange}
                        value={values.profile && values.profile.name1}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Mobile No"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="profile.contact1"
                        onChange={handleChange}
                        value={values.profile && values.profile.contact1}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="profile.name2"
                        onChange={handleChange}
                        value={values.profile && values.profile.name2}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Mobile No"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="profile.contact2"
                        onChange={handleChange}
                        value={values.profile && values.profile.contact2}
                      />
                    </div>
                  </div>
                </div>
                {values.organizationType === 1 && (
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group">
                      <label>Rate per candidate</label>
                    </div>
                  </div>
                )}
                {values.organizationType === 1 && (
                  <div
                    className="d-flex justify-content-flex-start"
                    style={{ marginTop: -20 }}
                  >
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Mobilization"
                          style={{ border: "1PX #7C7B7B solid" }}
                          id="rate_mobilization"
                          onChange={handleChange}
                          value={
                            values.rate_mobilization === 0
                              ? ""
                              : values.rate_mobilization
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Placement"
                          style={{ border: "1PX #7C7B7B solid" }}
                          id="rate_placement"
                          onChange={handleChange}
                          value={
                            values.rate_placement === 0
                              ? ""
                              : values.rate_placement
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* {values.organizationType === 2 && <>
                                    <div className="d-flex justify-content-flex-start" style={{ marginTop: -20 }}>
                                        <div className="form-group">
                                            <label className='font-15 font-weight-bold ml-10'>Specify rate</label>
                                        </div>
                                        <div className="form-group" style={{ marginLeft: 10 }}>
                                            <form
                                                id="rate_format"
                                                onChange={(e) => {
                                                    setFieldValue('rate_format', e.target.value);
                                                    if (e.target.value === 'percent') {
                                                        setFieldValue('rate_recruitment', 0)
                                                    }
                                                    else if (e.target.value === 'value') {
                                                        setFieldValue('percent_recruitment', 0)
                                                    }
                                                }}
                                            >
                                                <input type="radio" id="value" name="rate_format" value="value" checked={(values.rate_format === 'value') ? true : false} />
                                                <label for="value" style={{ fontSize: 13, marginLeft: 5 }}>By Value</label>
                                                <input type="radio" id="percent" name="rate_format" value="percent" checked={(values.percentage_recruitment !== 0) ? true : false} style={{ marginLeft: 10 }} />
                                                <label for="percent" style={{ fontSize: 13, marginLeft: 5 }}>By Percentage</label>
                                            </form>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Recruitment"
                                                style={{ border: '1PX #7C7B7B solid' }}
                                                id="rate_recruitment"
                                                onChange={handleChange}
                                                value={values.rate_recruitment === 0 ? '' : values.rate_recruitment}
                                            />
                                        </div>
                                    </div>
                                    </div>
                                    {values.rate_format === 'value' && <div style={{ marginTop: -10 }}>
                                        <div className="form-group d-flex justify-content-flex-start align-items-center">
                                            <input
                                                type="number"
                                                className="form-control col-lg-6 col-md-6 col-sm-12"
                                                placeholder="Recruitment"
                                                style={{ border: '1PX #7C7B7B solid', marginLeft: 10 }}
                                                id="rate_recruitment"
                                                onChange={handleChange}
                                                value={values.rate_recruitment === 0 ? '' : values.rate_recruitment}
                                            />
                                            <span style={{ marginLeft: 10 }}>Rs. per Candidate</span>
                                        </div>
                                    </div>}
                                    {(values.rate_format === 'percent' || values.percentage_recruitment !== 0) && <div style={{ marginTop: -10 }}>
                                        <div className="form-group d-flex justify-content-flex-start align-items-center">
                                            <input
                                                type="number"
                                                className="form-control col-lg-6 col-md-6 col-sm-12"
                                                placeholder="Recruitment"
                                                style={{ border: '1PX #7C7B7B solid', marginLeft: 10 }}
                                                id="percentage_recruitment"
                                                onChange={handleChange}
                                                value={values.percentage_recruitment === 0 ? '' : values.percentage_recruitment}
                                            />
                                            <span style={{ marginLeft: 10 }}>% of Candidate CTC</span>
                                        </div>
                                    </div>}
                                </>} */}
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ marginTop: 15 }}
                    onClick={handleSubmit}
                  >
                    {selectedOrganizationPayload?.id ? "Save" : "Add"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ marginTop: 15, marginLeft: 5 }}
                    onClick={() => setAddOrganizationView(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddNewOrganization = (props) => {
  const { setAddOrganizationView, selectedOrganizationPayload } = props;

  const [addOrganization] = useMutation(ADD_ORGANIZATION, {
    onCompleted: (data) => {
      const { createOrganization } = data || {};
      const { status, error } = createOrganization;
      console.log({ status, error });
      if (status) {
        setAddOrganizationView(false);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    onCompleted: (data) => {
      const { updateOrganization } = data || {};
      const { status, error } = updateOrganization;
      console.log({ status, error });
      if (status) {
        setAddOrganizationView(false);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const AddNewOrganizationWrapped = withFormik({
    mapPropsToValues: (props) => {
      const { selectedOrganizationPayload } = props;

      if (selectedOrganizationPayload?.profile) {
        console.log(JSON.parse(selectedOrganizationPayload.profile));
      }
      // const valueObject = {
      //     ...selectedOrganizationPayload,
      //     locationId: selectedOrganizationPayload.location?.id || null,
      //     stateId: selectedOrganizationPayload.state?.id || null,
      //     countryId: selectedOrganizationPayload.country?.id || null,
      //     cityId: selectedOrganizationPayload.city?.id || null,

      // }

      // const { __typename, city, country, state, location, industry, ...filteredValueObject } = valueObject
      // console.log('>>>>>>>>>>>>>>values', filteredValueObject);

      // return filteredValueObject;
      return {
        name: selectedOrganizationPayload?.name || "",
        description: selectedOrganizationPayload?.description || "",
        website: selectedOrganizationPayload?.website || "",
        address: selectedOrganizationPayload?.address || "",
        locationId: selectedOrganizationPayload.location?.id || "",
        stateId: selectedOrganizationPayload.state?.id || "",
        countryId: selectedOrganizationPayload.country?.id || "",
        cityId: selectedOrganizationPayload.city?.id || "",
        pincode: selectedOrganizationPayload?.pincode || "",
        industryId: selectedOrganizationPayload?.industry?.id || "",
        organizationType: selectedOrganizationPayload?.organizationType || "",
        lob: selectedOrganizationPayload?.lob || "",
        billing_address: selectedOrganizationPayload?.billing_address || "",
        shipping_address: selectedOrganizationPayload?.shipping_address || "",
        employees: selectedOrganizationPayload?.employees || 0,
        gst_number: selectedOrganizationPayload?.gst_number || "",
        rate_recruitment: selectedOrganizationPayload?.rate_recruitment || 0,
        rate_placement: selectedOrganizationPayload?.rate_placement || 0,
        rate_mobilization: selectedOrganizationPayload?.rate_mobilization || 0,
        percentage_recruitment:
          selectedOrganizationPayload?.percentage_recruitment || 0,
        profile: selectedOrganizationPayload.profile
          ? JSON.parse(selectedOrganizationPayload.profile)
          : {},
        rate_format:
          selectedOrganizationPayload &&
          selectedOrganizationPayload?.rate_recruitment !== 0
            ? "value"
            : "",
      };
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please enter organization name"),
      organizationType: Yup.number().required(
        "Please select an organization type"
      ),
      countryId: Yup.string().required("Please select country"),
      stateId: Yup.string().required("Please select state"),
      cityId: Yup.string().required("Please select city"),
      locationId: Yup.string().required("Please select location"),
    }),
    enableReinitialize: true,
    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);

      const { selectedOrganizationPayload } = props;
      delete values["rate_format"];

      if (selectedOrganizationPayload?.id) {
        updateOrganization({
          variables: {
            payload: {
              ...values,
              id: selectedOrganizationPayload?.id,
              profile: JSON.stringify(values.profile),
              name: values.name.trim()
            },
          },
        });
      } else {
        addOrganization({
          variables: {
            payload: {
              ...values,
              profile: JSON.stringify(values.profile),
              name: values.name.trim()
            },
          },
        });
      }
    },
  })(AddNewOrganizationForm);

  return (
    <AddNewOrganizationWrapped
      selectedOrganizationPayload={selectedOrganizationPayload}
      setAddOrganizationView={setAddOrganizationView}
    ></AddNewOrganizationWrapped>
  );
};

export default AddNewOrganization;
