import React, { useEffect } from "react";
import { Grid, Typography, TextField, Button } from "@mui/material";
import ciel_logo from "../../assets/imagesNew/ciel_logo_colored.png";
import login_img from "../../assets/imagesNew/login-img.svg";
import colors from "../../styles/colors";

import { useSnackbar } from "notistack";
import { withFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD_REQUEST } from "../../graphql/users";
import getStore from "../../zustandStore";

const ForgotPasswordForm = (props) => {
  const {
    setForgotPasswordView,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;

  return (
    <Grid container spacing={2} sx={{ p: { xs: 3, sm: 10 } }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <img src={ciel_logo} alt="logo" />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
        sx={{ my: 2 }}
      >
        <img src={login_img} alt="login" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: 2.5 }}>
        <Typography fontSize="26px" fontWeight={500} color={colors.dark}>
          Forgot Password
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography>Email Address</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
        <TextField
          id="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
        />
        {touched.email && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.email}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ mt: 3.5, display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{ width: "50%" }}
          onClick={handleSubmit}
        >
          Send link
        </Button>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
        <Typography fontSize="14px" color="primary" textAlign="center">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setForgotPasswordView(false)}
          >
            Back to login
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};

const ForgotPasswordFormik = (props) => {
  const { setForgotPasswordView } = props;

  const setLoading = getStore((state) => state.setLoading);
  const { enqueueSnackbar } = useSnackbar();

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD_REQUEST, {
    onCompleted: (data) => {
      const { resetPasswordRequest } = data;
      const { status, error } = resetPasswordRequest;
      if (status) {
        setForgotPasswordView(false);
        enqueueSnackbar(error, {
          variant: "success",
          sx: {
            "& .SnackbarContent-root": { backgroundColor: colors.success },
          },
        });
      } else {
        enqueueSnackbar(error, {
          variant: "error",
          sx: { "& .SnackbarContent-root": { backgroundColor: colors.danger } },
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const ForgotPasswordWrapped = withFormik({
    mapPropsToValues: () => ({
      email: "",
    }),

    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("Please enter your email address")
        .email("Please enter a valid email address"),
    }),

    handleSubmit(values, { props }) {
      resetPassword({
        variables: {
          payload: {
            ...values,
          },
        },
      });
    },
  })(ForgotPasswordForm);

  return <ForgotPasswordWrapped {...props} />;
};

export default ForgotPasswordFormik;
