import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import colors from "../styles/colors";

const StackedColumPlusLineChart = (props) => {
  const { xAxisLabels, data, partColors } = props;
  const options = {
    chart: {
      // height: 341,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: xAxisLabels,
    },
    yAxis: { min: 0, title: { text: "" } },
    plotOptions: {
      column: {
        stacking: "normal",
        pointWidth: 20,
      },
    },
    tooltip: {
      enabled: false,
    },
    colors: partColors,
    series: data,
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default StackedColumPlusLineChart;
