import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import FilterDrawer from "./FilterDrawer";
import NoCandidatesView from "./NoCandidatesView";
import NoResultView from "../EmployerSearchCandidate/NoResultView";
import EmployerNavbar from "../../common/EmployerNavBar";
import NoBorderDropdown from "../../common/NoBorderDropdown";
import ResultCard from "../../common/CandidateResultCard";
import Pagination from "../../common/PaginationCommon";
import { sortByArr } from "../../constants";
import colors from "../../styles/colors";

import { useQuery, useMutation } from "@apollo/client";
import {
  GET_JOB_DETAILS,
  GET_RECOMMENDED_CANDIDATES,
} from "../../graphql/EmployerJobs";
import { ADD_CANDIDATE_TO_JOB } from "../../graphql/jobs";
import getStore from "../../zustandStore";
import { useSnackbar } from "notistack";

function RecommendedCandidates(props) {
  const setLoading = getStore((state) => state.setLoading);
  const setSelectedJob = getStore((state) => state.setSelectedJob);
  const selectedJob = getStore((state) => state.selectedJob);
  const uriParams = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [sortBy, setSortBy] = useState("latest");
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [results, setResults] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({});

  const { data: job, loading: jobTitleLoading } = useQuery(GET_JOB_DETAILS, {
    variables: {
      id: uriParams.jobId,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const {
    data: recommendedCandidates,
    loading,
    refetch,
  } = useQuery(GET_RECOMMENDED_CANDIDATES, {
    variables: {
      jobId: uriParams.jobId,
      sort_by: sortBy === "latest" ? "-created_date" : "created_date",
      page: page + 1,
      limit: pageSize,
      filter: filters,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [addJobCandidate, { loading: adding }] = useMutation(
    ADD_CANDIDATE_TO_JOB,
    {
      onCompleted: (data) => {
        const { createJobCandidate } = data || {};
        const { status, error } = createJobCandidate;
        if (status) {
          enqueueSnackbar(error, { variant: "success" });
          refetch();
        } else {
          enqueueSnackbar(error, { variant: "error" });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (job && job.job) {
      setSelectedJob({ id: job.job.id, title: job.job.title });
    }
  }, [job, setSelectedJob]);

  useEffect(() => {
    if (
      recommendedCandidates &&
      recommendedCandidates.getAutoRecommendedCandidates &&
      recommendedCandidates.getAutoRecommendedCandidates.users
    ) {
      setCount(recommendedCandidates.getAutoRecommendedCandidates.count);
      const temp = recommendedCandidates.getAutoRecommendedCandidates.users.map(
        (item) => ({
          id: item.id,
          firstName: item.firstName,
          lastName: item.lastName,
          city: item.city.name,
          qualification: item.qualification.name,
          experience: item.experience,
          skills: item.skills,
          createdDate: item.createdDate,
          score: item.score,
          jobStatus: item.jobStatus,
        })
      );
      setResults(temp);
    } else {
      setResults([]);
    }
  }, [recommendedCandidates]);

  useEffect(() => {
    setLoading(jobTitleLoading || loading || adding);
  }, [adding, jobTitleLoading, loading, setLoading]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={8} md={8} sm={7} xs={12}>
        <Typography fontSize="24px" color={colors.dark} fontWeight={500}>
          Recommended Candidates {count ? `(${count})` : ""}
        </Typography>
      </Grid>
      <Grid item lg={4} md={4} sm={5} xs={12}>
        <Grid
          container
          spacing={1}
          justifyContent={{ xs: "flex-start", sm: "flex-end" }}
          alignItems="center"
        >
          <Grid item>
            <Typography fontSize="14px">Sort by :</Typography>
          </Grid>
          <Grid item>
            <NoBorderDropdown
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              bgColor={colors.secondary}
              textColor={colors.gray}
              fontSize="14px"
              dropdownArr={sortByArr}
            />
          </Grid>
          <Grid item>
            <Button size="small" onClick={() => setFilterDrawer(true)}>
              Filter
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {loading ? (
        <Grid container spacing={2} sx={{ mt: 5 }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography fontSize="26px" fontWeight={700} textAlign="center">
              Fetching...
            </Typography>
          </Grid>
        </Grid>
      ) : count > 0 ? (
        <>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {results &&
              results.map((res, index) => (
                <div key={res.id} style={{ marginTop: index === 0 ? 0 : 15 }}>
                  <ResultCard
                    masked={false}
                    fullWidth
                    recommended
                    candidateData={res}
                    addBtnAction={() => {
                      addJobCandidate({
                        variables: {
                          payload: {
                            jobId: uriParams?.jobId,
                            candidateId: res.id,
                            status: 4,
                          },
                        },
                      });
                    }}
                    avatarClickAction={() => {
                      history.push(`/candidate-details/${res.id}`);
                    }}
                  />
                </div>
              ))}
            <Pagination
              page={page}
              pageSize={pageSize}
              rowCount={count}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          </Grid>
        </>
      ) : Object.keys(filters)?.length > 0 ? (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <NoResultView />
        </Grid>
      ) : (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <NoCandidatesView />
        </Grid>
      )}
      <FilterDrawer
        open={filterDrawer}
        setOpen={setFilterDrawer}
        filters={filters}
        setFilters={setFilters}
        jobTitle={selectedJob.title || ""}
      />
    </Grid>
  );
}

const EmployerJobRecommendedCandidates = (props) => {
  return <EmployerNavbar mainComponent={<RecommendedCandidates />} />;
};

export default EmployerJobRecommendedCandidates;
