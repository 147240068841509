import React, { useEffect, useMemo, useState } from "react";
import { Grid, Avatar, Typography } from "@mui/material";
import Filters from "./Filters";
import FooterActions from "./FooterActions";
import EmployerNavbar from "../../common/EmployerNavBar";
import Table from "../../common/Table";
import NoBorderDropdown from "../../common/NoBorderDropdown";
import ScreeningQuestionsModal from "../../common/ScreeningQuestionsModal";
import { getDateInFormat, loadScreeningAnswers } from "../../helpers";
import { statuses } from "../../constants";
import help_icon from "../../assets/imagesNew/help-icon.svg";
import colors from "../../styles/colors";

import {
  GET_MINIMAL_JOB_CANDIDATES,
  GET_JOBS_MINIMAL,
  UPDATE_JOB_CANDIDATE,
} from "../../graphql/jobs";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { GET_RESUME } from "../../graphql/users";
import { downloadAny } from "../../utils";
import getStore from "../../zustandStore";
import { useHistory } from "react-router-dom";

function AppliedCandidates(props) {
  const setLoading = getStore((state) => state.setLoading);
  const history = useHistory();

  // const [candidateDetailView, setCandidateDetailView] = useState(false);
  // const [selectedCandidateData, setSelectedCandidateData] = useState({});
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [screeningQuestionsModal, setScreeningQuestionsModal] = useState(false);
  const [
    screeningQuestionsSelectedCandidate,
    setScreeningQuestionsSelectedCandidate,
  ] = useState("");
  const [screeningQuestionsResponses, setScreeningQuestionsResponses] =
    useState([]);
  // const [page, setPage] = useState(0);
  // const [pageSize, setPageSize] = useState(0);
  const [candidates, setCandidates] = useState([]);
  // const [candidateCount, setCandidateCount] = useState(0);
  const [sortBy, setSortBy] = useState("latest");
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({});
  const [jobs, setJobs] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(0);

  const {
    data: jobCandidates,
    refetch: refetchJobCandidates,
    loading: loadingJobCandidate,
  } = useQuery(GET_MINIMAL_JOB_CANDIDATES, {
    variables: {
      page: 1,
      limit: 0,
      filter: filters,
      recent_applicants: false,
      sorted: sortBy === "latest" ? "-created_date" : "created_date",
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: jobList } = useQuery(GET_JOBS_MINIMAL, {
    variables: {
      filter: {},
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [updateStatus, { loading: updatingStatus }] = useMutation(
    UPDATE_JOB_CANDIDATE,
    {
      onCompleted: (data) => {
        const { updateJobCandidate } = data;
        const { status } = updateJobCandidate;
        if (status) {
          setSelectedCandidates([]);
          setSelectedStatus(0);
          refetchJobCandidates();
        }
      },
    }
  );

  const [getResume, { downloadingResume, data }] = useLazyQuery(GET_RESUME, {
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only",
  });

  const downloadResume = async (userId) => {
    let temp = await getResume({
      variables: {
        userId: userId,
      },
    });
    let data = temp.data.getResume;
    downloadAny(data.file, data.fileName, data.fileType);
  };

  useEffect(() => {
    if (jobCandidates?.jobCandidates?.jobcandidates?.length > 0) {
      setCandidates(jobCandidates.jobCandidates.jobcandidates);
    } else {
      setCandidates([]);
    }
  }, [jobCandidates]);

  useEffect(() => {
    if (jobList?.jobs?.jobs) {
      setJobs(jobList.jobs.jobs);
    }
  }, [jobList]);

  useEffect(() => {
    setLoading(loadingJobCandidate);
  }, [loadingJobCandidate, setLoading]);

  useEffect(() => {
    setLoading(updatingStatus);
  }, [updatingStatus, setLoading]);

  const columns = useMemo(
    () => [
      {
        field: "profile",
        headerName: "Profile",
        minWidth: 190,
        flex: 1,
        renderCell: (params) => (
          <Grid
            container
            spacing={0.25}
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={() =>
              history.push(`/candidate-details/${params.row.candidate.id}`)
            }
          >
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Avatar
                sx={{
                  mr: 1,
                  backgroundColor: colors.primaryFill,
                  color: colors.primary,
                  border: `1px solid ${colors.primary}`,
                }}
              >
                {params.row.candidate.firstName.charAt(0)}
              </Avatar>
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Typography fontSize="12px">
                {params.row.candidate.firstName}{" "}
                {params.row.candidate.lastName || ""}
              </Typography>
              {/* <Typography
                color={colors.primary}
                fontSize="12px"
                fontWeight={700}
              >
                92%
              </Typography> */}
            </Grid>
          </Grid>
        ),
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Typography fontSize="12px" noWrap>
            {params.row.candidate.email || "-"}
          </Typography>
        ),
      },
      {
        field: "mobile",
        headerName: "Phone Number",
        minWidth: 160,
        flex: 1,
        renderCell: (params) => (
          <Typography fontSize="12px">
            {params.row.candidate.mobile
              ? `+91 ${params.row.candidate.mobile}`
              : "-"}
          </Typography>
        ),
      },
      {
        field: "skills",
        headerName: "Skills",
        minWidth: 190,
        flex: 1,
        renderCell: (params) => (
          <Typography fontSize="12px" noWrap>
            {params.row.candidate?.skills || "-"}
          </Typography>
        ),
      },
      {
        field: "title",
        headerName: "Job Title",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
          <Typography fontSize="12px" noWrap>
            {params.row.job?.title || "-"}
          </Typography>
        ),
      },
      {
        field: "appliedDate",
        headerName: "Applied Date",
        minWidth: 140,
        flex: 1,
        renderCell: (params) => (
          <Typography fontSize="12px">
            {getDateInFormat(params.row.createdDate, "DD MMM YYYY")}
          </Typography>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 120,
        flex: 1,
        renderCell: (params) => (
          <NoBorderDropdown
            value={params.row.status}
            onChange={(e) =>
              updateStatus({
                variables: {
                  payload: {
                    id: [params.row.id],
                    status: parseInt(e.target.value),
                  },
                },
              })
            }
            bgColor={colors.secondary}
            textColor={colors.gray}
            dropdownArr={statuses}
          />
        ),
      },
      {
        field: "actions",
        headerName: "Candidate Responses",
        minWidth: 150,
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <img
            src={help_icon}
            alt="help"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setScreeningQuestionsSelectedCandidate(
                params.row.candidate.firstName +
                  params.row.candidate.lastName || ""
              );
              loadScreeningAnswers(
                params.row.screeningAnswers,
                setScreeningQuestionsResponses
              );
              setScreeningQuestionsModal(true);
            }}
          />
        ),
      },
      {
        field: "resume",
        headerName: "Resume",
        minWidth: 30,
        flex: 1,
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderCell: (params) => (
          <button
            type="button"
            className="btn btn-icon"
            title="Resume"
            disabled={!params.row.candidate.resume}
            onClick={() => {
              downloadResume(params.row.candidate.id);
            }}
          >
            <i className="fa fa-download" />
          </button>
        ),
      },
    ],
    [updateStatus]
  );
  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="24px" color={colors.dark} fontWeight={500}>
            Applied Candidates
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <Filters
            jobs={jobs}
            sortBy={sortBy}
            setSortBy={setSortBy}
            search={search}
            setSearch={setSearch}
            filters={filters}
            setFilters={setFilters}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Table
            rows={candidates}
            columns={columns}
            checkboxSelection
            selectedRows={selectedCandidates}
            setSelectedRows={setSelectedCandidates}
          />
        </Grid>
        <ScreeningQuestionsModal
          open={screeningQuestionsModal}
          setOpen={setScreeningQuestionsModal}
          data={screeningQuestionsResponses}
          name={screeningQuestionsSelectedCandidate}
          setData={setScreeningQuestionsResponses}
        />
      </Grid>
      {/* <CandidateDetail
        open={candidateDetailView}
        setOpen={setCandidateDetailView}
        selectedCandidateData={selectedCandidateData}
      /> */}
      {selectedCandidates && selectedCandidates.length >= 1 && (
        <FooterActions
          selectedCandidates={selectedCandidates}
          updateStatus={updateStatus}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
      )}
    </>
  );
}

const EmployerAppliedCandidates = (props) => {
  return <EmployerNavbar mainComponent={<AppliedCandidates />} />;
};

export default EmployerAppliedCandidates;
