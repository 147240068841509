import React from "react";
import { Box, Grid, Typography, Chip } from "@mui/material";
import quotes from "../../assets/imagesNew/quotes.svg";
// import testimonial from "../../assets/imagesNew/testimonial.svg";
import colors from "../../styles/colors";

const chips = [
  "Agriculture",
  "Banking",
  "E-commerce",
  "EdTech",
  "Energy",
  "Financial Services",
  "FMCG",
  "FMCD",
  "Healthcare",
  "Infrastructure",
  "IT Services",
  "IT Products",
  "Start-Ups",
  "BPM/ KPO",
  "Manufacturing & Engineering",
  "Pharma",
  "Retail",
  "Services",
];

const Testimonials = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: colors.purple3,
        px: { xs: 3, sm: 10 },
        py: { xs: 5, sm: 7 },
      }}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography color="secondary" fontSize="54px" fontWeight={700}>
            IT Services
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} sm={8} xs={12}>
          <img src={quotes} alt="quotes" />
          <Typography
            color={`${colors.secondary}70`}
            fontSize="30px"
            fontStyle="italic"
          >
            I was looking for a Front Office Executive for our company. CIEL
            Jobs made the selection process way easier and helped us pick the
            right person quickly and save our valuable time
          </Typography>
        </Grid>
        {/* <Grid
          item
          lg={4}
          md={4}
          sm={4}
          xs={4}
          display="flex"
          justifyContent="flex-end"
        >
          <div>
            <img src={testimonial} alt="testimonial" />
            <Typography
              color="secondary"
              fontSize="20px"
              fontStyle="italic"
              sx={{ mt: 1 }}
            >
              Shyam Sundar R<br />
              HR Manager @ Tulips
            </Typography>
          </div>
        </Grid> */}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {chips &&
            chips.map((item) => (
              <Chip
                key={item}
                label={item}
                variant="outlined"
                color="secondary"
                sx={{ mr: 2, mt: 2 }}
              />
            ))}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            color="secondary"
            fontSize="18px"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
          >
            View all Testimonials
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Testimonials;
