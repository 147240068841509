import React from "react";
import { Chip } from "@mui/material";
import colors from "../styles/colors";

const Chips = (props) => {
  return (
    <Chip
      sx={{ backgroundColor: colors.primaryLight, color: colors.primary }}
      {...props}
    />
  );
};

export default Chips;
