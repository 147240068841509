import React from "react";
import Upload from "rc-upload";
import { Typography, Box, IconButton } from "@mui/material";
import { CloudUploadOutlined, ModeEditOutline } from "@mui/icons-material";
import { getMIMETypeOfFile, acceptImageTypes } from "../helpers";
import colors from "../styles/colors";

const UploadWrapper = (props) => {
  const { handleUpload, newUploadedFile, alreadyUploadedFile } = props;
  return (
    <>
      {!newUploadedFile && !alreadyUploadedFile && (
        <Upload
          openFileDialogOnClick
          beforeUpload={handleUpload}
          accept={acceptImageTypes}
        >
          <Box
            component="div"
            sx={{
              height: 85,
              border: `3px dotted ${colors.gray}30`,
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {}}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CloudUploadOutlined
                  sx={{ color: colors.fontLight, alignSelf: "center" }}
                />
              </Box>
              <Typography
                fontSize="12px"
                color={colors.fontLight}
                textAlign="center"
              >
                Upload
              </Typography>
            </Box>
          </Box>
        </Upload>
      )}
      {alreadyUploadedFile && !newUploadedFile && (
        <Box sx={{ position: "relative" }}>
          <img
            src={`data:image/${getMIMETypeOfFile(
              alreadyUploadedFile.type
            )};base64,${alreadyUploadedFile.base64}`}
            alt="logo"
            style={{ maxHeight: 90 }}
          />
          <Upload
            openFileDialogOnClick
            beforeUpload={handleUpload}
            accept={acceptImageTypes}
          >
            <IconButton
              size="small"
              sx={{
                backgroundColor: colors.secondary,
                border: `2px solid ${colors.strokeGray}`,
                position: "absolute",
                top: { xs: "96%", sm: "98%" },
                left: { xs: "96%", sm: "98%" },
              }}
            >
              <ModeEditOutline color="primary" fontSize="small" />
            </IconButton>
          </Upload>
        </Box>
      )}
      {newUploadedFile && (
        <Box sx={{ position: "relative" }}>
          <img
            src={URL.createObjectURL(newUploadedFile)}
            alt="temp logo"
            style={{ maxHeight: 90 }}
          />
          <Upload
            openFileDialogOnClick
            beforeUpload={handleUpload}
            accept={acceptImageTypes}
          >
            <IconButton
              size="small"
              sx={{
                backgroundColor: colors.secondary,
                border: `2px solid ${colors.strokeGray}`,
                position: "absolute",
                top: -10,
                left: { xs: "96%", sm: "98%" },
              }}
            >
              <ModeEditOutline color="primary" fontSize="small" />
            </IconButton>
          </Upload>
        </Box>
      )}
    </>
  );
};

export default UploadWrapper;
