import React from "react";
import { isJwtTokenValid } from "../../utils";
import { Redirect } from "react-router-dom";

const Header = (props) => {
  const { dataFromSubParent } = props;
  const { roles, username, hasExpired } = isJwtTokenValid();
  console.log({ roles, username });
  return (
    <>
      {hasExpired ? (
        <Redirect to="/login" />
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: 20,
              marginRight: 30,
            }}
          >
            <h1 className="page-title">{dataFromSubParent}</h1>
            <span style={{ color: "black" }}>
              Hi,&nbsp;<span style={{ color: "purple" }}>{username}</span>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: 30,
              marginTop: -20,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            {/* <span style={{ color: '#A8A8A8' }}>Role: &nbsp;</span> */}
            {roles[0] === "mcc_ho_user"
              ? "MCC HO User"
              : roles[0] === "mcc_state_head"
              ? "MCC State Head"
              : roles[0] === "mcc_cluster_manager"
              ? "MCC Cluster Manager"
              : roles[0] === "mcc_coordinator"
              ? "MCC User"
              : roles[0] === "pia_user"
              ? "PIA User"
              : "Employer User"}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
