import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation loginUser($username: String!, $password: String!) {
    loginUser(payload: { username: $username, password: $password }) {
      token
      status
      me {
        id
        firstName
        lastName
        organization {
          id
          name
        }
        cluster {
          id
          name
        }
      }
    }
  }
`;

export const RESET_PASSWORD_REQUEST = gql`
  mutation resetPasswordRequest($payload: ResetPasswordRequest!) {
    resetPasswordRequest(payload: $payload) {
      status
      error
    }
  }
`;

export const GET_USERS = gql`
  query users(
    $filter: UserFilterInput
    $limit: Int
    $page: Int
    $is_candidate: Boolean
    $sorted: String
  ) {
    users(
      filter: $filter
      limit: $limit
      page: $page
      is_candidate: $is_candidate
      sorted: $sorted
    ) {
      count
      users {
        username
        id
        firstName
        lastName
        mobile
        dob
        location {
          id
          name
        }
        city {
          id
          name
        }
        state {
          id
          name
        }
        country {
          id
          name
        }
        qualification {
          id
          name
        }
        gender
        jobStatus
        experience
        email
        address
        organizationType
        source
        forSource
        scheme
        aadhar
        pincode
        profile
        roles
        skills
        organization {
          id
          name
        }
        stage {
          id
          event {
            id
            name
          }
          eventDate
          comments
        }
        centre {
          id
          name
        }
        cluster {
          id
          name
        }
        resume
        createdDate
      }
    }
  }
`;

export const GET_USER = gql`
  query user($id: String!) {
    user(id: $id) {
      username
      id
      firstName
      lastName
      mobile
      dob
      location {
        id
        name
      }
      city {
        id
        name
      }
      state {
        id
        name
      }
      country {
        id
        name
      }
      qualification {
        id
        name
      }
      gender
      experience
      email
      address
      organizationType
      source
      scheme
      aadhar
      pincode
      profile
      roles
      skills
      centre {
        id
        name
      }
      jobType {
        id
        jobType {
          id
          name
        }
      }
      organization {
        id
        name
      }
      forOrganization {
        id
        name
      }
      stage {
        id
        event {
          id
          name
        }
        eventDate
        comments
      }
      jobStatus
      centre {
        id
        name
      }
      cluster {
        id
        name
      }
      resume
      resumeName
      isAccountManager
    }
  }
`;

export const SET_PASSWORD = gql`
  mutation setPassword($id: String!, $token: String!, $password: String!) {
    setPassword(payload: { token: $token, id: $id, password: $password }) {
      status
      error
    }
  }
`;

export const ADD_USER = gql`
  mutation createUser($payload: UserCreateInput!) {
    createUser(payload: $payload) {
      status
      error
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($payload: UserUpdateInput!) {
    updateUser(payload: $payload) {
      status
      error
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($ids: [String!]) {
    deleteUser(ids: $ids) {
      status
      error
    }
  }
`;

export const ARCHIVE_USER = gql`
  mutation archiveUser($ids: [String!]) {
    archiveUser(ids: $ids) {
      status
      error
    }
  }
`;

export const UNARCHIVE_USER = gql`
  mutation unarchiveUser($ids: [String!]) {
    unarchiveUser(ids: $ids) {
      status
      error
    }
  }
`;

export const GET_USER_TIMELINE = gql`
  query getUserTimeline($id: String!, $limit: Int, $page: Int) {
    userTimeline(id: $id, limit: $limit, page: $page) {
      count
      eventstimeline {
        id
        event {
          id
          name
        }
        eventDate
        comments
      }
    }
  }
`;

export const ADD_EVENT = gql`
  mutation addEvent($payload: TimelineCreateInput!) {
    createTimeline(payload: $payload) {
      status
      error
    }
  }
`;

export const IMPORT_CANDIDATES = gql`
  mutation uploadFile($file: Upload!) {
    bulkCreateCandidates(payload: $file) {
      status
      error
    }
  }
`;

export const DOWNLOAD_TEMPLATE = gql`
  query DataTemplate {
    dataTemplate {
      template
    }
  }
`;
export const CANCEL_MOBILISATION = gql`
  mutation cancelMobilization($payload: [String!], $reason: String!) {
    cancelMobilization(payload: $payload, reason: $reason) {
      status
      error
    }
  }
`;

export const QR_ADD_APPLICANT = gql`
  mutation createApplicant($payload: ApplicantCandidateInput!) {
    createApplicantCandidate(payload: $payload) {
      status
      error
      user {
        username
      }
    }
  }
`;

export const GET_QR_APPLICANTS = gql`
  query getApplicants(
    $filter: ApplicantCandidateFilterInput
    $limit: Int
    $page: Int
  ) {
    getApplicantCandidate(filter: $filter, limit: $limit, page: $page) {
      count
      users {
        username
        id
        firstName
        lastName
        mobile
        dob
        location {
          id
          name
        }
        city {
          id
          name
        }
        state {
          id
          name
        }
        country {
          id
          name
        }
        qualification {
          id
          name
        }
        gender
        profile

        centre {
          id
          name
        }
        resume
      }
    }
  }
`;
export const APPROVE_CANDIDATE = gql`
  mutation updateStatusApplicant($payload: ApplicantStatusUpdateInput!) {
    updateStatusApplicant(payload: $payload) {
      status
      error
    }
  }
`;
export const GET_RESUME = gql`
  query getResume($userId: String!) {
    getResume(userId: $userId) {
      file
      fileType
      fileName
    }
  }
`;
export const GET_APPLICANT_RESUME = gql`
  query getApplicantResume($userId: String!) {
    getApplicantResume(userId: $userId) {
      file
      fileType
      fileName
    }
  }
`;

export const SIGNUP_USER = gql`
  mutation registerUser($payload: Registration!) {
    registerUser(payload: $payload) {
      status
      error
    }
  }
`;

export const GET_SCREENING_QUESTIONS_JOB_TYPE_ID = gql`
  query getScreeningQuestions($id: String!) {
    jobTypeScreeningQuestions(job_type: $id) {
      id
      question
      answerType
    }
  }
`;
