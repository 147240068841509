import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  DOWNLOAD_ACTIVITY_REPORT,
  LAST_LOGIN_REPORT,
} from "../../graphql/activereport";
import { downloadExcel } from "../../utils";
import moment from "moment";
import { isJwtTokenValid } from "../../utils";
import ActiveReportTable from "./ActiveReportTable";

const ActiveReport = () => {
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "days").format("YYYY-MM-DD").concat(" 00:00")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD HH:mm"));
  const { roles, hasExpired } = isJwtTokenValid();

  const { data: activeReportTableData,loading:loadingActiveReport} = useQuery(LAST_LOGIN_REPORT, {
    variables: {
      filter: {
        lastLogin__gte: moment(startDate).format("YYYY-MM-DD").concat(" 00:00"),
        lastLogin__lte:
          endDate === moment().format("YYYY-MM-DD")
            ? moment(endDate).format("YYYY-MM-DD HH:mm")
            : moment(endDate).format("YYYY-MM-DD").concat(" 23:59"),
      },
      page: 3,
      //limit: For fetching all users.
    },
    // skip: roles[0] === "employer_user",
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  //Download Excel
  const { data: downloadActiveReport } = useQuery(DOWNLOAD_ACTIVITY_REPORT, {
    variables: {
      filter: {
        lastLogin__gte: moment(startDate).format("YYYY-MM-DD").concat(" 00:00"),
        lastLogin__lte:
          endDate === moment().format("YYYY-MM-DD")
            ? moment(endDate).format("YYYY-MM-DD HH:mm")
            : moment(endDate).format("YYYY-MM-DD").concat(" 23:59"),
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    },
  });

  return (
    <>
      {hasExpired && roles[0] !== "mcc_ho_user" ? (
        <Redirect to="/login" />
      ) : (
        <div>
          <div className="container-fluid">
            <div
              className="float-right d-flex justify-content-between align-items-center mr-20"
              style={{ marginBottom: 10 }}
            >
              <span
                style={{
                  color: "#A8A8A8",
                  cursor: "default",
                  marginRight: 5,
                  fontSize: 14,
                }}
              >
                Start Date
              </span>
              <input
                type="date"
                className="form-control"
                placeholder="Date of Birth"
                style={{
                  border: "1PX #7C7B7B solid",
                  marginRight: 10,
                  width: 170,
                }}
                // id="dob"
                onChange={(e) => setStartDate(e.target.value)}
                value={moment(startDate).format("YYYY-MM-DD")}
                // onBlur={handleBlur}
              />
              <span
                style={{
                  color: "#A8A8A8",
                  cursor: "default",
                  marginRight: 5,
                  fontSize: 14,
                }}
              >
                End Date
              </span>
              <input
                type="date"
                className="form-control"
                placeholder="Date of Birth"
                style={{
                  border: "1PX #7C7B7B solid",
                  marginRight: 10,

                  width: 170,
                }}
                // id="dob"
                onChange={(e) => setEndDate(e.target.value)}
                value={moment(endDate).format("YYYY-MM-DD")}
                // onBlur={handleBlur}
              />
              <button
                className="btn btn-primary"
                onClick={() => {
                  console.log(downloadActiveReport);
                  if (
                    downloadActiveReport?.getLastLoginReportAsExcel &&
                    downloadActiveReport?.getLastLoginReportAsExcel?.report
                  ) {
                    downloadExcel(
                      downloadActiveReport.getLastLoginReportAsExcel.report,
                      "ActiveReport.xlsx"
                    );
                  }
                }}
              >
                Export
              </button>
            </div>
          </div>
          <ActiveReportTable
            activeReportTableData={activeReportTableData}
            startDate={startDate}
            endDate={endDate}
            loadingActiveReport={loadingActiveReport}
          />
        </div>
      )}
    </>
  );
};
export default ActiveReport;
