import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { EmailOutlined, CallOutlined } from "@mui/icons-material";
import facebook from "../../assets/imagesNew/facebook.svg";
import twitter from "../../assets/imagesNew/twitter.svg";
import linkedIn from "../../assets/imagesNew/linkedIn.svg";
import youtube from "../../assets/imagesNew/youtube.svg";
import instagram from "../../assets/imagesNew/instagram.svg";
import colors from "../../styles/colors";

const Footer = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: colors.secondary,
        px: { xs: 3, sm: 10 },
        py: { xs: 5, sm: 7 },
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={3} md={3} sm={3} xs={12}>
          <Typography fontSize="22px" fontWeight={700} color={colors.darkGray}>
            Contact Us
          </Typography>
        </Grid>
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          xs={12}
          sx={{ display: { xs: "none", sm: "block" } }}
        />
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          xs={12}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <Typography fontSize="22px" fontWeight={700} color={colors.darkGray}>
            Legal
          </Typography>
        </Grid>
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          xs={12}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <Typography fontSize="22px" fontWeight={700} color={colors.darkGray}>
            Follow Us
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={12}>
          <Typography color={`${colors.gray}80`} fontSize="16px">
            CIEL HR Services Pvt Ltd,
            <br />
            #2802 (Broadway Building),
            <br />
            2nd &amp; 3rd Floor, 27th Main Road,
            <br />
            HSR Layout, Sector 1,
            <br />
            Bengaluru - 560102.
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <EmailOutlined
              fontSize="small"
              sx={{ color: `${colors.gray}80`, mr: 1 }}
            />
            <Typography color={`${colors.gray}80`} fontSize="16px">
              info@cielhr.com
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <CallOutlined
              fontSize="small"
              sx={{ color: `${colors.gray}80`, mr: 1 }}
            />
            <Typography color={`${colors.gray}80`} fontSize="16px">
              +91 7816 000 111
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          <Typography fontSize="22px" fontWeight={700} color={colors.darkGray}>
            Legal
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={12}>
          <div style={{ cursor: "pointer" }}>
            <Typography color={`${colors.gray}80`} fontSize="16px">
              Terms &amp; Conditions
            </Typography>
          </div>
          <div style={{ marginTop: 10, cursor: "pointer" }}>
            <Typography color={`${colors.gray}80`} fontSize="16px">
              Privacy
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          <Typography fontSize="22px" fontWeight={700} color={colors.darkGray}>
            Follow Us
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={12} display="flex">
          <img
            src={facebook}
            alt="facebook"
            height={30}
            style={{ cursor: "pointer", marginRight: 5 }}
            onClick={() => {}}
          />
          <img
            src={twitter}
            alt="twitter"
            height={30}
            style={{ cursor: "pointer", marginRight: 5 }}
          />
          <img
            src={linkedIn}
            alt="linkedIn"
            height={30}
            style={{ cursor: "pointer", marginRight: 5 }}
          />
          <img
            src={youtube}
            alt="youtube"
            height={30}
            style={{ cursor: "pointer", marginRight: 5 }}
          />
          <img
            src={instagram}
            alt="instagram"
            height={30}
            style={{ cursor: "pointer", marginRight: 5 }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 5 }}>
          <Box
            sx={{
              py: 1.5,
              borderTop: `3px solid ${colors.borderColor}`,
              borderBottom: `3px solid ${colors.borderColor}`,
            }}
          >
            <Typography
              color={`${colors.gray}80`}
              fontSize="14px"
              textAlign="center"
            >
              Copyright © 2022 CIEL Jobs. All rights reserved.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
