import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { ADD_EVENT } from "../../graphql/users";
import { useMutation } from "@apollo/client";
import { Modal } from "react-bootstrap";
import moment from "moment";

const UpdateStageModalForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleBlur,
    updateStageModal,
    setUpdateStageModal,
    stages,
  } = props;

  useEffect(() => {
    console.log({ updateStageModal, stages });
  }, [updateStageModal, stages]);

  return (
    <Modal show={updateStageModal} onHide={() => setUpdateStageModal(false)}>
      <Modal.Header>
        <Modal.Title>Bulk Mobilization</Modal.Title>
        <button
          type="button"
          class="close"
          aria-label="Close"
          onClick={() => setUpdateStageModal(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>Date</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <input
                id="eventDate"
                type="date"
                className="form-control"
                style={{ border: "1PX #7C7B7B solid" }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.eventDate}
              />
              {touched.eventDate && (
                <span style={{ color: "red", fontSize: 13 }}>
                  {errors.eventDate}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>Stage</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <select
                className="custom-select form-control"
                style={{ border: "1PX #7C7B7B solid" }}
                id="eventId"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
              >
                {stages &&
                  stages?.events &&
                  stages?.events?.map((e) => (
                    <option value={e.id} selected={e.name === 'Selected for Training' ? true : false}>{e.name}</option>
                  ))}
              </select>
              {touched.eventId && (
                <span style={{ color: "red", fontSize: 13 }}>
                  {errors.eventId}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>Comments</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <textarea
                className="form-control"
                style={{ border: "1PX #7C7B7B solid" }}
                onChange={handleChange}
                id="comments"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button 
          type="button" 
          class="btn btn-primary" 
          onClick={() => {
            if(stages && stages.events){
              stages.events.map((e) => {
                if(e.name === "Selected for Training"){
                  setFieldValue("eventId", e.id)
                }
              })
            }
            handleSubmit()
          }}
        >
          Update
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          onClick={() => setUpdateStageModal(false)}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const UpdateStageModal = (props) => {
  const {
    setUpdateStageModal,
    refetchCandidates,
    updateStageModal,
    stages,
    bulkSelectedCandidates,
  } = props;

  const [addEvent] = useMutation(ADD_EVENT, {
    onCompleted: (data) => {
      const { createTimeline } = data || {};
      const { status, error } = createTimeline;
      console.log("EVENT ADDED", status, error);
      if (status) {
        setUpdateStageModal(false);
        refetchCandidates();
        console.log("Refetching")
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  

  const UpdateStageModalWrapped = withFormik({
    mapPropsToValues: () => {
      return {
        eventDate: "",
        eventId:  stages.events.filter((e) => e.id === '2' || e.id === '18')[0].id,
        comments: "",
      };
    },

    validationSchema: Yup.object().shape({
      eventDate: Yup.date()
        .required("Please enter the activity date")
        .max(moment().format("YYYY-MM-DD")),
      // eventId: Yup.string().required("Please select a stage"),
    }),

    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);

      addEvent({
        variables: {
          payload: {
            userId: bulkSelectedCandidates,
            ...values,
          },
        },
      });
    },
  })(UpdateStageModalForm);

  return (
    <UpdateStageModalWrapped
      updateStageModal={updateStageModal}
      setUpdateStageModal={setUpdateStageModal}
      stages={stages}
    />
  );
};

export default UpdateStageModal;
