import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import otp_view from "../../assets/imagesNew/otp-view.svg";
import theme from "../../styles/theme";
import colors from "../../styles/colors";

import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { REGISTER_EMPLOYER, RESEND_OTP } from "../../graphql/SelfRegistration";
import getStore from "../../zustandStore";

const OtpForm = (props) => {
  const { setViewType, registeredUser, enteredMobileNumber, enteredValues } =
    props;

  const setLoading = getStore((state) => state.setLoading);
  const { enqueueSnackbar } = useSnackbar();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [otp, setOtp] = useState("");

  const [verifyOTP, { loading: verifying }] = useMutation(REGISTER_EMPLOYER, {
    onCompleted: (data) => {
      const { registerEmployerUser } = data;
      const { status, token, error, me } = registerEmployerUser;
      if (status) {
        setViewType("accountVerified");
        localStorage.setItem("token", token);
        localStorage.setItem("me", JSON.stringify(me));
      } else {
        enqueueSnackbar(error, {
          variant: "error",
          sx: { "& .SnackbarContent-root": { backgroundColor: colors.danger } },
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [resendOTP, { loading: resending }] = useMutation(RESEND_OTP, {
    onCompleted: (data) => {
      const { upsertRegisterUser } = data;
      const { error } = upsertRegisterUser;
      enqueueSnackbar(error, {
        variant: "success",
        sx: { "& .SnackbarContent-root": { backgroundColor: colors.success } },
      });
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const maskMobile = (mobile) => {
    let maskid = "";
    for (let i = 0; i < mobile.length; i++) {
      if (
        i === 0 ||
        i === 1 ||
        i === mobile.length - 3 ||
        i === mobile.length - 2 ||
        i === mobile.length - 1
      ) {
        maskid = maskid + mobile[i].toString();
      } else {
        maskid = maskid + "*";
      }
    }
    return maskid;
  };

  const handleChange = (otp) => setOtp(otp);

  const handleResendOtp = () => {
    resendOTP({
      variables: {
        payload: {
          mobile: enteredValues.mobile.toString(),
          role: "employer_user",
          email: enteredValues.email,
        },
      },
    });
  };

  useEffect(() => {
    if (otp && otp.length === 6) {
      let profileObj = {};
      if (enteredValues && enteredValues.designation) {
        profileObj = { designation: enteredValues.designation };
      }
      verifyOTP({
        variables: {
          payload: {
            companyName: enteredValues.companyName,
            firstName: enteredValues.firstName,
            lastName: enteredValues.lastName,
            mobile: enteredValues.mobile.toString(),
            email: enteredValues.email,
            profile: JSON.stringify(profileObj),
            watsappOptIn: enteredValues.otpInWhatsapp,
            role: "employer_user",
            otp,
          },
        },
      });
    }
  }, [enteredValues, otp, verifyOTP]);

  useEffect(() => {
    setLoading(verifying);
  }, [setLoading, verifying]);

  useEffect(() => {
    setLoading(resending);
  }, [resending, setLoading]);

  return (
    <Grid container spacing={3} sx={{ p: { xs: 3, sm: 0 } }}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
      >
        <img src={otp_view} alt="otp" width={matchesSm ? 200 : ""} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography
          fontSize="26px"
          fontWeight={500}
          color={colors.fontPrimary}
          textAlign="center"
        >
          Verification
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="16px" textAlign="center">
          {`We have sent a verification code to ${maskMobile(
            enteredMobileNumber
          )}`}
        </Typography>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
      >
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={6}
          inputStyle={{
            margin: matchesSm ? 5 : 10,
            border: "none",
            boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
            height: matchesSm ? 25 : 40,
            width: matchesSm ? 25 : 40,
            color: colors.primary,
          }}
          isInputNum
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px" textAlign="center">
          If you didn't receive a code?{" "}
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: colors.primary,
            }}
            onClick={handleResendOtp}
          >
            Resend
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OtpForm;
