import { gql } from "@apollo/client";

export const GET_JOBS = gql`
  query jobs($filter: JobFilterInput, $limit: Int, $page: Int) {
    jobs(filter: $filter, limit: $limit, page: $page) {
      count
      jobs {
        title
        id
        jobId
        description
        city {
          id
          name
        }
        state {
          id
          name
        }
        country {
          id
          name
        }
        organization {
          name
        }
        createdBy {
          id
          firstName
          lastName
        }
        positions
        AppliedCount
        ShortlistedCount
        OfferedCount
        RejectedCount
        totalApplicants
        status
        genderPreference
        skills
        status
        jobType {
          id
          name
        }
        industry {
          id
          name
        }
        qualification {
          id
          qualification {
            id
            name
          }
        }
        accountManager {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_JOBS_MINIMAL = gql`
  query jobs(
    $filter: JobFilterInput
    $limit: Int
    $page: Int
    $exclude_candidate: String
  ) {
    jobs(
      filter: $filter
      limit: $limit
      page: $page
      not_candidate: $exclude_candidate
    ) {
      count
      jobs {
        title
        id
        jobId
      }
    }
  }
`;

export const GET_JOB = gql`
  query job($id: String!) {
    job(id: $id) {
      title
      id
      description
      city {
        id
        name
      }
      state {
        id
        name
      }
      country {
        id
        name
      }
      organization {
        name
        id
      }
      locationId
      location {
        id
        name
      }
      minimumExperience
      minimumRemuneration
      maximumExperience
      maximumRemuneration
      positions
      noticePeriod
      closure
      confidential
      targetCompanies
      nopoachCompanies
      genderPreference
      status
      profile
      skills
      jobType {
        id
        name
      }
      industry {
        id
        name
      }
      qualification {
        id
        qualification {
          id
          name
        }
      }
      accountManager {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_JOB_CANDIDATES = gql`
  query jobCandidates(
    $filter: JobCandidateFilterInput
    $limit: Int
    $page: Int
    $recent_applicants: Boolean
  ) {
    jobCandidates(
      filter: $filter
      limit: $limit
      page: $page
      recent_applicants: $recent_applicants
    ) {
      count
      jobcandidates {
        id
        candidate {
          id
          firstName
          lastName
          gender
          qualification {
            name
          }
          country {
            id
            name
          }
          state {
            id
            name
          }
          city {
            id
            name
          }
          location {
            id
            name
          }
          mobile
          source
          resume
          organization {
            id
            name
            rate_placement
          }
        }
        status
        createdDate
        job {
          id
          title
          organization {
            id
            name
            rate_placement
          }
          createdBy {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_MINIMAL_JOB_CANDIDATES = gql`
  query jobCandidates(
    $filter: JobCandidateFilterInput
    $limit: Int
    $page: Int
    $recent_applicants: Boolean
    $sorted: String
  ) {
    jobCandidates(
      filter: $filter
      limit: $limit
      page: $page
      recent_applicants: $recent_applicants
      sorted: $sorted
    ) {
      count
      jobcandidates {
        id
        candidate {
          id
          firstName
          lastName
          gender
          mobile
          skills
          email
          score
          resume
        }
        status
        createdDate
        screeningAnswers {
          id
          question {
            id
            question
            answerType
            answer
            mandatory
          }
          answer
        }
        job {
          id
          title
        }
      }
    }
  }
`;

export const GET_ALL_JOB_CANDIDATES = gql`
  query getJobCandidates($search: String, $job: String, $city: String) {
    getJobCandidates(
      filter: {
        job__title__icontains: $search
        job__jobTypeId: $job
        job__cityId: $city
      }
      page: 1
      limit: 10
    ) {
      count
      jobcandidates {
        candidate {
          id
          firstName
          qualification {
            id
            name
          }
          skills
          mobile
          jobStatus
          experience
          gender
          jobType {
            id
            jobType {
              name
            }
          }
          country {
            id
            name
          }
          state {
            id
            name
          }
          city {
            id
            name
          }
          location {
            id
            name
          }
        }
        createdDate
      }
    }
  }
`;

export const GET_CANDIDATES = gql`
  query candidates(
    $jobId: String!
    $filter: CandidateFilterInput
    $limit: Int
    $page: Int
  ) {
    candidates(jobId: $jobId, filter: $filter, limit: $limit, page: $page) {
      count
      users {
        id
        firstName
        lastName
        qualification {
          id
          name
        }
        mobile
        jobStatus
        experience
        gender
        country {
          id
          name
        }
        state {
          id
          name
        }
        city {
          id
          name
        }
        location {
          id
          name
        }
      }
    }
  }
`;

export const ADD_JOB = gql`
  mutation createJob($payload: JobCreateInput!) {
    createJob(payload: $payload) {
      status
      error
    }
  }
`;

export const ADD_CANDIDATE_TO_JOB = gql`
  mutation createJobCandidate($payload: [JobCandidateCreateInput!]) {
    createJobCandidate(payload: $payload) {
      status
      error
    }
  }
`;

export const UPDATE_JOB = gql`
  mutation updateJob($payload: JobUpdateInput!) {
    updateJob(payload: $payload) {
      status
      error
    }
  }
`;

export const UPDATE_JOB_CANDIDATE = gql`
  mutation updateJobCandidate($payload: JobCandidateUpdateInput!) {
    updateJobCandidate(payload: $payload) {
      status
      error
    }
  }
`;

export const DELETE_JOB = gql`
  mutation deleteJob($ids: [String!]) {
    deleteJob(ids: $ids) {
      status
      error
    }
  }
`;

export const ARCHIVE_JOB = gql`
  mutation archiveJob($ids: [String!]) {
    archiveJob(ids: $ids) {
      status
      error
    }
  }
`;

export const UNARCHIVE_JOB = gql`
  mutation unarchiveJob($ids: [String!]) {
    unarchiveJob(ids: $ids) {
      status
      error
    }
  }
`;

export const UPDATE_REVENUE = gql`
  mutation updateRevenue($payload: [RevenueUpdateInput!]) {
    updateRevenue(payload: $payload) {
      status
      error
    }
  }
`;

export const OFFERED_STATUS_UPDATE = gql`
  mutation changeStatusOffered($payload: [StatusOfferedUpdateInput!]) {
    updateStatusToOffered(payload: $payload) {
      status
      error
    }
  }
`;

export const CANCEL_BOOKING = gql`
  mutation cancelBooking($payload: [String!], $reason: String!) {
    cancelBooking(payload: $payload, reason: $reason) {
      status
      error
    }
  }
`;

export const STAFF_BOOKING_ADD = gql`
  mutation addStaffingRevenue($payload: AddStaffingRevenue!) {
    addStaffingRevenue(payload: $payload) {
      status
      error
    }
  }
`;

export const CANCEL_REVENUE = gql`
  mutation cancelRevenue($payload: String!) {
    cancelRevenue(payload: $payload) {
      status
      error
    }
  }
`;

export const UPDATE_JOB_STATUS = gql`
  mutation updateJobStatus($payload: JobUpdateStatusInput!) {
    updateJobStatus(payload: $payload) {
      status
      error
    }
  }
`;

export const GET_ACCOUNT_MANAGERS = gql`
  query getAccountManager {
    getAccountManager {
      count
      users {
        id
        firstName
        lastName
      }
    }
  }
`;
