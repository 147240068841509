import { gql } from "@apollo/client";

export const LAST_LOGIN_REPORT=gql`
query lastloginreport($filter:LastLoginReportFilter, $limit:Int, $page:Int){
    getLastLoginReport(
        filter:$filter,
        page:$page,
        limit:$limit
    ){
        count
        report{
          id
          firstName
          lastName
          lastLogin
          email
        }
    }
}
`;

export const DOWNLOAD_ACTIVITY_REPORT=gql`
query lastloginreportexcel($filter:LastLoginReportFilter){
    getLastLoginReportAsExcel(
        filter:$filter
    ){
        report
    }
}`;