import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid, Typography, Button } from "@mui/material";
import {
  LocationOnOutlined,
  GroupOutlined,
  BusinessCenterOutlined,
  SchoolOutlined,
  FiberManualRecord,
  ModeEditOutline,
  IosShare,
  VisibilityOutlined,
} from "@mui/icons-material";
import HorizontalDivider from "./HorizontalDivider";
import Chip from "./Chip";
import {
  getDateInFormat,
  kFormatterNumber,
  formArrayFromStringSkills,
} from "../helpers";
import colors from "../styles/colors";

function getStatusColor(status) {
  if (status === 15) {
    return colors.warning;
  }
  if (status === 16 || status === 18) {
    return colors.danger;
  }
  return colors.success;
}

function getStatusTitle(status) {
  if (status === 15) {
    return "On Hold";
  }
  if (status === 16) {
    return "Closed";
  }
  if (status === 18) {
    return "Expired";
  }
  return "Active";
}

function IconAndInfo(props) {
  const { icon, data } = props;
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>{icon}</Grid>
      <Grid item>
        <Typography fontSize="13px">{data}</Typography>
      </Grid>
    </Grid>
  );
}

const ResultCard = (props) => {
  const { showDetails, jobData } = props;

  const history = useHistory();

  const [minSalary, setMinSalary] = useState(0);
  const [maxSalary, setMaxSalary] = useState(0);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    if (jobData && jobData.minimumRemuneration) {
      setMinSalary(jobData.minimumRemuneration);
    }
    if (jobData && jobData.maximumRemuneration) {
      setMaxSalary(jobData.maximumRemuneration);
    }
    if (jobData && jobData.skills) {
      setSkills(formArrayFromStringSkills(jobData.skills));
    }
  }, [jobData]);
  return (
    <Box
      sx={{
        borderRadius: "4px",
        border: `1px solid ${colors.strokeGray}`,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ p: { xs: 2, sm: 3 } }}
        alignItems="center"
      >
        <Grid
          item
          lg={8}
          md={8}
          sm={7}
          xs={12}
          sx={{
            cursor: showDetails
              ? jobData?.status === 16
                ? "default"
                : "pointer"
              : "default",
          }}
          onClick={() => {
            if (showDetails && jobData?.status !== 16) {
              history.push(`/employer-job-details/${jobData.id}`);
            }
          }}
        >
          <Typography
            color={showDetails ? colors.primary : colors.dark}
            fontSize={showDetails ? "18px" : "26px"}
            fontWeight={500}
          >
            {jobData?.title || "-"}
          </Typography>
          <Typography fontSize="14px">
            {jobData?.jobType?.name || "-"}
          </Typography>
        </Grid>
        {!showDetails && (
          <Grid item lg={4} md={4} sm={5} xs={12}>
            <Grid
              container
              spacing={1}
              justifyContent={{ xs: "flex-start", sm: "flex-end" }}
            >
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    history.push(`/recommended-candidates/${jobData.id}`)
                  }
                >
                  Recommended Candidates
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() =>
                    history.push(`/employer-job-applicants/${jobData.id}`)
                  }
                >
                  View Applicants
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {showDetails && (
          <Grid item lg={9} md={9} sm={9} xs={12}>
            <Typography fontSize="13px">
              {jobData?.description || "No description"}
            </Typography>
          </Grid>
        )}
        {!showDetails && (
          <Grid item lg={9} md={9} sm={9} xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <IconAndInfo
                  icon={<LocationOnOutlined color="primary" fontSize="small" />}
                  data={jobData?.city?.name || "-"}
                />
              </Grid>
              <Grid item>
                <IconAndInfo
                  icon={<GroupOutlined color="primary" fontSize="small" />}
                  data={`${
                    jobData.hasOwnProperty("positions") ? jobData.positions : 0
                  } Position(s)`}
                />
              </Grid>
              <Grid item>
                <IconAndInfo
                  icon={
                    <BusinessCenterOutlined color="primary" fontSize="small" />
                  }
                  data={`${jobData.minimumExperience} to ${jobData.maximumExperience} years`}
                />
              </Grid>
              <Grid item>
                <IconAndInfo
                  icon={<SchoolOutlined color="primary" fontSize="small" />}
                  data={jobData?.qualification?.qualification?.name || "-"}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item lg={3} md={3} sm={3} xs={12}>
          <Typography
            fontSize="20px"
            color={colors.primary}
            textAlign="right"
            fontWeight={700}
          >
            ₹ {kFormatterNumber(minSalary)} - {kFormatterNumber(maxSalary)}
            <span
              style={{
                color: colors.lightGray,
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {" "}
              / Month
            </span>
          </Typography>
        </Grid>
        {showDetails && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <IconAndInfo
                  icon={<LocationOnOutlined color="primary" fontSize="small" />}
                  data={jobData?.city?.name || "-"}
                />
              </Grid>
              <Grid item>
                <IconAndInfo
                  icon={<GroupOutlined color="primary" fontSize="small" />}
                  data={`${
                    jobData.hasOwnProperty("positions") ? jobData.positions : 0
                  } Position(s)`}
                />
              </Grid>
              <Grid item>
                <IconAndInfo
                  icon={
                    <BusinessCenterOutlined color="primary" fontSize="small" />
                  }
                  data={`${jobData.minimumExperience} to ${jobData.maximumExperience} years`}
                />
              </Grid>
              <Grid item>
                <IconAndInfo
                  icon={<SchoolOutlined color="primary" fontSize="small" />}
                  data={jobData?.qualification?.qualification?.name || "-"}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {showDetails && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={1} alignItems="center">
              {skills.length > 0 ? (
                skills.map((skill) => (
                  <Grid item key={skill}>
                    <Chip label={skill} />
                  </Grid>
                ))
              ) : (
                <Typography
                  color={colors.dark}
                  fontSize="14px"
                  fontWeight={700}
                >
                  No skills specified
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <HorizontalDivider />
      <Grid container spacing={2} sx={{ px: { xs: 2, sm: 3 }, py: 2 }}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography color={colors.lightGray} fontSize="13px">
                Posted on: {getDateInFormat(jobData.createdDate, "DD MMM YYYY")}
              </Typography>
            </Grid>
            <Grid item sx={{ mr: -0.5 }}>
              <FiberManualRecord
                sx={{ color: getStatusColor(jobData.status), fontSize: "13px" }}
              />
            </Grid>
            <Grid item>
              <Typography color={colors.lightGray} fontSize="13px">
                {getStatusTitle(jobData.status)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          {jobData?.status !== 16 && (
            <Grid
              container
              spacing={1}
              sx={{ justifyContent: { xs: "flex-start", md: "flex-end" } }}
            >
              <Grid item>
                <Button
                  size="small"
                  startIcon={
                    <ModeEditOutline fontSize="small" color="primary" />
                  }
                  sx={{ fontSize: "14px" }}
                  onClick={() =>
                    history.push(`/employer-add-job/${jobData.id}`)
                  }
                >
                  Edit
                </Button>
              </Grid>
              {/* <Grid item>
              <Button
                size="small"
                startIcon={<IosShare fontSize="small" color="primary" />}
                sx={{ fontSize: "14px" }}
              >
                Share
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                startIcon={
                  <VisibilityOutlined fontSize="small" color="primary" />
                }
                sx={{ fontSize: "14px" }}
              >
                30 Views
              </Button>
            </Grid> */}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResultCard;
