import React, { Component } from 'react';
import CKEditor from "react-ckeditor-component";


const Ckeditor = (props) => {

    const {
        onBlur,
        onChange,
        afterPaste,
        content
    } = props;




    return (
        <div>
            <CKEditor
                activeClass="p10"
                content={content}
                events={{
                    // "blur": onBlur,
                    // "afterPaste": afterPaste,
                    "change": onChange
                }}
            />
        </div>);


} 

// class Ckeditor extends Component {
//     constructor(props) {
//         super(props);
//         this.updateContent = this.updateContent.bind(this);
//         this.state = {
//             content: '',
//         }
//     }

//     updateContent(newContent) {
//         console.log(newContent)
//         // this.setState({
//         // 	content: newContent
//         // })
//     }

//     onChange(evt) {
//         console.log("onChange fired with event info: ", evt);
//        let newContent = evt.editor.getData();

//        if(this.props.onChange){
//            this.props.onChange(newContent);
//        }
//         // this.setState({
//         // 	content: newContent
//         // })
//     }

//     onBlur(evt) {
//         console.log("onBlur event called with event info: ", evt);
//     }

//     afterPaste(evt) {
//         console.log("afterPaste event called with event info: ", evt);
//     }
//     render() {
       
//         );
//     }
// }

export default Ckeditor;
