import { gql } from "@apollo/client";

export const GET_COUNTRY = gql`
  query Country {
    countries {
      id
      name
    }
  }
`;

export const GET_STATES = gql`
  query States($country: String!) {
    states(country: $country) {
      id
      name
    }
  }
`;

export const GET_ALL_STATES = gql`
  query allStates{
    allStates{
      id
      name
    }
  }
`

export const GET_CITIES = gql`
  query Cities($state: String!) {
    cities(state: $state) {
      id
      name
    }
  }
`;

export const GET_ALL_CITIES = gql`
  query allCities{
    allCities{
      id
      name
    }
  }
`

export const GET_LOCATIONS = gql`
  query Locations($city: String!) {
    locations(city: $city) {
      id
      name
    }
  }
`;

export const GET_ALL_LOCATIONS = gql`
  query allLocations{
    allLocations{
      id
      name
    }
  }
`

export const GET_DISTRICTS = gql`
  query Districts($state: String!) {
    districts(state: $state) {
      id
      name
    }
  }
`;

export const GET_QUALIFICATIONS = gql`
  query Qualifications {
    qualifications {
      id
      name
    }
  }
`;

export const GET_SKILLS = gql`
  query Skills {
    skills {
      id
      name
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query Industries{
    industries{
      id
      name
    }
  }
`;

export const GET_CITIES_JOBTYPES = gql`
  query homeMeta {
    jobTypes{
      id
      name
    }
    allCities{
      id
      name
    }

  }
`;

export const GET_SEARCH_META = gql`
  query searchMeta {
    jobTypes{
      id
      name
    }
    allCities{
      id
      name
    }
    qualifications {
      id
      name
    }

  }
`;


export const GET_CENTRES = gql`
  query Centres($filter: CentreFilterInput) {
    centres(filter: $filter) {
      id
      name
      description
      lat
      long
      country{
        id
        name
      }
      state{
        id
        name
      }
      city{
        id
        name
      }
      location{
        id
        name
      }
    }
  }
`;

export const GET_STAGES = gql`
  query Events{
    events{
      id
      name
    }
  }
`

export const JOB_TYPES = gql `
  query jobTypes{
    jobTypes{
      id
      name
    }
  }
`
