import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Button } from "@mui/material";
import HorizontalDivider from "../../common/HorizontalDivider";
import colors from "../../styles/colors";

import { withFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useQuery, useMutation } from "@apollo/client";
import { GET_CONTACT_DETAILS } from "../../graphql/EmployerProfile";
import { UPDATE_USER } from "../../graphql/users";
import getStore from "../../zustandStore";
import { getLoggedInUserId } from "../../helpers";

const ContactDetails = (props) => {
  const {
    editMode,
    setEditMode,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    handleSubmit,
  } = props;
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Typography color={colors.dark} fontSize="20px">
          Contact Details
        </Typography>
      </Grid>
      {!editMode && (
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={6}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            size="small"
            variant="contained"
            onClick={() => setEditMode(true)}
          >
            Edit
          </Button>
        </Grid>
      )}
      {editMode && <Grid item lg={6} md={6} sm={6} xs={6} />}
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Typography fontSize="14px">First Name</Typography>
      </Grid>
      <Grid
        item
        lg={4}
        md={4}
        sm={12}
        xs={12}
        display={{ xs: "none", md: "block" }}
      >
        <Typography fontSize="14px">Last Name</Typography>
      </Grid>
      <Grid item lg={4} md={4} display={{ xs: "none", md: "block" }} />
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: -1 }}>
        <TextField
          id="firstName"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
          disabled={!editMode}
        />
        {touched.firstName && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.firstName}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        lg={4}
        md={4}
        sm={12}
        xs={12}
        display={{ xs: "block", md: "none" }}
      >
        <Typography fontSize="14px">Last Name</Typography>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: -1 }}>
        <TextField
          id="lastName"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
          disabled={!editMode}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px">Phone number</Typography>
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12} sx={{ mt: -1 }}>
        <TextField
          id="mobile"
          value={values.mobile}
          onChange={handleChange}
          onBlur={handleBlur}
          type="number"
          size="small"
          fullWidth
          disabled={!editMode}
        />
        {touched.mobile && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.mobile}
          </Typography>
        )}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px">Official Mail Address</Typography>
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12} sx={{ mt: -1 }}>
        <TextField
          id="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
          disabled={!editMode}
        />
        {touched.email && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.email}
          </Typography>
        )}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px">Designation</Typography>
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12} sx={{ mt: -1 }}>
        <TextField
          id="designation"
          value={values.designation}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
          disabled={!editMode}
        />
      </Grid>
      {editMode && (
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ my: 1 }}>
          <HorizontalDivider />
        </Grid>
      )}
      {editMode && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                onClick={() => setEditMode(false)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button size="small" variant="contained" onClick={handleSubmit}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const ContactDetailsFormik = (props) => {
  const setLoading = getStore((state) => state.setLoading);
  const { enqueueSnackbar } = useSnackbar();

  const [editMode, setEditMode] = useState(false);
  const [userId, setUserId] = useState("");
  const [contactDetails, setContactDetails] = useState({});
  const [designationProfile, setDesignationProfile] = useState("");

  const {
    data: userDetails,
    loading: gettingDetails,
    refetch,
  } = useQuery(GET_CONTACT_DETAILS, {
    variables: {
      id: userId,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    skip: !userId,
  });

  const [updateContactDetails, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      const { updateUser } = data;
      const { status, error } = updateUser;
      if (status) {
        refetch();
        setEditMode(false);
        enqueueSnackbar(error, {
          variant: "success",
          sx: {
            "& .SnackbarContent-root": { backgroundColor: colors.success },
          },
        });
      } else {
        enqueueSnackbar(error, {
          variant: "error",
          sx: { "& .SnackbarContent-root": { backgroundColor: colors.danger } },
        });
      }
    },
  });

  useEffect(() => {
    const me = getLoggedInUserId();
    if (me) {
      const temp = JSON.parse(me);
      if (temp && temp.id) {
        setUserId(temp.id);
      }
    }
  }, []);

  useEffect(() => {
    if (userDetails && userDetails.user) {
      setContactDetails(userDetails.user);
    }
  }, [userDetails]);

  useEffect(() => {
    if (contactDetails && contactDetails.profile) {
      let profileObj = JSON.parse(contactDetails.profile);
      if (profileObj && profileObj.designation) {
        setDesignationProfile(profileObj.designation);
      }
    }
  }, [contactDetails]);

  useEffect(() => {
    setLoading(gettingDetails || loading);
  }, [gettingDetails, loading, setLoading]);

  const ContactDetailsWrapped = withFormik({
    mapPropsToValues: () => ({
      id: contactDetails?.id || null,
      firstName: contactDetails?.firstName || "",
      lastName: contactDetails?.lastName || "",
      mobile: contactDetails?.mobile || "",
      email: contactDetails?.email || "",
      designation: designationProfile || "",
    }),

    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Please enter the first name"),
      mobile: Yup.string()
        .required("Please enter the phone number")
        .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),
      email: Yup.string()
        .required("Please enter the email id")
        .email("Enter a valid email id"),
    }),

    handleSubmit(values, { props }) {
      const { handleReset } = props;
      let profileObj = {};
      if (values && values.designation) {
        profileObj = { ...profileObj, designation: values.designation };
      }
      if (values.id) {
        updateContactDetails({
          variables: {
            payload: {
              id: values.id,
              firstName: values.firstName,
              lastName: values.lastName,
              mobile: values.mobile.toString(),
              email: values.email,
              profile: JSON.stringify(profileObj),
            },
          },
        });
      }
      handleReset(values);
    },
  })(ContactDetails);

  return (
    <ContactDetailsWrapped editMode={editMode} setEditMode={setEditMode} />
  );
};

export default ContactDetailsFormik;
