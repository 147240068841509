import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { commaFormatterNumber } from "../../helpers";
import colors from "../../styles/colors";

function Item(props) {
  const { title, data } = props;
  return (
    <Box>
      <Typography fontSize="13px">{title}</Typography>
      <Typography color={colors.dark} fontSize="14px" fontWeight={700}>
        {data}
      </Typography>
    </Box>
  );
}

const SalaryDetails = (props) => {
  const { jobData } = props;

  const [minSalary, setMinSalary] = useState(0);
  const [maxSalary, setMaxSalary] = useState(0);
  const [perks, setPerks] = useState([]);

  useEffect(() => {
    if (jobData && jobData.minimumRemuneration) {
      setMinSalary(jobData.minimumRemuneration);
    }
    if (jobData && jobData.maximumRemuneration) {
      setMaxSalary(jobData.maximumRemuneration);
    }
    if (jobData && jobData.perks) {
      const temp = JSON.parse(jobData.perks);
      if (temp !== null) {
        setPerks(temp);
      }
    }
  }, [jobData]);

  return (
    <Box
      sx={{
        borderRadius: "4px",
        border: `1px solid ${colors.strokeGray}`,
        p: { xs: 2, sm: 3 },
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontWeight={500} fontSize="18px">
            Salary Details
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Item
            title="Salary Amount"
            data={`₹${commaFormatterNumber(minSalary)} - ${commaFormatterNumber(
              maxSalary
            )} /Month`}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Item
            title="Perks"
            data={
              perks && perks.length > 0 ? perks.join(", ") : "Not mentioned"
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SalaryDetails;
