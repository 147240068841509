import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import colors from "../../styles/colors";
import { genders } from "../../constants";
import { getDateInFormat } from "../../helpers";

function Item(props) {
  const { title, data } = props;
  return (
    <Box>
      <Typography fontSize="13px">{title}</Typography>
      <Typography color={colors.dark} fontSize="14px" fontWeight={700}>
        {data}
      </Typography>
    </Box>
  );
}

const GeneralDetails = (props) => {
  const { jobData } = props;

  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    if (jobData && jobData.languages) {
      const temp = JSON.parse(jobData.languages);
      if (temp !== null) {
        setLanguages(temp);
      }
    }
  }, [jobData]);

  return (
    <Box
      sx={{
        borderRadius: "4px",
        border: `1px solid ${colors.strokeGray}`,
        p: { xs: 2, sm: 3 },
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontWeight={500} fontSize="18px">
            General Details
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            title="Gender Preference"
            data={genders[jobData.genderPreference]}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            title="Languages Known"
            data={
              languages && languages.length > 0
                ? languages.join(", ")
                : "Not specified"
            }
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            title="Last to Apply"
            data={getDateInFormat(jobData.closure, "DD MMM YYYY")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralDetails;
