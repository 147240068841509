import React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import ResultCard from "../../common/CandidateResultCard";
import colors from "../../styles/colors";

const SearchResults = (props) => {
  const { candidates, candidatesCount, query, setLoginDrawer } = props;

  return (
    <Box
      sx={{
        backgroundColor: colors.secondary,
        px: { xs: 3, sm: 15 },
        pb: { xs: 5, sm: 7 },
        pt: 20,
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontWeight={700} fontSize="40px" textAlign="center">
            {candidatesCount > 0
              ? `${candidatesCount} Candidates Found`
              : "No matching candidates found."}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography color={colors.lightGray} textAlign="center">
            {query}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          {candidates &&
            candidates.map((res, index) => (
              <div key={res.id} style={{ marginTop: index === 0 ? 0 : 15 }}>
                <ResultCard masked recommended fullWidth candidateData={res} />
              </div>
            ))}
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <Button
            size="small"
            variant="contained"
            onClick={() => setLoginDrawer(true)}
          >
            Login to view all
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchResults;
