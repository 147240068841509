import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import JobDetails from "./JobDetails";
import SalaryDetails from "./SalaryDetails";
import GeneralDetails from "./GeneralDetails";
import Footer from "./Footer";
import SuccessModal from "./SuccessModal";
import EmployerNavbar from "../../common/EmployerNavBar";
import colors from "../../styles/colors";

import { withFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_JOB_CATEGORIES,
  GET_QUALIFICATIONS,
  GET_COUNTRY,
  GET_STATES,
  GET_CITIES,
} from "../../graphql/MasterData";
import {
  ADD_JOB,
  GET_JOB_DETAILS,
  UPDATE_JOB,
} from "../../graphql/EmployerJobs";
import getStore from "../../zustandStore";
import { handlePostJob } from "./utils";

function AddEditJobForm(props) {
  const { values, successModal, setSuccessModal } = props;

  const uriParams = useParams();

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const { data: jobCategories } = useQuery(GET_JOB_CATEGORIES, {
    variables: {},
    // fetchPolicy: "network-only",
    // nextFetchPolicy: "network-only",
  });

  const { data: qualifications } = useQuery(GET_QUALIFICATIONS, {
    variables: {},
    // fetchPolicy: "network-only",
    // nextFetchPolicy: "network-only",
  });

  const { data: countries } = useQuery(GET_COUNTRY, {
    variables: {},
    // fetchPolicy: "network-only",
    // nextFetchPolicy: "network-only",
  });

  const { data: states } = useQuery(GET_STATES, {
    variables: { country: selectedCountry },
    // fetchPolicy: "network-only",
    // nextFetchPolicy: "network-only",
    skip: !selectedCountry,
  });

  const { data: cities } = useQuery(GET_CITIES, {
    variables: { state: selectedState },
    // fetchPolicy: "network-only",
    // nextFetchPolicy: "network-only",
    skip: !selectedState,
  });

  useEffect(() => {
    if (values && values.countryId) {
      setSelectedCountry(values.countryId);
    }
    if (values && values.stateId) {
      setSelectedState(values.stateId);
    }
  }, [values]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="24px" color={colors.dark} fontWeight={500}>
          {uriParams.jobId !== "new" ? "Edit" : "Post"} Job
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mt: { xs: 0, md: 2 } }}>
        <Typography fontSize="18px" fontWeight={500} sx={{ mb: 1 }}>
          Job Details
        </Typography>
        <JobDetails
          {...props}
          jobCategories={jobCategories}
          qualifications={qualifications}
          countries={countries}
          states={states}
          cities={cities}
        />
      </Grid>
      <Grid item lg={5} md={5} sm={12} xs={12} sx={{ mt: { xs: 0, md: 2 } }}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography fontSize="18px" fontWeight={500} sx={{ mb: 1 }}>
              Salary Details
            </Typography>
            <SalaryDetails {...props} />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{ mt: { xs: 0, md: 2 } }}
          >
            <Typography fontSize="18px" fontWeight={500} sx={{ mb: 1 }}>
              General Details
            </Typography>
            <GeneralDetails {...props} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Footer {...props} edit={uriParams.jobId} />
      </Grid>
      <SuccessModal open={successModal} setOpen={setSuccessModal} />
    </Grid>
  );
}

const AddEditJobFormFormik = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const setLoading = getStore((state) => state.setLoading);
  const screeningQuestions = getStore((state) => state.screeningQuestions);
  const jobPayload = getStore((state) => state.jobPayload);
  const setJobPayload = getStore((state) => state.setJobPayload);
  const setScreeningQuestions = getStore(
    (state) => state.setScreeningQuestions
  );
  // const [jobPayload, setJobPayload] = useState({});

  const uriParams = useParams();

  const [getJob, { data: job, loading }] = useLazyQuery(GET_JOB_DETAILS, {
    variables: {
      id: uriParams.jobId,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    skip: uriParams.jobId === "new",
  });

  useEffect(() => {
    if (job && job.job) {
      const skills = job.job.skills ? job.job.skills.split(",") : [];
      const perks = job.job.perks ? JSON.parse(job.job.perks) : [];
      const languages = job.job.languages ? JSON.parse(job.job.languages) : [];
      const countryId = job.job.country ? job.job.country.id : null;
      const jobTypeId = job.job.jobType ? job.job.jobType.id : null;
      const cityId = job.job.city ? job.job.city.id : null;
      const stateId = job.job.state ? job.job.state.id : null;
      const qualificationId = job.job.qualification?.qualification
        ? job.job.qualification.qualification.id
        : null;
      const location = job.job.locationId ? job.job.locationId : null;

      setJobPayload({
        ...job.job,
        skills,
        perks,
        languages,
        countryId,
        cityId,
        stateId,
        jobTypeId,
        qualificationId,
        location,
      });
      if (job.job.screeningQuestions && screeningQuestions.length === 0) {
        const tempArr = [...job.job.screeningQuestions];
        const tsq = tempArr.map((ques) => ({
          id: ques.id,
          question: ques.question,
          answer: ques.answer,
          answerType: ques.answerType,
          mandatory: ques.mandatory,
        }));
        setScreeningQuestions([...tsq]);
      }
    }
  }, [job, setJobPayload, setScreeningQuestions]);

  // useEffect(() => {
  //   if (uriParams.jobId !== "new") {
  //     setLoading(loading);
  //   }
  // }, [loading, setLoading, uriParams]);

  useEffect(() => {
    if (uriParams.jobId !== "new") {
      getJob();
    }
  }, [getJob, uriParams]);

  const [successModal, setSuccessModal] = useState(false);

  const [addJob, { loading: addingJob }] = useMutation(ADD_JOB, {
    onCompleted: (data) => {
      const { createJob } = data;
      const { status, error } = createJob;
      if (status) {
        setJobPayload({});
        setScreeningQuestions([]);
        setSuccessModal(true);
      } else {
        enqueueSnackbar(error, {
          variant: "error",
          sx: { "& .SnackbarContent-root": { backgroundColor: colors.danger } },
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [updateJob, { loading: updatingJob }] = useMutation(UPDATE_JOB, {
    onCompleted: (data) => {
      const { updateJob } = data;
      const { status, error } = updateJob;
      if (status) {
        setJobPayload({});
        setScreeningQuestions([]);
        setSuccessModal(true);
      } else {
        enqueueSnackbar(error, {
          variant: "error",
          sx: { "& .SnackbarContent-root": { backgroundColor: colors.danger } },
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(addingJob);
  }, [addingJob, setLoading]);

  useEffect(() => {
    setLoading(updatingJob);
  }, [updatingJob, setLoading]);

  const AddEditJobFormWrapped = withFormik({
    mapPropsToValues: () => ({
      id: jobPayload.id || null,
      title: jobPayload.title || "",
      positions: jobPayload.positions || 0,
      jobMode: jobPayload.jobMode || "",
      jobTeamType: jobPayload.jobTeamType || "",
      description: jobPayload.description || "",
      jobTypeId: jobPayload.jobTypeId || "",
      qualificationId: jobPayload.qualificationId || "",
      skills: jobPayload.skills || [],
      countryId: jobPayload.countryId || "",
      stateId: jobPayload.stateId || "",
      cityId: jobPayload.cityId || "",
      location: jobPayload.location || "",
      minimumExperience: jobPayload.minimumExperience || 0,
      maximumExperience: jobPayload.maximumExperience || 0,
      minimumRemuneration: jobPayload.minimumRemuneration || 0,
      maximumRemuneration: jobPayload.maximumRemuneration || 0,
      perks: jobPayload.perks || [],
      minimumYearOfPassing: jobPayload.minimumYearOfPassing || "",
      maximumYearOfPassing: jobPayload.maximumYearOfPassing || "",
      genderPreference: jobPayload.genderPreference || "",
      languages: jobPayload.languages || [],
      closure: jobPayload.closure || "",
    }),

    validationSchema: Yup.object().shape({
      title: Yup.string().required("Please enter the job title"),
      positions: Yup.number()
        .required("Please enter the no. of positions")
        .min(0, "No. of positions cannot be in negative")
        .integer("No. of positions has to be an integer")
        .typeError("Please enter no. of positions"),
      jobTypeId: Yup.string().required("Please select a job category"),
      qualificationId: Yup.string().required("Please select a qualification"),
      skills: Yup.array()
        .of(Yup.string())
        .min(1, "Please enter atleast one skill"),
      countryId: Yup.string().required("Please select a country"),
      stateId: Yup.string().required("Please select a state"),
      cityId: Yup.string().required("Please select a city"),
      location: Yup.string().required("Please enter the address"),
      minimumRemuneration: Yup.number()
        .required("Please enter minimum salary")
        .min(0, "Remuneration cannot be in negative")
        .typeError("Please enter minimum remuneration"),
      maximumRemuneration: Yup.number()
        .required("Please enter maximum salary")
        .min(0, "Remuneration cannot be in negative")
        .typeError("Please enter maximum remuneration")
        .test({
          name: "min-max",
          message: "Maximum salary must be greater than the minimum salary",
          test: function (value) {
            return value >= this.parent.minimumRemuneration;
          },
        }),
      minimumExperience: Yup.number()
        .required("Please enter minimum experience")
        .min(0, "Experience cannot be in negative")
        .integer("Experience has to be an integer")
        .typeError("Please enter minimum experience"),
      maximumExperience: Yup.number()
        .required("Please enter maximum experience")
        .min(0, "Experience cannot be in negative")
        .integer("Experience has to be an integer")
        .typeError("Please enter maximum experience")
        .test({
          name: "min-max",
          message:
            "Maximum experience must be greater than the minimum experience",
          test: function (value) {
            return value >= this.parent.minimumExperience;
          },
        }),
      genderPreference: Yup.string().required(
        "Please select your gender preference"
      ),
      closure: Yup.string()
        .required("Please select last date to apply")
        // .test({
        //   name: "before today",
        //   message: "Please select a future date",
        //   test: function (value) {
        //     return moment(value) >= moment();
        //   },
        // }),
        ,
      minimumYearOfPassing: Yup.string().required(
        "Please enter minimum year of passing"
      ),
      maximumYearOfPassing: Yup.string()
        .required("Please enter maximum year of passing")
        .test({
          name: "min-max",
          message:
            "Maximum year of passing must be greater than the minimum year of passing",
          test: function (value) {
            return moment(value) >= moment(this.parent.minimumYearOfPassing);
          },
        }),
    }),

    handleSubmit(values, { props }) {
      const { handleReset } = props;
      const { id } = values;
      console.log(">>>>>>>>>>values", values);
      const handleJob = id ? updateJob : addJob;
      handlePostJob(handleJob, values, screeningQuestions);
      handleReset(values);
    },
  })(AddEditJobForm);

  return (
    <AddEditJobFormWrapped
      successModal={successModal}
      setSuccessModal={setSuccessModal}
    />
  );
};

const EmployerAddEditJob = (props) => {
  return <EmployerNavbar mainComponent={<AddEditJobFormFormik />} />;
};

export default EmployerAddEditJob;
