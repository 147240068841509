import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import {
  GET_COUNTRY,
  GET_STATES,
  GET_CITIES,
  GET_LOCATIONS,
  GET_QUALIFICATIONS,
  JOB_TYPES,
  GET_CENTRES,
} from "../../graphql/meta";
import {
  ADD_USER,
  UPDATE_USER,
  GET_USER_TIMELINE,
  GET_RESUME,
} from "../../graphql/users";
import { GET_ORGANIZATIONS } from "../../graphql/organizations";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import moment from "moment";
import Activity from "./Activity";
import { Modal } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { isJwtTokenValid, getLoggedInUserData, downloadAny } from "../../utils";

const AddNewCandidateForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setAddCandidateView,
    selectedCandidateData,
    handleBlur,
    editTimeline,
    roles,
  } = props;

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const [candidateId, setCandidateId] = useState(null);
  const [errorModal, setErrorModal] = useState(false);

  const { data: countriesDropDown } = useQuery(GET_COUNTRY, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: statesDropDown } = useQuery(GET_STATES, {
    variables: {
      country: selectedCountry,
    },
    skip: !selectedCountry,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: citiesDropDown } = useQuery(GET_CITIES, {
    variables: {
      state: selectedState,
    },
    skip: !selectedState,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: locationsDropDown } = useQuery(GET_LOCATIONS, {
    variables: {
      city: selectedCity,
    },
    skip: !selectedCity,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: qualificationsDropDown } = useQuery(GET_QUALIFICATIONS, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: jobTypes } = useQuery(JOB_TYPES, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: organizations } = useQuery(GET_ORGANIZATIONS, {
    variables: {
      filter: {
        organizationType: 1,
      },
    },
    skip: !selectedSource,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: centres } = useQuery(GET_CENTRES, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { cityId, stateId, countryId, profile, source } = values;

  useEffect(() => {
    if (cityId) {
      setSelectedCity(cityId);
    }
    if (profile && profile.preferredCityId) {
      setSelectedCity(profile.preferredCityId);
    }
  }, [cityId, profile]);

  useEffect(() => {
    if (countryId) {
      setSelectedCountry(countryId);
    }
    if (profile && profile.preferredCountryId) {
      setSelectedCountry(profile.preferredCountryId);
    }
  }, [countryId, profile]);

  useEffect(() => {
    if (stateId) {
      setSelectedState(stateId);
    }
    if (profile && profile.preferredStateId) {
      setSelectedState(profile.preferredStateId);
    }
  }, [stateId, profile]);

  useEffect(() => {
    if (source) {
      setSelectedSource(source);
    }
  }, [source]);

  useEffect(() => {
    // debugger
    if (
      selectedCandidateData &&
      selectedCandidateData?.jobType &&
      selectedCandidateData?.jobType.length > 0
    ) {
      // debugger
      let tempArr = [];
      selectedCandidateData.jobType.map((itm) => {
        tempArr.push(itm.jobType);
        console.log(selectedCandidateData);
      });
      setSelectedJobTypes(tempArr);
    }
  }, [selectedCandidateData]);

  const { data: timeLineData, refetch: refetchTimeline } = useQuery(
    GET_USER_TIMELINE,
    {
      variables: {
        id: candidateId,
        page: 1,
        limit: 10,
      },
      skip: !candidateId,
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (selectedCandidateData?.id) {
      setCandidateId(selectedCandidateData?.id);
    }
  }, [selectedCandidateData]);

  useEffect(() => {
    console.log({ values, errors, selectedJobTypes });
  }, [values, errors, selectedJobTypes]);

  useEffect(() => {
    if (values?.error !== "") {
      setErrorModal(true);
    }
  }, [values]);

  const [getResume, { loading, data }] = useLazyQuery(GET_RESUME, {
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only",
  });

  const downloadResume = async (userId) => {
    let temp = await getResume({
      variables: {
        userId: userId,
      },
    });
    let data = temp.data.getResume;
    downloadAny(data.file, data.fileName, data.fileType);
  };

  return (
    <>
      <div>
        <Modal show={errorModal} onHide={() => setErrorModal(false)}>
          <Modal.Header>
            <Modal.Title>Add Fail</Modal.Title>
            <button
              type="button"
              class="close"
              aria-label="Close"
              onClick={() => setErrorModal(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>{values.error}</Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              class="btn btn-secondary"
              onClick={() => {
                setFieldValue("error", "");
                setErrorModal(false);
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <div className="container-fluid" style={{ marginLeft: 20 }}>
          <div className="d-flex justify-content-between align-items-center">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => setAddCandidateView(false)}>
                    <span style={{ fontSize: 14, cursor: "pointer" }}>
                      Candidates
                    </span>
                  </a>
                </li>
                <li class="breadcrumb-item">
                  <span style={{ fontSize: 14 }}>
                    {selectedCandidateData?.id
                      ? `${selectedCandidateData.firstName || ""} ${
                          selectedCandidateData.lastName || ""
                        }`
                      : "New"}
                  </span>
                </li>
              </ol>
            </nav>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <ul className="nav nav-tabs page-header-tab">
              {editTimeline && (
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="user-tab"
                    data-toggle="tab"
                    href="#edit-candidate"
                  >
                    {candidateId ? (
                      <span>Edit User</span>
                    ) : (
                      <span>Add User</span>
                    )}
                  </a>
                </li>
              )}
              {candidateId && (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="user-tab"
                    data-toggle="tab"
                    href="#add-event"
                  >
                    {editTimeline ? "Add Activity" : "Activities"}
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="section-body mt-3">
          <div className="container-fluid">
            <div className="tab-content mt-3">
              <div
                className={`tab-pane fade ${editTimeline ? "show active" : ""}`}
                id="edit-candidate"
                role="tabpanel"
              >
                <div className="card container">
                  <div className="card-body">
                    <div className="d-flex flex-column" style={{ Width: 700 }}>
                      <div>
                        <div className="font-18 font-weight-bold">
                          Personal Details
                        </div>{" "}
                        <hr />
                      </div>
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">
                            First Name <span style={{ color: "red" }}>*</span>
                          </div>
                          <input
                            type="text"
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            placeholder="First Name"
                            style={{ border: "1px #7C7B7B solid" }}
                            id="firstName"
                            onChange={handleChange}
                            value={values.firstName}
                            onBlur={handleBlur}
                          />
                        </div>
                        {touched.firstName && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              alignContent: "centre",
                            }}
                          >
                            {errors.firstName}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">Last Name</div>
                          <input
                            type="text"
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            placeholder="Last Name"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id="lastName"
                            onChange={handleChange}
                            value={values.lastName}
                          />
                        </div>
                      </div>

                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">
                            Mobile Number{" "}
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <input
                            type="number"
                            className="form-control  col-lg-6 col-md-10 col-sm-10"
                            placeholder="Mobile No *"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id="mobile"
                            onChange={(event) => {
                              let mob = event.target.value.toString();
                              setFieldValue("mobile", mob);
                            }}
                            value={values.mobile}
                            onBlur={handleBlur}
                            disabled={selectedCandidateData?.id ? true : false}
                          />
                        </div>
                        {touched.mobile && (
                          <span style={{ fontSize: 12, color: "red" }}>
                            {errors.mobile}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">Email Address</div>
                          <input
                            type="text"
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            placeholder="Email ID"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id="email"
                            onChange={handleChange}
                            value={values.email}
                            onBlur={handleBlur}
                          />
                        </div>
                        {touched.email && (
                          <span style={{ fontSize: 12, color: "red" }}>
                            {errors.email}
                          </span>
                        )}
                      </div>

                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">
                            Date of Birth{" "}
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <input
                            type="date"
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            placeholder="Date of Birth"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id="dob"
                            onChange={handleChange}
                            value={values.dob}
                            onBlur={handleBlur}
                          />
                        </div>
                        {touched.dob && (
                          <span style={{ fontSize: 12, color: "red" }}>
                            {errors.dob}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">
                            Gender <span style={{ color: "red" }}>*</span>
                          </div>
                          <select
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id="gender"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option
                              disabled
                              selected={!values.gender ? true : false}
                            >
                              {" "}
                              -- Select Gender* --{" "}
                            </option>
                            <option
                              value="M"
                              selected={values.gender === "M" ? true : false}
                            >
                              Male
                            </option>
                            <option
                              value="F"
                              selected={values.gender === "F" ? true : false}
                            >
                              Female
                            </option>
                            <option
                              value="O"
                              selected={values.gender === "O" ? true : false}
                            >
                              Other
                            </option>
                            <option
                              value="NA"
                              selected={values.gender === "NA" ? true : false}
                            >
                              NA
                            </option>
                          </select>
                        </div>
                        {touched.gender && (
                          <span style={{ fontSize: 12, color: "red" }}>
                            {errors.gender}
                          </span>
                        )}
                      </div>

                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">
                            Aadhaar Number{" "}
                            {values?.scheme === 1 || values.scheme === 2 ? (
                              <span style={{ color: "red" }}>*</span>
                            ) : (
                              ""
                            )}
                          </div>
                          <input
                            type="text"
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            placeholder={
                              values?.scheme === 1 || values.scheme === 2
                                ? "Aadhaar No *"
                                : "Aadhaar No"
                            }
                            style={{ border: "1px #7C7B7B solid" }}
                            id="aadhar"
                            onChange={handleChange}
                            value={values.aadhar}
                            onBlur={handleBlur}
                          />
                        </div>
                        {touched.aadhar && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              alignContent: "centre",
                            }}
                          >
                            {errors.aadhar}
                          </span>
                        )}
                      </div>

                      <div style={{ marginTop: 20 }}>
                        <div className="font-18 font-weight-bold">
                          Current Location{" "}
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <hr />
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="col-lg-3 col-md-3 col-sm-12">
                          <div className="form-group form-group-lg">
                            <select
                              className="form-control"
                              style={{ border: "1PX #7C7B7B solid" }}
                              id="countryId"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option
                                disabled
                                selected={!values.countryId ? true : false}
                              >
                                {" "}
                                -- Select Country* --{" "}
                              </option>
                              {countriesDropDown &&
                                countriesDropDown.countries &&
                                countriesDropDown?.countries.map((country) => (
                                  <option
                                    value={country.id}
                                    selected={
                                      values.countryId === country.id
                                        ? true
                                        : false
                                    }
                                  >
                                    {country.name}
                                  </option>
                                ))}
                            </select>
                            {touched.countryId && (
                              <span style={{ fontSize: 12, color: "red" }}>
                                {errors.countryId}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                          <div className="form-group">
                            <select
                              className="form-control"
                              style={{ border: "1PX #7C7B7B solid" }}
                              id="stateId"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option
                                disabled
                                selected={!values.stateId ? true : false}
                              >
                                {" "}
                                -- Select State* --{" "}
                              </option>
                              {statesDropDown &&
                                statesDropDown.states &&
                                statesDropDown?.states.map((state) => (
                                  <option
                                    value={state.id}
                                    selected={
                                      values.stateId === state.id ? true : false
                                    }
                                  >
                                    {state.name}
                                  </option>
                                ))}
                            </select>
                            {touched.stateId && (
                              <span style={{ fontSize: 12, color: "red" }}>
                                {errors.stateId}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                          <div className="form-group">
                            <select
                              className="form-control"
                              style={{ border: "1PX #7C7B7B solid" }}
                              id="cityId"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option
                                disabled
                                selected={!values.cityId ? true : false}
                              >
                                {" "}
                                -- Select City* --{" "}
                              </option>
                              {citiesDropDown &&
                                citiesDropDown.cities &&
                                citiesDropDown?.cities.map((city) => (
                                  <option
                                    value={city.id}
                                    selected={
                                      values.cityId === city.id ? true : false
                                    }
                                  >
                                    {city.name}
                                  </option>
                                ))}
                            </select>
                            {touched.cityId && (
                              <span style={{ fontSize: 12, color: "red" }}>
                                {errors.cityId}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                          <div className="form-group">
                            <select
                              className="form-control"
                              style={{ border: "1PX #7C7B7B solid" }}
                              id="locationId"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option
                                disabled
                                selected={!values.locationId ? true : false}
                              >
                                {" "}
                                -- Select Location* --{" "}
                              </option>
                              {locationsDropDown &&
                                locationsDropDown.locations &&
                                locationsDropDown?.locations.map((location) => (
                                  <option
                                    value={location.id}
                                    selected={
                                      values.locationId === location.id
                                        ? true
                                        : false
                                    }
                                  >
                                    {location.name}
                                  </option>
                                ))}
                            </select>
                            {touched.locationId && (
                              <span style={{ fontSize: 12, color: "red" }}>
                                {errors.locationId}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div style={{ marginTop: 20 }}>
                        <div className="font-18 font-weight-bold">
                          Source And Center Details
                        </div>
                        <hr />
                      </div>
                      <div>
                        <div className="d-flex justify-content-between">
                          <div className="font-15">
                            Sourced By <span style={{ color: "red" }}>*</span>{" "}
                          </div>
                          <div className="form-group col-lg-8 col-md-12 col-sm-12 ">
                            <select
                              className="form-control col-lg-6 col-md-10 col-sm-10"
                              style={{ border: "1PX #7C7B7B solid" }}
                              id="source"
                              onChange={(event) => {
                                let so = parseInt(event.target.value);
                                setFieldValue("source", so);
                              }}
                              onBlur={handleBlur}
                              disabled={roles[0] === "pia_user" ? true : false}
                            >
                              <option
                                disabled
                                selected={!values.source ? true : false}
                              >
                                {" "}
                                -- Select Candidate Source* --{" "}
                              </option>
                              <option
                                value="1"
                                selected={values.source === 1 ? true : false}
                                disabled={values.source === 3 ? true : false}
                              >
                                PIA
                              </option>
                              <option
                                value="2"
                                selected={values.source === 2 ? true : false}
                                disabled={values.source === 3 ? true : false}
                              >
                                MCC
                              </option>
                              <option
                                value="3"
                                selected={values.source === 3 ? true : false}
                                disabled={values.source !== 3 ? true : false}
                              >
                                Mobile App
                              </option>
                              <option
                                value="4"
                                selected={values.source === 4 ? true : false}
                                disabled={values.source === 3 ? true : false}
                              >
                                Others
                              </option>
                            </select>
                            {touched.source && (
                              <span style={{ fontSize: 12, color: "red" }}>
                                {errors.source}
                              </span>
                            )}
                          </div>
                        </div>
                        {values.source === 1 && (
                          <div className="d-flex align-item-center justify-content-between">
                            <div className="font-15">
                              Select PIA <span style={{ color: "red" }}>*</span>{" "}
                            </div>
                            <div className="form-group col-lg-8 col-md-12 col-sm-12">
                              <select
                                className="form-control col-lg-6 col-md-10 col-sm-10"
                                style={{ border: "1PX #7C7B7B solid" }}
                                id="organizationId"
                                onChange={(e) => {
                                  setFieldValue(
                                    "organizationId",
                                    e.target.value
                                  );
                                  setFieldValue(
                                    "forOrganizationId",
                                    e.target.value
                                  );
                                }}
                                onBlur={handleBlur}
                                disabled={
                                  roles[0] === "pia_user" ? true : false
                                }
                              >
                                <option
                                  disabled
                                  selected={
                                    !values.organizationId ? true : false
                                  }
                                >
                                  -- Select PIA* --
                                </option>
                                {organizations &&
                                  organizations.organizations &&
                                  organizations.organizations.map((e) => (
                                    <option
                                      value={e.id}
                                      selected={
                                        values.organizationId === e.id
                                          ? true
                                          : false
                                      }
                                    >
                                      {e.name}
                                    </option>
                                  ))}
                              </select>
                              {/* {touched.organizationId && <span style={{ fontSize: 12, color: 'red' }}>{errors.organizationId}</span>} */}
                            </div>
                          </div>
                        )}
                        {values.source === 4 && (
                          <div className="d-flex align-item-center justify-content-between">
                            <div className="font-15">Specify Others</div>
                            <div className="form-group col-lg-8 col-md-12 col-sm-12">
                              <input
                                type="text"
                                className="form-control col-lg-6 col-md-10 col-sm-10"
                                style={{ border: "1px #7C7B7B solid" }}
                                placeholder="Other Source"
                                id="profile.sourceOthers"
                                onChange={handleChange}
                                value={
                                  values.profile && values.profile.sourceOthers
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div>
                          <div className="d-flex align-item-center justify-content-between">
                            <div className="font-15">Sourced For</div>
                            <div className="form-group col-lg-8 col-md-12 col-sm-12">
                              <select
                                className="form-control col-lg-6 col-md-10 col-sm-10"
                                style={{ border: "1PX #7C7B7B solid" }}
                                id="forOrganizationId"
                                onChange={(e) => {
                                  setFieldValue(
                                    "organizationId",
                                    e.target.value
                                  );
                                  setFieldValue(
                                    "forOrganizationId",
                                    e.target.value
                                  );
                                }}
                                onBlur={handleBlur}
                                disabled={
                                  roles[0] === "pia_user" ? true : false
                                }
                              >
                                <option
                                  disabled
                                  selected={
                                    !values.forOrganizationId ? true : false
                                  }
                                >
                                  -- Select PIA --
                                </option>
                                {organizations &&
                                  organizations.organizations &&
                                  organizations.organizations.map((e) => (
                                    <option
                                      value={e.id}
                                      selected={
                                        values.forOrganizationId === e.id
                                          ? true
                                          : false
                                      }
                                    >
                                      {e.name}
                                    </option>
                                  ))}
                              </select>
                              {/* {touched.organizationId && <span style={{ fontSize: 12, color: 'red' }}>{errors.organizationId}</span>} */}
                            </div>
                          </div>
                          {touched.source && (
                            <span style={{ fontSize: 12, color: "red" }}>
                              {errors.forOrganizationId}
                            </span>
                          )}
                        </div>
                        <div className="d-flex align-item-center justify-content-between">
                          <div className="font-15">Center</div>
                          <div className="form-group col-lg-8 col-md-12 col-sm-12">
                            <select
                              className="form-control col-lg-6 col-md-10 col-sm-10"
                              style={{ border: "1PX #7C7B7B solid" }}
                              id="centreId"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option
                                disabled
                                selected={!values.centreId ? true : false}
                              >
                                -- Select Centre --
                              </option>
                              {centres &&
                                centres.centres &&
                                centres.centres.map((c) => (
                                  <option
                                    value={c.id}
                                    selected={
                                      values.centreId === c.id ? true : false
                                    }
                                  >
                                    {c.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div style={{ marginTop: 20 }}>
                        <div className="font-18 font-weight-bold">
                          Qualification, Skills And Experience
                        </div>
                        <hr />
                      </div>
                      <div className="d-flex align-item-center justify-content-between">
                        <div className="font-15">
                          Qualifications <span style={{ color: "red" }}>*</span>{" "}
                        </div>
                        <div className="form-group col-lg-8 col-md-12 col-sm-12">
                          <select
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id="qualificationId"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option
                              disabled
                              selected={!values.qualificationId ? true : false}
                            >
                              {" "}
                              -- Select Qualification* --{" "}
                            </option>
                            {qualificationsDropDown &&
                              qualificationsDropDown.qualifications &&
                              qualificationsDropDown?.qualifications.map(
                                (qualification) => (
                                  <option
                                    value={qualification.id}
                                    selected={
                                      values.qualificationId ===
                                      qualification.id
                                        ? true
                                        : false
                                    }
                                  >
                                    {qualification.name}
                                  </option>
                                )
                              )}
                          </select>
                          {touched.qualificationId && (
                            <span style={{ fontSize: 12, color: "red" }}>
                              {errors.qualificationId}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="d-flex align-item-center justify-content-between">
                        <div className="font-15">Skills</div>
                        <div className="form-group col-lg-8 col-md-12 col-sm-12">
                          <input
                            type="text"
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            placeholder="Skills"
                            style={{ border: "1px #7C7B7B solid" }}
                            id="skills"
                            onChange={handleChange}
                            value={values.skills}
                          />
                        </div>
                      </div>

                      <div className="d-flex" style={{ marginLeft: -10 }}>
                        <div className="col-lg-8 col-md-4 col-sm-12">
                          <div className="d-flex justify-content-start align-items-center">
                            <div className="form-group">
                              <label className="font-15">Fresher?</label>
                            </div>

                            <div
                              className="form-group col-lg-6 col-md-10 col-sm-10"
                              style={{ marginLeft: "41%" }}
                            >
                              <form
                                id="profile.fresher"
                                onChange={(event) => {
                                  let selected = JSON.parse(event.target.value);
                                  setFieldValue("profile.fresher", selected);
                                }}
                              >
                                <input
                                  type="radio"
                                  id="yes"
                                  s
                                  name="profile.fresher"
                                  value="true"
                                  defaultChecked={
                                    values.profile &&
                                    values.profile.fresher === true
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  for="yes"
                                  style={{ fontSize: 15, marginLeft: 5 }}
                                >
                                  Yes
                                </label>
                                <input
                                  type="radio"
                                  id="no"
                                  name="profile.fresher"
                                  value="false"
                                  defaultChecked={
                                    values.profile &&
                                    values.profile.fresher === false
                                      ? true
                                      : false
                                  }
                                  style={{ marginLeft: 10 }}
                                />
                                <label
                                  for="yes"
                                  style={{ fontSize: 15, marginLeft: 5 }}
                                >
                                  No
                                </label>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      {selectedCandidateData?.resume && (
                        <div className="d-flex align-item-center justify-content-between">
                          <div className="font-15">Download Resume</div>
                          <div className="form-group col-lg-8 col-md-12 col-sm-12">
                            <>
                              <button
                                type="button"
                                className="btn btn-icon"
                                title="Resume"
                                onClick={() => {
                                  downloadResume(selectedCandidateData?.id);
                                }}
                              >
                                <i className="fa fa-download" />
                              </button>
                              <span>{selectedCandidateData?.resumeName}</span>
                            </>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-item-center justify-content-between">
                      <div className="font-15 d-flex flex-column align-item-center">
                        <span>Upload Resume</span>
                        <span className="font-12">(Maximum limit 5MB)</span>
                      </div>
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <input
                          type="file"
                          placeholder="upload resume"
                          onChange={(e) => {
                            let file = e.target.files[0];
                            if (file.size > 5242880) {
                              setFieldValue(
                                "error",
                                "File size cannot be more than 5mb."
                              );
                              // alert("File size cannot be more than 5mb.")
                              setErrorModal(true);
                              e.target.value = "";
                            } else {
                              setFieldValue("file", file);
                            }
                          }}
                        />
                      </div>
                    </div>
                    {values &&
                      values.profile &&
                      values.profile.fresher === false && (
                        <>
                          <div className="d-flex align-item-center justify-content-between">
                            <div className="font-15">Years of Experience</div>
                            <div className="form-group col-lg-8 col-md-12 col-sm-12">
                              <input
                                type="number"
                                className="form-control col-lg-6 col-md-10 col-sm-10"
                                style={{ border: "1PX #7C7B7B solid" }}
                                placeholder="Years of experience"
                                id="experience"
                                onChange={(event) => {
                                  let yoe = parseFloat(event.target.value);
                                  setFieldValue("experience", yoe);
                                }}
                                value={
                                  values.experience === 0
                                    ? ""
                                    : values.experience
                                }
                              />
                            </div>
                          </div>
                          <div className="d-flex align-item-center justify-content-between">
                            <div className="font-15">Salary (per month)</div>
                            <div className="form-group col-lg-8 col-md-12 col-sm-12">
                              <input
                                type="number"
                                className="form-control col-lg-6 col-md-10 col-sm-10"
                                style={{ border: "1PX #7C7B7B solid" }}
                                placeholder="Current Salary"
                                id="profile.currentSalary"
                                onChange={handleChange}
                                value={
                                  values.profile && values.profile.currentSalary
                                }
                              />
                              {/* <span style={{ fontSize: 14, marginLeft: 5 }}>
                                Per Month
                              </span> */}
                            </div>
                          </div>
                        </>
                      )}

                    <div style={{ marginTop: 20 }}>
                      <div className="font-18 font-weight-bold">
                        Job Preferences <hr />
                      </div>
                    </div>
                    <div className="d-flex align-item-center justify-content-between">
                      <div className="font-15">Job Preferences</div>
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        {jobTypes?.jobTypes && (
                          <Multiselect
                            id="jobType"
                            options={jobTypes.jobTypes || []}
                            className="form-group col-lg-6 col-md-10 col-sm-10"
                            onSelect={(e, m) => {
                              let arr = [];
                              e.map((itm) => arr.push(itm.id));
                              setFieldValue("jobType", arr);
                            }}
                            onRemove={(e, m) => {
                              let arr = [];
                              e.map((itm) => arr.push(itm.id));
                              setFieldValue("jobType", arr);
                            }}
                            selectedValues={
                              selectedJobTypes?.length > 0
                                ? selectedJobTypes
                                : null
                            }
                            displayValue="name"
                            avoidHighlightFirstOption={true}
                            placeholder="-- Select Job Types --"
                            style={{
                              multiselectContainer: {
                                border: "1PX #7C7B7B solid",
                                minHeight: 25,
                                borderRadius: 5,
                                paddingLeft: 5,
                                width: 50 + "%",
                              },
                              searchBox: {
                                minHeight: 25,
                                fontSize: 14,
                                border: 0,
                                color: "black",
                                paddingLeft: 5,
                              },
                              optionContainer: { background: "grey" },
                              option: {
                                color: "white",
                                fontSize: 14,
                                margin: 0,
                                fontWeight: "bold",
                                borderRadius: 5,
                              },
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="font-15">
                        Scheme <span style={{ color: "red" }}>*</span>{" "}
                      </div>
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <select
                          className="form-control col-lg-6 col-md-10 col-sm-10"
                          style={{ border: "1PX #7C7B7B solid" }}
                          id="scheme"
                          onChange={(event) => {
                            let sc = parseInt(event.target.value);
                            setFieldValue("scheme", sc);
                          }}
                        >
                          <option
                            disabled
                            selected={!values.scheme ? true : false}
                          >
                            {" "}
                            -- Select Scheme* --{" "}
                          </option>
                          <option
                            value={1}
                            selected={values.scheme === 1 ? true : false}
                          >
                            DDU-GKY
                          </option>
                          <option
                            value={2}
                            selected={values.scheme === 2 ? true : false}
                          >
                            PMKVY
                          </option>
                          <option
                            value={3}
                            selected={
                              values.scheme === 3 || values.scheme === 4
                                ? true
                                : false
                            }
                          >
                            No scheme
                          </option>
                        </select>
                        {touched.scheme && (
                          <span style={{ fontSize: 12, color: "red" }}>
                            {errors.scheme}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-4 col-md-4 col-sm-12">
                                            <div className="form-group">
                                                <label>Preferred Job Location</label>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between" style={{ marginTop: -20 }}>
                                            <div className="col-lg-3 col-md-3 col-sm-12">
                                                <div className="form-group">
                                                    <select
                                                        classname="form-group col-lg-6 col-md-10 col-sm-10"
                                                        style={{ border: '1PX #7C7B7B solid' }}
                                                        id="profile.preferredCountryId"
                                                        onChange={handleChange}
                                                    >
                                                        <option disabled selected={(values.profile && !values.profile.preferredCountryId) ? true : false}> -- select country -- </option>
                                                        {countriesDropDown && countriesDropDown.countries && countriesDropDown?.countries.map((country) => (
                                                            <option value={country.id} selected={(values.profile && values.profile.preferredCountryId && values.profile.preferredCountryId === country.id) ? true : false}>{country.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12">
                                                <div className="form-group">
                                                    <select
                                                        classname="form-group col-lg-6 col-md-10 col-sm-10"
                                                        style={{ border: '1PX #7C7B7B solid' }}
                                                        id="profile.preferredStateId"
                                                        onChange={handleChange}
                                                    >
                                                        <option disabled selected={(values.profile && !values.profile.preferredStateId) ? true : false}> -- select state -- </option>
                                                        {statesDropDown && statesDropDown.states && statesDropDown?.states.map((state) => (
                                                            <option value={state.id} selected={(values.profile && values.profile.preferredStateId && values.profile.preferredStateId === state.id) ? true : false}>{state.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12">
                                                <div className="form-group">
                                                    <select
                                                        className="form-control"
                                                        style={{ border: '1PX #7C7B7B solid' }}
                                                        id="profile.preferredCityId"
                                                        onChange={handleChange}
                                                    >
                                                        <option disabled selected={!values.cityId ? true : false}> -- select city -- </option>
                                                        {citiesDropDown && citiesDropDown.cities && citiesDropDown?.cities.map((city) => (
                                                            <option value={city.id} selected={(values.profile && values.profile.preferredCityId && values.profile.preferredCityId === city.id) ? true : false}>{city.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12">
                                                <div className="form-group">
                                                    <select
                                                        className="form-control"
                                                        style={{ border: '1PX #7C7B7B solid' }}
                                                        id="profile.preferredLocationId"
                                                        onChange={handleChange}
                                                    >
                                                        <option disabled selected={!values.locationId ? true : false}> -- select location -- </option>
                                                        {locationsDropDown && locationsDropDown.locations && locationsDropDown?.locations.map((location) => (
                                                            <option value={location.id} selected={(values.profile && values.profile.preferredLocationId && values.profile.preferredLocationId === location.id) ? true : false}>{location.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div> */}
                  {(values?.scheme === 1 || values?.scheme === 2) && (
                    <div
                    // className="col-lg-8 col-md-12 col-sm-12"
                    // style={{ marginTop: 10, marginBottom: -20 }}
                    >
                      {/* <div className="font-18 font-weight-bold">
                        Other Details <hr />
                      </div> */}
                      <div style={{ marginTop: 20 }}>
                        <div className="font-18 font-weight-bold">
                          Other Details <hr />
                        </div>
                      </div>
                    </div>
                  )}
                  {values.scheme === 2 && (
                    <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-flex-start align-items-center ml-2">
                      <div className="form-group">
                        <label className="font-15">Interested to join</label>
                      </div>
                      <div
                        className="form-group"
                        style={{ marginLeft: 210, marginTop: 3 }}
                      >
                        <form
                          onChange={handleChange}
                          id="profile.interestedToJoinIn"
                        >
                          <input
                            type="checkbox"
                            id="covid"
                            name="profile.interestedToJoinIn"
                            value="covid"
                            defaultChecked={
                              values.profile &&
                              values.profile.interestedToJoinIn &&
                              values.profile.interestedToJoinIn.indexOf(
                                "covid"
                              ) !== -1
                                ? true
                                : false
                            }
                          />
                          <label
                            for="covid"
                            style={{ fontSize: 15, marginLeft: 5 }}
                          >
                            COVID Frontline Warriors
                          </label>
                          <input
                            type="checkbox"
                            id="home"
                            name="profile.interestedToJoinIn"
                            value="home"
                            defaultChecked={
                              values.profile &&
                              values.profile.interestedToJoinIn &&
                              values.profile.interestedToJoinIn.indexOf(
                                "home"
                              ) !== -1
                                ? true
                                : false
                            }
                            style={{ marginLeft: 10 }}
                          />
                          <label
                            for="home"
                            style={{ fontSize: 15, marginLeft: 5 }}
                          >
                            Home Care Support
                          </label>
                          <input
                            type="checkbox"
                            id="emergency"
                            name="profile.interestedToJoinIn"
                            value="emergency"
                            defaultChecked={
                              values.profile &&
                              values.profile.interestedToJoinIn &&
                              values.profile.interestedToJoinIn.indexOf(
                                "emergency"
                              ) !== -1
                                ? true
                                : false
                            }
                            style={{ marginLeft: 10 }}
                          />
                          <label
                            for="emergency"
                            style={{ fontSize: 15, marginLeft: 5 }}
                          >
                            Emergency Care Support
                          </label>
                        </form>
                      </div>
                    </div>
                  )}
                  {values.scheme === 1 && (
                    <>
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">Address</div>
                          <textarea
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            placeholder="Address"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id="address"
                            onChange={handleChange}
                            value={values.address}
                          />
                        </div>
                      </div>
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">Marital Status</div>
                          <select
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id="profile.maritalStatus"
                            onChange={handleChange}
                          >
                            <option
                              disabled
                              selected={
                                !values?.profile ||
                                (values.profile &&
                                  !("maritalStatus" in values.profile))
                                  ? true
                                  : false
                              }
                            >
                              {" "}
                              -- Select Marital Status --{" "}
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.maritalStatus &&
                                values.profile.maritalStatus === "Single"
                                  ? true
                                  : false
                              }
                            >
                              Single
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.maritalStatus &&
                                values.profile.maritalStatus === "Married"
                                  ? true
                                  : false
                              }
                            >
                              Married
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.maritalStatus &&
                                values.profile.maritalStatus === "Widowed"
                                  ? true
                                  : false
                              }
                            >
                              Widowed
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.maritalStatus &&
                                values.profile.maritalStatus === "Divorced"
                                  ? true
                                  : false
                              }
                            >
                              Divorced
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">Community</div>
                          <select
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id="profile.community"
                            onChange={handleChange}
                          >
                            <option
                              disabled
                              selected={
                                Object.keys(values.profile).length === 0 ||
                                (values.profile &&
                                  !("community" in values.profile))
                                  ? true
                                  : false
                              }
                            >
                              {" "}
                              -- Select Community --{" "}
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.community &&
                                values.profile.community === "SC"
                                  ? true
                                  : false
                              }
                            >
                              SC
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.community &&
                                values.profile.community === "ST"
                                  ? true
                                  : false
                              }
                            >
                              ST
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.community &&
                                values.profile.community === "MBC"
                                  ? true
                                  : false
                              }
                            >
                              MBC
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.community &&
                                values.profile.community === "BC"
                                  ? true
                                  : false
                              }
                            >
                              BC
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.community &&
                                values.profile.community === "OBC"
                                  ? true
                                  : false
                              }
                            >
                              OBC
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.community &&
                                values.profile.community === "OC"
                                  ? true
                                  : false
                              }
                            >
                              OC
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.community &&
                                values.profile.community === "Other"
                                  ? true
                                  : false
                              }
                            >
                              Other
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">Father's Name</div>
                          <input
                            type="text"
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            placeholder="Father's Name"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id="profile.fathersName"
                            onChange={handleChange}
                            value={values.profile && values.profile.fathersName}
                          />
                        </div>
                      </div>
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">Father's Occupation</div>
                          <input
                            type="text"
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            placeholder="Father's Occupation"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id="profile.fathersOccupation"
                            onChange={handleChange}
                            value={
                              values.profile && values.profile.fathersOccupation
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">Mother's Name</div>
                          <input
                            type="text"
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            placeholder="Mother's Name"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id="profile.mothersName"
                            onChange={handleChange}
                            value={values.profile && values.profile.mothersName}
                          />
                        </div>
                      </div>
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">Mothers Occupation</div>
                          <input
                            type="text"
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            placeholder="Mother's Occupation"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id="profile.mothersOccupation"
                            onChange={handleChange}
                            value={
                              values.profile && values.profile.mothersOccupation
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">Alternate Phone</div>
                          <input
                            type="number"
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            placeholder="Alternate Phone no"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id="profile.alternativeContactNumber"
                            onChange={handleChange}
                            value={
                              values.profile &&
                              values.profile.alternativeContactNumber
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group col-lg-6 col-md-4 col-sm-12">
                        <div className="d-flex justify-content-start align-items-center">
                          <label className="font-15">
                            Employed under MNREGA?
                          </label>

                          <div
                            className="form-group"
                            style={{ marginLeft: 140, marginTop: 15 }}
                          >
                            <form
                              id="profile.employedUnderMnrega"
                              onChange={(event) => {
                                let selected = JSON.parse(event.target.value);
                                setFieldValue(
                                  "profile.employedUnderMnrega",
                                  selected
                                );
                              }}
                            >
                              <input
                                type="radio"
                                id="yes"
                                name="profile.employedUnderMnrega"
                                value="true"
                                // style={{ marginLeft: 100 }}
                                defaultChecked={
                                  values.profile &&
                                  values.profile.employedUnderMnrega === true
                                    ? true
                                    : false
                                }
                              />
                              <label
                                for="yes"
                                style={{ fontSize: 15, marginLeft: 5 }}
                              >
                                Yes
                              </label>
                              <input
                                type="radio"
                                id="no"
                                name="profile.employedUnderMnrega"
                                value="false"
                                style={{ marginLeft: 10 }}
                                defaultChecked={
                                  values.profile &&
                                  values.profile.employedUnderMnrega === false
                                    ? true
                                    : false
                                }
                              />
                              <label
                                for="no"
                                style={{ fontSize: 15, marginLeft: 5 }}
                              >
                                No
                              </label>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-6 col-md-4 col-sm-12"
                        style={{ marginTop: -15 }}
                      >
                        <div className="d-flex justify-content-start align-items-center">
                          <label className="font-15">Applied for PIP?</label>
                          <div
                            className="form-group"
                            style={{ marginLeft: 225, marginTop: 15 }}
                          >
                            <form
                              id="profile.appliedForPip"
                              onChange={(event) => {
                                let selected = JSON.parse(event.target.value);
                                setFieldValue(
                                  "profile.appliedForPip",
                                  selected
                                );
                              }}
                            >
                              <input
                                type="radio"
                                id="yes"
                                name="profile.appliedForPip"
                                value="true"
                                // style={{ marginLeft: 100 }}
                                defaultChecked={
                                  values.profile &&
                                  values.profile.appliedForPip === true
                                    ? true
                                    : false
                                }
                              />
                              <label
                                for="yes"
                                style={{ fontSize: 15, marginLeft: 5 }}
                              >
                                Yes
                              </label>
                              <input
                                type="radio"
                                id="no"
                                name="profile.appliedForPip"
                                value="false"
                                defaultChecked={
                                  values.profile &&
                                  values.profile.appliedForPip === false
                                    ? true
                                    : false
                                }
                                style={{ marginLeft: 10 }}
                              />
                              <label
                                for="yes"
                                style={{ fontSize: 15, marginLeft: 5 }}
                              >
                                No
                              </label>
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* <div className="d-flex justify-content-between">
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label className='font-14 font-weight-bold'>Employed under MNREGA?</label>
                                                    </div>
                                                    <div className="form-group" style={{ marginTop: -20 }}>
                                                        <form
                                                            id="profile.employedUnderMnrega"
                                                            onChange={(event) => {
                                                                let selected = JSON.parse(event.target.value)
                                                                setFieldValue('profile.employedUnderMnrega', selected)
                                                            }}
                                                        >
                                                            <input
                                                                type="radio"
                                                                id="yes"
                                                                name="profile.employedUnderMnrega"
                                                                value="true"
                                                                defaultChecked={(values.profile && values.profile.employedUnderMnrega === true) ? true : false}
                                                            />
                                                            <label for="yes" style={{ fontSize: 15, marginLeft: 5 }}>Yes</label>
                                                            <input
                                                                type="radio"
                                                                id="no"
                                                                name="profile.employedUnderMnrega"
                                                                value="false"
                                                                style={{ marginLeft: 10 }}
                                                                defaultChecked={(values.profile && values.profile.employedUnderMnrega === false) ? true : false}
                                                            />
                                                            <label for="no" style={{ fontSize: 15, marginLeft: 5 }}>No</label>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label className='font-14 font-weight-bold'>Applied for PIP?</label>
                                                    </div>
                                                    <div className="form-group" style={{ marginTop: -20 }}>
                                                        <form
                                                            id="profile.appliedForPip"
                                                            onChange={(event) => {
                                                                let selected = JSON.parse(event.target.value)
                                                                setFieldValue('profile.appliedForPip', selected)
                                                            }}
                                                        >
                                                            <input type="radio" id="yes" name="profile.appliedForPip" value="true" defaultChecked={(values.profile && values.profile.appliedForPip === true) ? true : false} />
                                                            <label for="yes" style={{ fontSize: 15, marginLeft: 5 }}>Yes</label>
                                                            <input type="radio" id="no" name="profile.appliedForPip" value="false" defaultChecked={(values.profile && values.profile.appliedForPip === false) ? true : false} style={{ marginLeft: 10 }} />
                                                            <label for="yes" style={{ fontSize: 15, marginLeft: 5 }}>No</label>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label className='font-14 font-weight-bold'>Applied for 100 days card?</label>
                                                    </div>
                                                    <div className="form-group" style={{ marginTop: -20 }}>
                                                        <form
                                                            id="profile.appliedForHundredDaysCard"
                                                            onChange={(event) => {
                                                                let selected = JSON.parse(event.target.value)
                                                                setFieldValue('profile.appliedForHundredDaysCard', selected)
                                                            }}
                                                        >
                                                            <input type="radio" id="yes" name="profile.appliedForHundredDaysCard" value="true" defaultChecked={(values.profile && values.profile.appliedForHundredDaysCard === true) ? true : false} />
                                                            <label for="yes" style={{ fontSize: 15, marginLeft: 5 }}>Yes</label>
                                                            <input type="radio" id="no" name="profile.appliedForHundredDaysCard" value="false" defaultChecked={(values.profile && values.profile.appliedForHundredDaysCard === false) ? true : false} style={{ marginLeft: 10 }} />
                                                            <label for="yes" style={{ fontSize: 15, marginLeft: 5 }}>No</label>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div> */}
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">Graduation Year</div>
                          <select
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id="profile.graduationYear"
                            onChange={(event) => {
                              let grad = parseInt(event.target.value);
                              setFieldValue("profile.graduationYear", grad);
                            }}
                          >
                            <option
                              disabled
                              selected={
                                Object.keys(values.profile).length === 0 ||
                                (values.profile &&
                                  !("graduationYear" in values.profile))
                                  ? true
                                  : false
                              }
                            >
                              {" "}
                              -- Select Graduation Year --{" "}
                            </option>
                            <option
                              selected={
                                (values.profile &&
                                  values.profile.graduationYear) === 2015
                                  ? true
                                  : false
                              }
                            >
                              2015
                            </option>
                            <option
                              selected={
                                (values.profile &&
                                  values.profile.graduationYear) === 2016
                                  ? true
                                  : false
                              }
                            >
                              2016
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.graduationYear === 2017
                                  ? true
                                  : false
                              }
                            >
                              2017
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.graduationYear === 2018
                                  ? true
                                  : false
                              }
                            >
                              2018
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.graduationYear === 2019
                                  ? true
                                  : false
                              }
                            >
                              2019
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.graduationYear === 2020
                                  ? true
                                  : false
                              }
                            >
                              2020
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.graduationYear === 2021
                                  ? true
                                  : false
                              }
                            >
                              2021
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group col-lg-8 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <div className="font-15">Qualification Stream</div>
                          <select
                            className="form-control col-lg-6 col-md-10 col-sm-10"
                            style={{ border: "1PX #7C7B7B solid" }}
                            id="profile.qualificationStream"
                            onChange={handleChange}
                          >
                            <option
                              disabled
                              selected={
                                Object.keys(values.profile).length === 0 ||
                                (values.profile &&
                                  !("qualificationStream" in values.profile))
                                  ? true
                                  : false
                              }
                            >
                              {" "}
                              -- Select Qualification Stream --{" "}
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.qualificationStream === "Science"
                                  ? true
                                  : false
                              }
                            >
                              Science
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.qualificationStream ===
                                  "Commerce"
                                  ? true
                                  : false
                              }
                            >
                              Commerce
                            </option>
                            <option
                              selected={
                                values.profile &&
                                values.profile.qualificationStream === "Arts"
                                  ? true
                                  : false
                              }
                            >
                              Arts
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-4 col-sm-12 mt-10">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="form-group">
                            <label className="font-15">
                              Pending Academic Arrears?
                            </label>
                          </div>
                          <div
                            className="form-group"
                            style={{ marginLeft: 140, marginTop: 5 }}
                          >
                            <form
                              id="profile.pendingAcademicArrears"
                              onChange={(event) => {
                                let selected = JSON.parse(event.target.value);
                                setFieldValue(
                                  "profile.pendingAcademicArrears",
                                  selected
                                );
                              }}
                            >
                              <input
                                type="radio"
                                id="yes"
                                name="profile.pendingAcademicArrears"
                                value="true"
                                // style={{ marginLeft: 75 }}
                                defaultChecked={
                                  values.profile &&
                                  values.profile.pendingAcademicArrears === true
                                    ? true
                                    : false
                                }
                              />
                              <label
                                for="yes"
                                style={{ fontSize: 15, marginLeft: 5 }}
                              >
                                Yes
                              </label>
                              <input
                                type="radio"
                                id="no"
                                name="profile.pendingAcademicArrears"
                                value="false"
                                defaultChecked={
                                  values.profile &&
                                  values.profile.pendingAcademicArrears ===
                                    false
                                    ? true
                                    : false
                                }
                                style={{ marginLeft: 10 }}
                              />
                              <label
                                for="yes"
                                style={{ fontSize: 15, marginLeft: 5 }}
                              >
                                No
                              </label>
                            </form>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-12 mb-15">
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ marginTop: 15 }}
                      onClick={handleSubmit}
                    >
                      {selectedCandidateData?.id ? "Save" : "Add"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ marginTop: 15, marginLeft: 5 }}
                      onClick={() => setAddCandidateView(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`tab-pane fade ${
                  !editTimeline ? "show active" : ""
                }`}
                id="add-event"
                role="tabpanel"
              >
                <Activity
                  setAddCandidateView={setAddCandidateView}
                  timeLineData={timeLineData}
                  id={candidateId}
                  refetchTimeline={refetchTimeline}
                  editTimeline={editTimeline}
                  selectedCandidateData={selectedCandidateData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddNewCandidate = (props) => {
  const { setAddCandidateView, selectedCandidateData, editTimeline } = props;
  const [error, setError] = useState("");
  const [jobTypes, setJobTypes] = useState([]);
  const { roles } = isJwtTokenValid();

  useEffect(() => {
    if (selectedCandidateData?.jobType) {
      console.log(selectedCandidateData.jobType);
      let temp = [];
      selectedCandidateData.jobType.map((item) => {
        temp.push(item.jobType.id);
      });
      setJobTypes(temp);
    }
  }, [selectedCandidateData]);

  const [addUser] = useMutation(ADD_USER, {
    onCompleted: (data) => {
      const { createUser } = data || {};
      const { status, error } = createUser;
      console.log({ status, error });
      if (status) {
        setAddCandidateView(false);
        setError("");
      } else {
        setError(error);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      const { updateUser } = data || {};
      const { status, error } = updateUser;
      console.log("hiAdduser----------------------->>", { status, error });
      if (status) {
        setAddCandidateView(false);
        setError("");
      } else {
        setError(error);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const AddNewCandidateWrapped = withFormik({
    mapPropsToValues: (props) => {
      const { selectedCandidateData } = props;
      console.log({ selectedCandidateData });
      const me = getLoggedInUserData();

      return {
        firstName: selectedCandidateData?.firstName || "",
        lastName: selectedCandidateData?.lastName || "",
        mobile: selectedCandidateData?.mobile || "",
        email: selectedCandidateData?.email || "",
        dob: selectedCandidateData?.dob || "",
        gender: selectedCandidateData?.gender || "",
        locationId: selectedCandidateData.location?.id || "",
        stateId: selectedCandidateData.state?.id || "",
        countryId: selectedCandidateData.country?.id || "",
        cityId: selectedCandidateData.city?.id || "",
        qualificationId: selectedCandidateData.qualification?.id || "",
        profile: selectedCandidateData.profile
          ? JSON.parse(selectedCandidateData.profile) === null
            ? {}
            : JSON.parse(selectedCandidateData.profile)
          : {},
        source:
          roles[0] === "pia_user" &&
          Object.keys(selectedCandidateData).length === 0
            ? 1
            : selectedCandidateData?.source || "",
        scheme: selectedCandidateData?.scheme || 0,
        aadhar: selectedCandidateData?.aadhar || "",
        address: selectedCandidateData?.address || "",
        experience: selectedCandidateData?.experience || 0,
        skills: selectedCandidateData?.skills || "",
        // organizationId: selectedCandidateData?.organization?.id || '',
        organizationId:
          roles[0] === "pia_user" &&
          Object.keys(selectedCandidateData).length === 0 &&
          me?.organization
            ? me.organization.id
            : selectedCandidateData?.organization?.id || "",
        jobType: jobTypes,
        centreId: selectedCandidateData?.centre?.id || "",
        error: error,
        forOrganizationId: selectedCandidateData?.forOrganization?.id || "",
        file: null,
      };
    },

    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Please enter first name"),
      mobile: Yup.string()
        .required("Please enter mobile number")
        .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
      email: Yup.string().email("Enter a valid email ID"),
      dob: Yup.date()
        .required("Please enter DOB")
        .max(moment().format("YYYY-MM-DD")),
      gender: Yup.string().required("Please select gender"),
      countryId: Yup.string().required("Please select country"),
      stateId: Yup.string().required("Please select state"),
      cityId: Yup.string().required("Please select city"),
      locationId: Yup.string().required("Please select location"),
      qualificationId: Yup.string().required("Please select qualification"),
      source: Yup.number().required("Please select source"),
      // forSource: Yup.number().required("Please select For source"),
      scheme: Yup.number()
        .required("Please select scheme")
        .test({
          name: "scheme test",
          message: "Please select scheme",
          test: function (value) {
            return value > 0;
          },
        }),
      aadhar: Yup.string()
        .matches(/^[0-9]{12}$/, "Aadhar number must be exactly 12 digits")
        .when("scheme", {
          is: (scheme) => scheme === 1 || scheme === 2,
          then: Yup.string().required("Please enter your aadhar number"),
        }),
      // organizationId: Yup.string().when('source', {
      //     is: (source) => source === 1 || source === 2,
      //     then: Yup.string().required('Please select a PIA')
      // })
    }),

    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);

      const { selectedCandidateData } = props;
      delete values["error"];

      if (selectedCandidateData?.id) {
        updateUser({
          variables: {
            payload: {
              ...values,
              profile: JSON.stringify(values.profile),
              id: selectedCandidateData?.id,
              firstName: values.firstName.trim(),
              lastName: values.lastName.trim(),
              email: values.email.trim(),
            },
          },
        });
      } else {
        addUser({
          variables: {
            payload: {
              ...values,
              roles: ["candidate"],
              profile: JSON.stringify(values.profile),
              firstName: values.firstName.trim(),
              lastName: values.lastName.trim(),
              email: values.email.trim(),
            },
          },
        });
      }
    },
  })(AddNewCandidateForm);

  return (
    <AddNewCandidateWrapped
      setAddCandidateView={setAddCandidateView}
      selectedCandidateData={selectedCandidateData}
      editTimeline={editTimeline}
      roles={roles}
    />
  );
};

export default AddNewCandidate;
