import { gql } from "@apollo/client";

export const UPSERT_COMPANY_DETAILS = gql`
  mutation update_employer_company(
    $payload: EmployerOrganizationInput!
    $file: Upload
  ) {
    updateEmployerCompany(payload: $payload, file: $file) {
      status
      error
    }
  }
`;

export const GET_COMPANY_DETAILS = gql`
  query user($id: String!) {
    user(id: $id) {
      organization {
        id
        name
        description
        website
        address
        country {
          id
          name
        }
        state {
          id
          name
        }
        city {
          id
          name
        }
        pincode
      }
    }
  }
`;

export const GET_CONTACT_DETAILS = gql`
  query user($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      mobile
      email
      profile
    }
  }
`;

export const GET_COMPANY_LOGO = gql`
  query getOrganizationDocuments {
    organizationDocuments {
      documentType
      document
    }
  }
`;
