import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import CommunityPost from './CommunityPost';
import { useQuery, useMutation } from '@apollo/client';
import { ADD_COMMUNITY, GET_COMMUNITIES, DELETE_COMMUNITY } from '../../graphql/community';
import AddCommunityForm from './AddCommunity';
import $ from "jquery";
import { Modal } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { isJwtTokenValid } from '../../utils';

const Community = (props) => {

    const { fixNavbar } = props;
    const [communityView, setCommunityView] = useState(false);
    const [communities, setCommunities] = useState([]);
    const [selectedCommunity, setSelectedCommunity] = useState('')
    const [selectedCommunityData, setSelectedCommunityData] = useState({})
    const [page, setPage] = React.useState(0);
    const [rowCount, setRowCount] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [filter, setFilter] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [addCommunityView, setAddCommunityView] = useState(false);
    const { roles, hasExpired } = isJwtTokenValid()

    const { data, refetch,loading:communityLoading } = useQuery(GET_COMMUNITIES, {
        variables: {
            // page: page + 1,
            // limit: pageSize,
            filter: filter
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    });

    const [deleteCommunity] = useMutation(DELETE_COMMUNITY, {
        onCompleted: (data) => {
            const { deleteCommunity } = data;
            const { status, error } = deleteCommunity;
            console.log(">>>>>>>>>>>>", status, error)
            if (status) {
                setConfirmModal(false)
                refetch()
            }
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only'
    })

    useEffect(() => {
        if (data?.allCommunities?.communities) {
            setRowCount(data.allCommunities.count)
            setCommunities(data.allCommunities.communities)
        }
    }, [data])

    useEffect(() => {
        if (addCommunityView === false) {
            setSelectedCommunity('')
            setSelectedCommunityData({})

        }
    }, [addCommunityView])

    const CommunityCard = (props) => {

        const { o } = props;

        return (
            <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="card">
                    <div className="card-body text-center ribbon">
                        <span
                            className="avatar avatar-cyan"
                            data-toggle="tooltip"
                            data-placement="top"
                            data-original-title="Avatar Name"
                        >
                            {o.title ? o.title.substring(0, 1) : '-'}
                        </span>
                        <h6 className="mt-3 mb-0">{o.title}</h6>
                        <span>{o.description}</span>
                        <ul className="mt-3 list-unstyled d-flex justify-content-center align-items-center">
                            {/* <li><a className="p-3" target="_blank"><i className="fa fa-edit" /></a></li>
                            <li><a className="p-3" target="_blank" ><i className="fa fa-trash" /></a></li> */}
                            <li>
                                <button
                                    type="button"
                                    className="btn btn-icon"
                                    title="Edit"
                                    onClick={() => {
                                        setSelectedCommunity(o.id)
                                        setSelectedCommunityData(o)
                                        setAddCommunityView(true)
                                    }}
                                >
                                    <i className="fa fa-edit" />
                                </button>
                            </li>
                            <li>
                                <button
                                    type="button"
                                    className="btn btn-icon"
                                    title="Delete"
                                    onClick={() => {
                                        setSelectedCommunity(o.id)
                                        setConfirmModal(true)
                                    }}
                                >
                                    <i className="fa fa-trash" />
                                </button>
                            </li>
                        </ul>
                        <div className="row text-center mt-4" style={{ position: 'relative', left: '35%', marginTop: -10 }}>
                            <div className="d-flex justify-content-flex-start">
                                <label className="mb-0">Threads  :</label>
                                <h4 className="font-18" style={{ marginLeft: 10, marginTop: 2, color: 'purple' }}>{o.threads}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (<>
        {hasExpired ? <Redirect to="/login" /> :
            <>
                <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
                    <div className="container-fluid" >
                        <div className="row clearfix" style={{ marginLeft: 20 }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <ul className="nav nav-tabs page-header-tab">
                                    <li className="nav-item">
                                        <a
                                            className="nav-link active"
                                            id="user-tab"
                                            data-toggle="tab"
                                            href="#edit-candidate"
                                            onClick={() => {
                                                if (communityView) {
                                                    setCommunityView(false);
                                                }
                                            }}
                                        >
                                            Feed
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="user-tab" data-toggle="tab"
                                            onClick={() => {
                                                if (!communityView) {
                                                    setCommunityView(true);
                                                }
                                            }}
                                        >
                                            Communities
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {(communityView && !addCommunityView) && <div className="row row-deck clearfix">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Search Communities" onChange={(event) => { setFilter({ ...filter, title__icontains: event.target.value }) }} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-5 col-sm-12">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{ height: 30, marginBottom: 10 }}
                                                    onClick={() => setAddCommunityView(true)}
                                                >
                                                    <i className="fe fe-plus mr-2" />
                                                    Add Community
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {communities && communities.map((o) => <CommunityCard o={o} key={o.id} />)}
                            <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
                                <Modal.Header>
                                    <Modal.Title>Confirm</Modal.Title>
                                    <button type="button" class="close" aria-label="Close" onClick={() => setConfirmModal(false)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </Modal.Header>
                                <Modal.Body>Are you sure you want to delete the community?</Modal.Body>
                                <Modal.Footer>
                                    <button type="button" className="btn btn-outline-success" onClick={() =>
                                        deleteCommunity({
                                            variables: {
                                                id: selectedCommunity,
                                            },
                                        })
                                    }>
                                        Yes
                                    </button>
                                    <button type="button" class="btn btn-outline-danger" onClick={() => setConfirmModal(false)}>No</button>
                                </Modal.Footer>
                            </Modal>
                        </div>}
                        {communityLoading && <><p style={{ font: 15 }}>Loading... </p>
                                    <div
                                      class="spinner-border ml-auto"
                                      role="status"
                                      aria-hidden="true"
                                    ></div>
                                    </>}
                        {!communityLoading && addCommunityView && <AddCommunityForm refetchCommunities={refetch} addCommunityView={addCommunityView} setAddCommunityView={setAddCommunityView} selectedCommunityData={selectedCommunityData} />}
                        {!communityLoading && !communityView && <CommunityPost communities={communities} refetchCommunities={refetch} />}
                    </div>
                </div>
            </>}
    </>
    )
}
export default Community;