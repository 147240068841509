import React from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import ciel_logo_white from "../../assets/imagesNew/ciel_logo_white.png";
import registration_left_img from "../../assets/imagesNew/registration-left-img.svg";
import theme from "../../styles/theme";

const LeftPartInfo = (props) => {
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid
      container
      spacing={matchesSm ? 4 : 8}
      sx={{ pt: 10, px: { sm: 3, md: 5 } }}
    >
      <Grid item lg={12} md={12} sm={12}>
        <img src={ciel_logo_white} alt="logo" />
      </Grid>
      <Grid item lg={9} md={9} sm={10}>
        <Typography color="secondary" fontSize="40px" fontWeight={700}>
          Let us know a little bit about yourself
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={3} />
      <Grid item lg={12} md={12} sm={12} display="flex" justifyContent="center">
        <div style={{ position: "absolute", bottom: 0 }}>
          <img
            src={registration_left_img}
            alt="registeration"
            width={matchesSm ? 35 : ""}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default LeftPartInfo;
