import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Filters from "./Filters";
import Results from "./Results";
import JobSelectionModal from "./JobSelectionModal";
import SuccessModal from "./SuccessModal";
import NoResultView from "./NoResultView";
import EmployerNavbar from "../../common/EmployerNavBar";
import NoBorderDropdown from "../../common/NoBorderDropdown";
import colors from "../../styles/colors";

import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_USERS } from "../../graphql/users";
import { GET_JOBS_MINIMAL, ADD_CANDIDATE_TO_JOB } from "../../graphql/jobs";
import getStore from "../../zustandStore";

function SearchCandidates(props) {
  const setLoading = getStore((state) => state.setLoading);

  const [sortBy, setSortBy] = useState("latest");
  const [jobSelectionModal, setJobSelectionModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [filters, setFilters] = useState({});
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);

  const [getCandidates, { loading, data: candidateList }] = useLazyQuery(
    GET_USERS,
    {
      nextFetchPolicy: "network-only",
      fetchPolicy: "network-only",
      variables: {
        page: page + 1,
        limit: pageSize,
        is_candidate: true,
        filter: {
          qualificationId: filters.qualification || undefined,
          cityId: filters.city || undefined,
          gender: filters.gender || undefined,
          experience__gt:
            filters.minExperience > 0 ? filters.minExperience : undefined,
          experience__lt:
            filters.maxExperience > 0 ? filters.maxExperience : undefined,
          profile__currentSalary__gt:
            filters.minRemuneration > 0 ? filters.minRemuneration : undefined,
          profile__currentSalary__lt:
            filters.minRemuneration > 0 ? filters.maxRemuneration : undefined,
          userjobtype__job_type__name__in: filters.jobType
            ? [filters.jobType]
            : undefined,
        },
        sorted: sortBy === "latest" ? "-created_date" : "created_date",
      },
    }
  );

  const [getJobs, { data: jobList }] = useLazyQuery(GET_JOBS_MINIMAL, {
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only",
    variables: {
      exclude_candidate: selectedCandidate,
      filter: {},
    },
  });

  const [addJobCandidate] = useMutation(ADD_CANDIDATE_TO_JOB, {
    onCompleted: async (data) => {
      const { createJobCandidate } = data || {};
      const { status, error } = createJobCandidate;
      console.log({ status, error });
      allJobsActions(false, null);
      setSuccessModal(true);
      setJobs([]);
      getCandidates();
      setSelectedJob(null);
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (candidateList?.users?.users?.length > 0) {
      setCount(candidateList.users.count);

      let users = candidateList?.users?.users || [];

      users = users.map((u) => {
        const {
          firstName,
          lastName,
          qualification,
          experience,
          city,
          id,
          skills,
          createdDate,
        } = u;

        const { name: cityName } = city || {};
        const { name: qualificationName } = qualification || {};

        return {
          id,
          firstName,
          lastName,
          experience,
          city: cityName,
          createdDate,
          skills,
          qualification: qualificationName,
        };
      });
      setCandidates(users);
    }
  }, [candidateList]);

  useEffect(() => {
    if (jobList?.jobs?.jobs) {
      setJobs(jobList.jobs.jobs);
    }
  }, [jobList]);

  const allJobsActions = (open, candidateId) => {
    setJobSelectionModal(open);
    setSelectedCandidate(candidateId);
    setSelectedJob(null);
    setJobs([]);
  };

  useEffect(() => {
    if (selectedCandidate) {
      getJobs();
    }
  }, [getJobs, selectedCandidate]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={9} md={9} sm={9} xs={12}>
        <Typography fontSize="24px" color={colors.dark} fontWeight={500}>
          Search Candidates {count > 0 ? `(${count})` : ""}
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={12}>
        <Grid
          container
          spacing={1}
          justifyContent={{ xs: "flex-start", sm: "flex-end" }}
          alignItems="center"
        >
          <Grid item>
            <Typography fontSize="14px">Sort by :</Typography>
          </Grid>
          <Grid item>
            <NoBorderDropdown
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              bgColor={colors.secondary}
              textColor={colors.gray}
              fontSize="14px"
              dropdownArr={[
                { id: "latest", name: "Latest" },
                { id: "oldest", name: "Oldest" },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={3} md={3} sm={4} xs={12}>
        <Filters
          setFilters={setFilters}
          filters={filters}
          search={() => {
            getCandidates();
            setPage(0);
            setPageSize(10);
          }}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </Grid>
      <Grid item lg={9} md={9} sm={8} xs={12}>
        {candidates && candidates.length > 0 ? (
          <Results
            setJobSelectionModal={allJobsActions}
            count={count}
            candidates={candidates}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        ) : (
          <NoResultView />
        )}
      </Grid>
      <JobSelectionModal
        open={jobSelectionModal}
        setOpen={(open) => allJobsActions(open, null)}
        setSuccessModal={setSuccessModal}
        jobs={jobs}
        addCandidate={() => {
          addJobCandidate({
            variables: {
              payload: [
                {
                  jobId: selectedJob,
                  candidateId: selectedCandidate,
                  status: 4,
                },
              ],
            },
          });
        }}
        setSelectedJob={setSelectedJob}
        selectedJob={selectedJob}
      />
      <SuccessModal open={successModal} setOpen={setSuccessModal} />
    </Grid>
  );
}

const EmployerSearchCandidates = (props) => {
  return <EmployerNavbar mainComponent={<SearchCandidates />} />;
};

export default EmployerSearchCandidates;
