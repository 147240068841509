import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import NoBorderDropdown from "../../common/NoBorderDropdown";
import search_job_home_page from "../../assets/imagesNew/search-job-home-page.svg";
import colors from "../../styles/colors";
import { GET_CITIES_JOBTYPES } from "../../graphql/meta";
import { useQuery } from "@apollo/client";

const useStyles = makeStyles(() => ({
  popper: { width: "250px !important" }, // a style rule
}));

const SearchJob = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [selectedCity, setSelectedCity] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");
  const [citiesList, setCitiesList] = useState([{ id: "0", name: "City" }]);
  const [jobTypesList, setJobTypesList] = useState([
    { id: "0", name: "Job Type" },
  ]);
  const [searchText, setSearchText] = useState("");
  const { data: dropDownData } = useQuery(GET_CITIES_JOBTYPES, {
    variables: {},
    // fetchPolicy: "network-only",
    // nextFetchPolicy: "network-only",
  });

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  useEffect(() => {
    const { allCities: cities, jobTypes: jobs } = dropDownData || {};

    if (cities && cities.length > 0) {
      setSelectedCity("0");
      setCitiesList([...citiesList, ...cities]);
    }

    if (jobs && jobs.length > 0) {
      setSelectedJobType("0");
      setJobTypesList([...jobTypesList, ...jobs]);
    }
  }, [dropDownData]);

  return (
    <Box
      sx={{
        backgroundColor: colors.purple,
        px: { xs: 3, sm: 20 },
        py: { xs: 5, sm: 7 },
      }}
    >
      <Grid container spacing={8}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <img src={search_job_home_page} alt="search-job" />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <Typography
            fontSize="40px"
            fontWeight={700}
            color="#FFAB97"
            textAlign="center"
          >
            10,235
          </Typography>
          <Typography
            fontSize="26px"
            fontWeight={700}
            color="secondary"
            textAlign="center"
          >
            Total Jobs
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <Typography
            fontSize="40px"
            fontWeight={700}
            color="#FFAB97"
            textAlign="center"
          >
            145
          </Typography>
          <Typography
            fontSize="26px"
            fontWeight={700}
            color="secondary"
            textAlign="center"
          >
            Today's Jobs
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <Typography
            fontSize="40px"
            fontWeight={700}
            color="#FFAB97"
            textAlign="center"
          >
            1,235
          </Typography>
          <Typography
            fontSize="26px"
            fontWeight={700}
            color="secondary"
            textAlign="center"
          >
            Matched Candidates
          </Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={6}
          sx={{ display: { xs: "flex", sm: "none" }, justifyContent: "center" }}
        >
          <Autocomplete
            autoComplete
            disableClearable
            filterOptions={filterOptions}
            options={jobTypesList ? jobTypesList : []}
            getOptionLabel={(option) => option.name}
            value={
              jobTypesList.find((x) => x.id === selectedJobType) || {
                id: "",
                name: "",
              }
            }
            onChange={(event, value) => setSelectedJobType(value.id)}
            size="small"
            renderInput={(params) => <TextField {...params} size="small" />}
            fullWidth
          />
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={6}
          sx={{ display: { xs: "flex", sm: "none" }, justifyContent: "center" }}
        >
          <Autocomplete
            autoComplete
            disableClearable
            filterOptions={filterOptions}
            options={citiesList ? citiesList : []}
            getOptionLabel={(option) => option.name}
            value={
              citiesList.find((x) => x.id === selectedCity) || {
                id: "",
                name: "",
              }
            }
            onChange={(event, value) => setSelectedCity(value.id)}
            size="small"
            fullWidth
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="center"
          display="flex"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: colors.secondary,
              borderRadius: "37px",
              py: 1,
              width: { lg: "72%", sm: "80%", xs: "30%" },
            }}
          >
            <Autocomplete
              autoComplete
              disableClearable
              filterOptions={filterOptions}
              options={jobTypesList ? jobTypesList : []}
              getOptionLabel={(option) => option.name}
              value={
                jobTypesList.find((x) => x.id === selectedJobType) || {
                  id: "",
                  name: "",
                }
              }
              onChange={(event, value) => setSelectedJobType(value.id)}
              size="small"
              // classes={{ popper: classes.popper }}
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                width: { lg: "40%", sm: "35%" },
                display: { xs: "none", sm: "flex" },
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
            <Autocomplete
              autoComplete
              disableClearable
              filterOptions={filterOptions}
              options={citiesList ? citiesList : []}
              getOptionLabel={(option) => option.name}
              value={
                citiesList.find((x) => x.id === selectedCity) || {
                  id: "",
                  name: "",
                }
              }
              onChange={(event, value) => setSelectedCity(value.id)}
              size="small"
              // classes={{
              //   popper: classes.popper,
              // }}
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                width: { lg: "40%", sm: "35%" },
                display: { xs: "none", sm: "flex" },
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
            <Button
              variant="contained"
              disabled={selectedCity === "0" && selectedJobType === "0"}
              disableElevation
              sx={{ backgroundColor: colors.purple, ml: { lg: 1, xs: 0 } }}
              onClick={() =>
                history.push({
                  pathname: "/search-results",
                  search: `?city=${selectedCity}&search=${searchText}&job=${selectedJobType}`,
                })
              }
            >
              Search
            </Button>
          </Box>
          {/* <TextField
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              borderRadius: "37px",
              display: { xs: "none", sm: "block" },
            }}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ pl: 1, borderLeft: "2px solid #E0E0E0" }}
                  >
                    <Autocomplete
                      autoComplete
                      disableClearable
                      filterOptions={filterOptions}
                      options={jobTypesList ? jobTypesList : []}
                      getOptionLabel={(option) => option.name}
                      value={
                        jobTypesList.find((x) => x.id === selectedJobType) || {
                          id: "",
                          name: "",
                        }
                      }
                      onChange={(event, value) => setSelectedJobType(value.id)}
                      size="small"
                      classes={{
                        popper: classes.popper,
                      }}
                      sx={{ width: 150 }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                    <Autocomplete
                      autoComplete
                      disableClearable
                      filterOptions={filterOptions}
                      options={citiesList ? citiesList : []}
                      getOptionLabel={(option) => option.name}
                      value={
                        citiesList.find((x) => x.id === selectedCity) || {
                          id: "",
                          name: "",
                        }
                      }
                      onChange={(event, value) => setSelectedCity(value.id)}
                      size="small"
                      classes={{
                        popper: classes.popper,
                      }}
                      sx={{ width: 150 }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                    <Button
                      variant="contained"
                      disabled={selectedCity === "0" && selectedJobType === "0"}
                      disableElevation
                      sx={{ backgroundColor: colors.purple }}
                      onClick={() =>
                        history.push({
                          pathname: "/search-results",
                          search: `?city=${selectedCity}&search=${searchText}&job=${selectedJobType}`,
                        })
                      }
                    >
                      Search
                    </Button>
                  </Box>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              borderRadius: "37px",
              display: { xs: "block", sm: "none" },
            }}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    disabled={selectedCity === "0" && selectedJobType === "0"}
                    disableElevation
                    sx={{ backgroundColor: colors.purple }}
                    onClick={() =>
                      history.push({
                        pathname: "/search-results",
                        search: `?city=${selectedCity}&search=${searchText}&job=${selectedJobType}`,
                      })
                    }
                  >
                    Search
                  </Button>
                </InputAdornment>
              ),
            }}
          /> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchJob;
