import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import Modal from "../../common/CommonModal";
import circle_tick from "../../assets/imagesNew/circle-tick.svg";
import colors from "../../styles/colors";

const SuccessModal = (props) => {
  const { open, setOpen } = props;
  const history = useHistory();
  return (
    <Modal open={open} setOpen={setOpen}>
      <Grid container spacing={2}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <img src={circle_tick} alt="tick" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            fontSize="24px"
            fontWeight={500}
            color={colors.dark}
            textAlign="center"
          >
            Awesome!
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            fontSize="16px"
            color={colors.lightGray}
            textAlign="center"
          >
            Your Job has been posted successfully!
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <Button
            variant="contained"
            sx={{ width: "60%" }}
            onClick={() => setOpen(false)}
          >
            Add Another Job Post
          </Button>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <Button onClick={() => history.push("/employer-dashboard")}>
            Back to Dashboard
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SuccessModal;
