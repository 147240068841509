import jwt_decode from "jwt-decode";
import moment from "moment";

export const isJwtTokenValid = () => {
  const token = localStorage.getItem("token") || "";
  let hasExpired = true;
  let isVerified = false;
  let roles = [];
  let lastLogin = null;
  let username = "";

  if (Boolean(token)) {
    const decoded = jwt_decode(token);
    if (Date.now() < decoded.exp * 1000) {
      hasExpired = false;
      if (Boolean(decoded.verified)) {
        isVerified = true;
      }
      if (Boolean(decoded.roles)) {
        roles = decoded.roles;
      }

      if (Boolean(decoded.lastLogin)) {
        lastLogin = decoded.lastLogin;
      }
      if (Boolean(decoded.username)) {
        username = decoded.username;
      }
    }
  }

  return { hasExpired, isVerified, roles, lastLogin, username };
};

export const clearSession = () => {
  ["token"].forEach((k) => localStorage.removeItem(k));
  ["me"].forEach((k) => localStorage.removeItem(k));
};

export const isTokenPresent = () => {
  return Boolean(localStorage.getItem("token"));
};

export const getDefaultRoute = () => {
  const { hasExpired, roles } = isJwtTokenValid();
  if (!hasExpired) {
    if (roles[0] === "pia_user") {
      return "/candidates";
    } else if (roles[0] === "employer_user") {
      return "/employer-dashboard";
    } else {
      return "/dashboard";
    }
  }
  return "/";
};

export const getPrettyDate = (d) => {
  let dMoment = moment(d);
  return dMoment.format("DD MMM YYYY hh:mm A");
};

export const getMonthAndYear = (d) => {
  let dMoment = moment(d);
  return dMoment.format("MMM YYYY");
};

export const downloadCSV = (csvContent, filename) => {
  var hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
  hiddenElement.target = "_blank";
  hiddenElement.download = filename;
  hiddenElement.click();
};

export const downloadExcel = (excelContent, filename) => {
  var hiddenElement = document.createElement("a");
  hiddenElement.href =
    "data:application/vnd.ms-excel;base64," + encodeURI(excelContent);
  hiddenElement.target = "_blank";
  hiddenElement.download = filename;
  hiddenElement.click();
};

export const downloadAny = (fileContent, filename, fileType) => {
  var hiddenElement = document.createElement("a");
  // var file = new Blob([fileContent], {type: fileType});
  hiddenElement.href = "data:" + fileType + ";base64," + encodeURI(fileContent);
  // hiddenElement.href = URL.createObjectURL(file);
  hiddenElement.target = "_blank";
  hiddenElement.download = filename;
  hiddenElement.click();
};

export const capitalize = function (word) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
};

export const getLoggedInUserData = () => {
  let meJSON = localStorage.getItem("me");
  let me = {};
  if (meJSON !== null) {
    me = JSON.parse(meJSON);
  }
  return me;
};
