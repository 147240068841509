import React from "react";
import moment from "moment";
const ActiveReportTable = (props) => {
            const { activeReportTableData, startDate, endDate,loadingActiveReport } = props;

  return (
    <div className="ActiveReportTable">
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="summary"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th
                            colSpan={10}
                            style={{
                              textAlign: "center",
                              fontSize: 17,
                              fontWeight: "bold",
                              background: "rgba(58, 34, 85, 0.1)",
                            }}
                          >
                            Login Report (
                            {moment(startDate).format("DD.MM.YYYY")} -{" "}
                            {moment(endDate).format("DD.MM.YYYY")})
                          </th>
                        </tr>
                        <tr>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            USER NAME
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            lAST LOGIN
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Email id
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {loadingActiveReport && <td
                                    colSpan={10}
                                    style={{ textAlign: "center" }}
                                  >
                                    <p style={{ font: 15 }}>Loading... </p>
                                    <div
                                      class="spinner-border ml-auto"
                                      role="status"
                                      aria-hidden="true"
                                    ></div>
                                  </td>
                            }
                        {!loadingActiveReport &&activeReportTableData &&
                        activeReportTableData?.getLastLoginReport?.report
                          .length > 0 &&
                          activeReportTableData?.getLastLoginReport?.report.map(
                            (report) => {
                              return (
                                <tr key={report.id}>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {report.firstName || ""}{" "}
                                    {report.lastName || ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {moment(report.lastLogin).format(
                                      "DD.MM.YYYY  h:mm:ss a"
                                    )}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {report.email}
                                  </td>
                                </tr>
                              );
                            }
                          )
                          }
                          
                          {!loadingActiveReport && !activeReportTableData &&
                              !activeReportTableData?.getLastLoginReport?.report
                                .length >0   && <tr>
                            <td
                              colSpan={10}
                              style={{ textAlign: "center", fontSize: 18 }}
                            >
                              No data available
                            </td>
                          </tr>
                          }
                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveReportTable;
