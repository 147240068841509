import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import EmployerNavbar from "../../common/EmployerNavBar";
import GeneralDetails from "./GeneralDetails";
import PersonalDetails from "./PersonalDetails";
import ExpAndSkills from "./ExpAndSkills";
import JobPReferenceDetails from "./JobPReferenceDetails";

import { useQuery } from "@apollo/client";
import { GET_USER } from "../../graphql/users";
import getStore from "../../zustandStore";

const CandidateDetails = () => {
  const uriParmas = useParams();
  const setLoading = getStore((state) => state.setLoading);

  const [user, setUser] = useState(null);

  const { data: userDetails, loading } = useQuery(GET_USER, {
    variables: { id: uriParmas?.candidateId },
    skip: !uriParmas?.candidateId,
  });

  useEffect(() => {
    if (userDetails && userDetails?.user) {
      setUser(userDetails?.user);
    }
  }, [userDetails]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <GeneralDetails user={user} />
      </Grid>
      <Grid item lg={9} md={6} sm={12} xs={12}>
        <Grid container spacing={2} flexDirection="column">
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <PersonalDetails user={user} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ExpAndSkills user={user} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={3} md={6} sm={12} xs={12}>
        <JobPReferenceDetails user={user} />
      </Grid>
    </Grid>
  );
};

const EmployerJobRecommendedCandidateDetails = (props) => {
  return <EmployerNavbar mainComponent={<CandidateDetails />} />;
};

export default EmployerJobRecommendedCandidateDetails;
