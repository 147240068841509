import React, { useState, useEffect } from "react";
import {
  Pagination,
  PaginationItem,
  Typography,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  KeyboardArrowRightOutlined,
  KeyboardArrowLeftOutlined,
} from "@mui/icons-material";
import colors from "../styles/colors";

function PagePrevIcon() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <KeyboardArrowLeftOutlined fontSize="small" color="primary" />
      <Typography
        color="primary"
        fontSize="14px"
        sx={{ mr: 1, display: { xs: "none", sm: "block" } }}
      >
        Prev
      </Typography>
    </div>
  );
}

function PageNextIcon() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Typography
        color="primary"
        fontSize="14px"
        sx={{ mr: 1, display: { xs: "none", sm: "block" } }}
      >
        Next
      </Typography>
      <KeyboardArrowRightOutlined fontSize="small" color="primary" />
    </div>
  );
}

function CommonPagination(props) {
  const { page, pageSize, rowCount, setPage, setPageSize } = props;
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    if (pageSize && rowCount) {
      setPageCount(Math.ceil(rowCount / pageSize));
    }
  }, [pageSize, rowCount]);

  return (
    <Grid container alignItems="center" sx={{ mt: 2 }}>
      <Grid item lg={2} md={2} sm={6} xs={6}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Typography color={colors.fontLight} fontSize="14px">
              Show :
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              value={pageSize}
              onChange={(event) => {
                setPage(0);
                setPageSize(parseInt(event.target.value));
              }}
              variant="outlined"
              select
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              size="small"
              inputProps={{
                sx: {
                  color: colors.fontLight,
                  fontSize: "14px",
                  padding: 0,
                },
              }}
            >
              <MenuItem value={5}>5 rows</MenuItem>
              <MenuItem value={10}>10 rows</MenuItem>
              <MenuItem value={20}>20 rows</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sm={6}
        xs={6}
        sx={{
          display: { md: "none", lg: "none", sm: "flex", xs: "flex" },
          justifyContent: "flex-end",
        }}
      >
        <Typography fontSize="14px" color={colors.fontLight}>
          {page * pageSize + 1}-
          {page + 1 === pageCount ? rowCount : (page + 1) * pageSize} of{" "}
          {rowCount} items
        </Typography>
      </Grid>
      <Grid item lg={10} md={10} sm={12} xs={12}>
        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid
            item
            sx={{
              display: { sm: "none", xs: "none", md: "block" },
            }}
          >
            <Typography fontSize="14px" color={colors.fontLight}>
              {page * pageSize + 1}-
              {page + 1 === pageCount ? rowCount : (page + 1) * pageSize} of{" "}
              {rowCount} items
            </Typography>
          </Grid>
          {pageCount > 1 && (
            <Grid item>
              <Pagination
                color="primary"
                count={pageCount}
                page={page + 1}
                onChange={(event, value) => setPage(value - 1)}
                renderItem={(item) => (
                  <PaginationItem
                    components={{ previous: PagePrevIcon, next: PageNextIcon }}
                    {...item}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CommonPagination;
