import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import JobDescription from "./JobDescription";
import JobDetails from "./JobDetails";
import QualificationDetails from "./QualificationDetails";
import SalaryDetails from "./SalaryDetails";
import GeneralDetails from "./GeneralDetails";
import ResultCard from "../../common/JobResultCard";
import EmployerNavbar from "../../common/EmployerNavBar";

import { useQuery } from "@apollo/client";
import { GET_JOB_DETAILS } from "../../graphql/EmployerJobs";
import getStore from "../../zustandStore";

function JobDetailsMain(props) {
  const setLoading = getStore((state) => state.setLoading);
  const setSelectedJob = getStore((state) => state.setSelectedJob);
  const uriParams = useParams();

  const [jobData, setJobData] = useState({});

  const { data: job, loading } = useQuery(GET_JOB_DETAILS, {
    variables: {
      id: uriParams.jobId,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (job && job.job) {
      setJobData(job.job);
      setSelectedJob({ id: job.job.id, title: job.job.title });
    }
  }, [job, setSelectedJob]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);
  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ResultCard showDetails={false} jobData={jobData} />
      </Grid>
      <Grid item lg={9} md={9} sm={12} xs={12}>
        <JobDescription jobData={jobData} />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <JobDetails jobData={jobData} />
      </Grid>
      <Grid item lg={9} md={9} sm={12} xs={12}>
        <QualificationDetails jobData={jobData} />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <SalaryDetails jobData={jobData} />
      </Grid>
      <Grid item lg={9} md={9} sm={12} xs={12}>
        <GeneralDetails jobData={jobData} />
      </Grid>
    </Grid>
  );
}

const EmployerJobDetails = (props) => {
  return <EmployerNavbar mainComponent={<JobDetailsMain />} />;
};

export default EmployerJobDetails;
