import React from "react";
import { Box } from "@mui/material";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import CustomPagination from "./PaginationDataGrid";
import colors from "../styles/colors";

const StyledDataGrid = styled(DataGrid)(() => ({
  border: 0,
  fontFamily: ["DM Sans"].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  ".MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-virtualScrollerContent": {
    border: `1px solid ${colors.lightGreyBorder}`,
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader": {
    border: "none",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    color: colors.gray,
    fontSize: "14px",
    fontWeight: 500,
  },
  "& .MuiDataGrid-cell": {
    fontSize: "12px",
    color: colors.gray,
    borderRight: 0,
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus-within": {
    outline: "none",
  },
  "& .MiuDataGrid-row": {
    borderLeft: `1px solid ${colors.lightGreyBorder}`,
  },
}));

function CustomUnSortIcon(props) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ArrowDropUp size="small" sx={{ mb: -2, color: colors.fontLighter }} />
      <ArrowDropDown size="small" sx={{ color: colors.fontLighter }} />
    </div>
  );
}

function CustomAscIcon(props) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ArrowDropUp size="small" sx={{ mb: -2, color: colors.fontPrimary }} />
      <ArrowDropDown size="small" sx={{ color: colors.fontLighter }} />
    </div>
  );
}

function CustomDescIcon(props) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ArrowDropUp size="small" sx={{ mb: -2, color: colors.fontLighter }} />
      <ArrowDropDown size="small" sx={{ color: colors.fontPrimary }} />
    </div>
  );
}

export default function Table(props) {
  const {
    rows,
    columns,
    checkboxSelection,
    hideFooter,
    hideSortIcons,
    density,
    selectedRows,
    setSelectedRows,
  } = props;
  return (
    <Box
      sx={{
        "& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer":
          {
            width: "auto",
            visibility: hideSortIcons ? "hidden" : "visible",
          },
        "& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon":
          {
            opacity: 0.5,
          },
      }}
    >
      <StyledDataGrid
        density={density || "standard"}
        getRowHeight={() => "auto"}
        autoHeight
        checkboxSelection={checkboxSelection || false}
        rows={rows}
        rowsPerPageOptions={[5, 10, 20]}
        columns={columns}
        disableSelectionOnClick
        disableColumnMenu
        hideFooterSelectedRowCount
        hideFooter={hideFooter || false}
        disableExtendRowFullWidth
        onSelectionModelChange={(selectionModel, details) =>
          setSelectedRows && setSelectedRows(selectionModel)
        }
        selectionModel={selectedRows || []}
        components={{
          ColumnSortedAscendingIcon: CustomAscIcon,
          ColumnSortedDescendingIcon: CustomDescIcon,
          ColumnUnsortedIcon: CustomUnSortIcon,
          Pagination: CustomPagination,
        }}
        initialState={{
          pagination: {
            pageSize: 10,
          },
        }}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
            py: 1,
            px: 1,
          },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: 2,
            px: 1,
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: 3,
            px: 1,
          },
        }}
      />
    </Box>
  );
}
