import React from "react";
import { Link } from "react-router-dom";
import ceil_logo from "../../assets/images/CIELlogo-1.png";
import { RESET_PASSWORD_REQUEST } from "../../graphql/users";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { withFormik } from "formik";
import * as Yup from "yup";

const ForgotPasswordForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    apiStatus,
    loginError,
  } = props;

  React.useEffect(() => {
    console.log({ apiStatus, loginError });
  }, [apiStatus, loginError]);

  return (
    <div className="auth">
      <div className="auth_left">
        <div className="card">
          <div className="text-center mb-5">
            <Link className="header-brand" to="/">
              {/* <i className="fe fe-command brand-logo" /> */}
              <img src={ceil_logo} alt="LOGO" />
            </Link>
          </div>
          <div className="card-body">
            {apiStatus && loginError && (
              <span style={{ fontSize: 13, color: "#019E01" }}>
                {loginError}
              </span>
            )}
            {!apiStatus && loginError && (
              <span style={{ fontSize: 13, color: "red" }}>{loginError}</span>
            )}
            <div className="card-title">Forgot password</div>
            {/* <p className="text-muted">
              Enter your email address and your password will be reset and
              emailed to you.
            </p> */}
            <form>
              <div className="form-group">
                <label className="form-label" htmlFor="exampleInputEmail1">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email id"
                  onChange={handleChange}
                  value={values.email}
                />
                {touched.email && (
                  <span style={{ fontSize: 13, color: "red" }}>
                    {errors.email}
                  </span>
                )}
              </div>
              <div className="form-footer">
                <button
                  className="btn btn-primary btn-block"
                  onClick={handleSubmit}
                >
                  Send me new password
                </button>
                {/* <Link className="btn btn-primary btn-block" to="/">
									Send me new password
								</Link> */}
              </div>
            </form>
          </div>
          <div className="text-center text-muted">
            <Link to="/login">Send me Back</Link> to the Sign in screen.
          </div>
        </div>
      </div>
      <div className="auth_right">
        <div
          className="carousel slide"
          data-ride="carousel"
          data-interval={3000}
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="https://www.incimages.com/uploaded_files/image/1920x1080/getty_475352876_301971.jpg"
                className="img-fluid"
                alt="login page"
                style={{ width: "100%", height: 790 }}
              />
              {/* <div className="px-4 mt-4">
									<h4>Fully Responsive</h4>
									<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
								</div> */}
            </div>
            {/* <div className="carousel-item">
								<img src="assets/images/slider2.svg" className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
									<h4>Quality Code and Easy Customizability</h4>
									<p>There are many variations of passages of Lorem Ipsum available.</p>
								</div>
							</div>
							<div className="carousel-item">
								<img src="assets/images/slider3.svg" className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
									<h4>Cross Browser Compatibility</h4>
									<p>Overview We're a group of women who want to learn JavaScript.</p>
								</div>
							</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const ForgotPassword = (props) => {
  const history = useHistory();
  const [loginError, setLoginError] = React.useState("");
  const [apiStatus, setApiStatus] = React.useState(false);

  const [forgotPassword] = useMutation(RESET_PASSWORD_REQUEST, {
    onCompleted: (data) => {
      const { resetPasswordRequest } = data;
      const { status, error } = resetPasswordRequest;
      console.log({ status, error });
      setApiStatus(Boolean(status));
      if (!Boolean(status)) {
        setLoginError("Invalid email ID.");
      } else {
        setLoginError(error);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleReset = (formValues) => {
    const { email } = formValues;
    forgotPassword({
      variables: {
        payload: {
          email,
        },
      },
    });
  };

  const ForgotPasswordWrapped = withFormik({
    mapPropsToValues: () => ({
      email: "",
    }),

    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("*Email ID Required")
        .email("Enter a Valid Email"),
    }),

    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values);
      const { handleReset } = props;

      handleReset(values);
    },
  })(ForgotPasswordForm);

  return (
    <ForgotPasswordWrapped
      handleReset={handleReset}
      apiStatus={apiStatus}
      loginError={loginError}
    />
  );
};

export default ForgotPassword;
