import React from "react";
import { Grid, Typography } from "@mui/material";

const NoCandidatesView = (props) => {
  return (
    <Grid container spacing={2} sx={{ mt: 5 }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="26px" fontWeight={700} textAlign="center">
          NO RECOMMENDED CANDIDATES
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoCandidatesView;
