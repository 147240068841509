import React, { useState, useEffect } from "react";
import { GET_USERS, GET_USER, DELETE_USER } from "../../graphql/users";
import { useQuery, useMutation } from "@apollo/client";
import { Modal } from "react-bootstrap";
import Pagination from "../Shared/Pagination";
import CandidateForm from "./UserForm";

const ManageUsers = () => {
  const [addCandidateView, setAddCandidateView] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedCandidateData, setSelectedCandidateData] = useState({});
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [filter, setFilter] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);

  const { data, refetch,loading:loadingManageUser } = useQuery(GET_USERS, {
    variables: {
      page: page + 1,
      limit: pageSize,
      filter: filter,
      is_candidate: false,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: candidate } = useQuery(GET_USER, {
    variables: {
      id: selectedCandidate,
    },
    skip: !selectedCandidate,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [deleteUser] = useMutation(DELETE_USER, {
    refetchQueries: [{ query: GET_USERS }],
    onCompleted: (data) => {
      const { deleteUser } = data || {};
      const { error } = deleteUser;
      setSelectedCandidate(null);
      setSelectedCandidateData({});
      setConfirmModal(false);

      console.log(">>>>>>error", error);
      refetch();
    },
  });

  useEffect(() => {
    if (data && data.users && data.users.users) {
      setRowCount(data.users.count);
      setCandidates(data.users.users);
    }
  }, [data]);

  useEffect(() => {
    if (candidate?.user) {
      setSelectedCandidateData(candidate.user);
    } else {
      setSelectedCandidateData({});
    }
  }, [candidate]);

  useEffect(() => {
    if (addCandidateView === false) {
      setSelectedCandidate(null);
      setSelectedCandidateData({});
      refetch();
    }
  }, [addCandidateView, refetch]);

  const Candidate = ({ user }) => {
    return (
      <tr>
        <td>
          <div className="d-flex justfy-content-between align-items-center">
            <span className="avatar avatar-cyan">
              {user?.firstName?.substring(0, 1) || "-"}
            </span>
            <h6 className="mb-0" style={{ marginLeft: 5 }}>
              {user?.firstName || ""} {user?.lastName || ""}
            </h6>
          </div>
        </td>
        <td>
          {user?.email || (
            <hr style={{ backgroundColor: "black", width: 15 }} />
          )}
        </td>
        <td>{user?.mobile || ""}</td>
        <td>
          {user?.roles[0] === "mcc_ho_user"
            ? "MCC HO User"
            : user?.roles[0] === "mcc_state_head"
            ? "MCC State Head"
            : user?.roles[0] === "mcc_cluster_manager"
            ? "MCC Cluster Manager"
            : user?.roles[0] === "mcc_coordinator"
            ? "MCC User"
            : user?.roles[0] === "pia_user"
            ? "PIA User"
            : "Employer User"}
        </td>
        <td>{user?.cluster?.name || "-"}</td>
        <td className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-icon"
            title="Edit"
            onClick={() => {
              setAddCandidateView(true);
              setSelectedCandidate(user.id);
            }}
          >
            <i className="fa fa-edit" />
          </button>
          <button
            type="button"
            className="btn btn-icon js-sweetalert"
            title="Delete"
            onClick={() => {
              setSelectedCandidate(user.id);
              setConfirmModal(true);
            }}
          >
            <i className="fa fa-trash-o text-danger" />
          </button>
        </td>
      </tr>
    );
  };

  return (
    <>
      {!addCandidateView && (
        <div>
          <div className="container-fluid">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ marginLeft: 20, marginRight: 20, marginTop: 10 }}
            >
              <div className="header-action">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    style={{ marginRight: 10 }}
                    onChange={(event) => {
                      setFilter({
                        ...filter,
                        firstName__icontains: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="header-action" style={{ marginLeft: 20 }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    console.log("clicked");
                    setAddCandidateView(true);
                  }}
                >
                  <i className="fe fe-plus mr-2" />
                  Add
                </button>
              </div>
            </div>
          </div>
          <div className="section-body mt-3">
            <div className="container-fluid">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover table-vcenter mb-0">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email id</th>
                          <th>Mobile no</th>
                          <th>Role</th>
                          <th>Cluster</th>
                          <th className="w100">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loadingManageUser &&candidates &&
                          candidates.map((user) => (
                            <Candidate key={user.id} user={user} />
                          ))}
                          {loadingManageUser && <td
                                    colSpan={10}
                                    style={{ textAlign: "center" }}
                                  >
                                    <p style={{ font: 15 }}>Loading... </p>
                                    <div
                                      class="spinner-border ml-auto"
                                      role="status"
                                      aria-hidden="true"
                                    ></div>
                                  </td>
                            }
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    page={page}
                    pageSize={pageSize}
                    rowCount={rowCount}
                    setPage={setPage}
                    setPageSize={setPageSize}
                  />
                </div>
              </div>
            </div>
          </div>
          <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
            <Modal.Header>
              <Modal.Title>Confirm</Modal.Title>
              <button
                type="button"
                class="close"
                aria-label="Close"
                onClick={() => setConfirmModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete the candidate?
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-outline-success"
                onClick={() =>
                  deleteUser({
                    variables: {
                      ids: [selectedCandidate],
                    },
                  })
                }
              >
                Yes
              </button>
              <button
                type="button"
                class="btn btn-outline-danger"
                onClick={() => setConfirmModal(false)}
              >
                No
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      {addCandidateView && (
        <CandidateForm
          setAddCandidateView={setAddCandidateView}
          selectedCandidateData={selectedCandidateData}
        />
      )}
    </>
  );
};

export default ManageUsers;
