import React, { useState, useEffect, useRef } from "react";
import CandidateForm from "./CandidateForm";
import {
  GET_USERS,
  GET_USER,
  DELETE_USER,
  IMPORT_CANDIDATES,
  DOWNLOAD_TEMPLATE,
  CANCEL_MOBILISATION,
  GET_RESUME,
} from "../../graphql/users";
import {
  GET_QUALIFICATIONS,
  GET_ALL_STATES,
  GET_ALL_CITIES,
  GET_STAGES,
  GET_CENTRES,
} from "../../graphql/meta";
import { GET_CLUSTER_CENTRES } from "../../graphql/clusters";
import { getLoggedInUserData } from "../../utils";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import Pagination from "../Shared/Pagination";
import { Modal } from "react-bootstrap";
import { downloadAny, downloadCSV } from "../../utils";
import moment from "moment";
import UpdateStageModal from "./UpdateStageModal";
import { isJwtTokenValid } from "../../utils";
import { Redirect } from "react-router-dom";
import accessControlMenu from "../Shared/AccessControl";
import MobiliseModal from "./MobilizeModal";
import Multiselect from "multiselect-react-dropdown";
import { GET_ORGANIZATIONS } from "../../graphql/organizations";
import ApproveCandidate from "./ApproveCandidate";

const trackingStatus = [
  { id: 4, name: "Applied" },
  { id: 5, name: "Shortlisted" },
  { id: 6, name: "Interviewed" },
  { id: 7, name: "Offered" },
  { id: 13, name: "Joined" },
  { id: 9, name: "Rejected" },
];

const Candidates = (props) => {
  const [addCandidateView, setAddCandidateView] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedCandidateData, setSelectedCandidateData] = useState({});
  const [bulkSelectedCandidates, setBulkSelectedCandidates] = useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [filter, setFilter] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [updateStageModal, setUpdateStageModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [uploadFailMsg, setUploadFailMsg] = useState("");
  const [uploadFailObject, setUploadFailObject] = useState({});
  const [importStatus, setImportStatus] = useState(false);
  const [inputkey, setInputkey] = useState("");
  const [editCandidate, setEditCandidate] = useState(true);
  const [editTimeline, setEditTimeline] = useState(true);
  const [mobiliseModal, setMobiliseModal] = useState(false);
  const [errorMobilisation, setErrorMobilisation] = useState(false);
  const [cancelMobilisationModal, setCancelMobilisationModal] = useState(false);
  const { roles, hasExpired } = isJwtTokenValid();
  const [approveView, setApproveView] = useState(false);
  const { fixNavbar } = props;
  const [cancellationReason, setCancellationReason] = useState("");
  const [selectedPIA, setSelectedPIA] = useState([]);

  useEffect(() => {
    accessControlMenu.map((item) => {
      if (item.role === roles[0]) {
        setEditCandidate(item.editCandidates);
        setEditTimeline(item.editTimeline);
      }
    });
  }, [roles]);

  const hiddenFileInput = useRef(null);

  useEffect(() => {
    console.log({ hiddenFileInput, inputkey, updateStageModal });
  }, [hiddenFileInput, inputkey, updateStageModal]);

  const { data: qualificationsDropdown } = useQuery(GET_QUALIFICATIONS, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: allStates } = useQuery(GET_ALL_STATES, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: allCities } = useQuery(GET_ALL_CITIES, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: stagesDropDown } = useQuery(GET_STAGES, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const {
    data,
    refetch,
    loading: userLoading,
  } = useQuery(GET_USERS, {
    variables: {
      page: page + 1,
      limit: pageSize,
      filter: filter,
      is_candidate: true,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: candidate } = useQuery(GET_USER, {
    variables: {
      id: selectedCandidate,
    },
    skip: !selectedCandidate,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: organizations } = useQuery(GET_ORGANIZATIONS, {
    variables: {
      filter: {
        organizationType: 1,
      },
    },
    // skip: !selectedSource,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: centres } = useQuery(GET_CENTRES, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const me = getLoggedInUserData();

  const { data: clusterCentres } = useQuery(GET_CLUSTER_CENTRES, {
    variables: {
      filter: {
        clusterId: me?.cluster?.id || "",
      },
    },
    skip: !me,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [deleteUser] = useMutation(DELETE_USER, {
    refetchQueries: [{ query: GET_USERS }],
    onCompleted: (data) => {
      const { deleteUser } = data || {};
      const { error } = deleteUser;
      setSelectedCandidate(null);
      setSelectedCandidateData({});
      setConfirmModal(false);

      console.log(">>>>>>error", error);
      refetch();
    },
  });

  const [cancelMobilization] = useMutation(CANCEL_MOBILISATION, {
    onCompleted: (data) => {
      const { cancelMobilization } = data || {};
      const { status, error } = cancelMobilization;
      console.log("EVENT CANCELLED", status, error);
      if (status) {
        console.log("CANCELLED MOBILISED");
        refetch();
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [uploadFile, { loading: importLoading }] = useMutation(
    IMPORT_CANDIDATES,
    {
      onCompleted: (data) => {
        const { bulkCreateCandidates } = data || {};
        const { status, error } = bulkCreateCandidates;
        if (status) {
          setUploadFailMsg(error);
          refetch();
        } else {
          if (error !== "Mandatory Fields Missing") {
            setUploadFailObject(JSON.parse(error));
          }
          setUploadFailMsg(error);
          console.log("UPLOAD FAILED", error);
        }
        setImportStatus(status);
        // setUploadFailMsg(error)
        setErrorModal(true);
        hiddenFileInput.current.blur();
        setInputkey(moment());
      },
    }
  );
  console.log(roles);
  console.log(importLoading);
  // const [downloadTemplate, { data: template }] = useLazyQuery(DOWNLOAD_TEMPLATE, {
  // 	variables: {},
  // onCompleted: (data) => {
  // 	const { dataTemplate } = data || {};
  // 	if (dataTemplate?.template) {
  // 		downloadCSV(dataTemplate.template, 'Template.csv')
  // 	}
  // setDownloadClicked(false)
  // },
  // 	fetchPolicy: 'network-only',
  // 	nextFetchPolicy: 'network-only',
  // })
  const { data: template } = useQuery(DOWNLOAD_TEMPLATE, {
    variables: {},
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only",
  });

  // useEffect(() => {
  // 	if (template && template.dataTemplate) {
  // 		downloadCSV(template.dataTemplate.template, 'Template.csv');
  // 	}
  // }, [template])

  // const handleDownload = () => {
  // setDownloadClicked(true)
  // 	downloadTemplate();
  // }

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    uploadFile({
      variables: {
        file: fileUploaded,
      },
    });
  };

  useEffect(() => {
    if (data && data.users && data.users.users) {
      setRowCount(data.users.count);
      setCandidates(data.users.users);
    }
  }, [data]);

  useEffect(() => {
    if (candidate?.user) {
      setSelectedCandidateData(candidate.user);
    } else {
      setSelectedCandidateData({});
    }
  }, [candidate]);

  useEffect(() => {
    if (addCandidateView === false) {
      setSelectedCandidate(null);
      setSelectedCandidateData({});
      refetch();
    }
  }, [addCandidateView, refetch]);

  const Candidate = ({ user }) => {
    const [tag, setTag] = useState("");
    const [jobStatus, setJobStatus] = useState("");
    useEffect(() => {
      if (user?.stage?.event?.id) {
        const eventId = user.stage.event.id;
        if (eventId === "1" || eventId === "17") {
          setTag("tag-danger");
        } else if (eventId === "2" || eventId === "18") {
          setTag("tag-cyan");
        } else if (
          eventId === "3" ||
          eventId === "4" ||
          eventId === "5" ||
          eventId === "6" ||
          eventId === "7" ||
          eventId === "8"
        ) {
          setTag("tag-info");
        } else if (
          eventId === "9" ||
          eventId === "10" ||
          eventId === "11" ||
          eventId === "12" ||
          eventId === "13" ||
          eventId === "14"
        ) {
          setTag("tag-purple");
        }
      }
      if (user?.jobStatus) {
        for (let i = 0; i < trackingStatus.length; i++) {
          if (trackingStatus[i].id === user.jobStatus) {
            setJobStatus(trackingStatus[i].name);
            break;
          }
        }
      }
    }, [user]);

    const [getResume, { loading, data }] = useLazyQuery(GET_RESUME, {
      nextFetchPolicy: "network-only",
      fetchPolicy: "network-only",
    });

    const downloadResume = async (userId) => {
      let temp = await getResume({
        variables: {
          userId: userId,
        },
      });
      let data = temp.data.getResume;
      downloadAny(data.file, data.fileName, data.fileType);
    };

    return (
      <>
        <tr>
          {editCandidate && (
            <td className="w40">
              <label className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="example-checkbox1"
                  // defaultValue="option1"
                  value={user?.id}
                  checked={
                    bulkSelectedCandidates.indexOf(user?.id) !== -1
                      ? true
                      : false
                  }
                  onChange={(event) => {
                    let arr = [...bulkSelectedCandidates];
                    if (event.target.checked) {
                      if (
                        roles[0] !== "pia_user" &&
                        user?.stage?.event?.name !== "Selected for Training" &&
                        user?.source !== 1
                      ) {
                        if (!user.organization) {
                          setErrorMobilisation(true);
                        } else {
                          arr.push(event.target.value);
                        }
                      }
                    } else if (!event.target.checked) {
                      arr = arr.filter((itm) => itm !== event.target.value);
                    }
                    setBulkSelectedCandidates(arr);
                  }}
                />
                <span className="custom-control-label">&nbsp;</span>
              </label>
            </td>
          )}
          <td>
            <h6 className="mb-0">
              {user?.firstName || ""} {user?.lastName || ""}
            </h6>
          </td>
          <td>
            <div style={{ minWidth: 150 }}>
              <span>
                {user.city?.name || "---"}, {user.state?.name || "---"}
              </span>
            </div>
          </td>
          <td>{user?.mobile || ""}</td>
          <td>{user?.skills || "---"}</td>
          <td>{user?.qualification?.name || "---"}</td>
          <td>
            {user?.stage?.event?.name ? (
              <div style={{ width: 200 }}>
                <span className={`tag ${tag}`}>{user?.stage?.event?.name}</span>
              </div>
            ) : (
              "---"
            )}
          </td>
          <td>{jobStatus || "---"}</td>
          <td>
            {user?.source === 1
              ? "PIA"
              : user.source === 2
              ? "MCC"
              : user.source === 3
              ? "Mobile App"
              : "Others"}
          </td>
          <td>{user?.forSource === 1 ? "PIA" : ""}</td>
          <td className="d-flex justify-content-end">
            <>
              {user?.resume && (
                <>
                  <button
                    type="button"
                    className="btn btn-icon"
                    title="Resume"
                    onClick={() => {
                      downloadResume(user.id);
                    }}
                  >
                    <i className="fa fa-download" />
                  </button>
                </>
              )}
              {roles[0] !== "pia_user" &&
                roles[0] !== "mcc_ho_user" &&
                user?.stage?.event?.name !== "Selected for Training" &&
                user?.source !== 1 && (
                  <button
                    type="button"
                    className="btn btn-icons"
                    title="Mobilise"
                    onClick={() => {
                      console.log("Clicked Mobilise", user);
                      if (user.organization) {
                        setMobiliseModal(true);
                        console.log(mobiliseModal);
                        setSelectedCandidate(user.id);
                      } else {
                        setErrorMobilisation(true);
                      }
                    }}
                  >
                    <i class="fa fa-money" aria-hidden="true"></i>
                  </button>
                )}
              {roles[0] !== "pia_user" &&
                user?.stage?.event?.name === "Selected for Training" &&
                user?.source !== 1 && (
                  <button
                    type="button"
                    className="btn btn-icon "
                    title="Cancel Mobilisation"
                    onClick={() => {
                      setSelectedCandidate(user.id);
                      setCancelMobilisationModal(true);
                    }}
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </button>
                )}
              {editCandidate && (
                <>
                  <button
                    type="button"
                    className="btn btn-icon"
                    title="Edit"
                    onClick={() => {
                      setAddCandidateView(true);
                      setSelectedCandidate(user.id);
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                  <button
                    type="button"
                    className="btn btn-icon js-sweetalert"
                    title="Delete"
                    onClick={() => {
                      setSelectedCandidate(user.id);
                      setConfirmModal(true);
                    }}
                  >
                    <i className="fa fa-trash-o text-danger" />
                  </button>
                </>
              )}
              {!editTimeline && (
                <>
                  <button
                    type="button"
                    className="btn btn-icon"
                    title="Timeline"
                    onClick={() => {
                      setAddCandidateView(true);
                      setSelectedCandidate(user.id);
                    }}
                  >
                    <i className="fa fa-bars" />
                  </button>
                </>
              )}
            </>
          </td>
        </tr>
      </>
    );
  };

  return (
    <>
      {hasExpired ? (
        <Redirect to="/login" />
      ) : (
        <>
          <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
            <div className="container-fluid">
              <div className="row clearfix" style={{ marginLeft: 20 }}>
                <div className="d-flex justify-content-between align-items-center">
                  {!addCandidateView && (
                    <ul className="nav nav-tabs page-header-tab">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="user-tab"
                          data-toggle="tab"
                          href="#edit-candidate"
                          onClick={() => {
                            if (approveView) {
                              setApproveView(false);
                            }
                          }}
                        >
                          Candidates
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="user-tab"
                          data-toggle="tab"
                          onClick={() => {
                            if (!approveView) {
                              setApproveView(true);
                            }
                          }}
                        >
                          Approve
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
          {approveView && (
            <div>
              <ApproveCandidate />
            </div>
          )}
          {!addCandidateView && !approveView && (
            <div>
              <div>
                <div className="container-fluid">
                  <div style={{ marginLeft: 20, marginRight: 20 }}>
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                onChange={(event) => {
                                  setFilter({
                                    ...filter,
                                    firstName__icontains: event.target.value,
                                  });
                                }}
                                value={
                                  Object.keys(filter).length === 0
                                    ? ""
                                    : filter &&
                                      !("firstName__icontains" in filter)
                                    ? ""
                                    : filter.firstName__icontains
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="input-group">
                              <select
                                className="custom-select"
                                onChange={(e) => {
                                  const { stage, ...mutableFilter } = filter;
                                  const Ofilter =
                                    e.target.value !== "none"
                                      ? {
                                          ...mutableFilter,
                                          stage: e.target.value,
                                        }
                                      : mutableFilter;
                                  setFilter(Ofilter);
                                }}
                              >
                                <option
                                  disabled
                                  selected={
                                    Object.keys(filter).length === 0 ||
                                    (filter && !("stage" in filter))
                                      ? true
                                      : false
                                  }
                                >
                                  {" "}
                                  -- Select a Stage --{" "}
                                </option>
                                <option value="none">None</option>
                                {stagesDropDown &&
                                  stagesDropDown.events &&
                                  stagesDropDown.events.map((event) => (
                                    <option value={event.id}>
                                      {event.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          {/* <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="input-group">
                              <select
                                className="custom-select"
                                onChange={(e) => {
                                  const { source, ...mutableFilter } = filter;
                                  const Ofilter =
                                    e.target.value !== "none"
                                      ? {
                                          ...mutableFilter,
                                          source: parseInt(e.target.value),
                                        }
                                      : mutableFilter;
                                  setFilter(Ofilter);
                                }}
                              >
                                <option
                                  disabled
                                  selected={
                                    Object.keys(filter).length === 0 ||
                                    (filter && !("source" in filter))
                                      ? true
                                      : false
                                  }
                                >
                                  {" "}
                                  -- Select a Sourced by --{" "}
                                </option>
                                <option value="none">None</option>
                                <option value="1">PIA</option>
                                <option value="2">MCC</option>
                                <option value="3">Mobile App</option>
                              </select>
                            </div>
                          </div> */}
                          <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="form-group" style={{ margin: 0 }}>
                              <Multiselect
                                id="jobType"
                                options={organizations?.organizations || []}
                                className="custom-select"
                                selectionLimit={1}
                                onSelect={(e, m) => {
                                  let arr = [];
                                  e.map((itm) => arr.push(itm.id));
                                  setFilter({
                                    ...filter,
                                    forOrganization__id__in: arr,
                                  });
                                }}
                                onRemove={(e, m) => {
                                  let arr = [];
                                  e.map((itm) => arr.push(itm.id));
                                  if (arr.length !== 0) {
                                    setFilter({
                                      ...filter,
                                      forOrganization__id__in: arr,
                                    });
                                  } else {
                                    arr = { ...filter };
                                    delete arr["forOrganization__id__in"];
                                    setFilter({ ...arr });
                                  }
                                }}
                                selectedValues={
                                  selectedPIA?.length > 0 ? selectedPIA : null
                                }
                                displayValue="name"
                                avoidHighlightFirstOption={true}
                                placeholder="-- Select Source For PIA --"
                                style={{
                                  multiselectContainer: {
                                    // border: "1PX #7C7B7B solid",
                                    minHeight: 25,
                                    // borderRadius: 5,
                                    padding: "3px 0px",
                                    paddingLeft: 11,
                                  },
                                  searchBox: {
                                    // minHeight: 25,
                                    fontSize: 14,
                                    border: 0,
                                    color: "black",
                                    padding: 0,
                                    margin: "0px 0px!important",
                                  },
                                  chips: {
                                    fontWeight: "inherit",
                                    fontSize: "inherit",
                                    margin: "0px",
                                  },
                                  optionContainer: { background: "grey" },
                                  option: {
                                    color: "white",
                                    fontSize: 14,
                                    margin: 0,
                                    fontWeight: "bold",
                                    borderRadius: 5,
                                  },
                                }}
                              />
                            </div>
                          </div>
                          {/* <div className="col-lg-3 col-md-6 col-sm-6">
											<div className="input-group">
												<select className="custom-select"
												onChange={(e) => {
													const { qualificationId, ...mutableFilter } = filter;
													const Ofilter = e.target.value !== 'none' ? { ...mutableFilter, qualificationId: e.target.value } : mutableFilter;
													setFilter(Ofilter);
												}}
												>
													<option disabled selected value> -- Select a Qualification -- </option>
													{qualificationsDropdown && qualificationsDropdown.qualifications && qualificationsDropdown.qualifications.map((qual) => (
														<option value={qual.id}>{qual.name}</option>
													))}
												</select>
											</div>
										</div> */}
                        </div>
                        <div className="row" style={{ marginTop: 10 }}>
                          <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="input-group">
                              <select
                                className="custom-select"
                                onChange={(e) => {
                                  const { qualificationId, ...mutableFilter } =
                                    filter;
                                  const Ofilter =
                                    e.target.value !== "none"
                                      ? {
                                          ...mutableFilter,
                                          qualificationId: e.target.value,
                                        }
                                      : mutableFilter;
                                  setFilter(Ofilter);
                                }}
                              >
                                <option
                                  disabled
                                  selected={
                                    Object.keys(filter).length === 0 ||
                                    (filter && !("qualificationId" in filter))
                                      ? true
                                      : false
                                  }
                                >
                                  {" "}
                                  -- Select a Qualification --{" "}
                                </option>
                                <option value={"none"}>None</option>
                                {qualificationsDropdown &&
                                  qualificationsDropdown.qualifications &&
                                  qualificationsDropdown.qualifications.map(
                                    (qual) => (
                                      <option value={qual.id}>
                                        {qual.name}
                                      </option>
                                    )
                                  )}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6">
                            <select
                              className="custom-select"
                              onChange={(e) => {
                                const { stateId, ...mutableFilter } = filter;
                                const Ofilter =
                                  e.target.value !== "none"
                                    ? {
                                        ...mutableFilter,
                                        stateId: e.target.value,
                                      }
                                    : mutableFilter;
                                setFilter(Ofilter);
                              }}
                            >
                              <option
                                disabled
                                selected={
                                  Object.keys(filter).length === 0 ||
                                  (filter && !("stateId" in filter))
                                    ? true
                                    : false
                                }
                              >
                                {" "}
                                -- Select a State --{" "}
                              </option>
                              <option value={"none"}>None</option>
                              {allStates &&
                                allStates.allStates &&
                                allStates.allStates.map((state) => (
                                  <option value={state.id}>{state.name}</option>
                                ))}
                            </select>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6">
                            <select
                              className="custom-select"
                              onChange={(e) => {
                                const { cityId, ...mutableFilter } = filter;
                                const Ofilter =
                                  e.target.value !== "none"
                                    ? {
                                        ...mutableFilter,
                                        cityId: e.target.value,
                                      }
                                    : mutableFilter;
                                setFilter(Ofilter);
                              }}
                            >
                              <option
                                disabled
                                selected={
                                  Object.keys(filter).length === 0 ||
                                  (filter && !("cityId" in filter))
                                    ? true
                                    : false
                                }
                              >
                                {" "}
                                -- Select a City --{" "}
                              </option>
                              <option value={"none"}>None</option>
                              {allCities &&
                                allCities.allCities &&
                                allCities.allCities.map((city) => (
                                  <option value={city.id}>{city.name}</option>
                                ))}
                            </select>
                          </div>
                          {/* <div className="col-lg-3 col-md-6 col-sm-6">
											<select className="custom-select"
											// onChange={(e) => {
											// 	const { source, ...mutableFilter } = filter;
											// 	const Ofilter = e.target.value !== 'none' ? { ...mutableFilter, source: parseInt(e.target.value) } : mutableFilter;
											// 	setFilter(Ofilter);
											// }}
											>
												<option disabled selected value> -- Select a Skill -- </option>
												{skillsDropdown && skillsDropdown.skills && skillsDropdown.skills.map((skill) => (
													<option value={skill.id}>{skill.name}</option>
												))}
											</select>
										</div>
										<div className="col-lg-3 col-md-6 col-sm-6">
											<select className="custom-select"
											// onChange={(e) => {
											// 	const { source, ...mutableFilter } = filter;
											// 	const Ofilter = e.target.value !== 'none' ? { ...mutableFilter, source: parseInt(e.target.value) } : mutableFilter;
											// 	setFilter(Ofilter);
											// }}
											>
												<option disabled selected value> -- Select a Status -- </option>
												{trackingStatus && trackingStatus.map((status) => (
													<option value={status.id}>{status.name}</option>
												))}
											</select>
										</div> */}
                        </div>
                        <div className="row" style={{ marginTop: 10 }}>
                          <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="input-group">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Mobile No."
                                onChange={(event) => {
                                  setFilter({
                                    ...filter,
                                    mobile__icontains: event.target.value,
                                  });
                                }}
                                value={
                                  Object.keys(filter).length === 0
                                    ? ""
                                    : filter && !("mobile__icontains" in filter)
                                    ? ""
                                    : filter.mobile__icontains
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6">
                            <select
                              className="custom-select"
                              onChange={(e) => {
                                const { source, ...mutableFilter } = filter;
                                const Ofilter =
                                  e.target.value !== "none"
                                    ? {
                                        ...mutableFilter,
                                        source: parseInt(e.target.value),
                                      }
                                    : mutableFilter;
                                setFilter(Ofilter);
                              }}
                            >
                              <option
                                disabled
                                selected={
                                  Object.keys(filter).length === 0 ||
                                  (filter && !("source" in filter))
                                    ? true
                                    : false
                                }
                              >
                                {" "}
                                -- Select Sourced By --{" "}
                              </option>
                              <option value={"none"}>None</option>
                              <option value="2">MCC</option>
                              <option value="1">PIA</option>
                              <option value="3">Mobile App</option>
                            </select>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="form-group" style={{ margin: 0 }}>
                              <Multiselect
                                id="jobType"
                                options={
                                  roles[0] === "mcc_ho_user"
                                    ? centres?.centres || []
                                    : clusterCentres?.clusterCentres?.clustercentres?.map(
                                        (e) => e.centre
                                      ) || []
                                }
                                className="custom-select"
                                selectionLimit={1}
                                onSelect={(e, m) => {
                                  let arr = [];
                                  e.map((itm) => arr.push(itm.id));
                                  setFilter({
                                    ...filter,
                                    centreId: arr && arr[0] && parseInt(arr[0]),
                                  });
                                }}
                                onRemove={(e, m) => {
                                  let arr = [];
                                  e.map((itm) => arr.push(itm.id));
                                  if (arr.length !== 0) {
                                    setFilter({
                                      ...filter,
                                      centreId:
                                        arr && arr[0] && parseInt(arr[0]),
                                    });
                                  } else {
                                    arr = { ...filter };
                                    delete arr["centreId"];
                                    setFilter({ ...arr });
                                  }
                                }}
                                selectedValues={
                                  selectedPIA?.length > 0 ? selectedPIA : null
                                }
                                displayValue="name"
                                avoidHighlightFirstOption={true}
                                placeholder="-- Select Centre --"
                                style={{
                                  multiselectContainer: {
                                    minHeight: 25,
                                    padding: "3px 0px",
                                    paddingLeft: 11,
                                  },
                                  searchBox: {
                                    fontSize: 14,
                                    border: 0,
                                    color: "black",
                                    padding: 0,
                                    margin: "0px 0px!important",
                                  },
                                  chips: {
                                    fontWeight: "inherit",
                                    fontSize: "inherit",
                                    margin: "0px",
                                  },
                                  optionContainer: { background: "grey" },
                                  option: {
                                    color: "white",
                                    fontSize: 14,
                                    margin: 0,
                                    fontWeight: "bold",
                                    borderRadius: 5,
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="header-action float-right"
                          style={{ marginTop: 10 }}
                        >
                          <button
                            type="button"
                            className="btn btn-default"
                            onClick={() => setFilter({})}
                          >
                            <i className="fa fa-times mr-2" />
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                    {editCandidate && (
                      <div
                        className="header-action float-right"
                        style={{ marginBottom: 10 }}
                      >
                        <div className="dropdown" style={{ marginRight: 5 }}>
                          <button
                            type="button"
                            className="btn btn-primary dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            Import
                          </button>
                          <div className="dropdown-menu">
                            <a
                              className="dropdown-item"
                              // onClick={handleDownload}
                              onClick={() => {
                                if (
                                  template &&
                                  template?.dataTemplate &&
                                  template?.dataTemplate?.template
                                ) {
                                  downloadCSV(
                                    template.dataTemplate.template,
                                    "Template.csv"
                                  );
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Download Template
                            </a>
                            <a
                              className="dropdown-item"
                              onClick={handleClick}
                              style={{ cursor: "pointer" }}
                            >
                              Import csv file
                              {/* <input
												type="file"
												ref={hiddenFileInput}
												onChange={handleChange}
												style={{ display: 'none' }}
												accept="text/csv"
											/> */}
                            </a>
                            <input
                              type="file"
                              ref={hiddenFileInput}
                              onChange={handleChange}
                              style={{ display: "none" }}
                              accept="text/csv"
                              key={inputkey}
                            />
                          </div>
                        </div>
                        {/* <Dropdown style={{ marginRight: 5 }}>
									<Dropdown.Toggle variant="primary" id="dropdown-basic">
										Import
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item onClick={handleDownload}>Download Template</Dropdown.Item>
										<Dropdown.Item onClick={(e) => handleChange(e)}>Import csv file</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown> */}
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => setAddCandidateView(true)}
                        >
                          <i className="fe fe-plus mr-2" />
                          Add
                        </button>
                        <Modal
                          show={errorModal}
                          onHide={() => setErrorModal(false)}
                        >
                          <Modal.Header>
                            <Modal.Title>
                              {importStatus ? "Success" : "Upload Fail"}
                            </Modal.Title>
                            <button
                              type="button"
                              className="close"
                              aria-label="Close"
                              onClick={() => setErrorModal(false)}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </Modal.Header>
                          <Modal.Body>
                            {importLoading && (
                              <div className="d-flex flex-column justify-content-centre align-items-center">
                                <p style={{ font: 15 }}>
                                  Importing Candidates...{" "}
                                </p>
                                <div
                                  class="spinner-border"
                                  role="status"
                                  aria-hidden="true"
                                ></div>
                              </div>
                            )}

                            {!importLoading && importStatus ? (
                              uploadFailMsg
                            ) : (
                              <>
                                {uploadFailMsg ===
                                "Mandatory Fields Missing" ? (
                                  uploadFailMsg
                                ) : (
                                  <div className="table-responsive">
                                    <table className="table table_custom spacing5 border-style mb-0">
                                      <thead>
                                        <tr>
                                          <th>Error</th>
                                          <th>Row</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.keys(uploadFailObject).map(
                                          (item) => {
                                            return (
                                              <tr>
                                                <td>{item}</td>
                                                <td>
                                                  {!importStatus &&
                                                    uploadFailObject[item].map(
                                                      (e, i) => (
                                                        <span>
                                                          {e}
                                                          {i ===
                                                          uploadFailObject[item]
                                                            .length -
                                                            1
                                                            ? ""
                                                            : ", "}
                                                        </span>
                                                      )
                                                    )}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </>
                            )}
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => setErrorModal(false)}
                            >
                              Close
                            </button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    )}
                    {/* <div>
                      <div className="d-flex align-items-start">
                          <i class="fa fa-money" aria-hidden="true"> </i>
                          <p style={{marginLeft:10}}>- Mobilize</p>
                      </div>
                      <div className="d-flex align-items-start">
                          <i class="fa fa-times" aria-hidden="true"></i>
                          <p style={{marginLeft:10}}> - Cancel Mobilization</p>
                      </div>
                        

                    </div> */}
                  </div>
                </div>
              </div>
              <div className="section-body mt-3">
                <div className="container-fluid">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-striped table-hover table-vcenter mb-0">
                          <thead>
                            <tr>
                              {editCandidate && <th>#</th>}
                              <th>Name</th>
                              <th>Location</th>
                              <th>Mobile no</th>
                              <th>Skill</th>
                              <th>Qualification</th>
                              <th>Stage</th>
                              <th>Employment Status</th>
                              <th>Sourced By</th>
                              <th>Sourced For</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {candidates &&
                            candidates.length > 0 &&
                            !userLoading ? (
                              candidates.map((user) => (
                                <Candidate key={user.id} user={user} />
                              ))
                            ) : (
                              <tr>
                                {userLoading && (
                                  <td
                                    colSpan={editCandidate ? 10 : 9}
                                    style={{ textAlign: "center" }}
                                  >
                                    <p style={{ font: 15 }}>Loading... </p>
                                    <div
                                      class="spinner-border ml-auto"
                                      role="status"
                                      aria-hidden="true"
                                    ></div>
                                  </td>
                                )}
                              </tr>
                            )}
                            {!userLoading &&
                            candidates &&
                            candidates.length === 0 ? (
                              <tr>
                                <td
                                  colSpan={editCandidate ? 10 : 9}
                                  style={{ textAlign: "center" }}
                                >
                                  No Candidates Enrolled
                                </td>
                              </tr>
                            ) : (
                              <div className=""></div>
                            )}
                          </tbody>
                        </table>
                        <Modal
                          show={errorMobilisation}
                          hide={() => setErrorMobilisation(false)}
                        >
                          <Modal.Header>Error</Modal.Header>
                          <Modal.Body>
                            To mobilise this candidate, please specify which PIA
                            is this candidate being Sourced for.
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              className="btn btn-primary"
                              onClick={() => setErrorMobilisation(false)}
                            >
                              OK
                            </button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                      <Pagination
                        page={page}
                        pageSize={pageSize}
                        rowCount={rowCount}
                        setPage={setPage}
                        setPageSize={setPageSize}
                      />
                      {editCandidate && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => setUpdateStageModal(true)}
                          disabled={
                            bulkSelectedCandidates.length <= 0 ? true : false
                          }
                        >
                          Bulk Mobilization
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {updateStageModal && (
                <UpdateStageModal
                  updateStageModal={updateStageModal}
                  setUpdateStageModal={setUpdateStageModal}
                  bulkSelectedCandidates={bulkSelectedCandidates}
                  refetchCandidates={refetch}
                  stages={stagesDropDown}
                  selectedCandidate={selectedCandidate}
                />
              )}

              <MobiliseModal
                mobiliseModal={mobiliseModal}
                setMobiliseModal={setMobiliseModal}
                selectedCandidate={selectedCandidate}
                refetchCandidates={refetch}
                selectedCandidateData={selectedCandidateData}
              />

              <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
                <Modal.Header>
                  <Modal.Title>Confirm</Modal.Title>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => setConfirmModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete the candidate?
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    onClick={() =>
                      deleteUser({
                        variables: {
                          ids: [selectedCandidate],
                        },
                      })
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={() => setConfirmModal(false)}
                  >
                    No
                  </button>
                </Modal.Footer>
              </Modal>

              <Modal show={cancelMobilisationModal}>
                <Modal.Header>
                  <Modal.Title>Cancel Mobilization</Modal.Title>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => setCancelMobilisationModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <span className="col-lg-12 col-md-12 col-sm-12">
                    Do you want to cancel Mobilization?
                  </span>
                  <div className="d-flex flex-row align-item-center justify-content-between mt-2">
                    <div className="font-15 col-lg-4 col-md-4 col-sm-12">
                      Cancellation Reason
                    </div>
                    <div className="font-15 form-group col-lg-8 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control col-lg-12 col-md-12 col-sm-12"
                        style={{ border: "1px #7C7B7B solid" }}
                        value={cancellationReason}
                        onChange={(e) => {
                          setCancellationReason(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      console.log(selectedCandidate);
                      setCancelMobilisationModal(false);
                      console.log(selectedCandidateData);
                      cancelMobilization({
                        variables: {
                          payload: [selectedCandidate],
                          reason: cancellationReason,
                        },
                      });
                    }}
                    disabled={cancellationReason ? false : true}
                  >
                    OK
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
          {addCandidateView && (
            <CandidateForm
              setAddCandidateView={setAddCandidateView}
              selectedCandidateData={selectedCandidateData}
              editTimeline={editTimeline}
            />
          )}
        </>
      )}
    </>
  );
};

export default Candidates;
