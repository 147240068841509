import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import firebaseConfig from "./firebaseconfig";
// import * as firebase from "firebase/app";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";
import { SnackbarProvider } from "notistack";
// import registerServiceWorker from './registerServiceWorker';

// firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <Provider store={configureStore()}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={1} autoHideDuration={4000}>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
// registerServiceWorker();
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
