import create from "zustand";

const getStore = create((set) => ({
  loading: false,
  setLoading: (loading) => set({ loading }),

  selectedJob: { id: "", title: "" },
  setSelectedJob: (job) => set({ selectedJob: job }),

  jobPayload: {},
  setJobPayload: (payload) => set({ jobPayload: payload }),

  screeningQuestions: [],
  setScreeningQuestions: (questions) => set({ screeningQuestions: questions }),
}));

export default getStore;
