import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { LocationOnOutlined } from "@mui/icons-material";
import { getDateInFormat } from "../../helpers";
import { jobModes, jobTeamTypes } from "../../constants";
import colors from "../../styles/colors";

function Item(props) {
  const { title, data } = props;
  return (
    <Box>
      <Typography fontSize="13px">{title}</Typography>
      <Typography color={colors.dark} fontSize="14px" fontWeight={700}>
        {data}
      </Typography>
    </Box>
  );
}

const JobDetails = (props) => {
  const { jobData } = props;

  const [jobType, setJobType] = useState("");

  useEffect(() => {
    let temp = "";
    if (jobData && jobData.jobMode) {
      temp += jobModes[jobData.jobMode];
    }
    if (jobData && jobData.jobTeamType) {
      temp += ` / ${jobTeamTypes[jobData.jobTeamType]}`;
    }
    if (temp) {
      setJobType(temp);
    } else {
      setJobType("Not mentioned");
    }
  }, [jobData]);

  return (
    <Box
      sx={{
        borderRadius: "4px",
        border: `1px solid ${colors.strokeGray}`,
        p: { xs: 2, sm: 3 },
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontWeight={500} fontSize="18px">
            Job Details
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Item title="Job Type" data={jobType} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Item title="Job Category" data={jobData?.jobType?.name || "-"} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Item
            title="Posted On"
            data={getDateInFormat(jobData.createdDate, "DD MMM YYYY")}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Item title="State" data={jobData?.state?.name || "-"} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Item title="City" data={jobData?.city?.name || "-"} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container alignItems="center">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography fontSize="13px">Work Location</Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                color={colors.dark}
                fontSize="14px"
                fontWeight={700}
                noWrap
              >
                {jobData?.locationId || ""}
              </Typography>
            </Grid>
            {/* <Grid item lg={2} md={2} sm={2} xs={2}>
              <IconButton size="small">
                <LocationOnOutlined color="primary" fontSize="small" />
              </IconButton>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default JobDetails;
