import { gql } from "@apollo/client";

export const GET_ALL_JOBS = gql`
  query jobs(
    $filter: JobFilterInput
    $limit: Int
    $page: Int
    $sorted: String
  ) {
    jobs(filter: $filter, limit: $limit, page: $page, sorted: $sorted) {
      count
      jobs {
        title
        id
        jobId
        description
        city {
          id
          name
        }
        status
        positions
        genderPreference
        skills
        jobType {
          id
          name
        }
        qualification {
          id
          qualification {
            id
            name
          }
        }
        minimumExperience
        minimumRemuneration
        maximumExperience
        maximumRemuneration
        createdDate
      }
    }
  }
`;

export const GET_JOB_DETAILS = gql`
  query job($id: String!) {
    job(id: $id) {
      title
      id
      description
      city {
        id
        name
      }
      state {
        id
        name
      }
      country {
        id
        name
      }
      locationId
      minimumExperience
      minimumRemuneration
      maximumExperience
      maximumRemuneration
      positions
      genderPreference
      status
      profile
      skills
      createdDate
      closure
      perks
      languages
      jobMode
      jobTeamType
      jobType {
        id
        name
      }
      qualification {
        id
        qualification {
          id
          name
        }
      }
      minimumYearOfPassing
      maximumYearOfPassing
      screeningQuestions {
        id
        question
        answerType
        answer
        mandatory
      }
    }
  }
`;

export const GET_JOB_APPLICANTS = gql`
  query jobCandidates(
    $filter: JobCandidateFilterInput
    $limit: Int
    $page: Int
  ) {
    jobCandidates(
      filter: $filter
      limit: $limit
      page: $page
      recent_applicants: false
    ) {
      count
      shortlisted
      interviewed
      rejected
      total
      jobcandidates {
        id
        candidate {
          id
          firstName
          lastName
          email
          mobile
          skills
          resume
        }
        status
        createdDate
        score
        screeningAnswers {
          id
          question {
            id
            question
            answerType
            answer
            mandatory
          }
          answer
        }
      }
    }
  }
`;

export const UPDATE_JOB_CANDIDATE_STATUS = gql`
  mutation updateJobCandidate($payload: JobCandidateUpdateInput!) {
    updateJobCandidate(payload: $payload) {
      status
      error
    }
  }
`;

export const ADD_JOB = gql`
  mutation createJob($payload: JobCreateInput!) {
    createJob(payload: $payload) {
      status
      error
    }
  }
`;

export const UPDATE_JOB = gql`
  mutation updateJob($payload: JobUpdateInput!) {
    updateJob(payload: $payload) {
      status
      error
    }
  }
`;

export const GET_RECOMMENDED_CANDIDATES = gql`
  query getAutoRecommendedCandidates(
    $jobId: String!
    $filter: ElasticSearchUserFilterInput
    $limit: Int
    $page: Int
    $sort_by: String
  ) {
    getAutoRecommendedCandidates(
      job_id: $jobId
      filter: $filter
      limit: $limit
      page: $page
      sort_by: $sort_by
    ) {
      count
      users {
        id
        firstName
        lastName
        jobStatus
        email
        mobile
        city {
          id
          name
        }
        experience
        qualification {
          id
          name
        }
        skills
        createdDate
        score
      }
    }
  }
`;
