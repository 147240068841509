import React, { useEffect, useState } from "react";
import { Grid, Typography, Paper } from "@mui/material";
import {
  AccountCircleOutlined,
  Grading,
  HowToRegOutlined,
  PersonRemoveOutlined,
} from "@mui/icons-material";
import colors from "../../styles/colors";

function Card(props) {
  const { count, title, icon, color } = props;
  return (
    <Paper
      elevation={1}
      sx={{
        borderRadius: "5px",
        px: 3,
        py: 2,
        borderLeft: `7px solid ${color}`,
      }}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {icon}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2, mb: -1 }}>
          <Typography color={color} fontSize="24px" fontWeight={700}>
            {count}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography color={colors.lightGray} fontSize="13px">
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

const MetricCards = (props) => {
  const { jobMetricData } = props;

  const [totalApplicants, setTotalApplicants] = useState(0);
  const [shortlistedCount, setShortlistedCount] = useState(0);
  const [interviewedCount, setInterviewedCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);

  useEffect(() => {
    if (jobMetricData && jobMetricData.total) {
      setTotalApplicants(jobMetricData.total);
    }
    if (jobMetricData && jobMetricData.shortlisted) {
      setShortlistedCount(jobMetricData.shortlisted);
    }
    if (jobMetricData && jobMetricData.interviewed) {
      setInterviewedCount(jobMetricData.interviewed);
    }
    if (jobMetricData && jobMetricData.rejected) {
      setRejectedCount(jobMetricData.rejected);
    }
  }, [jobMetricData]);
  return (
    <Grid container spacing={2}>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Card
          count={totalApplicants}
          title="Total Applicants"
          icon={<AccountCircleOutlined fontSize="large" color="primary" />}
          color={colors.primary}
        />
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Card
          count={shortlistedCount}
          title="Candidates Shortlisted"
          icon={<Grading fontSize="large" sx={{ color: colors.warning }} />}
          color={colors.warning}
        />
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Card
          count={interviewedCount}
          title="Candidates Interviewed"
          icon={
            <HowToRegOutlined fontSize="large" sx={{ color: colors.success }} />
          }
          color={colors.success}
        />
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Card
          count={rejectedCount}
          title="Candidates Rejected"
          icon={
            <PersonRemoveOutlined
              fontSize="large"
              sx={{ color: colors.danger }}
            />
          }
          color={colors.danger}
        />
      </Grid>
    </Grid>
  );
};

export default MetricCards;
