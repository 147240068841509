import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  InputAdornment,
  Button,
  FormHelperText,
  createFilterOptions,
} from "@mui/material";
import HorizontalDivider from "../../common/HorizontalDivider";
import { gendersArr } from "../../constants";
import colors from "../../styles/colors";

import { useQuery } from "@apollo/client";
import { GET_SEARCH_META } from "../../graphql/meta";

const Filters = (props) => {
  const { setFilters, filters, search, setPage, setPageSize } = props;

  const [dropDownData, setDropDownData] = useState({});
  const [errors, setErrors] = useState({});

  const validate = () => {
    let newErrors = {};
    let dirty = false;
    if (filters.minExperience) {
      if (filters.minExperience < 0) {
        newErrors = {
          ...newErrors,
          experience: "Min Experience cannot be less than 0",
        };
        dirty = true;
      }
    }

    if (filters.maxExperience) {
      if (filters.maxExperience < 0) {
        newErrors = {
          ...newErrors,
          experience: "Max Experience cannot be less than 0",
        };
        dirty = true;
      }
    }

    if (
      filters.minExperience &&
      filters.maxExperience &&
      filters.maxExperience < filters.minExperience
    ) {
      newErrors = {
        ...newErrors,
        experience: "Min Experience cannot be more than Max Experience",
      };
      dirty = true;
    }

    if (filters.minRemuneration) {
      if (filters.minRemuneration < 0) {
        newErrors = {
          ...newErrors,
          remuneration: "Min Remuneration cannot be less than 0",
        };
        dirty = true;
      }
    }

    if (filters.maxRemuneration) {
      if (filters.maxRemuneration < 0) {
        newErrors = {
          ...newErrors,
          remuneration: "Max Remuneration cannot be less than 0",
        };
        dirty = true;
      }
    }

    if (
      filters.minRemuneration &&
      filters.maxRemuneration &&
      filters.maxRemuneration < filters.minRemuneration
    ) {
      newErrors = {
        ...newErrors,
        remuneration: "Min Remuneration cannot be more than Max Remuneration",
      };
      dirty = true;
    }

    if (dirty) {
      setErrors(newErrors);
    } else {
      setErrors({});
    }
  };

  const { data: dropDownDataRaw } = useQuery(GET_SEARCH_META, {
    variables: {},
  });

  useEffect(() => {
    setDropDownData(dropDownDataRaw);
  }, [dropDownDataRaw]);

  const setFilter = (key, val) => {
    setFilters({ ...filters, [key]: val });
  };

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  return (
    <Box sx={{ border: `1px solid ${colors.strokeGray}`, borderRadius: "4px" }}>
      <Grid container spacing={2} sx={{ p: 2 }} alignItems="center">
        <Grid item lg={5} md={5} sm={5} xs={12}>
          <Typography color={colors.dark} fontSize="18px" fontWeight={500}>
            FILTERS
          </Typography>
        </Grid>
        <Grid
          item
          lg={7}
          md={7}
          sm={7}
          xs={12}
          display="flex"
          justifyContent={{ xs: "flex-start", sm: "flex-end" }}
        >
          <Typography
            color="primary"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              setFilters({});
              setPage(0);
              setPageSize(10);
            }}
          >
            Clear all Filters
          </Typography>
        </Grid>
      </Grid>
      <HorizontalDivider />
      <Grid container spacing={2} alignItems="center" sx={{ py: 2, px: 2.5 }}>
        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px" color={colors.dark}>
            Job Title
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <TextField size="small" fullWidth />
        </Grid> */}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px" color={colors.dark}>
            Job Category
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <Autocomplete
            id="jobCategoryPrefs"
            autoComplete
            disableClearable
            onChange={(_, value) => setFilter("jobType", value.name)}
            value={
              dropDownData?.jobTypes
                ? dropDownData.jobTypes.find(
                    (x) => x.name === filters["jobType"]
                  )
                : {}
            }
            options={dropDownData?.jobTypes || []}
            getOptionLabel={(option) => option.name || ""}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose..."
                // onBlur={handleBlur}
              />
            )}
          />
        </Grid>
        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px" color={colors.dark}>
            Job Type
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <Autocomplete
            id="jobTypePrefs"
            autoComplete
            disableClearable
            options={dropDownData?.jobTypes || []}
            getOptionLabel={(option) => option.name}

            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose..." />
            )}
          />
        </Grid> */}
        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px" color={colors.dark}>
            Key Skills
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <Autocomplete
            id="skillsPreference"
            autoComplete
            disableClearable
            freeSolo
            multiple
            options={dropDownData?.skills || []}
            getOptionLabel={(option) => option.name}

            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose..." />
            )}
            ChipProps={{
              sx: { backgroundColor: colors.primary, color: colors.secondary },
            }}
          />
        </Grid> */}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px" color={colors.dark}>
            Qualification
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <Autocomplete
            id="qualificationPrefs"
            autoComplete
            disableClearable
            onChange={(_, value) => setFilter("qualification", value.id)}
            value={
              dropDownData?.qualifications
                ? dropDownData.qualifications.find(
                    (x) => x.id === filters["qualification"]
                  )
                : {}
            }
            options={dropDownData?.qualifications || []}
            getOptionLabel={(option) => option.name || ""}
            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose..." />
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px" color={colors.dark}>
            Work Location Preference
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <Autocomplete
            filterOptions={filterOptions}
            id="cityPrefs"
            autoComplete
            disableClearable
            options={dropDownData?.allCities || []}
            onChange={(_, value) => setFilter("city", value.id)}
            value={
              dropDownData?.allCities
                ? dropDownData.allCities.find((x) => x.id === filters["city"])
                : {}
            }
            getOptionLabel={(option) => option.name || ""}
            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose..." />
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px" color={colors.dark}>
            Gender
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <Autocomplete
            id="genderPreference"
            autoComplete
            disableClearable
            onChange={(_, value) => setFilter("gender", value.id)}
            value={gendersArr.find((x) => x.id === filters["gender"]) || {}}
            getOptionLabel={(option) => option.name || ""}
            options={gendersArr}
            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose..." />
            )}
          />
        </Grid>
        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px" color={colors.dark}>
            Languages
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <Autocomplete
            id="languagePref"
            autoComplete
            disableClearable
            freeSolo
            multiple
            options={[]}
            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose..." />
            )}
            ChipProps={{
              sx: { backgroundColor: colors.primary, color: colors.secondary },
            }}
          />
        </Grid> */}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px" color={colors.dark}>
            Experience{" "}
            <span style={{ color: colors.gray, fontSize: "11px" }}>
              (in years)
            </span>
          </Typography>
          {errors.experience && (
            <FormHelperText error>{errors.experience}</FormHelperText>
          )}
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -1 }}>
          <Typography fontSize="13px" color={colors.dark}>
            Min
          </Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{ mt: -1, display: { xs: "none", md: "block" } }}
        >
          <Typography fontSize="13px" color={colors.dark}>
            Max
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -1 }}>
          <TextField
            onBlur={validate}
            value={filters?.minExperience || ""}
            size="small"
            type="number"
            fullWidth
            onChange={(e) => {
              setFilter("minExperience", parseInt(e.target.value));
            }}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -1 }}>
          <TextField
            size="small"
            type="number"
            fullWidth
            onBlur={validate}
            value={filters?.maxExperience || ""}
            onChange={(e) => {
              setFilter("maxExperience", parseInt(e.target.value));
            }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px" color={colors.dark}>
            Expected CTC{" "}
            <span style={{ color: colors.gray, fontSize: "11px" }}>
              (Per Month)
            </span>
          </Typography>
          {errors.remuneration && (
            <FormHelperText error>{errors.remuneration}</FormHelperText>
          )}
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -1 }}>
          <Typography fontSize="13px" color={colors.dark}>
            Min
          </Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{ mt: -1, display: { xs: "none", md: "block" } }}
        >
          <Typography fontSize="13px" color={colors.dark}>
            Max
          </Typography>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -1 }}>
          <TextField
            onChange={(e) =>
              setFilter("minRemuneration", parseInt(e.target.value))
            }
            onBlur={validate}
            value={filters?.minRemuneration || ""}
            size="small"
            type="number"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₹</InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -1 }}>
          <TextField
            onChange={(e) =>
              setFilter("maxRemuneration", parseInt(e.target.value))
            }
            onBlur={validate}
            value={filters?.maxRemuneration || ""}
            size="small"
            type="number"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₹</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
          sx={{ mt: 2 }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={() => search()}
            disabled={
              Object.keys(filters)?.length === 0 ||
              Object.keys(errors)?.length > 0
            }
          >
            Apply Filter
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Filters;
