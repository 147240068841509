import React, { Component } from "react";
import { connect } from "react-redux";
import "./App.css";
import getStore from "./zustandStore";
import Layout from "./components/Shared/Layout";
import Login from "./components/Authentication/login";
import SignUp from "./components/Authentication/signup";
import ForgotPassword from "./components/Authentication/forgotpassword";
import NotFound from "./components/Authentication/404";
import InternalServer from "./components/Authentication/500";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import AppApolloprovider from "./components/Providers/ApolloProvider";
import ProtectedRoute from "./components/Authentication/ProtectedRoute";
// import SetPassword from "./components/Authentication/SetPassword";
import QrAddCandidate from "./components/QrCode/QrCandidateForm";
import LandingPage from "./components/LandingPage/LandingPage";

import SetPassword from "./components/SetPassword";
import AboutUs from "./components/AboutUs";
import HomePage from "./components/HomePage";
import HomePageSearchCandidates from "./components/HomePageSearchCandidates";
import EmployerSelfRegistration from "./components/EmployerSelfRegistration";
import EmployerDashbord from "./components/EmployerDashboard/EmployerDashbord";
import EmployerAppliedCandidates from "./components/EmployerAppliedCandidates";
import EmployerJobs from "./components/EmployerJobs";
import EmployerJobDetails from "./components/EmployerJobDetails";
import EmployerJobApplicants from "./components/EmployerJobApplicants";
import EmployerAddEditJob from "./components/EmployerAddEditJob";
import EmployerScreeningQuestions from "./components/EmployerScreeningQuestions";
import EmployerSearchCandidates from "./components/EmployerSearchCandidate";
import EmployerJobRecommendedCandidates from "./components/EmployerJobRecommendedCandidates";
import EmployerJobRecommendedCandidateDetails from "./components/EmployerJobRecommendedCandidateDetails";
import EmployerProfile from "./components/EmployerProfile";

import Backdrop from "./common/Backdrop";

const App = (props) => {
  const {
    darkMode,
    boxLayout,
    darkSidebar,
    iconColor,
    gradientColor,
    rtl,
    fontType,
  } = props;
  const loading = getStore((state) => state.loading);
  return (
    <div
      className={`${darkMode ? "dark-mode" : ""}${
        darkSidebar ? "sidebar_dark" : ""
      } ${iconColor ? "iconcolor" : ""} ${gradientColor ? "gradient" : ""} ${
        rtl ? "rtl" : ""
      } ${fontType ? fontType : ""}${boxLayout ? "boxlayout" : ""}`}
    >
      <Router>
        <AppApolloprovider>
          <Switch>
            {/* <Route exact path="/setpassword" component={SetPassword} /> */}
            {/* <Route exact path="/" component={LandingPage} /> */}
            <Route exact path="/login" component={Login} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route path="/notfound" component={NotFound} />
            <Route path="/internalserver" component={InternalServer} />
            <Route path="/qrForm/" component={QrAddCandidate} />
            <ProtectedRoute
              exact
              path="/dashboard"
              component={Layout}
              authenticationPath="/"
              pageTitle="Dashboard"
            />

            <Route exact path="/setpassword" component={SetPassword} />
            <Route exact path="/" component={HomePage} />
            <Route
              exact
              path="/search-results"
              component={HomePageSearchCandidates}
            />
            <Route exact path="/about-us" component={AboutUs} />
            <Route
              exact
              path="/employer-registration"
              component={EmployerSelfRegistration}
            />
            <Route
              exact
              path="/employer-dashboard"
              component={EmployerDashbord}
            />
            <Route
              exact
              path="/employer-applied-candidates"
              component={EmployerAppliedCandidates}
            />
            <Route exact path="/employer-jobs" component={EmployerJobs} />
            <Route
              exact
              path="/employer-job-details/:jobId"
              component={EmployerJobDetails}
            />
            <Route
              exact
              path="/employer-job-applicants/:jobId"
              component={EmployerJobApplicants}
            />
            <Route
              exact
              path="/employer-add-job/:jobId"
              component={EmployerAddEditJob}
            />
            <Route
              exact
              path="/employer-add-screeningQuestions"
              component={EmployerScreeningQuestions}
            />
            <Route
              exact
              path="/employer-search-candidate"
              component={EmployerSearchCandidates}
            />
            <Route
              exact
              path="/recommended-candidates/:jobId"
              component={EmployerJobRecommendedCandidates}
            />
            <Route
              exact
              path="/candidate-details/:candidateId"
              component={EmployerJobRecommendedCandidateDetails}
            />
            <Route exact path="/employer-profile" component={EmployerProfile} />

            <Route component={Layout} />
          </Switch>
        </AppApolloprovider>
      </Router>
      <Backdrop open={loading || false} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  darkMode: state.settings.isDarkMode,
  darkSidebar: state.settings.isDarkSidebar,
  iconColor: state.settings.isIconColor,
  gradientColor: state.settings.isGradientColor,
  rtl: state.settings.isRtl,
  fontType: state.settings.isFont,
  boxLayout: state.settings.isBoxLayout,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(App);
