import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Paper,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { LocationOnOutlined } from "@mui/icons-material";
import { jobModesArr, jobTeamTypesArr } from "../../constants";
import colors from "../../styles/colors";

const JobDetails = (props) => {
  const {
    jobCategories,
    qualifications,
    countries,
    states,
    cities,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    errors,
  } = props;

  const [jobCategoriesArr, setJobCategoriesArr] = useState([]);
  const [qualificationsArr, setQualificationsArr] = useState([]);
  const [countryArr, setCountryArr] = useState([]);
  const [stateArr, setStateArr] = useState([]);
  const [cityArr, setCityArr] = useState([]);
  const [selectedJobCat, setSelectedJobCat] = useState({ id: "", name: "" });
  const [selectedQualification, setSelectedQualification] = useState({
    id: "",
    name: "",
  });
  const [selectedCountry, setSelectedCountry] = useState({ id: "", name: "" });
  const [selectedState, setSelectedState] = useState({ id: "", name: "" });
  const [selectedCity, setSelectedCity] = useState({ id: "", name: "" });

  useEffect(() => {
    if (jobCategories && jobCategories.jobTypes) {
      setJobCategoriesArr(jobCategories.jobTypes);
    }
  }, [jobCategories]);

  useEffect(() => {
    if (qualifications && qualifications.qualifications) {
      setQualificationsArr(qualifications.qualifications);
    }
  }, [qualifications]);

  useEffect(() => {
    if (countries && countries.countries) {
      setCountryArr(countries.countries);
    }
  }, [countries]);

  useEffect(() => {
    if (states && states.states) {
      setStateArr(states.states);
    }
  }, [states]);

  useEffect(() => {
    if (cities && cities.cities) {
      setCityArr(cities.cities);
    }
  }, [cities]);

  useEffect(() => {
    if (values && values.jobTypeId) {
      setSelectedJobCat(
        jobCategoriesArr.find((x) => x.id === values.jobTypeId) || {
          id: "",
          name: "",
        }
      );
    }
  }, [jobCategoriesArr, values]);

  useEffect(() => {
    if (values && values.qualificationId) {
      setSelectedQualification(
        qualificationsArr.find((x) => x.id === values.qualificationId) || {
          id: "",
          name: "",
        }
      );
    }
  }, [qualificationsArr, values]);

  useEffect(() => {
    if (values && values.countryId) {
      setSelectedCountry(
        countryArr.find((x) => x.id === values.countryId) || {
          id: "",
          name: "",
        }
      );
    }
  }, [countryArr, values]);

  useEffect(() => {
    if (values && values.stateId) {
      setSelectedState(
        stateArr.find((x) => x.id === values.stateId) || { id: "", name: "" }
      );
    }
  }, [stateArr, values]);

  useEffect(() => {
    if (values && values.cityId) {
      setSelectedCity(
        cityArr.find((x) => x.id === values.cityId) || { id: "", name: "" }
      );
    }
  }, [cityArr, values]);

  return (
    <Paper
      elevation={1}
      sx={{ pt: { xs: 2, md: 5 }, px: 4, pb: { xs: 2, md: 10 } }}
    >
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">Job Title</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
          <TextField
            id="title"
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
          />
          {touched.title && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.title}
            </Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">No. of Positions</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
          <TextField
            id="positions"
            value={values.positions}
            onChange={(e) =>
              setFieldValue("positions", parseInt(e.target.value))
            }
            onBlur={handleBlur}
            size="small"
            type="number"
            fullWidth
          />
          {touched.positions && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.positions}
            </Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">Job Type</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -2 }}>
          <Autocomplete
            id="jobMode"
            autoComplete
            disableClearable
            options={jobModesArr}
            getOptionLabel={(option) => option.name}
            value={jobModesArr.find((x) => x.id === values.jobMode)}
            onChange={(event, value) => setFieldValue("jobMode", value.id)}
            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose..." />
            )}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -2 }}>
          <Autocomplete
            id="jobTeamType"
            autoComplete
            disableClearable
            options={jobTeamTypesArr}
            getOptionLabel={(option) => option.name}
            value={jobTeamTypesArr.find((x) => x.id === values.jobTeamType)}
            onChange={(event, value) => setFieldValue("jobTeamType", value.id)}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Eg: In-Office, Work from home"
              />
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">Job Description</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
          <TextField
            id="description"
            value={values.description}
            onChange={handleChange}
            size="small"
            fullWidth
            multiline
            minRows={5}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">Job Category</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
          <Autocomplete
            id="jobTypeId"
            autoComplete
            disableClearable
            options={jobCategoriesArr}
            getOptionLabel={(option) => option.name}
            value={selectedJobCat}
            onChange={(event, value) => setFieldValue("jobTypeId", value.id)}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose..."
                onBlur={handleBlur}
              />
            )}
          />
          {touched.jobTypeId && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.jobTypeId}
            </Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">Qualification</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
          <Autocomplete
            id="qualificationId"
            autoComplete
            disableClearable
            options={qualificationsArr}
            getOptionLabel={(option) => option.name}
            value={selectedQualification}
            onChange={(event, value) =>
              setFieldValue("qualificationId", value.id)
            }
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose..."
                onBlur={handleBlur}
              />
            )}
          />
          {touched.qualificationId && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.qualificationId}
            </Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">Key Skills</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
          <Autocomplete
            id="skills"
            autoComplete
            disableClearable
            multiple
            freeSolo
            options={[]}
            value={values.skills}
            onChange={(event, newArr) => setFieldValue("skills", newArr)}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Add here"
                onBlur={handleBlur}
              />
            )}
            ChipProps={{
              sx: { backgroundColor: colors.primary, color: colors.secondary },
            }}
          />
          {touched.skills && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.skills}
            </Typography>
          )}
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Typography fontSize="13px">Country</Typography>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Typography fontSize="13px">State</Typography>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Typography fontSize="13px">City</Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: -2 }}>
          <Autocomplete
            id="countryId"
            autoComplete
            disableClearable
            options={countryArr}
            getOptionLabel={(option) => option.name}
            value={selectedCountry}
            onChange={(event, value) => setFieldValue("countryId", value.id)}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose..."
                onBlur={handleBlur}
              />
            )}
          />
          {touched.countryId && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.countryId}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          <Typography fontSize="13px">State</Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: -2 }}>
          <Autocomplete
            id="stateId"
            autoComplete
            disableClearable
            options={stateArr}
            getOptionLabel={(option) => option.name}
            value={selectedState}
            onChange={(event, value) => setFieldValue("stateId", value.id)}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose..."
                onBlur={handleBlur}
              />
            )}
          />
          {touched.stateId && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.stateId}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          <Typography fontSize="13px">City</Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: -2 }}>
          <Autocomplete
            id="cityId"
            autoComplete
            disableClearable
            options={cityArr}
            getOptionLabel={(option) => option.name}
            value={selectedCity}
            onChange={(event, value) => setFieldValue("cityId", value.id)}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose..."
                onBlur={handleBlur}
              />
            )}
          />
          {touched.cityId && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.cityId}
            </Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">Work Location</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
          <TextField
            id="location"
            value={values.location}
            onChange={handleChange}
            size="small"
            fullWidth
            placeholder="Enter address"
            onBlur={handleBlur}
          />
          {touched.location && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.location}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default JobDetails;
