import React, { useState, useEffect } from "react";
import { GET_CENTRES } from "../../graphql/meta";
import { DELETE_CENTRE } from "../../graphql/centres";
import { useQuery, useMutation } from "@apollo/client";
import CentreForm from "./CentreForm";
import { Modal } from "react-bootstrap";

const ManageCentres = () => {
  const [addCentreView, setAddCentreView] = useState(false);
  const [selectedCentreData, setSelectedCentreData] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [filter, setFilter] = useState("");

  const { data: centresDropDown, refetch,loading:loadingCentres } = useQuery(GET_CENTRES, {
    variables: {
      filter: {
        name__icontains: filter,
      },
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [deleteCentre] = useMutation(DELETE_CENTRE, {
    onCompleted: (data) => {
      const { deleteCentre } = data || {};
      const { status, error } = deleteCentre;
      console.log("CENTRE DELETED", status, error);
      if (status) {
        setConfirmModal(false);
        refetch();
      }
    },
  });

  useEffect(() => {
    if (addCentreView === false) {
      setSelectedCentreData({});
      refetch();
    }
  }, [addCentreView, refetch]);

  const CentreCard = (props) => {
    const { o } = props;

    return (
      <div className="col-xl-3 col-lg-4 col-md-6">
        <div className="card">
          <div className="card-body text-center ribbon">
            <span
              className="avatar avatar-cyan"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Avatar Name"
            >
              {o.name ? o.name.substring(0, 1) : "-"}
            </span>
            <h6 className="mt-3 mb-0">{o.name}</h6>
            <span>
              {o?.state?.name || "-"}, {o?.country?.name || "-"}
            </span>
            <ul className="mt-3 list-unstyled d-flex justify-content-center align-items-center">
              <li>
                <button
                  type="button"
                  className="btn btn-icon"
                  title="Edit"
                  onClick={() => {
                    setSelectedCentreData(o);
                    setAddCentreView(true);
                  }}
                >
                  <i className="fa fa-edit" />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="btn btn-icon"
                  title="Delete"
                  onClick={() => {
                    setSelectedCentreData(o);
                    setConfirmModal(true);
                  }}
                >
                  <i className="fa fa-trash" />
                </button>
              </li>
            </ul>
            {/* <div className="row text-center mt-4">
                            <div className="col-lg-6 border-right">
                                <label className="mb-0">Posts</label>
                                <h4 className="font-18">{o.posts}</h4>
                            </div>
                            <div className="col-lg-6">
                                <label className="mb-0">Threads</label>
                                <h4 className="font-18">{o.threads}</h4>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {!addCentreView && (
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col-lg-3 col-md-3 col-sm-6">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Centre"
                          onChange={(e) => setFilter(e.target.value)}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-2 col-md-4 col-sm-6"
                      style={{ textAlign: "right" }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        // style={{ height: 30, marginBottom: 10 }}
                        onClick={() => setAddCentreView(true)}
                      >
                        <i className="fe fe-plus mr-2" />
                        Add Centre
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!loadingCentres && centresDropDown &&
              centresDropDown.centres &&
              centresDropDown.centres.map((o) => (
                <CentreCard o={o} key={o.id} />
              ))}
              {loadingCentres && <div className="d-flex justify-content-center w-100">
                  <div className="d-flex flex-column align-items-center">
                    <p style={{ font: 15 }}>Loading... </p>
                  <div
                  class="spinner-border m-auto"
                  role="status"
                  aria-hidden="true"
                ></div>
                </div>
                  </div>}
          </div>
          <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
            <Modal.Header>
              <Modal.Title>Confirm</Modal.Title>
              <button
                type="button"
                class="close"
                aria-label="Close"
                onClick={() => setConfirmModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete the centre?</Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-outline-success"
                onClick={() =>
                  deleteCentre({
                    variables: {
                      ids: [selectedCentreData.id],
                    },
                  })
                }
              >
                Yes
              </button>
              <button
                type="button"
                class="btn btn-outline-danger"
                onClick={() => setConfirmModal(false)}
              >
                No
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      {addCentreView && (
        <CentreForm
          setAddCentreView={setAddCentreView}
          selectedCentreData={selectedCentreData}
          refetchCentres={refetch}
        />
      )}
    </div>
  );
};

export default ManageCentres;
