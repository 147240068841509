import React from "react";
import { Avatar, Grid, Paper, Typography } from "@mui/material";
import {
  CalendarTodayOutlined,
  CallOutlined,
  LocationOnOutlined,
  MailOutline,
  SchoolOutlined,
  Transgender,
} from "@mui/icons-material";
import colors from "../../styles/colors";
import { getDateInFormat } from "../../helpers";
import { genders } from "../../constants";

const PersonalDetails = (props) => {
  const { user } = props;
  return (
    <Paper elevation={2} sx={{ px: 2, py: 3 }}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontWeight={600} fontSize="18px">
            Personal Details
          </Typography>
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          sm={6}
          xs={12}
          display="flex"
          alignItems="center"
        >
          <Avatar sx={{ backgroundColor: colors.primaryFill }}>
            <MailOutline color="primary" />
          </Avatar>
          <Typography sx={{ ml: 1 }} fontSize="14px">
            {user?.email}
          </Typography>
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          sm={6}
          xs={12}
          display="flex"
          alignItems="center"
        >
          <Avatar sx={{ backgroundColor: colors.primaryFill }}>
            <CallOutlined color="primary" />
          </Avatar>
          <Typography sx={{ ml: 1 }} fontSize="14px">
            {user?.mobile}
          </Typography>
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          sm={6}
          xs={12}
          display="flex"
          alignItems="center"
        >
          <Avatar sx={{ backgroundColor: colors.primaryFill }}>
            <CalendarTodayOutlined color="primary" />
          </Avatar>
          <Typography sx={{ ml: 1 }} fontSize="14px">
            {user?.dob
              ? `DOB: ${getDateInFormat(user?.dob, "DD MMM YYYY")}`
              : ""}
          </Typography>
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          sm={6}
          xs={12}
          display="flex"
          alignItems="center"
        >
          <Avatar sx={{ backgroundColor: colors.primaryFill }}>
            <LocationOnOutlined color="primary" />
          </Avatar>
          <Typography sx={{ ml: 1 }} fontSize="14px">
            {user?.city?.name || ""}, {user?.state?.name}
          </Typography>
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          sm={6}
          xs={12}
          display="flex"
          alignItems="center"
        >
          <Avatar sx={{ backgroundColor: colors.primaryFill }}>
            <Transgender color="primary" />
          </Avatar>
          <Typography sx={{ ml: 1 }} fontSize="14px">
            {user?.gender ? genders[user?.gender] : ""}
          </Typography>
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          sm={6}
          xs={12}
          display="flex"
          alignItems="center"
        >
          <Avatar sx={{ backgroundColor: colors.primaryFill }}>
            <SchoolOutlined color="primary" />
          </Avatar>
          <Typography sx={{ ml: 1 }} fontSize="14px">
            {user?.qualification?.name || ""}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PersonalDetails;
