import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import JobsList from "./JobsList";
import getStore from "../../zustandStore";
import EmployerNavbar from "../../common/EmployerNavBar";
import colors from "../../styles/colors";

function Jobs(props) {
  const history = useHistory();
  const setJobPayload = getStore((state) => state.setJobPayload);
  const setScreeningQuestions = getStore(
    (state) => state.setScreeningQuestions
  );

  const [jobsCount, setJobsCount] = useState(0);

  useEffect(() => {
    setJobPayload({});
    setScreeningQuestions([]);
  }, [setJobPayload, setScreeningQuestions]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <Typography fontSize="24px" color={colors.dark} fontWeight={500}>
          Jobs
        </Typography>
      </Grid>
      {jobsCount > 0 && (
        <Grid item lg={8} md={8} sm={8} xs={12}>
          <Grid
            container
            spacing={1}
            sx={{ justifyContent: { xs: "flex-start", sm: "flex-end" } }}
          >
            {/* <Grid item>
              <Button variant="outlined" size="small" disableElevation>
                Drafts
              </Button>
            </Grid> */}
            <Grid item>
              <Button
                variant="contained"
                size="small"
                disableElevation
                onClick={() => history.push("/employer-add-job/new")}
              >
                Post a Job
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <JobsList rowCount={jobsCount} setRowCount={setJobsCount} />
      </Grid>
    </Grid>
  );
}

const EmployerJobs = (props) => {
  return <EmployerNavbar mainComponent={<Jobs />} />;
};

export default EmployerJobs;
