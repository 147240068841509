import React, { useState, useEffect } from "react";

const Pagination = (props) => {
  const [noOfPages, setNoOfPages] = useState(0);
  //   const [pageArray, setPageArray] = useState([]);
  const [numArr, setNumArr] = useState([]);
  const { page, pageSize, rowCount, setPage, setPageSize } = props;

  console.log({ rowCount, page, pageSize });

  useEffect(() => {
    if (rowCount > pageSize) {
      let pages = Math.ceil(rowCount / pageSize);
      setNoOfPages(pages);
    } else {
      setNoOfPages(1);
    }
  }, [rowCount, pageSize]);

  //   useEffect(() => {
  //     console.log({ noOfPages });
  //     let arr = [];
  //     for (let i = 0; i < noOfPages; i++) {
  //       arr.push(i);
  //     }
  //     setPageArray(arr);
  //   }, [noOfPages]);

  useEffect(() => {
    let arr = [];
    if (rowCount < 5) {
      arr.push(5);
    }
    if (rowCount >= 5 && rowCount <= 10) {
      arr.push(5);
      arr.push(10);
    }
    if (rowCount > 10 && rowCount < 50) {
      arr.push(5);
      arr.push(10);
      arr.push(20);
    }
    if (rowCount >= 50 && rowCount < 100) {
      arr.push(5);
      arr.push(10);
      arr.push(20);
      arr.push(50);
    }
    if (rowCount >= 100 && rowCount < 500) {
      arr.push(5);
      arr.push(10);
      arr.push(20);
      arr.push(50);
      arr.push(100);
    }
    if (rowCount >= 500 && rowCount < 1000) {
      arr.push(5);
      arr.push(10);
      arr.push(20);
      arr.push(50);
      arr.push(100);
      arr.push(500);
    }
    if (rowCount >= 1000) {
      arr.push(5);
      arr.push(10);
      arr.push(20);
      arr.push(50);
      arr.push(100);
      arr.push(500);
      arr.push(1000);
    }
    setNumArr(arr);
  }, [rowCount]);

  //   const Pages = ({ pageNo }) => {
  //     return (
  //       <li class="page-item">
  //         <a class="page-link" onClick={() => setPage(pageNo)}>
  //           {pageNo + 1}
  //         </a>
  //       </li>
  //     );
  //   };

  return (
    <nav
      aria-label="Page navigation example"
      style={{ justifyContent: "space-between", display: "flex" }}
    >
      <ul class="pagination">
        <li class="page-item">
          <div className="page-link" style={{ padding: 0 }}>
            <button
              class="btn btn-icon"
              aria-label="Previous"
              onClick={() => setPage(0)}
              disabled={page === 0 ? true : false}
              style={{ color: page === 0 ? "#D6D6D6" : "black" }}
            >
              <i className="fa fa-angle-double-left" />
            </button>
          </div>
        </li>
        <li class="page-item">
          <div className="page-link" style={{ padding: 0 }}>
            <button
              class="btn btn-icon"
              aria-label="Previous"
              onClick={() => setPage((prev) => prev - 1)}
              disabled={page === 0 ? true : false}
              style={{ color: page === 0 ? "#D6D6D6" : "black" }}
            >
              <i className="fa fa-chevron-left" />
            </button>
          </div>
        </li>
        {/* {pageArray.length > 0 && pageArray.map((itm) => (
                    <Pages pageNo={itm} key={itm} />
                ))} */}
        <li class="page-item">
          <div className="page-link" style={{ padding: 0 }}>
            <button
              class="btn btn-icon"
              aria-label="Next"
              onClick={() => setPage((prev) => prev + 1)}
              disabled={page === noOfPages - 1 ? true : false}
              style={{ color: page === noOfPages - 1 ? "#D6D6D6" : "black" }}
            >
              <i className="fa fa-chevron-right" />
            </button>
          </div>
        </li>
        <li class="page-item">
          <div className="page-link" style={{ padding: 0 }}>
            <button
              class="btn btn-icon"
              aria-label="Next"
              onClick={() => setPage(noOfPages - 1)}
              disabled={page === noOfPages - 1 ? true : false}
              style={{ color: page === noOfPages - 1 ? "#D6D6D6" : "black" }}
            >
              <i className="fa fa-angle-double-right" />
            </button>
          </div>
        </li>
      </ul>
      <div className="d-flex justify-content-between align-items-center">
        <span style={{ fontSize: 14, marginRight: 10 }}>
          {`${1 + page * pageSize} - ${
            pageSize * (page + 1) > rowCount ? rowCount : pageSize * (page + 1)
          } / ${rowCount}`}
        </span>
        <select
          id="status"
          className="form-control"
          style={{
            border: "1PX #7C7B7B solid",
            width: 80,
            padding: 5,
            height: 30,
          }}
          onChange={(event) => {
            let x = parseInt(event.target.value);
            setPageSize(x);
          }}
        >
          {numArr &&
            numArr.map((i) => (
              <option
                key={i}
                value={i}
                selected={
                  rowCount >= pageSize
                    ? i === pageSize
                      ? true
                      : false
                    : i > rowCount
                    ? true
                    : false
                }
              >
                {i}
              </option>
            ))}
        </select>
      </div>
    </nav>
  );
};

export default Pagination;
