import { gql } from "@apollo/client";

export const TRANSACTION_TABLE_DATA = gql`
  query transactions($filter: TransactionsFilterInput) {
    transactions(filter: $filter) {
      count
      transactions 
    }
  }
`;

export const SUMMARY_TABLE_DATA = gql`
  query consolidatedReport($filter: TransactionsFilterInput) {
    consolidatedReport(filter: $filter) {
      organization {
        id
        name
      }
      centre
      mobilizationCount
      mobilizationRevenue
      placementCount
      placementRevenue
      recruitmentCount
      recruitmentRevenue
      mobilizationOrganizations
      placementOrganizations
      recruitmentOrganizations
    }
  }
`;

export const NEW_ENROLLED_TABLE_DATA = gql`
  query enrolledusers($filter: TransactionsFilterInput) {
    enrolledUsers(filter: $filter) {
      count
      users 
    }
  }
`;

export const EMPLOYER_JOB_REPORT = gql`
  query JobReport($filter: TransactionsFilterInput) {
    jobReport(filter: $filter) {
      candidates {
        id
        candidate {
          id
          firstName
          lastName
          mobile
          qualification {
            id
            name
          }
          experience
          country {
            id
            name
          }
          state {
            id
            name
          }
          city {
            id
            name
          }
          profile
        }
        job {
          id
          title
          country {
            id
            name
          }
          state {
            id
            name
          }
          city {
            id
            name
          }
          positions
        }
      }
    }
  }
`;

export const DOWNLOAD_REPORT = gql`
  query reports($filter: TransactionsFilterInput) {
    report(filter: $filter) {
      report
    }
  }
`;

export const STAFFING_REVENUE_REPORT = gql`
  query staffingRevenue(
    $filter: TransactionsFilterInput
    $limit: Int
    $page: Int
  ) {
    staffingRevenue(filter: $filter, limit: $limit, page: $page) {
      count
      transactions 
      __typename
    }
  }
`;
