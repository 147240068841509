import React, { useEffect, useState } from "react";
import MapChart from "../common/MapChart";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_JOB_CANDIDATES } from "../../graphql/jobs";
import { TOP_FIVE_EMPLOYERS, REVENUE } from "../../graphql/dashboard";
import moment from "moment";
import { isJwtTokenValid } from "../../utils";
import { Redirect } from "react-router-dom";
// import { analytics } from "../../firebaseconfig";
// import { logEvent } from "firebase/analytics";
const Dashboard = (props) => {
  const [candidates, setCandidates] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const { hasExpired, roles } = isJwtTokenValid();

  // const { data } = useQuery(GET_USERS, {
  //     variables: {
  //         page: 1,
  //         limit: 5,
  //         filter: {}
  //     },
  //     fetchPolicy: 'network-only',
  //     nextFetchPolicy: 'network-only',
  // });

  const { data } = useQuery(GET_JOB_CANDIDATES, {
    variables: {
      page: 1,
      limit: 5,
      filter: { status: 4 },
      recent_applicants: true,
    },
  });

  const { data: top5Employers } = useQuery(TOP_FIVE_EMPLOYERS, {
    variables: {
      page: 1,
      limit: 5,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: revenueData } = useQuery(REVENUE, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (revenueData?.revenue?.mobilizationCount) {
      setTotalCount(
        revenueData.revenue.mobilizationCount +
          revenueData.revenue.placementCount +
          revenueData.revenue.recruitmentCount
      );
    }
    // logEvent(analytics,"Dashboard Visited");
  }, [revenueData]);

  useEffect(() => {
    if (data && data.jobCandidates && data.jobCandidates.jobcandidates) {
      setCandidates(data.jobCandidates.jobcandidates);
    }
  }, [data]);

  return (
    <>
      {hasExpired ? (
        <Redirect to="/login" />
      ) : (
        <div>
          <div className="container-fluid">
            <div className="row clearfix row-deck">
              <div className="col-lg-6 col-md-12">
                {roles[0] === "mcc_ho_user" ? (
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title" style={{ fontWeight: "bold" }}>
                        MCC CENTRES
                      </h3>
                    </div>
                    <div className="card-body">
                      <MapChart />
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col-xl-4 col-md-12">
                          <div className="clearfix">
                            <div className="float-left">
                              <strong>
                                {revenueData?.revenue?.mobilizationCount || "0"}
                              </strong>
                            </div>
                            <div className="float-right">
                              <small className="text-muted">
                                ₹
                                {revenueData?.revenue?.mobilizationRevenue
                                  ? revenueData.revenue.mobilizationRevenue.toFixed(
                                      2
                                    )
                                  : 0}
                              </small>
                            </div>
                          </div>
                          <div className="progress progress-xs">
                            <div
                              className="progress-bar bg-gray"
                              role="progressbar"
                              style={{
                                width: `${
                                  (revenueData?.revenue?.mobilizationCount /
                                    totalCount) *
                                  100
                                }%`,
                              }}
                              aria-valuenow={42}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <span className="text-uppercase font-10">
                            Mobilization
                          </span>
                        </div>
                        <div className="col-xl-4 col-md-12">
                          <div className="clearfix">
                            <div className="float-left">
                              <strong>
                                {revenueData?.revenue?.placementCount || "0"}
                              </strong>
                            </div>
                            <div className="float-right">
                              <small className="text-muted">
                                ₹
                                {revenueData?.revenue?.placementRevenue
                                  ? revenueData.revenue.placementRevenue.toFixed(
                                      2
                                    )
                                  : 0}
                              </small>
                            </div>
                          </div>
                          <div className="progress progress-xs">
                            <div
                              className="progress-bar bg-gray"
                              role="progressbar"
                              style={{
                                width: `${
                                  (revenueData?.revenue?.placementCount /
                                    totalCount) *
                                  100
                                }%`,
                              }}
                              aria-valuenow={42}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <span className="text-uppercase font-10">
                            Placement
                          </span>
                        </div>
                        <div className="col-xl-4 col-md-12">
                          <div className="clearfix">
                            <div className="float-left">
                              <strong>
                                {revenueData?.revenue?.recruitmentCount || "0"}
                              </strong>
                            </div>
                            <div className="float-right">
                              <small className="text-muted">
                                ₹
                                {revenueData?.revenue?.recruitmentRevenue
                                  ? revenueData.revenue.recruitmentRevenue.toFixed(
                                      2
                                    )
                                  : 0}
                              </small>
                            </div>
                          </div>
                          <div className="progress progress-xs">
                            <div
                              className="progress-bar bg-gray"
                              role="progressbar"
                              style={{
                                width: `${
                                  (revenueData?.revenue?.recruitmentCount /
                                    totalCount) *
                                  100
                                }%`,
                              }}
                              aria-valuenow={42}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <span className="text-uppercase font-10">
                            Recruitment
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="d-column col-xl-12 col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title font-weight-bold">
                          Mobilization
                        </h3>
                      </div>
                      <div className="card-body" style={{ paddingTop: 6 }}>
                        <ul className="list-group">
                          <li className="list-group-item d-flex justify-content-flex-start align-items-center">
                            <small className="text-muted">Count: </small>
                            <p className="mb-0 ml-20">
                              {revenueData?.revenue?.mobilizationCount || "0"}
                            </p>
                          </li>
                          <li className="list-group-item d-flex justify-content-flex-start align-items-center">
                            <small className="text-muted">Revenue: </small>
                            <p className="mb-0 ml-20">
                              ₹
                              {revenueData?.revenue?.mobilizationRevenue
                                ? revenueData.revenue.mobilizationRevenue.toFixed(
                                    2
                                  )
                                : 0}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title font-weight-bold">
                          Placement
                        </h3>
                      </div>
                      <div className="card-body" style={{ paddingTop: 6 }}>
                        <ul className="list-group">
                          <li className="list-group-item d-flex justify-content-flex-start align-items-center">
                            <small className="text-muted">Count: </small>
                            <p className="mb-0 ml-20">
                              {revenueData?.revenue?.placementCount || "0"}
                            </p>
                          </li>
                          <li className="list-group-item d-flex justify-content-flex-start align-items-center">
                            <small className="text-muted">Revenue: </small>
                            <p className="mb-0 ml-20">
                              ₹
                              {revenueData?.revenue?.placementRevenue
                                ? revenueData.revenue.placementRevenue.toFixed(
                                    2
                                  )
                                : 0}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title font-weight-bold">
                          Selection
                        </h3>
                      </div>
                      <div className="card-body" style={{ paddingTop: 6 }}>
                        <ul className="list-group">
                          <li className="list-group-item d-flex justify-content-flex-start align-items-center">
                            <small className="text-muted">Count: </small>
                            <p className="mb-0 ml-20">
                              {revenueData?.revenue?.recruitmentCount || "0"}
                            </p>
                          </li>
                          <li className="list-group-item d-flex justify-content-flex-start align-items-center">
                            <small className="text-muted">Revenue: </small>
                            <p className="mb-0 ml-20">
                              ₹
                              {revenueData?.revenue?.recruitmentRevenue
                                ? revenueData.revenue.recruitmentRevenue.toFixed(
                                    2
                                  )
                                : 0}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="col-lg-3 col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <h6>Open Positions</h6>
                                    <h3 className="pt-3">
                                        <span className="counter"><CountUp end={1000} /></span>
                                    </h3>
                                    <hr></hr>
                                    <h6>Closed Positions</h6>
                                    <h3 className="pt-3">
                                        <span className="counter"><CountUp end={497} /></span>
                                    </h3>
                                </div>
                                <div className="card-footer" style={{ minHeight: '12vh' }}>
                                    <div className="row">
                                        <small>Have a look at all the employers and their openings. <Link to="/jobs-list">Click here</Link></small>
                                    </div>
                                </div>
                            </div>
                        </div> */}
              <div className="col-lg-6 col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title" style={{ fontWeight: "bold" }}>
                      CLIENT ACQUISITIONS
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row text-center" style={{ marginTop: -10 }}>
                      <div className="col-lg-4 col-sm-12 border-right">
                        <label className="mb-0 font-12">This month</label>
                        <h4 className="font-20" style={{ color: "purple" }}>
                          {top5Employers?.topEmployers?.clientAcquisition
                            ?.monthCount || "0"}
                        </h4>
                      </div>
                      <div className="col-lg-4 col-sm-12 border-right">
                        <label className="mb-0 font-12">This week</label>
                        <h4 className="font-20" style={{ color: "purple" }}>
                          {top5Employers?.topEmployers?.clientAcquisition
                            ?.weekCount || "0"}
                        </h4>
                      </div>
                      <div className="col-lg-4 col-sm-12">
                        <label className="mb-0 font-12">Today</label>
                        <h4 className="font-20" style={{ color: "purple" }}>
                          {top5Employers?.topEmployers?.clientAcquisition
                            ?.todaysCount || "0"}
                        </h4>
                      </div>
                    </div>
                    <h3
                      className="card-title"
                      style={{
                        marginTop: 20,
                        marginBottom: -5,
                        fontWeight: "bold",
                      }}
                    >
                      TOP 5 EMPLOYERS
                    </h3>
                    <table
                      className="table card-table mt-2"
                      style={{ marginBottom: -20 }}
                    >
                      <tbody>
                        {top5Employers &&
                        top5Employers?.topEmployers?.topemployers &&
                        top5Employers?.topEmployers?.topemployers.length > 0 ? (
                          top5Employers?.topEmployers?.topemployers.map((e) => (
                            <tr key={e.id}>
                              <td className="d-flex align-items-center">
                                <span
                                  className="avatar avatar-purple"
                                  style={{ marginRight: 5 }}
                                >
                                  <span>{e.name.substring(0, 1)}</span>
                                </span>
                                {e.name}
                              </td>
                              <td className="text-right">
                                <p className="mb-0">
                                  Closed: {e.closedPositions}
                                </p>
                                <span className="text-success font-13">
                                  Open: {e.openPositions}
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={2}>No clients on-boarded yet!</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer">
                    <div className="row">
                      <span className="font-14">
                        Have a look at all the clients on-boarded.{" "}
                        <Link to="/organizations">Click here</Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix row-deck">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title" style={{ fontWeight: "bold" }}>
                      Recent Applicants
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover table-striped table-vcenter mb-0">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Client Name</th>
                            <th>Applied for</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {candidates && candidates.length > 0 ? (
                            candidates.map((c) => (
                              <tr key={c.id}>
                                <td>
                                  <div className="d-flex justfy-content-between align-items-center">
                                    <span className="avatar avatar-cyan">
                                      {c.candidate.firstName?.substring(0, 1) ||
                                        "-"}
                                    </span>
                                    <div
                                      className="font-15"
                                      style={{ marginLeft: 5 }}
                                    >
                                      {c.candidate.firstName || ""}{" "}
                                      {c.candidate.lastName || ""}
                                    </div>
                                  </div>
                                </td>
                                <td>{c?.candidate?.mobile || "-"}</td>
                                <td>{c?.job?.organization?.name || "-"}</td>
                                <td>
                                  <span>{c?.job?.title || "-"}</span>
                                </td>
                                <td>
                                  {c?.createdDate
                                    ? moment(c.createdDate).format(
                                        "DD MMM YYYY"
                                      )
                                    : "-"}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={5} style={{ textAlign: "center" }}>
                                No applicants yet
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
