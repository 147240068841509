import { gql } from "@apollo/client";

export const GET_ALL_JOBS_COUNT_AND_LIST = gql`
  query jobs {
    jobs(filter: {}) {
      count
      jobs {
        title
        id
        jobId
        organization {
          name
        }
      }
    }
  }
`;

export const GET_TOTAL_APPLIED_COUNT = gql`
  query jobCandidates {
    jobCandidates(
      filter: { status: 4 }
      limit: 0
      page: 1
      recent_applicants: false
    ) {
      count
    }
  }
`;

export const GET_TOTAL_SHORTLISTED_COUNT = gql`
  query jobCandidates {
    jobCandidates(
      filter: { status: 5 }
      limit: 0
      page: 1
      recent_applicants: false
    ) {
      count
    }
  }
`;

export const GET_TOTAL_INTERVIEWED_COUNT = gql`
  query jobCandidates {
    jobCandidates(
      filter: { status: 6 }
      limit: 0
      page: 1
      recent_applicants: false
    ) {
      count
    }
  }
`;

export const GET_RECENT_APPLICANTS = gql`
  query jobCandidates($sorted: String) {
    jobCandidates(
      filter: {}
      limit: 0
      page: 1
      recent_applicants: false
      sorted: $sorted
    ) {
      count
      jobcandidates {
        id
        candidate {
          id
          firstName
          lastName
          email
          mobile
          skills
          score
        }
        status
        createdDate
        screeningAnswers {
          id
          question {
            id
            question
            answerType
            answer
            mandatory
          }
          answer
        }
        job {
          id
          title
        }
      }
    }
  }
`;

export const GET_CHARTS_DATA = gql`
  query empDashboard(
    $payload: EmployerDashboardInput
    $recent_applicants: Boolean
  ) {
    getEmployerDashboard(
      payload: $payload
      recent_applicants: $recent_applicants
    ) {
      totalCandidates
      shortlistedRatio
      interviewedRatio
      rejectedRatio
      genderRatio
      chartData
    }
  }
`;
