import React, { useState, useEffect } from "react";
import {
  GET_CANDIDATES,
  GET_JOB_CANDIDATES,
  ADD_CANDIDATE_TO_JOB,
  UPDATE_JOB_CANDIDATE,
  CANCEL_BOOKING,
} from "../../graphql/jobs";
import { GET_RESUME } from "../../graphql/users";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  GET_QUALIFICATIONS,
  GET_ALL_LOCATIONS,
  GET_ALL_CITIES,
  GET_ALL_STATES,
} from "../../graphql/meta";
import Pagination from "../Shared/Pagination";
import PlacedModal from "./PlacedModal";
import { Modal } from "react-bootstrap";
import UpdateStatusModal from "./UpdateStatusModal";
import OfferedModal from "./OfferedModal";
import { isJwtTokenValid, downloadAny } from "../../utils";

const trackingStatus = [
  { id: 4, name: "Applied" },
  { id: 5, name: "Shortlisted" },
  { id: 6, name: "Interviewed" },
  { id: 7, name: "Offered" },
  { id: 13, name: "Joined" },
  { id: 9, name: "Rejected" },
];

const JobDetails = (props) => {
  const {
    fixNavbar,
    setJobDetailsView,
    selectedJobPayload,
    addApplicants,
    organization,
  } = props;
  const [searchView, setSearchView] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [jobCandidates, setJobCandidates] = useState([]);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page1, setPage1] = useState(0);
  const [rowCount1, setRowCount1] = useState(0);
  const [pageSize1, setPageSize1] = useState(10);
  const [candidateFilter, setCandidateFilter] = useState({});
  const [jobCandidateFilter, setJobCandidateFilter] = useState({});
  //   const [timer, setTimer] = useState(null);
  const [updateStatusModal, setUpdateStatusModal] = useState(false);
  const [bulkSelectedCandidates, setBulkSelectedCandidates] = useState([]);
  const [bulkSelectedJobCandidates, setBulkSelectedJobCandidates] = useState(
    []
  );
  const [placedModal, setPlacedModal] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState("");
  const [warningModal, setWarningModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [changeConfirmModal, setChangeConfirmModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(-1);
  const [selectedApplicant, setSelectedApplicant] = useState("");
  const [selectedApplicantData, setSelectedApplicantData] = useState({});
  const [updateOffered, setupdateOffered] = useState(false);
  const [addingApplicants, setAddingApplicant]=useState(false);

  const { roles } = isJwtTokenValid();

  const [cancelBookingConfirmModal, setCancelBookingConfirmModal] =
    useState(false);
  const [selectedBookingForCancel, setSelectedBookingForCancel] =
    useState(null);

  const [cancellationReason, setCancellationReason] = useState("");

  useEffect(() => {
    console.log({ bulkSelectedCandidates, bulkSelectedJobCandidates });
  }, [bulkSelectedJobCandidates, bulkSelectedCandidates]);

  const { data, refetch: refetchCandidates, loading: loadingCandidates } = useQuery(GET_CANDIDATES, {
    variables: {
      page: page1 + 1,
      limit: pageSize1,
      filter: candidateFilter,
      jobId: selectedJobPayload?.id,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    skip: !searchView
  });

  useEffect(() => {
    if (data && data.candidates && data.candidates.users) {
      setRowCount1(data.candidates.count);
      setCandidates(data.candidates.users);
    }
  }, [data]);

  const { data: qualifications } = useQuery(GET_QUALIFICATIONS, {
    variables: {},
  });

  const { data: allStates } = useQuery(GET_ALL_STATES, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: allCities } = useQuery(GET_ALL_CITIES, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: allLocations } = useQuery(GET_ALL_LOCATIONS, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: jcs, refetch: refetchJobCandidates, loading: loadingJobCandidate } = useQuery(
    GET_JOB_CANDIDATES,
    {
      variables: {
        page: page + 1,
        limit: pageSize,
        filter: { jobId: selectedJobPayload?.id, ...jobCandidateFilter },
        recent_applicants: false,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      skip: !selectedJobPayload,
    }
  );

  useEffect(() => {
    if (jcs && jcs.jobCandidates && jcs.jobCandidates.jobcandidates) {
      setRowCount(jcs.jobCandidates.count);
      setJobCandidates(jcs.jobCandidates.jobcandidates);
    }
  }, [jcs]);

  useEffect(() => {
    console.log({ jcs });
  }, [jcs]);

  const [addJobCandidate] = useMutation(ADD_CANDIDATE_TO_JOB, {
    onCompleted: async (data) => {
      const { createJobCandidate } = data || {};
      const { status, error } = createJobCandidate;
      console.log({ status, error });
      if (status) {
        setConfirmModal(false);
        await refetchCandidates();
        await refetchJobCandidates();
      }
      setAddingApplicant(false)
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [updateJobCandidate] = useMutation(UPDATE_JOB_CANDIDATE, {
    onCompleted: (data) => {
      const { updateJobCandidate } = data || {};
      const { status, error } = updateJobCandidate;
      console.log({ status, error });
      if (status) {
        setChangeConfirmModal(false);
        setSelectedStatus(-1);
        // setSelectedApplicant('')
        refetchJobCandidates();
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [cancelBooking] = useMutation(CANCEL_BOOKING, {
    onCompleted: (data) => {
      const { cancelBooking } = data || {};
      const { status, error } = cancelBooking;
      console.log({ status, error });
      if (status) {
        setCancelBookingConfirmModal(false);
        setSelectedBookingForCancel("");
        // setSelectedApplicant('')
        refetchJobCandidates();
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const ApplicantTracker = ({
    jobApplicantId,
    status,
    candidateId,
    candidateData,
  }) => {
    const handleChange = (e) => {
      // updateJobCandidate({
      //     variables: {
      //         payload: {
      //             id: [jobApplicantId],
      //             status: parseInt(e.target.value)
      //         }
      //     }
      // })
      // debugger
      if (e.target.value === "7") {
        setSelectedCandidate(candidateId);
        setSelectedApplicantData(candidateData);
        setSelectedApplicant(jobApplicantId);
        setupdateOffered(true);
        console.log(selectedStatus);
      } else if (e.target.value === "13") {
        setPlacedModal(true);
        setSelectedApplicant(jobApplicantId);
        setSelectedCandidate(candidateId);
        setSelectedApplicantData(candidateData);
      } else {
        setChangeConfirmModal(true);
        setSelectedStatus(parseInt(e.target.value));
        setSelectedApplicant(jobApplicantId);
        // if (e.target.value === '13') {
        //     setPlacedModal(true)
        setSelectedCandidate(candidateId);
        setSelectedApplicantData(candidateData);
        // }
        // if (placedModal === false) {
        //     setChangeConfirmModal(true)
        //     setSelectedStatus(parseInt(e.target.value))
        //     setSelectedApplicant(jobApplicantId)
        // }
      }
    };

    return (
      <select
        id="status"
        className="form-control"
        style={{ border: "1PX #7C7B7B solid" }}
        onChange={handleChange}
      >
        <option
          selected={
            status === 0 ||
            status === 1 ||
            status === 2 ||
            status === 3 ||
            status === 8 ||
            status === 10 ||
            status === 11 ||
            status === 12
          }
          value
          key={"status0"}
        >
          {" "}
          -- Select Status --{" "}
        </option>
        {trackingStatus.map((c) => (
          <option
            key={c.id}
            value={c.id}
            selected={status === c.id ? true : false}
          >
            {" "}
            {c.name}{" "}
          </option>
        ))}
      </select>
    );
  };

  const Candidate = ({ o }) => {
    const [jobStatus, setJobStatus] = useState("");

    useEffect(() => {
      if (o?.jobStatus) {
        for (let i = 0; i < trackingStatus.length; i++) {
          if (trackingStatus[i].id === o.jobStatus) {
            setJobStatus(trackingStatus[i].name);
            break;
          }
        }
        // setJobStatus(status)
      }
    }, [o]);

    return (
      <tr>
        <td className="w40">
          <label className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              name="example-checkbox1"
              // defaultValue="option1"
              value={o?.id}
              // checked={bulkSelected'Candidates.indexOf(o?.id) !== -1 ? true : false}
              checked={
                bulkSelectedCandidates.findIndex(
                  (e) => e.candidateId === o?.id
                ) !== -1
                  ? true
                  : false
              }
              onChange={(event) => {
                let arr = [...bulkSelectedCandidates];
                if (event.target.checked) {
                  arr.push({
                    jobId: selectedJobPayload?.id,
                    candidateId: o?.id,
                    status: 4,
                  });
                } else if (!event.target.checked) {
                  arr = arr.filter(
                    (itm) => itm.candidateId !== event.target.value
                  );
                }
                setBulkSelectedCandidates(arr);
              }}
            />
            <span className="custom-control-label">&nbsp;</span>
          </label>
        </td>
        <td>
          {o.firstName || ""} {o.lastName || ""}
        </td>
        <td>{o.gender}</td>
        <td>
          {o.qualification?.name || (
            <hr style={{ backgroundColor: "black", width: 15 }} />
          )}
        </td>
        <td>
          <span>
            {o.city?.name || "-"}, {o.state?.name || "-"},{" "}
            {o.country?.name || "-"}
          </span>
        </td>
        <td>
          {o?.experience === null
            ? "NA"
            : o?.experience === 0
            ? "Fresher"
            : o?.experience}
        </td>
        <td>
          {jobStatus || <hr style={{ backgroundColor: "black", width: 15 }} />}
        </td>
        <td>
          <button
            type="button"
            className="btn btn-icon"
            title="Add"
            onClick={() => {
              setAddingApplicant(true)
              if (jobStatus === "Shortlisted") {
                setWarningModal(true);
                console.log("WARNING");
              }
              addJobCandidate({
                variables: {
                  payload: [
                    {
                      jobId: selectedJobPayload?.id,
                      candidateId: o.id,
                      status: 4,
                    },
                  ],
                },
              });
            }}
            disabled={addingApplicants}
          >
            <i className="fa fa-plus" />
          </button>
        </td>
      </tr>
    );
  };

  const JobCandidate = ({ o }) => {
    const [jobStatus1, setJobStatus1] = useState("");
    useEffect(() => {
      if (o?.status) {
        for (let i = 0; i < trackingStatus.length; i++) {
          if (trackingStatus[i].id === o.status) {
            setJobStatus1(trackingStatus[i].name);
            break;
          }
        }
        // setJobStatus(status)
      }
    }, [o]);
    const [getResume, { loading, data }] = useLazyQuery(GET_RESUME, {
      nextFetchPolicy: "network-only",
      fetchPolicy: "network-only",
    });

    const downloadResume = async (userId) => {
      let temp = await getResume({
        variables: {
          userId: userId,
        },
      });
      let data = temp.data.getResume;
      downloadAny(data.file, data.fileName, data.fileType);
    };
    return (
      <tr>
        {addApplicants && selectedJobPayload?.status === 1 && (
          <td className="w40">
            <label className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                name="example-checkbox1"
                // defaultValue="option1"
                value={o?.id}
                checked={
                  bulkSelectedJobCandidates.indexOf(o?.id) !== -1 ? true : false
                }
                onChange={(event) => {
                  let arr = [...bulkSelectedJobCandidates];
                  if (event.target.checked) {
                    arr.push(event.target.value);
                  } else if (!event.target.checked) {
                    arr = arr.filter((itm) => itm !== event.target.value);
                  }
                  setBulkSelectedJobCandidates(arr);
                }}
              />
              <span className="custom-control-label">&nbsp;</span>
            </label>
          </td>
        )}
        <td>
          {o.candidate.firstName || ""} {o.candidate.lastName || ""}
          {/* <br /> */}
          {/* {o?.candidate?.profile || ''} */}
        </td>
        <td>{o?.candidate?.mobile || "-"}</td>
        <td>{o.candidate.qualification?.name || "-"}</td>
        <td>
          <span>
            {o.candidate.city?.name || "-"}, {o.candidate.state?.name || "-"},{" "}
            {o.candidate.country?.name || "-"}
          </span>
        </td>
        <td>{o.candidate.gender}</td>
        <td>
          {o?.candidate?.source === 1
            ? "PIA"
            : o?.candidate?.source === 2
            ? "MCC"
            : o?.candidate?.source === 3
            ? "Mobile App"
            : "Others"}
        </td>
        {addApplicants && selectedJobPayload?.status === 1 ? (
          <td>
            <ApplicantTracker
              jobApplicantId={o.id}
              status={o.status}
              candidateId={o.candidate.id}
              candidateData={o}
            />
          </td>
        ) : (
          <td>{jobStatus1 || "-"}</td>
        )}
        {roles[0] === "employer_user" && (
          <td>
            {o.candidate?.resume ? (
              <>
                <button
                  type="button"
                  className="btn btn-icon"
                  title="Resume"
                  onClick={() => {
                    downloadResume(o.candidate.id);
                  }}
                >
                  <i className="fa fa-download" />
                </button>
              </>
            ) : (
              "Not Available"
            )}
          </td>
        )}
        {(roles[0] === "mcc_ho_user" || roles[0] === "mcc_coordinator") &&
          selectedJobPayload?.status === 1 && (
            <td
              className="d-flex justify-content-between"
              style={{ maxWidth: 120 }}
            >
              {jobStatus1 === "Joined" ? (
                // <span
                //   style={{ cursor: "pointer" }}
                //   onClick={()=>{
                //     cancelBooking({
                //       variables: {
                //         payload: [o.id]
                //       }
                //     })
                //   }}
                // >
                //   Cancel Booking
                // </span>
                <button
                  type="button"
                  className="btn btn-icon js-sweetalert"
                  title="Cancel Booking"
                  onClick={() => {
                    console.log("Cancelling.....");
                    setSelectedBookingForCancel(o.id);
                    setCancelBookingConfirmModal(true);
                    // cancelBooking({
                    //   variables: {
                    //     payload: [o.id]
                    //   }
                    // })
                  }}
                >
                  <i className="fa fa-times fa-lg" />
                </button>
              ) : (
                <span style={{ color: "red", padding: "0.375rem 0.75rem" }}>
                  NA
                </span>
              )}
            </td>
          )}
      </tr>
    );
  };

  //   const changeDelay = (e) => {
  //     if (timer) {
  //       clearTimeout(timer);
  //       setTimer(null);
  //     }
  //     setTimer(
  //       setTimeout(() => {
  //         console.log(e);
  //         let {
  //           candidate__firstName__icontains,
  //           candidate__lastName__icontains,
  //           ...mutableFilter
  //         } = jobCandidateFilter;
  //         if (e) {
  //           mutableFilter = {
  //             ...jobCandidateFilter,
  //             candidate__firstName__icontains: e,
  //             candidate__lastName__icontains: e,
  //           };
  //         }
  //         setJobCandidateFilter(mutableFilter);
  //       }, 1000)
  //     );
  //   };

  //   const changeDelay1 = (e) => {
  //     if (timer) {
  //       clearTimeout(timer);
  //       setTimer(null);
  //     }
  //     setTimer(
  //       setTimeout(() => {
  //         console.log(e);
  //         let { firstName__icontains, lastName__icontains, ...mutableFilter } =
  //           candidateFilter;
  //         if (e) {
  //           mutableFilter = {
  //             ...candidateFilter,
  //             firstName__icontains: e,
  //             lastName__icontains: e,
  //           };
  //         }
  //         setCandidateFilter(mutableFilter);
  //       }, 1000)
  //     );
  //   };

  return (
    <>
      <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a onClick={() => setJobDetailsView(false)}>
                    <span style={{ fontSize: 14, cursor: "pointer" }}>
                      Jobs
                    </span>
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span style={{ fontSize: 14 }}>
                    {selectedJobPayload?.title}
                  </span>
                </li>
              </ol>
            </nav>
          </div>
          <div className="row clearfix">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="nav nav-tabs page-header-tab">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="user-tab"
                    data-toggle="tab"
                    href="#edit-candidate"
                    onClick={() => {
                      if (searchView) {
                        setSearchView(false);
                      }
                    }}
                  >
                    Applied Candidates
                  </a>
                </li>
                {addApplicants && selectedJobPayload?.status === 1 && (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="user-tab"
                      data-toggle="tab"
                      onClick={() => {
                        if (!searchView) {
                          setSearchView(true);
                        }
                      }}
                    >
                      Search and Add Candidates
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
          {!searchView && (
            <div className="row clearfix">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <label>Search</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Name"
                            onChange={(e) => {
                              let {
                                candidate__firstName__icontains,
                                candidate__lastName__icontains,
                                ...mutableFilter
                              } = jobCandidateFilter;
                              if (e) {
                                mutableFilter = {
                                  ...jobCandidateFilter,
                                  candidate__firstName__icontains:
                                    e.target.value,
                                  candidate__lastName__icontains:
                                    e.target.value,
                                };
                              }
                              setJobCandidateFilter(mutableFilter);
                            }}
                            value={
                              Object.keys(jobCandidateFilter).length === 0
                                ? ""
                                : jobCandidateFilter &&
                                  !(
                                    "candidate__firstName__icontains" in
                                    jobCandidateFilter
                                  ) &&
                                  !(
                                    "candidate__lastName__icontains" in
                                    jobCandidateFilter
                                  )
                                ? ""
                                : jobCandidateFilter.candidate__firstName__icontains
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <label>Qualification</label>
                        <div className="multiselect_div">
                          <select
                            className="custom-select"
                            onChange={(e) => {
                              let mutableFilter = {
                                ...jobCandidateFilter,
                                candidate__qualificationId: e.target.value,
                              };
                              if (e.target.value === "0") {
                                const {
                                  candidate__qualificationId,
                                  ...restFilter
                                } = jobCandidateFilter;
                                mutableFilter = restFilter;
                              }
                              setJobCandidateFilter(mutableFilter);
                            }}
                          >
                            <option
                              selected={
                                Object.keys(jobCandidateFilter).length === 0 ||
                                (jobCandidateFilter &&
                                  !(
                                    "candidate__qualificationId" in
                                    jobCandidateFilter
                                  ))
                                  ? true
                                  : false
                              }
                              disabled
                            >
                              {" "}
                              -- Select Qualification --{" "}
                            </option>
                            <option value={"0"} key={"state0"}>
                              None
                            </option>
                            {qualifications?.qualifications?.map((c) => (
                              <option key={c.id} value={c.id}>
                                {" "}
                                {c.name}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <label>Status</label>
                        <div className="multiselect_div">
                          <select
                            className="custom-select"
                            onChange={(e) => {
                              let mutableFilter = {
                                ...jobCandidateFilter,
                                status: parseInt(e.target.value),
                              };
                              if (e.target.value === "0") {
                                const { status, ...restFilter } =
                                  jobCandidateFilter;
                                mutableFilter = restFilter;
                              }
                              setJobCandidateFilter(mutableFilter);
                            }}
                          >
                            <option
                              selected={
                                Object.keys(jobCandidateFilter).length === 0 ||
                                (jobCandidateFilter &&
                                  !("status" in jobCandidateFilter))
                                  ? true
                                  : false
                              }
                              disabled
                            >
                              {" "}
                              -- Select Status --{" "}
                            </option>
                            <option value={"0"} key={"state0"}>
                              None
                            </option>
                            {trackingStatus.map((c) => (
                              <option key={c.id} value={c.id}>
                                {" "}
                                {c.name}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <label>Gender</label>
                        <div className="multiselect_div">
                          <select
                            className="custom-select"
                            onChange={(e) => {
                              let mutableFilter = {
                                ...jobCandidateFilter,
                                candidate__gender: e.target.value,
                              };
                              if (e.target.value === "null") {
                                const { candidate__gender, ...restFilter } =
                                  jobCandidateFilter;
                                mutableFilter = restFilter;
                              }
                              setJobCandidateFilter(mutableFilter);
                            }}
                            style={{ marginRight: 10 }}
                          >
                            <option
                              selected={
                                Object.keys(jobCandidateFilter).length === 0 ||
                                (jobCandidateFilter &&
                                  !("candidate__gender" in jobCandidateFilter))
                                  ? true
                                  : false
                              }
                              disabled
                            >
                              {" "}
                              -- Select Gender --{" "}
                            </option>
                            <option value={"null"} key={"state0"}>
                              None
                            </option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                            <option value="O">Other</option>
                            <option value="NA">NA</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: 5 }}>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <label>State</label>
                        <div className="multiselect_div">
                          <select
                            className="custom-select"
                            onChange={(e) => {
                              let mutableFilter = {
                                ...jobCandidateFilter,
                                candidate__stateId: e.target.value,
                              };
                              if (e.target.value === "null") {
                                const { candidate__stateId, ...restFilter } =
                                  jobCandidateFilter;
                                mutableFilter = restFilter;
                              }
                              setJobCandidateFilter(mutableFilter);
                            }}
                          >
                            <option
                              selected={
                                Object.keys(jobCandidateFilter).length === 0 ||
                                (jobCandidateFilter &&
                                  !("candidate__stateId" in jobCandidateFilter))
                                  ? true
                                  : false
                              }
                              disabled
                            >
                              {" "}
                              -- Select State --{" "}
                            </option>
                            <option value={"null"} key={"state0"}>
                              None
                            </option>
                            {allStates &&
                              allStates.allStates.map((c) => (
                                <option key={c.id} value={c.id}>
                                  {" "}
                                  {c.name}{" "}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <label>City</label>
                        <div className="multiselect_div">
                          <select
                            className="custom-select"
                            onChange={(e) => {
                              let mutableFilter = {
                                ...jobCandidateFilter,
                                candidate__cityId: e.target.value,
                              };
                              if (e.target.value === "null") {
                                const { candidate__cityId, ...restFilter } =
                                  jobCandidateFilter;
                                mutableFilter = restFilter;
                              }
                              setJobCandidateFilter(mutableFilter);
                            }}
                          >
                            <option
                              selected={
                                Object.keys(jobCandidateFilter).length === 0 ||
                                (jobCandidateFilter &&
                                  !("candidate__cityId" in jobCandidateFilter))
                                  ? true
                                  : false
                              }
                              disabled
                            >
                              {" "}
                              -- Select City --{" "}
                            </option>
                            <option value={"null"} key={"state0"}>
                              None
                            </option>
                            {allCities &&
                              allCities.allCities.map((c) => (
                                <option key={c.id} value={c.id}>
                                  {" "}
                                  {c.name}{" "}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <label>Location</label>
                        <div className="multiselect_div">
                          <select
                            className="custom-select"
                            onChange={(e) => {
                              let mutableFilter = {
                                ...jobCandidateFilter,
                                candidate__locationId: e.target.value,
                              };
                              if (e.target.value === "null") {
                                const { candidate__locationId, ...restFilter } =
                                  jobCandidateFilter;
                                mutableFilter = restFilter;
                              }
                              setJobCandidateFilter(mutableFilter);
                            }}
                          >
                            <option
                              selected={
                                Object.keys(jobCandidateFilter).length === 0 ||
                                (jobCandidateFilter &&
                                  !(
                                    "candidate__locationId" in
                                    jobCandidateFilter
                                  ))
                                  ? true
                                  : false
                              }
                              disabled
                            >
                              {" "}
                              -- Select Location --{" "}
                            </option>
                            <option value={"null"} key={"state0"}>
                              None
                            </option>
                            {allLocations &&
                              allLocations.allLocations.map((c) => (
                                <option key={c.id} value={c.id}>
                                  {" "}
                                  {c.name}{" "}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      className="header-action float-right"
                      style={{ marginTop: 10 }}
                    >
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => setJobCandidateFilter({})}
                      >
                        <i className="fa fa-times mr-2" />
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-hover table-vcenter table_custom spacing5 mb-0">
                    <thead>
                      <tr>
                        {addApplicants && selectedJobPayload?.status === 1 && (
                          <th>#</th>
                        )}
                        <th>Applicant Name</th>
                        <th>Mobile</th>
                        <th>Qualification</th>
                        <th>Location</th>
                        <th>Gender</th>
                        <th>Sourced By</th>
                        <th>Job Status</th>
                        {roles[0] === "employer_user" && <th>Resume</th>}
                        {(roles[0] === "mcc_ho_user" ||
                          roles[0] === "mcc_coordinator") &&
                          selectedJobPayload?.status === 1 && <th>Actions</th>}
                      </tr>
                    </thead>
                    <tbody>
                    {loadingJobCandidate && (
                            <td colSpan={10} style={{ textAlign: "center" }}>
                              <p style={{ font: 15 }}>Loading... </p>
                              <div
                                class="spinner-border ml-auto"
                                role="status"
                                aria-hidden="true"
                              ></div>
                            </td>
                          )}
                      {!loadingJobCandidate &&
                      jobCandidates.length > 0  &&
                        jobCandidates.map((o) => <JobCandidate o={o} key={o.id} />)
                      } 
                      {!loadingJobCandidate && !jobCandidates.length > 0 && (
                        <tr>
                          <td
                            colSpan={addApplicants ? 9 : 8}
                            style={{ textAlign: "center" }}
                          >
                            No candidate has applied for this job
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  page={page}
                  pageSize={pageSize}
                  rowCount={rowCount}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
                {addApplicants && selectedJobPayload?.status === 1 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setUpdateStatusModal(true)}
                    disabled={
                      bulkSelectedJobCandidates.length <= 1 ? true : false
                    }
                  >
                    Update Status
                  </button>
                )}
              </div>
              <UpdateStatusModal
                updateStatusModal={updateStatusModal}
                setUpdateStatusModal={setUpdateStatusModal}
                bulkSelectedCandidates={bulkSelectedJobCandidates}
                refetchJobCandidates={refetchJobCandidates}
              />
              <PlacedModal
                placedModal={placedModal}
                setPlacedModal={setPlacedModal}
                selectedCandidate={selectedApplicant}
                selectedJobPayload={selectedJobPayload}
                selectedCandidateData={selectedApplicantData}
                refetchJobCandidates={refetchJobCandidates}
              />
              <OfferedModal
                setupdateOffered={setupdateOffered}
                updateOffered={updateOffered}
                selectedJobPayload={selectedJobPayload}
                selectedCandidate={selectedApplicant}
                selectedCandidateData={selectedApplicantData}
                refreshJobCandidates={refetchJobCandidates}
              />
              <Modal
                show={changeConfirmModal}
                onHide={() => setChangeConfirmModal(false)}
              >
                <Modal.Header>
                  <Modal.Title>Confirm</Modal.Title>
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    onClick={() => setChangeConfirmModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to change the status?
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    onClick={() => {
                      console.log(selectedStatus);
                      updateJobCandidate({
                        variables: {
                          payload: {
                            id: [selectedApplicant],
                            status: selectedStatus,
                          },
                        },
                      });
                      setChangeConfirmModal(false);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    onClick={() => setChangeConfirmModal(false)}
                  >
                    No
                  </button>
                </Modal.Footer>
              </Modal>
              <Modal
                show={cancelBookingConfirmModal}
                onHide={() => setCancelBookingConfirmModal(false)}
              >
                <Modal.Header>
                  <Modal.Title>Confirm</Modal.Title>
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    onClick={() => setCancelBookingConfirmModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <span className="col-lg-12 col-md-12 col-sm-12">
                    Are you sure you want to cancel booking?
                  </span>
                  <div className="d-flex flex-row align-item-center justify-content-between mt-2">
                    <div className="font-15 col-lg-4 col-md-4 col-sm-12">
                      Cancellation Reason
                    </div>
                    <div className="font-15 form-group col-lg-8 col-md-12 col-sm-12">
                      <input
                        type="text"
                        className="form-control col-lg-12 col-md-12 col-sm-12"
                        style={{ border: "1px #7C7B7B solid" }}
                        value={cancellationReason}
                        onChange={(e) => {
                          setCancellationReason(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    onClick={() => {
                      console.log(selectedBookingForCancel);
                      setCancelBookingConfirmModal(false);
                      cancelBooking({
                        variables: {
                          payload: [selectedBookingForCancel],
                          reason: cancellationReason,
                        },
                      });
                    }}
                    disabled={cancellationReason ? false : true}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    onClick={() => setCancelBookingConfirmModal(false)}
                  >
                    No
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
          {searchView && (
            <div className="row clearfix">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <label>Search</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            onChange={(e) => {
                              let {
                                firstName__icontains,
                                lastName__icontains,
                                ...mutableFilter
                              } = candidateFilter;
                              if (e) {
                                mutableFilter = {
                                  ...candidateFilter,
                                  firstName__icontains: e.target.value,
                                  lastName__icontains: e.target.value,
                                };
                              }
                              setCandidateFilter(mutableFilter);
                            }}
                            value={
                              Object.keys(candidateFilter).length === 0
                                ? ""
                                : candidateFilter &&
                                  !(
                                    "firstName__icontains" in candidateFilter
                                  ) &&
                                  !("lastName__icontains" in candidateFilter)
                                ? ""
                                : candidateFilter.firstName__icontains
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <label>Qualification</label>
                        <div className="form-group">
                          <select
                            className="custom-select"
                            onChange={(e) => {
                              const { qualificationId, ...mutableFilter } =
                                candidateFilter;
                              const filter =
                                e.target.value !== "0"
                                  ? {
                                      ...mutableFilter,
                                      qualificationId: e.target.value,
                                    }
                                  : mutableFilter;
                              setCandidateFilter(filter);
                            }}
                          >
                            <option
                              selected={
                                Object.keys(candidateFilter).length === 0 ||
                                (candidateFilter &&
                                  !("qualificationId" in candidateFilter))
                                  ? true
                                  : false
                              }
                              disabled
                            >
                              {" "}
                              -- Select Qualification --{" "}
                            </option>
                            <option value={"0"} key={"state0"}>
                              None
                            </option>
                            {qualifications?.qualifications?.map((c) => (
                              <option key={c.id} value={c.id}>
                                {" "}
                                {c.name}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <label>Gender</label>
                        <div className="multiselect_div">
                          <select
                            className="custom-select"
                            onChange={(e) => {
                              let mutableFilter = {
                                ...candidateFilter,
                                gender: e.target.value,
                              };
                              if (e.target.value === "null") {
                                const { gender, ...restFilter } =
                                  candidateFilter;
                                mutableFilter = restFilter;
                              }
                              setCandidateFilter(mutableFilter);
                            }}
                          >
                            <option
                              selected={
                                Object.keys(candidateFilter).length === 0 ||
                                (candidateFilter &&
                                  !("gender" in candidateFilter))
                                  ? true
                                  : false
                              }
                              disabled
                            >
                              {" "}
                              -- Select Gender --{" "}
                            </option>
                            <option value={"null"} key={"state0"}>
                              None
                            </option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                            <option value="O">Other</option>
                            <option value="NA">NA</option>
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-lg-3 col-md-6 col-sm-6">
                                            <label>Skill</label>
                                            <div className="multiselect_div">
                                                <select
                                                    className="custom-select"
                                                    onChange={(e) => {
                                                        let mutableFilter = { ...candidateFilter, skillId: e.target.value };
                                                        if (e.target.value === 'null') {
                                                            const { skillId, ...restFilter } = candidateFilter;
                                                            mutableFilter = restFilter;
                                                        }
                                                        setCandidateFilter(mutableFilter);
                                                    }}
                                                >
                                                    <option disabled selected value='null'> -- Select Skill -- </option>
                                                    {skills?.skills?.map((c) => <option key={c.id} value={c.id}> {c.name} </option>)}
                                                </select>
                                            </div>
                                        </div> */}
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <label>Experience</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Experience"
                            value={
                              Object.keys(candidateFilter).length === 0
                                ? ""
                                : candidateFilter &&
                                  !("experience" in candidateFilter)
                                ? ""
                                : candidateFilter.experience
                            }
                            onChange={(e) => {
                              let { experience, ...mutableFilter } =
                                candidateFilter;
                              if (e) {
                                mutableFilter = {
                                  ...candidateFilter,
                                  experience: parseFloat(e.target.value),
                                };
                              }
                              setCandidateFilter(mutableFilter);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <label>State</label>
                        <div className="multiselect_div">
                          <select
                            className="custom-select"
                            onChange={(e) => {
                              let mutableFilter = {
                                ...candidateFilter,
                                stateId: e.target.value,
                              };
                              if (e.target.value === "null") {
                                const { stateId, ...restFilter } =
                                  candidateFilter;
                                mutableFilter = restFilter;
                              }
                              setCandidateFilter(mutableFilter);
                            }}
                          >
                            <option
                              selected={
                                Object.keys(candidateFilter).length === 0 ||
                                (candidateFilter &&
                                  !("stateId" in candidateFilter))
                                  ? true
                                  : false
                              }
                              disabled
                            >
                              {" "}
                              -- Select State --{" "}
                            </option>
                            <option value={"null"} key={"state0"}>
                              None
                            </option>
                            {allStates &&
                              allStates.allStates.map((c) => (
                                <option key={c.id} value={c.id}>
                                  {" "}
                                  {c.name}{" "}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <label>City</label>
                        <div className="multiselect_div">
                          <select
                            className="custom-select"
                            onChange={(e) => {
                              let mutableFilter = {
                                ...candidateFilter,
                                cityId: e.target.value,
                              };
                              if (e.target.value === "null") {
                                const { cityId, ...restFilter } =
                                  candidateFilter;
                                mutableFilter = restFilter;
                              }
                              setCandidateFilter(mutableFilter);
                            }}
                          >
                            <option
                              selected={
                                Object.keys(candidateFilter).length === 0 ||
                                (candidateFilter &&
                                  !("cityId" in candidateFilter))
                                  ? true
                                  : false
                              }
                              disabled
                            >
                              {" "}
                              -- Select City --{" "}
                            </option>
                            <option value={"null"} key={"state0"}>
                              None
                            </option>
                            {allCities &&
                              allCities.allCities.map((c) => (
                                <option key={c.id} value={c.id}>
                                  {" "}
                                  {c.name}{" "}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <label>Location</label>
                        <div className="multiselect_div">
                          <select
                            className="custom-select"
                            onChange={(e) => {
                              let mutableFilter = {
                                ...candidateFilter,
                                locationId: e.target.value,
                              };
                              if (e.target.value === "null") {
                                const { locationId, ...restFilter } =
                                  candidateFilter;
                                mutableFilter = restFilter;
                              }
                              setCandidateFilter(mutableFilter);
                            }}
                          >
                            <option
                              selected={
                                Object.keys(candidateFilter).length === 0 ||
                                (candidateFilter &&
                                  !("locationId" in candidateFilter))
                                  ? true
                                  : false
                              }
                              disabled
                            >
                              {" "}
                              -- Select Location --{" "}
                            </option>
                            <option value={"null"} key={"state0"}>
                              None
                            </option>
                            {allLocations &&
                              allLocations.allLocations.map((c) => (
                                <option key={c.id} value={c.id}>
                                  {" "}
                                  {c.name}{" "}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      className="header-action float-right"
                      style={{ marginTop: 10 }}
                    >
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => setCandidateFilter({})}
                      >
                        <i className="fa fa-times mr-2" />
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-hover table-vcenter table_custom spacing5 mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Candidate Name</th>
                        <th>Gender</th>
                        <th>Qualification</th>
                        <th>Location</th>
                        <th>Experience</th>
                        <th>Job Status</th>
                        <th>Add Candidate</th>
                      </tr>
                    </thead>
                    <tbody>
                    {(loadingCandidates || loadingJobCandidate || addingApplicants) && (
                            <td colSpan={10} style={{ textAlign: "center" }}>
                              <p style={{ font: 15 }}>Loading... </p>
                              <div
                                class="spinner-border ml-auto"
                                role="status"
                                aria-hidden="true"
                              ></div>
                            </td>
                          )}
                          {
                            !loadingCandidates && !addingApplicants &&
                            candidates.length > 0  &&
                            candidates.map((o) => <Candidate o={o} key={o.id} />)
                          } 
                          {!loadingCandidates && !candidates.length >0 &&(
                            <tr>
                              <td
                                colSpan={addApplicants ? 9 : 8}
                                style={{ textAlign: "center" }}
                              >
                                No candidates Found
                              </td>
                            </tr>
                          )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  page={page1}
                  pageSize={pageSize1}
                  rowCount={rowCount1}
                  setPage={setPage1}
                  setPageSize={setPageSize1}
                />
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setConfirmModal(true)}
                  disabled={bulkSelectedCandidates.length <= 1 ? true : false}
                >
                  Add Candidates
                </button>
              </div>
              <Modal show={warningModal} onHide={() => setWarningModal(false)}>
                <Modal.Header>
                  <Modal.Title>Warning</Modal.Title>
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    onClick={() => setWarningModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>The candidate is already shortlisted.</Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    onClick={() => setWarningModal(false)}
                  >
                    Okay
                  </button>
                </Modal.Footer>
              </Modal>
              <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
                <Modal.Header>
                  <Modal.Title>Confirm</Modal.Title>
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    onClick={() => setConfirmModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to add all the selected candidates?
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    onClick={() =>
                      addJobCandidate({
                        variables: {
                          payload: bulkSelectedCandidates,
                        },
                      })
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    onClick={() => setConfirmModal(false)}
                  >
                    No
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default JobDetails;
