import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import no_jobs_jobs_employer from "../../assets/imagesNew/no-jobs-jobs-employer.svg";
import colors from "../../styles/colors";

const NoJobsView = (props) => {
  const history = useHistory();
  return (
    <Grid container spacing={2} sx={{ py: 6 }}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
      >
        <img src={no_jobs_jobs_employer} alt="no jobs" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography textAlign="center" fontSize="18px" color={colors.dark}>
          No job posts yet
        </Typography>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
      >
        <Button
          variant="contained"
          onClick={() => history.push("/employer-add-job/new")}
        >
          Post a Job
        </Button>
      </Grid>
    </Grid>
  );
};

export default NoJobsView;
