import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Autocomplete,
  IconButton,
} from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import RadioGroup from "../../common/RadioGroup";
import { questionTypeArr } from "../../constants";
import colors from "../../styles/colors";

const QuestionCard = (props) => {
  const { questionNo, screeningQuestions, setScreeningQuestions } = props;

  const [questionType, setQuestionType] = useState(
    screeningQuestions[questionNo].answerType || "YES_NO"
  );

  useEffect(() => {
    if (screeningQuestions && screeningQuestions.length === 1) {
      let tempObj = screeningQuestions[0];
      if (Object.keys(tempObj).length === 0) {
        const tempArr = [...screeningQuestions];
        tempArr[0] = { answerType: "YES_NO" };
        setScreeningQuestions(tempArr);
      }
    }
  }, [screeningQuestions, setScreeningQuestions]);

  const handleDelete = () => {
    let tempArr = [...screeningQuestions];
    tempArr.splice(questionNo, 1);
    setScreeningQuestions(tempArr);
  };

  return (
    <Box
      sx={{
        borderRadius: "4px",
        backgroundColor: `${colors.strokeGray}15`,
        p: { xs: 2, md: 3 },
        position: "relative",
      }}
    >
      <IconButton
        size="small"
        sx={{
          backgroundColor: colors.secondary,
          border: `2px solid ${colors.strokeGray}`,
          position: "absolute",
          top: -10,
          left: { xs: "96%", sm: "98%" },
        }}
      >
        <DeleteOutlined
          fontSize="small"
          sx={{ color: colors.danger }}
          onClick={handleDelete}
        />
      </IconButton>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography fontSize="13px">Question</Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <Typography fontSize="13px">Answer Type</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mt: -2 }}>
          <TextField
            size="small"
            fullWidth
            value={screeningQuestions[questionNo].question || ""}
            onChange={(e) => {
              const tempArr = [...screeningQuestions];
              tempArr[questionNo] = {
                ...tempArr[questionNo],
                question: e.target.value,
              };
              setScreeningQuestions(tempArr);
            }}
          />
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          <Typography fontSize="13px">Answer Type</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mt: -2 }}>
          <Autocomplete
            id="combo-box-demo"
            autoComplete
            disableClearable
            options={questionTypeArr}
            getOptionLabel={(option) => option.name}
            value={questionTypeArr.find((x) => x.id === questionType)}
            onChange={(e, value) => {
              setQuestionType(value.id);
              const tempArr = [...screeningQuestions];
              tempArr[questionNo] = {
                ...tempArr[questionNo],
                answerType: value.id,
              };
              setScreeningQuestions(tempArr);
            }}
            size="small"
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography fontSize="13px">Ideal Answer</Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <Typography fontSize="13px">Must have this question</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mt: -2 }}>
          {questionType === "YES_NO" && (
            <RadioGroup
              radioBtns={[
                { id: "Yes", name: "Yes" },
                { id: "No", name: "No" },
              ]}
              value={screeningQuestions[questionNo].answer || ""}
              onChange={(e) => {
                const tempArr = [...screeningQuestions];
                tempArr[questionNo] = {
                  ...tempArr[questionNo],
                  answer: e.target.value,
                };
                setScreeningQuestions(tempArr);
              }}
            />
          )}
          {questionType === "RANGE" && (
            <Box display="flex">
              <TextField
                size="small"
                type="number"
                sx={{
                  width: "50%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    },
                  },
                }}
                value={screeningQuestions[questionNo].answer || 0}
                onChange={(e) => {
                  const tempArr = [...screeningQuestions];
                  tempArr[questionNo] = {
                    ...tempArr[questionNo],
                    answer: e.target.value,
                  };
                  setScreeningQuestions(tempArr);
                }}
              />
              <TextField
                size="small"
                type="number"
                sx={{
                  width: "50%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    },
                  },
                }}
                value={screeningQuestions[questionNo].answer || 0}
                onChange={(e) => {
                  const tempArr = [...screeningQuestions];
                  tempArr[questionNo] = {
                    ...tempArr[questionNo],
                    answer: e.target.value,
                  };
                  setScreeningQuestions(tempArr);
                }}
              />
            </Box>
          )}
          {questionType === "NUMERIC" && (
            <TextField
              size="small"
              type="number"
              fullWidth
              value={screeningQuestions[questionNo].answer || 0}
              onChange={(e) => {
                const tempArr = [...screeningQuestions];
                tempArr[questionNo] = {
                  ...tempArr[questionNo],
                  answer: e.target.value,
                };
                setScreeningQuestions(tempArr);
              }}
            />
          )}
          {questionType === "SHORT_ANSWER" && (
            <TextField
              size="small"
              fullWidth
              value={screeningQuestions[questionNo].answer || ""}
              onChange={(e) => {
                const tempArr = [...screeningQuestions];
                tempArr[questionNo] = {
                  ...tempArr[questionNo],
                  answer: e.target.value,
                };
                setScreeningQuestions(tempArr);
              }}
            />
          )}
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          <Typography fontSize="13px">Must have this question</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mt: -2 }}>
          <RadioGroup
            radioBtns={[
              { id: "Yes", name: "Yes" },
              { id: "No", name: "No" },
            ]}
            value={
              screeningQuestions[questionNo].hasOwnProperty("mandatory")
                ? screeningQuestions[questionNo].mandatory
                  ? "Yes"
                  : "No"
                : ""
            }
            onChange={(e) => {
              const tempArr = [...screeningQuestions];
              tempArr[questionNo] = {
                ...tempArr[questionNo],
                mandatory: e.target.value === "Yes" ? true : false,
              };
              setScreeningQuestions(tempArr);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuestionCard;
