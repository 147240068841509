import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material";
import { SearchOutlined, Clear } from "@mui/icons-material";
import NoJobsView from "./NoJobsView";
import NoBorderDropdown from "../../common/NoBorderDropdown";
import ResultCard from "../../common/JobResultCard";
import Pagination from "../../common/PaginationCommon";
import colors from "../../styles/colors";

import { useQuery } from "@apollo/client";
import { GET_ALL_JOBS } from "../../graphql/EmployerJobs";
import getStore from "../../zustandStore";

const JobsList = (props) => {
  const { rowCount, setRowCount } = props;
  const setLoading = getStore((state) => state.setLoading);

  const [sortBy, setSortBy] = useState("-created_date");
  const [jobsList, setJobsList] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [jobsFilter, setJobsFilter] = useState({});

  const { data: jobs, loading } = useQuery(GET_ALL_JOBS, {
    variables: {
      filter: jobsFilter,
      limit: pageSize,
      page: page + 1,
      sorted: sortBy,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (jobs && jobs.jobs) {
      setRowCount(jobs.jobs.count);
    }
    if (jobs && jobs.jobs && jobs.jobs.jobs) {
      setJobsList(jobs.jobs.jobs);
    }
  }, [jobs, setRowCount]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <>
      {rowCount <= 0 ? (
        <NoJobsView />
      ) : (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <TextField
              size="small"
              fullWidth
              placeholder="Search Job Title..."
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchString && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          const tempFilter = { ...jobsFilter };
                          delete tempFilter["title__icontains"];
                          setJobsFilter({
                            ...tempFilter,
                          });
                          setSearchString("");
                        }}
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    )}
                    <Box
                      sx={{
                        borderLeft: `1px solid ${colors.strokeGray}`,
                        pl: 1,
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          setJobsFilter({
                            ...jobsFilter,
                            title__icontains: searchString,
                          });
                          setPage(0);
                        }}
                      >
                        <SearchOutlined fontSize="small" />
                      </IconButton>
                    </Box>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                backgroundColor: "#F7F7F7",
              }}
            />
          </Grid>
          <Grid item lg={9} md={9} sm={6} xs={12}>
            <Grid
              container
              spacing={0.5}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item>
                <Typography fontSize="14px" color={colors.gray}>
                  Sort by :
                </Typography>
              </Grid>
              <Grid item>
                <NoBorderDropdown
                  value={sortBy}
                  onChange={(e) => {
                    setSortBy(e.target.value);
                    setPage(0);
                  }}
                  bgColor={colors.secondary}
                  textColor={colors.gray}
                  fontSize="14px"
                  dropdownArr={[
                    { id: "-created_date", name: "Latest" },
                    { id: "created_date", name: "Oldest" },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {jobsList.length > 0 &&
              jobsList.map((job, index) => (
                <div key={job.id} style={{ marginTop: index === 0 ? 0 : 10 }}>
                  <ResultCard showDetails={true} jobData={job} />
                </div>
              ))}
            <Pagination
              page={page}
              pageSize={pageSize}
              rowCount={rowCount}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default JobsList;
