import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, Button, Avatar } from "@mui/material";
import Table from "../../common/Table";
import ScreeningQuestionsModal from "../../common/ScreeningQuestionsModal";
import { getDateInFormat, loadScreeningAnswers } from "../../helpers";
import help_icon from "../../assets/imagesNew/help-icon.svg";
import colors from "../../styles/colors";

import { useQuery } from "@apollo/client";
import { GET_RECENT_APPLICANTS } from "../../graphql/EmployerDashboard";
import getStore from "../../zustandStore";

const RecentApplicants = (props) => {
  const setLoading = getStore((state) => state.setLoading);
  const history = useHistory();

  const [rows, setRows] = useState([]);
  const [screeningQuestionsModal, setScreeningQuestionsModal] = useState(false);
  const [screeningQuestionsResponses, setScreeningQuestionsResponses] =
    useState([]);
  const [
    screeningQuestionsSelectedCandidate,
    setScreeningQuestionsSelectedCandidate,
  ] = useState("");

  const { data: recentApplicantsData, loading: loadingRecentApplicants } =
    useQuery(GET_RECENT_APPLICANTS, {
      variables: { sorted: "-created_date" },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    });

  const columns = useMemo(
    () => [
      {
        field: "firstName",
        headerName: "Profile",
        minWidth: 210,
        flex: 1,
        renderCell: (params) => (
          <Grid container spacing={0.5} alignItems="center">
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Avatar
                sx={{
                  mr: 1,
                  backgroundColor: colors.primaryFill,
                  color: colors.primary,
                  border: `1px solid ${colors.primary}`,
                }}
              >
                {params.row.candidate.firstName.charAt(0)}
              </Avatar>
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Typography fontSize="12px">{`${
                params.row.candidate.firstName || ""
              } ${params.row.candidate.lastName || ""}`}</Typography>
              {/* <Typography
                color={colors.primary}
                fontSize="12px"
                fontWeight={700}
              >
                92%
              </Typography> */}
            </Grid>
          </Grid>
        ),
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Typography fontSize="12px" noWrap>
            {params.row.candidate.email || "-"}
          </Typography>
        ),
      },
      {
        field: "mobile",
        headerName: "Phone Number",
        minWidth: 160,
        flex: 1,
        renderCell: (params) => (
          <Typography fontSize="12px">
            {params.row.candidate.mobile
              ? `+91 ${params.row.candidate.mobile}`
              : "-"}
          </Typography>
        ),
      },
      {
        field: "title",
        headerName: "Title",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
          <Typography fontSize="12px" noWrap>
            {params.row.job.title}
          </Typography>
        ),
      },
      {
        field: "skills",
        headerName: "Skills",
        minWidth: 220,
        flex: 1,
        renderCell: (params) => (
          <Typography fontSize="12px" noWrap>
            {params.row.candidate.skills || "-"}
          </Typography>
        ),
      },
      {
        field: "appliedDate",
        headerName: "Applied Date",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
          <Typography fontSize="12px">
            {getDateInFormat(params.row.createdDate, "DD MMM YYYY")}
          </Typography>
        ),
      },
      {
        field: "actions",
        headerName: "Candidate Responses",
        minWidth: 180,
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <img
            src={help_icon}
            alt="help"
            style={{ cursor: "pointer" }}
            onClick={() => {
              const name =
                params.row.candidate.firstName +
                  " " +
                  params.row.candidate.lastName || "";

              setScreeningQuestionsSelectedCandidate(name);
              loadScreeningAnswers(
                params.row.screeningAnswers,
                setScreeningQuestionsResponses
              );
              setScreeningQuestionsModal(true);
            }}
          />
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (
      recentApplicantsData &&
      recentApplicantsData.jobCandidates &&
      recentApplicantsData.jobCandidates.jobcandidates
    ) {
      setRows(recentApplicantsData.jobCandidates.jobcandidates);
    }
  }, [recentApplicantsData]);

  useEffect(() => {
    setLoading(loadingRecentApplicants);
  }, [loadingRecentApplicants, setLoading]);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Typography color={colors.dark} fontWeight={500} fontSize="24px">
          Recent Applicants
        </Typography>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: { xs: "flex-start", sm: "flex-end" },
        }}
      >
        <Button
          variant="contained"
          size="small"
          disableElevation
          onClick={() => history.push("/employer-applied-candidates")}
        >
          View All
        </Button>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Table rows={rows} columns={columns} />
      </Grid>
      <ScreeningQuestionsModal
        open={screeningQuestionsModal}
        setOpen={setScreeningQuestionsModal}
        data={screeningQuestionsResponses}
        name={screeningQuestionsSelectedCandidate}
        setData={setScreeningQuestionsResponses}
      />
    </Grid>
  );
};

export default RecentApplicants;
