import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { CalendarTodayOutlined } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getDateInFormat } from "../../helpers";
import colors from "../../styles/colors";
import moment from "moment";

function DynamicDateRangePicker(props) {
  const { spanOfData, startDate, setStartDate, endDate, setEndDate } = props;
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  if (spanOfData === "WEEKLY") {
    return (
      <Box
        display="flex"
        sx={{
          backgroundColor: "#F7F7F7",
          py: 0.3,
          px: 2,
          borderRadius: "27px",
          alignItems: "center",
        }}
      >
        <Typography fontSize="12px" color={colors.fontLight}>
          {`${getDateInFormat(startDate, "DD MMM")} - ${getDateInFormat(
            endDate,
            "DD MMM"
          )}`}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            open={open1}
            onClose={() => setOpen1(false)}
            value={startDate}
            onChange={(value) => {
              setStartDate(value);
              setEndDate(moment(value).add(6, "days"));
            }}
            PopperProps={{ anchorEl: anchorEl1 }}
            renderInput={({
              ref,
              inputProps,
              disabled,
              onChange,
              value,
              ...other
            }) => (
              <div ref={ref} {...other}>
                <input
                  style={{ display: "none" }}
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                  {...inputProps}
                />
                <IconButton
                  size="small"
                  onClick={(e) => {
                    handleClick1(e);
                    setOpen1(true);
                  }}
                >
                  <CalendarTodayOutlined
                    fontSize="small"
                    sx={{ color: colors.fontLight }}
                  />
                </IconButton>
              </div>
            )}
          />
        </LocalizationProvider>
      </Box>
    );
  }
  if (spanOfData === "MONTHLY") {
    return (
      <Box
        display="flex"
        sx={{
          backgroundColor: "#F7F7F7",
          py: 0.3,
          px: 2,
          borderRadius: "27px",
          alignItems: "center",
        }}
      >
        <Typography fontSize="12px" color={colors.fontLight}>
          {getDateInFormat(startDate, "MMM YYYY")}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            open={open1}
            onClose={() => setOpen1(false)}
            views={["month", "year"]}
            value={startDate}
            onChange={(value) => {
              setStartDate(value);
              setEndDate(moment(value).endOf("month"));
            }}
            PopperProps={{ anchorEl: anchorEl1 }}
            renderInput={({
              ref,
              inputProps,
              disabled,
              onChange,
              value,
              ...other
            }) => (
              <div ref={ref} {...other}>
                <input
                  style={{ display: "none" }}
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                  {...inputProps}
                />
                <IconButton
                  size="small"
                  onClick={(e) => {
                    handleClick1(e);
                    setOpen1(true);
                  }}
                >
                  <CalendarTodayOutlined
                    fontSize="small"
                    sx={{ color: colors.fontLight }}
                  />
                </IconButton>
              </div>
            )}
          />
        </LocalizationProvider>
      </Box>
    );
  }
  if (spanOfData === "custom") {
    return (
      <Box display="flex" alignItems="center">
        <Typography fontSize="12px" color={colors.fontLight} sx={{ mr: 1 }}>
          From
        </Typography>
        <Box
          display="flex"
          sx={{
            backgroundColor: "#F7F7F7",
            py: 0.3,
            px: 2,
            borderRadius: "27px",
            alignItems: "center",
          }}
        >
          <Typography fontSize="12px" color={colors.fontLight}>
            {getDateInFormat(startDate, "DD MMM YYYY")}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              open={open1}
              onClose={() => setOpen1(false)}
              value={startDate}
              onChange={(value) => {
                setStartDate(value);
              }}
              PopperProps={{ anchorEl: anchorEl1 }}
              renderInput={({
                ref,
                inputProps,
                disabled,
                onChange,
                value,
                ...other
              }) => (
                <div ref={ref} {...other}>
                  <input
                    style={{ display: "none" }}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    {...inputProps}
                  />
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      handleClick1(e);
                      setOpen1(true);
                    }}
                  >
                    <CalendarTodayOutlined
                      fontSize="small"
                      sx={{ color: colors.fontLight }}
                    />
                  </IconButton>
                </div>
              )}
            />
          </LocalizationProvider>
        </Box>
        <Typography fontSize="12px" color={colors.fontLight} sx={{ mx: 1 }}>
          To
        </Typography>
        <Box
          display="flex"
          sx={{
            backgroundColor: "#F7F7F7",
            py: 0.3,
            px: 2,
            borderRadius: "27px",
            alignItems: "center",
          }}
        >
          <Typography fontSize="12px" color={colors.fontLight}>
            {getDateInFormat(endDate, "DD MMM YYYY")}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              open={open2}
              onClose={() => setOpen2(false)}
              value={endDate}
              onChange={(value) => {
                setEndDate(value);
              }}
              PopperProps={{ anchorEl: anchorEl2 }}
              renderInput={({
                ref,
                inputProps,
                disabled,
                onChange,
                value,
                ...other
              }) => (
                <div ref={ref} {...other}>
                  <input
                    style={{ display: "none" }}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    {...inputProps}
                  />
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      handleClick2(e);
                      setOpen2(true);
                    }}
                  >
                    <CalendarTodayOutlined
                      fontSize="small"
                      sx={{ color: colors.fontLight }}
                    />
                  </IconButton>
                </div>
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    );
  }
  return null;
}

export default DynamicDateRangePicker;
