import React from "react";
import { Drawer } from "@mui/material";

const RightSideDrawer = (props) => {
  const { open, onClose, children } = props;
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: { xs: 350, sm: 650 },
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: "#000000",
          opacity: "70%",
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export default RightSideDrawer;
