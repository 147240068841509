import React, { useState } from "react";
import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import Modal from "../../common/CommonModal";
import colors from "../../styles/colors";

const JobSelectionModal = (props) => {
  const { open, setOpen, jobs, addCandidate, setSelectedJob, selectedJob } = props;
  return (
    <Modal open={open} setOpen={setOpen}>
      <Grid container spacing={3} sx={{ px: 3 }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            fontSize="20px"
            fontWeight={500}
            color={colors.dark}
            textAlign="center"
          >
            Add Candidate
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px">Job Title</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
          <Autocomplete
            id="jobsReference"
            autoComplete
            disableClearable
            onChange={(_, value) => setSelectedJob(value.id)}
            value={
              jobs ? jobs.find((x) => x.id === selectedJob) : {}
            }
            options={jobs || []}
            getOptionLabel={(option) => option.title || ''}            
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose..."
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ my: 3 }}
          display="flex"
          justifyContent="center"
        >
          <Button
            disabled={!selectedJob}
            size="small"
            variant="contained"
            onClick={() => {
              addCandidate();
            }}
          >
            Confirm Add
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default JobSelectionModal;
