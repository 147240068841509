import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Paper, Typography, IconButton } from "@mui/material";
import {
  BusinessCenterOutlined,
  AccountCircleOutlined,
  HowToRegOutlined,
  BusinessOutlined,
  ArrowForwardOutlined,
} from "@mui/icons-material";
import colors from "../../styles/colors";

import { useQuery } from "@apollo/client";
import {
  GET_TOTAL_APPLIED_COUNT,
  GET_TOTAL_SHORTLISTED_COUNT,
  GET_TOTAL_INTERVIEWED_COUNT,
} from "../../graphql/EmployerDashboard";
import getStore from "../../zustandStore";

function Card(props) {
  const { count, title, icon, color, link, search } = props;

  const history = useHistory();
  return (
    <Paper
      elevation={1}
      sx={{
        borderRadius: "5px",
        px: 3,
        py: 2,
        borderLeft: `7px solid ${color}`,
        cursor: "pointer",
      }}
      onClick={() => history.push({ pathname: link, search: search })}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {icon}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2, mb: -1 }}>
          <Typography color={color} fontSize="24px" fontWeight={700}>
            {count}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography color={colors.lightGray} fontSize="13px">
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

const DashboardCards = (props) => {
  const { jobsData } = props;
  const setLoading = getStore((state) => state.setLoading);
  const history = useHistory();

  const [jobCount, setJobCount] = useState(0);
  const [appliedCount, setAppliedCount] = useState(0);
  const [shortlistedCount, setShortlistedCount] = useState(0);
  const [interviewedCount, setInterviewedCount] = useState(0);

  const { data: appliedCountData, loading: loadingAppliedCount } = useQuery(
    GET_TOTAL_APPLIED_COUNT,
    {
      variables: {},
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const { data: shortlistedCountData, loading: loadingShortlistedCount } =
    useQuery(GET_TOTAL_SHORTLISTED_COUNT, {
      variables: {},
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    });

  const { data: interviewedCountData, loading: loadingInterviewedCount } =
    useQuery(GET_TOTAL_INTERVIEWED_COUNT, {
      variables: {},
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    });

  useEffect(() => {
    if (jobsData && jobsData.jobs && jobsData.jobs.count) {
      setJobCount(jobsData.jobs.count);
    }
  }, [jobsData]);

  useEffect(() => {
    if (
      appliedCountData &&
      appliedCountData.jobCandidates &&
      appliedCountData.jobCandidates.count
    ) {
      setAppliedCount(appliedCountData.jobCandidates.count);
    }
  }, [appliedCountData]);

  useEffect(() => {
    setLoading(loadingAppliedCount);
  }, [loadingAppliedCount, setLoading]);

  useEffect(() => {
    if (
      shortlistedCountData &&
      shortlistedCountData.jobCandidates &&
      shortlistedCountData.jobCandidates.count
    ) {
      setShortlistedCount(shortlistedCountData.jobCandidates.count);
    }
  }, [shortlistedCountData]);

  useEffect(() => {
    setLoading(loadingShortlistedCount);
  }, [loadingShortlistedCount, setLoading]);

  useEffect(() => {
    if (
      interviewedCountData &&
      interviewedCountData.jobCandidates &&
      interviewedCountData.jobCandidates.count
    ) {
      setInterviewedCount(interviewedCountData.jobCandidates.count);
    }
  }, [interviewedCountData]);

  useEffect(() => {
    setLoading(loadingInterviewedCount);
  }, [loadingInterviewedCount, setLoading]);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography color={colors.dark} fontSize="24px" fontWeight={500}>
          Dashboard
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Card
          count={jobCount}
          title="Total Job Posts"
          icon={
            <BusinessCenterOutlined
              sx={{ color: colors.primary }}
              fontSize="large"
            />
          }
          color={colors.primary}
          link="/employer-jobs"
          search=""
        />
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Card
          count={appliedCount}
          title="Applicants by this week"
          icon={
            <AccountCircleOutlined
              sx={{ color: colors.warning }}
              fontSize="large"
            />
          }
          color={colors.warning}
          link="/employer-applied-candidates"
          search="?filter=applied"
        />
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Card
          count={shortlistedCount}
          title="Candidates Shortlisted by this week"
          icon={
            <HowToRegOutlined sx={{ color: colors.success }} fontSize="large" />
          }
          color={colors.success}
          link="/employer-applied-candidates"
          search="?filter=shortlisted"
        />
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Paper
          elevation={0}
          sx={{
            borderRadius: "5px",
            px: 3,
            py: 2,
            backgroundColor: colors.primaryFill,
          }}
        >
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <BusinessOutlined
                sx={{ color: colors.primary }}
                fontSize="large"
              />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 5 }}
            >
              <Typography color={colors.primary} fontSize="13px">
                Fill your company details
              </Typography>
              <IconButton
                size="small"
                onClick={() => history.push("/employer-profile")}
              >
                <ArrowForwardOutlined color="primary" fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DashboardCards;
