import React, { Component } from 'react';
import { connect } from 'react-redux';
import Datepicker from '../common/datepicker';
import {
	statisticsCloseAction,
	friendListCloseAction
} from '../../actions/settingsAction';
class Settings extends Component {

	constructor(props) {
		super(props);
		this.closeStatistics = this.closeStatistics.bind(this);
		this.closeAccount = this.closeAccount.bind(this);
	}

	closeStatistics(e) {
		this.props.statisticsCloseAction(e)
	}
	closeAccount(e) {
		this.props.accountCloseAction(e)
	}

	render() {
		const { statisticsClose, friendListClose } = this.props //const { statisticsClose, accountClose, friendListClose } = this.props
		const { fixNavbar } = this.props;
		return (
			<>
				<div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
					<div className="container-fluid">
						<div className="row clearfix">
							<div className="col-lg-4 col-md-12">
								<ul className="list-group mb-3">
									<li className="list-group-item">
										<div className="media mb-0">
											<img
												className="rounded mr-3"
												src="../assets/images/xs/avatar4.jpg"
												alt="fake_url"
											/>
											<div className="media-body">
												<h5 className="m-0">Deborah Cox</h5>
												<p className="text-muted mb-0">Webdeveloper</p>
											</div>
										</div>
									</li>
									<li className="list-group-item">
										<small className="text-muted">Title: </small>
										<p className="mb-0">FaceBook Inc.</p>
									</li>
									<li className="list-group-item">
										<small className="text-muted">Address: </small>
										<p className="mb-0">44 Shirley Ave. IL 60185</p>
									</li>
									<li className="list-group-item">
										<small className="text-muted">Date: </small>
										<p className="mb-0">07 Feb 2019</p>
									</li>
									<li className="list-group-item">
										<div>In Progress</div>
										<div className="progress progress-xs mt-2">
											<div
												className="progress-bar"
												data-transitiongoal={67}
												aria-valuenow={67}
												style={{ width: '67%' }}
											/>
										</div>
									</li>
									<li className="list-group-item">
										Notifications
										<div className="float-right">
											<label className="custom-control custom-checkbox">
												<input
													type="checkbox"
													className="custom-control-input"
													name="example-checkbox1"
													defaultValue="option1"
													defaultChecked
												/>
												<span className="custom-control-label">&nbsp;</span>
											</label>
										</div>
									</li>
									<li className="list-group-item">
										Messages
										<div className="float-right">
											<label className="custom-control custom-checkbox">
												<input
													type="checkbox"
													className="custom-control-input"
													name="example-checkbox1"
													defaultValue="option1"
													defaultChecked
												/>
												<span className="custom-control-label">&nbsp;</span>
											</label>
										</div>
									</li>
									<li className="list-group-item">
										Message email
										<div className="float-right">
											<label className="custom-control custom-checkbox">
												<input
													type="checkbox"
													className="custom-control-input"
													name="example-checkbox1"
													defaultValue="option1"
												/>
												<span className="custom-control-label">&nbsp;</span>
											</label>
										</div>
									</li>
									<li className="list-group-item">
										Applicant email
										<div className="float-right">
											<label className="custom-control custom-checkbox">
												<input
													type="checkbox"
													className="custom-control-input"
													name="example-checkbox1"
													defaultValue="option1"
													defaultChecked
												/>
												<span className="custom-control-label">&nbsp;</span>
											</label>
										</div>
									</li>
								</ul>
							</div>
							<div className="col-lg-8 col-md-12">
								{statisticsClose ?
									<div className="card" >

										<div className="card-header">
											<h3 className="card-title">General Settings</h3>
											<div className="card-options">
												<span

													className="card-options-remove"
													data-toggle="card-remove" onClick={() => this.closeStatistics(false)}
												>
													<i className="fe fe-x" />
												</span>
												<div className="item-action dropdown ml-2">
													<a href="fake_url" data-toggle="dropdown">
														<i className="fe fe-more-vertical" />
													</a>
													<div className="dropdown-menu dropdown-menu-right">
														<a href="fake_url" className="dropdown-item">
															<i className="dropdown-icon fa fa-eye" /> View Details{' '}
														</a>
														<a href="fake_url" className="dropdown-item">
															<i className="dropdown-icon fa fa-share-alt" /> Share{' '}
														</a>
														<a href="fake_url" className="dropdown-item">
															<i className="dropdown-icon fa fa-cloud-download" /> Download
													</a>
														<div className="dropdown-divider" />
														<a href="fake_url" className="dropdown-item">
															<i className="dropdown-icon fa fa-copy" /> Copy to
													</a>
														<a href="fake_url" className="dropdown-item">
															<i className="dropdown-icon fa fa-folder" /> Move to
													</a>
														<a href="fake_url" className="dropdown-item">
															<i className="dropdown-icon fa fa-edit" /> Rename
													</a>
														<a href="fake_url" className="dropdown-item">
															<i className="dropdown-icon fa fa-trash" /> Delete
													</a>
													</div>
												</div>
											</div>
										</div>
										<div className="card-body">
											<div className="row clearfix">
												<div className="col-lg-6 col-md-12">
													<div className="form-group">
														<input
															type="text"
															className="form-control"
															placeholder="First Name"
														/>
													</div>
												</div>
												<div className="col-lg-6 col-md-12">
													<div className="form-group">
														<input
															type="text"
															className="form-control"
															placeholder="Last Name"
														/>
													</div>
												</div>
												<div className="col-lg-4 col-md-12">
													<div className="form-group">
														<select className="form-control">
															<option value>-- Select Gander --</option>
															<option value="AF">Male</option>
															<option value="AX">Female</option>
														</select>
													</div>
												</div>
												<div className="col-lg-4 col-md-12">
													<div className="form-group">
														<div className="input-group">

															<Datepicker></Datepicker>
														</div>
													</div>
												</div>
												<div className="col-lg-4 col-md-12">
													<div className="form-group">
														<div className="input-group">
															<div className="input-group-prepend">
																<span className="input-group-text">
																	<i className="icon-globe" />
																</span>
															</div>
															<input
																type="text"
																className="form-control"
																placeholder="http://"
															/>
														</div>
													</div>
												</div>
												<div className="col-lg-4 col-md-12">
													<div className="form-group">
														<select className="form-control">
															<option value>-- Select Country --</option>
															<option value="AF">Afghanistan</option>
															<option value="AX">Åland Islands</option>
															<option value="AL">Albania</option>
															<option value="DZ">Algeria</option>
															<option value="AS">American Samoa</option>
															<option value="AD">Andorra</option>
															<option value="AO">Angola</option>
															<option value="AI">Anguilla</option>
															<option value="AQ">Antarctica</option>
															<option value="AG">Antigua and Barbuda</option>
															<option value="AR">Argentina</option>
															<option value="AM">Armenia</option>
															<option value="AW">Aruba</option>
															<option value="AU">Australia</option>
															<option value="AT">Austria</option>
															<option value="AZ">Azerbaijan</option>
															<option value="BS">Bahamas</option>
															<option value="BH">Bahrain</option>
															<option value="BD">Bangladesh</option>
															<option value="BB">Barbados</option>
															<option value="BY">Belarus</option>
															<option value="BE">Belgium</option>
															<option value="BZ">Belize</option>
															<option value="BJ">Benin</option>
															<option value="BM">Bermuda</option>
															<option value="BT">Bhutan</option>
															<option value="BO">Bolivia, Plurinational State of</option>
															<option value="BQ">Bonaire, Sint Eustatius and Saba</option>
															<option value="BA">Bosnia and Herzegovina</option>
															<option value="BW">Botswana</option>
															<option value="BV">Bouvet Island</option>
															<option value="BR">Brazil</option>
															<option value="IO">British Indian Ocean Territory</option>
															<option value="BN">Brunei Darussalam</option>
															<option value="BG">Bulgaria</option>
															<option value="BF">Burkina Faso</option>
															<option value="BI">Burundi</option>
															<option value="KH">Cambodia</option>
															<option value="CM">Cameroon</option>
															<option value="CA">Canada</option>
															<option value="CV">Cape Verde</option>
															<option value="KY">Cayman Islands</option>
															<option value="CF">Central African Republic</option>
															<option value="TD">Chad</option>
															<option value="CL">Chile</option>
															<option value="CN">China</option>
															<option value="CX">Christmas Island</option>
															<option value="CC">Cocos (Keeling) Islands</option>
															<option value="CO">Colombia</option>
															<option value="KM">Comoros</option>
															<option value="CG">Congo</option>
															<option value="CD">
																Congo, the Democratic Republic of the
														</option>
															<option value="CK">Cook Islands</option>
															<option value="CR">Costa Rica</option>
															<option value="CI">Côte d'Ivoire</option>
															<option value="HR">Croatia</option>
															<option value="CU">Cuba</option>
															<option value="CW">Curaçao</option>
															<option value="CY">Cyprus</option>
															<option value="CZ">Czech Republic</option>
															<option value="DK">Denmark</option>
															<option value="DJ">Djibouti</option>
															<option value="DM">Dominica</option>
															<option value="DO">Dominican Republic</option>
															<option value="EC">Ecuador</option>
															<option value="EG">Egypt</option>
															<option value="SV">El Salvador</option>
															<option value="GQ">Equatorial Guinea</option>
															<option value="ER">Eritrea</option>
															<option value="EE">Estonia</option>
															<option value="ET">Ethiopia</option>
															<option value="FK">Falkland Islands (Malvinas)</option>
															<option value="FO">Faroe Islands</option>
															<option value="FJ">Fiji</option>
															<option value="FI">Finland</option>
															<option value="FR">France</option>
															<option value="GF">French Guiana</option>
															<option value="PF">French Polynesia</option>
															<option value="TF">French Southern Territories</option>
															<option value="GA">Gabon</option>
															<option value="GM">Gambia</option>
															<option value="GE">Georgia</option>
															<option value="DE">Germany</option>
															<option value="GH">Ghana</option>
															<option value="GI">Gibraltar</option>
															<option value="GR">Greece</option>
															<option value="GL">Greenland</option>
															<option value="GD">Grenada</option>
															<option value="GP">Guadeloupe</option>
															<option value="GU">Guam</option>
															<option value="GT">Guatemala</option>
															<option value="GG">Guernsey</option>
															<option value="GN">Guinea</option>
															<option value="GW">Guinea-Bissau</option>
															<option value="GY">Guyana</option>
															<option value="HT">Haiti</option>
															<option value="HM">Heard Island and McDonald Islands</option>
															<option value="VA">Holy See (Vatican City State)</option>
															<option value="HN">Honduras</option>
															<option value="HK">Hong Kong</option>
															<option value="HU">Hungary</option>
															<option value="IS">Iceland</option>
															<option value="IN">India</option>
															<option value="ID">Indonesia</option>
															<option value="IR">Iran, Islamic Republic of</option>
															<option value="IQ">Iraq</option>
															<option value="IE">Ireland</option>
															<option value="IM">Isle of Man</option>
															<option value="IL">Israel</option>
															<option value="IT">Italy</option>
															<option value="JM">Jamaica</option>
															<option value="JP">Japan</option>
															<option value="JE">Jersey</option>
															<option value="JO">Jordan</option>
															<option value="KZ">Kazakhstan</option>
															<option value="KE">Kenya</option>
															<option value="KI">Kiribati</option>
															<option value="KP">
																Korea, Democratic People's Republic of
														</option>
															<option value="KR">Korea, Republic of</option>
															<option value="KW">Kuwait</option>
															<option value="KG">Kyrgyzstan</option>
															<option value="LA">Lao People's Democratic Republic</option>
															<option value="LV">Latvia</option>
															<option value="LB">Lebanon</option>
															<option value="LS">Lesotho</option>
															<option value="LR">Liberia</option>
															<option value="LY">Libya</option>
															<option value="LI">Liechtenstein</option>
															<option value="LT">Lithuania</option>
															<option value="LU">Luxembourg</option>
															<option value="MO">Macao</option>
															<option value="MK">
																Macedonia, the former Yugoslav Republic of
														</option>
															<option value="MG">Madagascar</option>
															<option value="MW">Malawi</option>
															<option value="MY">Malaysia</option>
															<option value="MV">Maldives</option>
															<option value="ML">Mali</option>
															<option value="MT">Malta</option>
															<option value="MH">Marshall Islands</option>
															<option value="MQ">Martinique</option>
															<option value="MR">Mauritania</option>
															<option value="MU">Mauritius</option>
															<option value="YT">Mayotte</option>
															<option value="MX">Mexico</option>
															<option value="FM">Micronesia, Federated States of</option>
															<option value="MD">Moldova, Republic of</option>
															<option value="MC">Monaco</option>
															<option value="MN">Mongolia</option>
															<option value="ME">Montenegro</option>
															<option value="MS">Montserrat</option>
															<option value="MA">Morocco</option>
															<option value="MZ">Mozambique</option>
															<option value="MM">Myanmar</option>
															<option value="NA">Namibia</option>
															<option value="NR">Nauru</option>
															<option value="NP">Nepal</option>
															<option value="NL">Netherlands</option>
															<option value="NC">New Caledonia</option>
															<option value="NZ">New Zealand</option>
															<option value="NI">Nicaragua</option>
															<option value="NE">Niger</option>
															<option value="NG">Nigeria</option>
															<option value="NU">Niue</option>
															<option value="NF">Norfolk Island</option>
															<option value="MP">Northern Mariana Islands</option>
															<option value="NO">Norway</option>
															<option value="OM">Oman</option>
															<option value="PK">Pakistan</option>
															<option value="PW">Palau</option>
															<option value="PS">Palestinian Territory, Occupied</option>
															<option value="PA">Panama</option>
															<option value="PG">Papua New Guinea</option>
															<option value="PY">Paraguay</option>
															<option value="PE">Peru</option>
															<option value="PH">Philippines</option>
															<option value="PN">Pitcairn</option>
															<option value="PL">Poland</option>
															<option value="PT">Portugal</option>
															<option value="PR">Puerto Rico</option>
															<option value="QA">Qatar</option>
															<option value="RE">Réunion</option>
															<option value="RO">Romania</option>
															<option value="RU">Russian Federation</option>
															<option value="RW">Rwanda</option>
															<option value="BL">Saint Barthélemy</option>
															<option value="SH">
																Saint Helena, Ascension and Tristan da Cunha
														</option>
															<option value="KN">Saint Kitts and Nevis</option>
															<option value="LC">Saint Lucia</option>
															<option value="MF">Saint Martin (French part)</option>
															<option value="PM">Saint Pierre and Miquelon</option>
															<option value="VC">Saint Vincent and the Grenadines</option>
															<option value="WS">Samoa</option>
															<option value="SM">San Marino</option>
															<option value="ST">Sao Tome and Principe</option>
															<option value="SA">Saudi Arabia</option>
															<option value="SN">Senegal</option>
															<option value="RS">Serbia</option>
															<option value="SC">Seychelles</option>
															<option value="SL">Sierra Leone</option>
															<option value="SG">Singapore</option>
															<option value="SX">Sint Maarten (Dutch part)</option>
															<option value="SK">Slovakia</option>
															<option value="SI">Slovenia</option>
															<option value="SB">Solomon Islands</option>
															<option value="SO">Somalia</option>
															<option value="ZA">South Africa</option>
															<option value="GS">
																South Georgia and the South Sandwich Islands
														</option>
															<option value="SS">South Sudan</option>
															<option value="ES">Spain</option>
															<option value="LK">Sri Lanka</option>
															<option value="SD">Sudan</option>
															<option value="SR">Suriname</option>
															<option value="SJ">Svalbard and Jan Mayen</option>
															<option value="SZ">Swaziland</option>
															<option value="SE">Sweden</option>
															<option value="CH">Switzerland</option>
															<option value="SY">Syrian Arab Republic</option>
															<option value="TW">Taiwan, Province of China</option>
															<option value="TJ">Tajikistan</option>
															<option value="TZ">Tanzania, United Republic of</option>
															<option value="TH">Thailand</option>
															<option value="TL">Timor-Leste</option>
															<option value="TG">Togo</option>
															<option value="TK">Tokelau</option>
															<option value="TO">Tonga</option>
															<option value="TT">Trinidad and Tobago</option>
															<option value="TN">Tunisia</option>
															<option value="TR">Turkey</option>
															<option value="TM">Turkmenistan</option>
															<option value="TC">Turks and Caicos Islands</option>
															<option value="TV">Tuvalu</option>
															<option value="UG">Uganda</option>
															<option value="UA">Ukraine</option>
															<option value="AE">United Arab Emirates</option>
															<option value="GB">United Kingdom</option>
															<option value="US">United States</option>
															<option value="UM">United States Minor Outlying Islands</option>
															<option value="UY">Uruguay</option>
															<option value="UZ">Uzbekistan</option>
															<option value="VU">Vanuatu</option>
															<option value="VE">Venezuela, Bolivarian Republic of</option>
															<option value="VN">Viet Nam</option>
															<option value="VG">Virgin Islands, British</option>
															<option value="VI">Virgin Islands, U.S.</option>
															<option value="WF">Wallis and Futuna</option>
															<option value="EH">Western Sahara</option>
															<option value="YE">Yemen</option>
															<option value="ZM">Zambia</option>
															<option value="ZW">Zimbabwe</option>
														</select>
													</div>
												</div>
												<div className="col-lg-4 col-md-12">
													<div className="form-group">
														<input
															type="text"
															className="form-control"
															placeholder="State/Province"
														/>
													</div>
												</div>
												<div className="col-lg-4 col-md-12">
													<div className="form-group">
														<input type="text" className="form-control" placeholder="City" />
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<textarea
															rows={4}
															type="text"
															className="form-control"
															placeholder="Address"
															defaultValue={''}
														/>
													</div>
												</div>
											</div>
											<button type="button" className="btn btn-round btn-primary">
												Update
										</button>{' '}
										&nbsp;&nbsp;
										<button type="button" className="btn btn-round btn-default">
												Cancel
										</button>
										</div>
									</div>
									: ""}
								{friendListClose ?
									<div className="card">
										<div className="card-header">
											<h3 className="card-title">Account Data</h3>
											<div className="card-options">
												<span

													className="card-options-remove"
													data-toggle="card-remove" onClick={() => this.closeAccount(false)}
												>
													<i className="fe fe-x" />
												</span>
												<div className="item-action dropdown ml-2">
													<a href="fake_url" data-toggle="dropdown">
														<i className="fe fe-more-vertical" />
													</a>
													<div className="dropdown-menu dropdown-menu-right">
														<a href="fake_url" className="dropdown-item">
															<i className="dropdown-icon fa fa-eye" /> View Details{' '}
														</a>
														<a href="fake_url" className="dropdown-item">
															<i className="dropdown-icon fa fa-share-alt" /> Share{' '}
														</a>
														<a href="fake_url" className="dropdown-item">
															<i className="dropdown-icon fa fa-cloud-download" /> Download
													</a>
														<div className="dropdown-divider" />
														<a href="fake_url" className="dropdown-item">
															<i className="dropdown-icon fa fa-copy" /> Copy to
													</a>
														<a href="fake_url" className="dropdown-item">
															<i className="dropdown-icon fa fa-folder" /> Move to
													</a>
														<a href="fake_url" className="dropdown-item">
															<i className="dropdown-icon fa fa-edit" /> Rename
													</a>
														<a href="fake_url" className="dropdown-item">
															<i className="dropdown-icon fa fa-trash" /> Delete
													</a>
													</div>
												</div>
											</div>
										</div>
										<div className="card-body">
											<div className="row clearfix">
												<div className="col-lg-4 col-md-12">
													<div className="form-group">
														<input
															type="text"
															className="form-control"
															defaultValue="louispierce"
															disabled
															placeholder="Username"
														/>
													</div>
												</div>
												<div className="col-lg-4 col-md-12">
													<div className="form-group">
														<input
															type="email"
															className="form-control"
															defaultValue="louis.info@yourdomain.com"
															placeholder="Email"
														/>
													</div>
												</div>
												<div className="col-lg-4 col-md-12">
													<div className="form-group">
														<input
															type="text"
															className="form-control"
															placeholder="Phone Number"
														/>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<hr />
													<h6>Change Password</h6>
													<div className="form-group">
														<input
															type="password"
															className="form-control"
															placeholder="Current Password"
														/>
													</div>
													<div className="form-group">
														<input
															type="password"
															className="form-control"
															placeholder="New Password"
														/>
													</div>
													<div className="form-group">
														<input
															type="password"
															className="form-control"
															placeholder="Confirm New Password"
														/>
													</div>
												</div>
											</div>
											<button type="button" className="btn btn-round btn-primary">
												Update
										</button>{' '}
										&nbsp;&nbsp;
										<button type="button" className="btn btn-round btn-default">
												Cancel
										</button>
										</div>
									</div>
									: ""}
							</div>
						</div>
					</div>
				</div>

			</>
		);
	}
}
const mapStateToProps = state => ({
	fixNavbar: state.settings.isFixNavbar,
	statisticsClose: state.settings.isStatisticsClose,
	statisticsOpen: state.settings.isStatistics,
	friendListClose: state.settings.isFriendListClose
})

const mapDispatchToProps = dispatch => ({
	statisticsCloseAction: (e) => dispatch(statisticsCloseAction(e)),
	accountCloseAction: (e) => dispatch(friendListCloseAction(e))
})
export default connect(mapStateToProps, mapDispatchToProps)(Settings);