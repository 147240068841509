import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Fade,
  Fab,
  Avatar,
  Paper,
  Breadcrumbs,
  // Badge,
  useScrollTrigger,
  useMediaQuery,
} from "@mui/material";
import {
  // NotificationsNoneOutlined,
  KeyboardArrowUp,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import CommonFooter from "../common/Footer";
import { getEmployerBreadcrumbs, getEmployerNavbarSelected } from "../helpers";
import getStore from "../zustandStore";
import ciel_logo_white from "../assets/imagesNew/ciel_logo_white.png";
import ciel_logo_colored from "../assets/imagesNew/ciel_logo_colored.png";
import theme from "../styles/theme";
import colors from "../styles/colors";

const drawerWidth = 240;
const navItems = [
  { id: 0, name: "Dashboard", path: "/employer-dashboard" },
  { id: 1, name: "Jobs", path: "/employer-jobs" },
  { id: 2, name: "Applied Candidates", path: "/employer-applied-candidates" },
  { id: 3, name: "Search Candidate", path: "/employer-search-candidate" },
];

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

const EmployerNavbar = (props) => {
  const { window, mainComponent } = props;
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const location = useLocation();
  const selectedJob = getStore((state) => state.selectedJob);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [selectedJobDetails, setSelectedJobDetails] = useState({});
  const [loggedInUser, setLoggedInUser] = useState("");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img
        src={ciel_logo_colored}
        alt="logo"
        style={{ height: 30, margin: 5 }}
      />
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton
              selected={getEmployerNavbarSelected(location, item)}
              onClick={() => history.push(item.path)}
              sx={{
                borderLeft: getEmployerNavbarSelected(location, item)
                  ? `5px solid ${colors.primary}`
                  : "none",
              }}
            >
              <ListItemText disableTypography>
                <Typography
                  color={
                    getEmployerNavbarSelected(location, item)
                      ? colors.primary
                      : colors.fontLight
                  }
                  fontSize="16px"
                >
                  {item.name}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    if (selectedJob && selectedJob.id && selectedJob.title) {
      setSelectedJobDetails(selectedJob);
    }
  }, [selectedJob]);

  useEffect(() => {
    setBreadcrumbs(getEmployerBreadcrumbs(location, selectedJobDetails));
  }, [location, selectedJobDetails]);

  useEffect(() => {
    const user = localStorage.getItem("me");
    if (user) {
      setLoggedInUser(JSON.parse(user).firstName);
    }
  }, []);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar component="nav" elevation={0} color="primary">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              // sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
              sx={{ flexGrow: 1, display: "block" }}
            >
              <img
                src={ciel_logo_white}
                alt="logo"
                style={{ height: matchesSm ? 30 : 50 }}
              />
            </Typography>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
              }}
            >
              {navItems &&
                navItems.map((item, index) => (
                  <Typography
                    key={item.id}
                    color={
                      getEmployerNavbarSelected(location, item)
                        ? colors.secondary
                        : `${colors.secondary}50`
                    }
                    fontSize="16px"
                    sx={{ cursor: "pointer", mr: 2 }}
                    onClick={() => {
                      history.push(item.path);
                    }}
                  >
                    {item.name}
                  </Typography>
                ))}
            </Box>
            {/* <IconButton sx={{ mr: 2 }}>
              <Badge
                badgeContent={2}
                color="secondary"
                sx={{ "& .MuiBadge-badge": { color: colors.primary } }}
              >
                <NotificationsNoneOutlined color="secondary" />
              </Badge>
            </IconButton> */}
            <Avatar
              sx={{ cursor: "pointer" }}
              onClick={() => history.push("/employer-profile")}
            >
              {loggedInUser ? loggedInUser.charAt(0) : "A"}
            </Avatar>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ width: "100%", backgroundColor: colors.secondary }}
        >
          <Toolbar id="back-to-top-anchor" />
          <Paper
            elevation={0}
            sx={{
              py: 2,
              px: { md: 15, sm: 5, xs: 3 },
              borderRadius: 0,
              backgroundColor: "#F1F1F1",
            }}
          >
            <Breadcrumbs separator={"/"} aria-label="breadcrumb">
              {breadcrumbs.map((crumb, index) => (
                <NavLink
                  key={crumb.id}
                  role="button"
                  to={crumb.path}
                  style={{
                    textDecoration: "none",
                    color: crumb.color,
                    fontSize: "16px",
                  }}
                  onClick={(e) => {
                    if (index === breadcrumbs.length - 1) {
                      e.preventDefault();
                    }
                  }}
                >
                  {crumb.name}
                </NavLink>
              ))}
            </Breadcrumbs>
          </Paper>
          <Box
            sx={{
              py: 3,
              px: { md: 15, sm: 5, xs: 3 },
            }}
          >
            {mainComponent}
          </Box>
          <CommonFooter />
        </Box>
      </Box>
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default EmployerNavbar;
