import React, { useEffect } from "react";
import { capitalize, getMonthAndYear, getPrettyDate } from "../../utils";
import moment from "moment";

const MCC_Report = (props) => {
  const {
    summaryTableData,
    transactionData,
    enrolledUsers,
    staffingRevenueData,
    startDate,
    endDate,
    staffingStartDate,
    setStaffingStartDate,
    staffingEndDate,
    setStaffingEndDate,
    loadingTableData,
    loadingTransactionData,
    loadingEnrolledUsers,
    loadingStaffingRevenue,
  } = props;

  useEffect(() => {
    console.log({
      transactionData,
      summaryTableData,
      enrolledUsers,
      staffingRevenueData,
    });
  }, [transactionData, summaryTableData, enrolledUsers, staffingRevenueData]);

  return (
    <div>
      {loadingEnrolledUsers && loadingTableData && loadingTransactionData && (
        <div className="d-flex justify-content-center">
          <div className="d-flex flex-column align-items-center">
            <p style={{ font: 15 }}>Loading... </p>
            <div
              class="spinner-border m-auto"
              role="status"
              aria-hidden="true"
            ></div>
          </div>
        </div>
      )}
      {!loadingEnrolledUsers && !loadingTableData && !loadingTransactionData && (
        <div className="section-body mt-3">
          <div className="container-fluid">
            <div className="tab-content mt-3">
              <div
                className="tab-pane fade show active"
                id="summary"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped table-hover mb-0">
                        <thead>
                          <tr>
                            <th
                              colSpan={10}
                              style={{
                                textAlign: "center",
                                fontSize: 17,
                                fontWeight: "bold",
                                background: "rgba(58, 34, 85, 0.1)",
                              }}
                            >
                              MCC Branch Report (
                              {moment(startDate).format("DD.MM.YYYY")} -{" "}
                              {moment(endDate).format("DD.MM.YYYY")})
                            </th>
                          </tr>
                          <tr>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Branch
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Mobilization Revenue
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              No
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Placement Revenue
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              No
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Selection Revenue
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              No
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Mobilization
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Placement
                            </th>
                            <th style={{ fontWeight: 550 }}>Selection</th>
                          </tr>
                        </thead>
                        <tbody>
                          {summaryTableData &&
                          summaryTableData?.consolidatedReport.length > 0 ? (
                            summaryTableData?.consolidatedReport.map(
                              (sum, i) => (
                                <tr
                                  style={{
                                    borderBottom:
                                      i ===
                                      summaryTableData.consolidatedReport
                                        .length -
                                        1
                                        ? "1px #D6D6D6 solid"
                                        : "",
                                  }}
                                >
                                  <td
                                    style={{
                                      borderRight: "1px #D6D6D6 solid",
                                      fontWeight:
                                        i ===
                                        summaryTableData.consolidatedReport
                                          .length -
                                          1
                                          ? 550
                                          : "",
                                    }}
                                  >
                                    {sum?.centre || ""}
                                  </td>
                                  <td
                                    style={{
                                      borderRight: "1px #D6D6D6 solid",
                                      fontWeight:
                                        i ===
                                        summaryTableData.consolidatedReport
                                          .length -
                                          1
                                          ? 550
                                          : "",
                                    }}
                                  >
                                    {sum?.mobilizationRevenue > 0
                                      ? `₹${sum?.mobilizationRevenue.toLocaleString(
                                          "en-IN"
                                        )}`
                                      : "₹0"}
                                  </td>
                                  <td
                                    style={{
                                      borderRight: "1px #D6D6D6 solid",
                                      fontWeight:
                                        i ===
                                        summaryTableData.consolidatedReport
                                          .length -
                                          1
                                          ? 550
                                          : "",
                                    }}
                                  >
                                    {sum?.mobilizationCount || 0}
                                  </td>
                                  <td
                                    style={{
                                      borderRight: "1px #D6D6D6 solid",
                                      fontWeight:
                                        i ===
                                        summaryTableData.consolidatedReport
                                          .length -
                                          1
                                          ? 550
                                          : "",
                                    }}
                                  >
                                    {sum?.placementRevenue > 0
                                      ? `₹${sum?.placementRevenue.toLocaleString(
                                          "en-IN"
                                        )}`
                                      : "₹0"}
                                  </td>
                                  <td
                                    style={{
                                      borderRight: "1px #D6D6D6 solid",
                                      fontWeight:
                                        i ===
                                        summaryTableData.consolidatedReport
                                          .length -
                                          1
                                          ? 550
                                          : "",
                                    }}
                                  >
                                    {sum?.placementCount || 0}
                                  </td>
                                  <td
                                    style={{
                                      borderRight: "1px #D6D6D6 solid",
                                      fontWeight:
                                        i ===
                                        summaryTableData.consolidatedReport
                                          .length -
                                          1
                                          ? 550
                                          : "",
                                    }}
                                  >
                                    {sum?.recruitmentRevenue > 0
                                      ? `₹${sum?.recruitmentRevenue.toLocaleString(
                                          "en-IN"
                                        )}`
                                      : "₹0"}
                                  </td>
                                  <td
                                    style={{
                                      borderRight: "1px #D6D6D6 solid",
                                      fontWeight:
                                        i ===
                                        summaryTableData.consolidatedReport
                                          .length -
                                          1
                                          ? 550
                                          : "",
                                    }}
                                  >
                                    {sum?.recruitmentCount || 0}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {sum?.mobilizationOrganizations || ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {sum?.placementOrganizations || ""}
                                  </td>
                                  <td>{sum?.recruitmentOrganizations || ""}</td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td
                                colSpan={10}
                                style={{ textAlign: "center", fontSize: 14 }}
                              >
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="transaction" role="tabpanel">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped table-hover mb-0">
                        <thead>
                          <tr>
                            <th
                              colSpan={10}
                              style={{
                                textAlign: "center",
                                fontSize: 17,
                                fontWeight: "bold",
                                background: "rgba(58, 34, 85, 0.1)",
                              }}
                            >
                              Transaction Report
                            </th>
                          </tr>
                          <tr>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Transaction Date &#38; Time
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Event Date
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Centre Name
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Transaction Type
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Beneficiary Org Name
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Candidate Name
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Candidate Mobile
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Agreed Commission Rate
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Transaction Amount
                            </th>
                            <th style={{ fontWeight: 550 }}>Posted By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactionData &&
                          transactionData?.transactions &&
                          JSON.parse(transactionData?.transactions?.transactions).length >
                            0 ? (
                            JSON.parse(transactionData?.transactions?.transactions).map(
                              (tran, i) => {
                                let commissionRate = 0;
                                // debugger
                                if (tran?.commissionType === "Fixed Rate") {
                                  commissionRate =
                                    tran?.organization?.rate_placement || 0;
                                } else {
                                  let profile = JSON.parse(tran?.profile);
                                  commissionRate =
                                    profile?.percentage_recruitment || 0;
                                }
                                console.log({ commissionRate });
                                return (
                                  <tr
                                    style={{
                                      borderBottom:
                                        i ===
                                        JSON.parse(transactionData.transactions
                                          .transactions).length -
                                          1
                                          ? "1px #D6D6D6 solid"
                                          : "",
                                    }}
                                  >
                                    <td
                                      style={{
                                        borderRight: "1px #D6D6D6 solid",
                                      }}
                                    >
                                      {getPrettyDate(tran?.createdDate) || ""}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px #D6D6D6 solid",
                                      }}
                                    >
                                      {getPrettyDate(tran?.commissionDate) ||
                                        ""}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px #D6D6D6 solid",
                                      }}
                                    >
                                      {tran?.user?.centre?.name || ""}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px #D6D6D6 solid",
                                      }}
                                    >
                                      {tran?.transactionType
                                        ? capitalize(tran?.transactionType)
                                        : ""}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px #D6D6D6 solid",
                                      }}
                                    >
                                      {tran?.organization?.name || ""}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px #D6D6D6 solid",
                                      }}
                                    >
                                      {tran?.user?.firstName || ""}{" "}
                                      {tran?.user?.lastName || ""}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px #D6D6D6 solid",
                                      }}
                                    >
                                      {tran?.user?.mobile || ""}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px #D6D6D6 solid",
                                      }}
                                    >
                                      {tran?.commissionType === "Fixed Rate"
                                        ? commissionRate > 0
                                          ? `₹${commissionRate.toLocaleString(
                                              "en-IN"
                                            )}`
                                          : "₹0"
                                        : `${commissionRate}%`}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px #D6D6D6 solid",
                                      }}
                                    >
                                      {tran?.commission || ""}
                                    </td>
                                    <td>{tran?.createdBy?.firstName || ""}</td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td
                                colSpan={10}
                                style={{ textAlign: "center", fontSize: 14 }}
                              >
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="newCandidates" role="tabpanel">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped table-hover mb-0">
                        <thead>
                          <tr>
                            <th
                              colSpan={7}
                              style={{
                                textAlign: "center",
                                fontSize: 17,
                                fontWeight: "bold",
                                background: "rgba(58, 34, 85, 0.1)",
                              }}
                            >
                              New Candidates Enrolled
                            </th>
                          </tr>
                          <tr>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Transaction Date &#38; Time
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Centre Name
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Sourced By
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Sourced For
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Candidate Name
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Candidate Mobile
                            </th>
                            <th style={{ fontWeight: 550 }}>Posted By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {enrolledUsers &&
                          enrolledUsers?.enrolledUsers &&
                          JSON.parse(enrolledUsers?.enrolledUsers?.users).length > 0 ? (
                            JSON.parse(enrolledUsers?.enrolledUsers?.users).map(
                              (user, i) => (
                                <tr
                                  style={{
                                    borderBottom:
                                      i ===
                                      JSON.parse(enrolledUsers.enrolledUsers.users).length -
                                        1
                                        ? "1px #D6D6D6 solid"
                                        : "",
                                  }}
                                >
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {getPrettyDate(user?.createdDate) || ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {user?.centre?.name || ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {user?.source === 1
                                      ? "PIA"
                                      : user?.source === 2
                                      ? "MCC"
                                      : user?.source === 3
                                      ? "Mobile App"
                                      : "Others"}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {user?.forSource === 1 ? "PIA" : ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {user?.firstName || ""}{" "}
                                    {user?.lastName || ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {user?.mobile || ""}
                                  </td>
                                  <td>{user?.createdBy || ""}</td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td
                                colSpan={10}
                                style={{ textAlign: "center", fontSize: 14 }}
                              >
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="staffingRevenue"
                role="tabpanel"
              >
                <div className="d-flex align-items-center float-right mb-2">
                  <span
                    style={{
                      color: "#A8A8A8",
                      cursor: "default",
                      marginRight: 5,
                      fontSize: 14,
                    }}
                  >
                    Start Month
                  </span>
                  <input
                    type="month"
                    className="form-control"
                    placeholder="Date of Birth"
                    style={{
                      border: "1PX #7C7B7B solid",
                      marginRight: 10,
                      width: 170,
                    }}
                    // id="dob"
                    onChange={(e) => setStaffingStartDate(e.target.value)}
                    value={staffingStartDate}
                    // onBlur={handleBlur}
                  />
                  <span
                    style={{
                      color: "#A8A8A8",
                      cursor: "default",
                      marginRight: 5,
                      fontSize: 14,
                    }}
                  >
                    End Month
                  </span>
                  <input
                    type="month"
                    className="form-control"
                    placeholder="Date of Birth"
                    style={{
                      border: "1PX #7C7B7B solid",
                      marginRight: 10,
                      width: 170,
                    }}
                    // id="dob"
                    onChange={(e) => setStaffingEndDate(e.target.value)}
                    value={staffingEndDate}
                    // onBlur={handleBlur}
                  />
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped table-hover mb-0">
                        <thead>
                          <tr>
                            <th
                              colSpan={7}
                              style={{
                                textAlign: "center",
                                fontSize: 17,
                                fontWeight: "bold",
                                background: "rgba(58, 34, 85, 0.1)",
                              }}
                            >
                              Staffing Revenue
                            </th>
                          </tr>
                          <tr>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Transaction Date &#38; Time
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Cluster Name
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Month and year
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Transaction Type
                            </th>
                            {/* <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Beneficiary Org Name
                          </th> */}
                            {/* <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Candidate Name
                          </th> */}
                            {/* <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Candidate Mobile
                          </th> */}
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Number of Deputees
                            </th>
                            <th
                              style={{
                                fontWeight: 550,
                                borderRight: "1px #D6D6D6 solid",
                              }}
                            >
                              Revenue for Month
                            </th>
                            <th style={{ fontWeight: 550 }}>Posted By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loadingStaffingRevenue && (
                            <td colSpan={10} style={{ textAlign: "center" }}>
                              <p style={{ font: 15 }}>Loading... </p>
                              <div
                                class="spinner-border ml-auto"
                                role="status"
                                aria-hidden="true"
                              ></div>
                            </td>
                          )}
                          {!loadingStaffingRevenue &&
                          staffingRevenueData &&
                          staffingRevenueData?.staffingRevenue &&
                          JSON.parse(staffingRevenueData?.staffingRevenue?.transactions)
                            .length > 0 ? (
                            JSON.parse(staffingRevenueData?.staffingRevenue?.transactions).map(
                              (tran, i) => {
                                let commissionRate = 0;
                                // debugger
                                // if (tran?.commissionType === "Fixed Rate") {
                                //   commissionRate =
                                //     tran?.organization?.rate_placement || 0;
                                // } else {
                                //   let profile = JSON.parse(tran?.profile);
                                //   commissionRate =
                                //     profile?.percentage_recruitment || 0;
                                // }
                                console.log("staff", { commissionRate });
                                return (
                                  <tr
                                    style={{
                                      borderBottom:
                                        i ===
                                        JSON.parse(staffingRevenueData.staffingRevenue
                                          .transactions).length -
                                          1
                                          ? "1px #D6D6D6 solid"
                                          : "",
                                    }}
                                  >
                                    <td
                                      style={{
                                        borderRight: "1px #D6D6D6 solid",
                                      }}
                                    >
                                      {getPrettyDate(tran?.createdDate) || ""}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px #D6D6D6 solid",
                                      }}
                                    >
                                      {tran?.user?.cluster?.name || ""}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px #D6D6D6 solid",
                                      }}
                                    >
                                      {getMonthAndYear(tran?.commissionDate) ||
                                        ""}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px #D6D6D6 solid",
                                      }}
                                    >
                                      {tran?.transactionType
                                        ? capitalize(
                                            tran?.transactionType.replaceAll(
                                              "_",
                                              " "
                                            )
                                          )
                                        : ""}
                                    </td>
                                    {/* <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {tran?.organization?.name || ""}
                                  </td> */}
                                    {/* <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {tran?.user?.firstName || ""}{" "}
                                    {tran?.user?.lastName || ""}
                                  </td> */}
                                    {/* <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {tran?.user?.mobile || ""}
                                  </td> */}
                                    <td
                                      style={{
                                        borderRight: "1px #D6D6D6 solid",
                                      }}
                                    >
                                      {tran?.numberOfDeputees}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px #D6D6D6 solid",
                                      }}
                                    >
                                   {tran?.commission
                                  ? `₹${tran.commission.toLocaleString(
                                      "en-IN"
                                    )}`
                                  : ""}
                                    </td>
                                    <td>{tran?.createdBy?.firstName || ""}</td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td
                                colSpan={10}
                                style={{ textAlign: "center", fontSize: 14 }}
                              >
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MCC_Report;
