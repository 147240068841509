import React, { useEffect } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { UPDATE_JOB_CANDIDATE } from "../../graphql/jobs";
import { useMutation } from "@apollo/client";
import { Modal } from "react-bootstrap";

const trackingStatus = [
  { id: 4, name: "Applied" },
  { id: 5, name: "Shortlisted" },
  { id: 6, name: "Interviewed" },
  // { id: 7, name: "Offered" },
  // { id: 13, name: "Joined" },
  { id: 9, name: "Rejected" },
];

const UpdateStatusModalForm = (props) => {
  const {
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    updateStatusModal,
    setUpdateStatusModal,
  } = props;

  useEffect(() => {
    console.log({ updateStatusModal });
  }, [updateStatusModal]);

  return (
    <Modal show={updateStatusModal} onHide={() => setUpdateStatusModal(false)}>
      <Modal.Header>
        <Modal.Title>Update Status</Modal.Title>
        <button
          type="button"
          class="close"
          aria-label="Close"
          onClick={() => setUpdateStatusModal(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>Status</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <select
                className="custom-select"
                style={{ border: "1PX #7C7B7B solid" }}
                id="status"
                onChange={(e) => {
                  setFieldValue("status", parseInt(e.target.value));
                }}
              >
                <option disabled selected value>
                  {" "}
                  -- Select a Status --{" "}
                </option>
                {trackingStatus &&
                  trackingStatus.map((status) => (
                    <option value={status.id}>{status.name}</option>
                  ))}
              </select>
              {touched.status && (
                <span style={{ color: "red", fontSize: 13 }}>
                  {errors.status}
                </span>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" class="btn btn-primary" onClick={handleSubmit}>
          Update
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          onClick={() => setUpdateStatusModal(false)}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const UpdateStatusModal = (props) => {
  const {
    setUpdateStatusModal,
    refetchJobCandidates,
    updateStatusModal,
    bulkSelectedCandidates,
  } = props;

  const [updateJobCandidate] = useMutation(UPDATE_JOB_CANDIDATE, {
    onCompleted: (data) => {
      const { updateJobCandidate } = data || {};
      const { status, error } = updateJobCandidate;
      console.log({ status, error });
      if (status) {
        setUpdateStatusModal(false);
        refetchJobCandidates();
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const UpdateStatusModalWrapped = withFormik({
    mapPropsToValues: () => {
      return {
        status: "",
      };
    },

    validationSchema: Yup.object().shape({
      status: Yup.string().required("Please select a status"),
    }),

    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);

      updateJobCandidate({
        variables: {
          payload: {
            id: bulkSelectedCandidates,
            status: values.status,
          },
        },
      });
    },
  })(UpdateStatusModalForm);

  return (
    <UpdateStatusModalWrapped
      updateStatusModal={updateStatusModal}
      setUpdateStatusModal={setUpdateStatusModal}
    />
  );
};

export default UpdateStatusModal;
