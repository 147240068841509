import React from "react";
import { RadioGroup, Radio, FormControlLabel, Typography } from "@mui/material";

const RadioGroups = (props) => {
  const { radioBtns, onChange, value } = props;
  return (
    <RadioGroup
      row
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
      value={value}
      onChange={onChange}
    >
      {radioBtns &&
        radioBtns.map((btn) => (
          <FormControlLabel
            value={btn.id}
            control={<Radio size="small" />}
            label={<Typography fontSize="13px">{btn.name}</Typography>}
          />
        ))}
    </RadioGroup>
  );
};

export default RadioGroups;
