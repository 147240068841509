import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography } from "@mui/material";
import circle_tick from "../../assets/imagesNew/circle-tick.svg";
import colors from "../../styles/colors";

const RegistrationComplete = (props) => {
  const history = useHistory();
  return (
    <Grid container spacing={3} sx={{ p: { xs: 3, sm: 0 } }}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <img src={circle_tick} alt="tick" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography
          color={colors.dark}
          fontWeight={500}
          fontSize="26px"
          textAlign="center"
        >
          Awesome !
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="16px" textAlign="center">
          Your company has been registered successfully
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 5 }}>
        <Typography fontSize="18px" textAlign="center" fontWeight={500}>
          Your company has been registered successfully
        </Typography>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={() => history.push("/employer-add-job/new")}
        >
          Post Now
        </Button>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", justifyContent: "center", mt: 3 }}
      >
        <Button
          size="small"
          onClick={() => history.push("/employer-dashboard")}
        >
          Go to Dashboard
        </Button>
      </Grid>
    </Grid>
  );
};

export default RegistrationComplete;
