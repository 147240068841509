import React, { useState } from "react";
import {
  Grid,
  TextField,
  Autocomplete,
  InputAdornment,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import {
  SearchOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { statuses } from "../../constants";
import colors from "../../styles/colors";
import NoBorderDropdown from "../../common/NoBorderDropdown";

const Filters = (props) => {
  const { jobApplicantFilter, setJobApplicantFilter } = props;

  const [sortBy, setSortBy] = useState("latest");
  const [anchorEl, setAnchorEl] = useState(null);
  const openExportMenu = Boolean(anchorEl);
  const [searchString, setSearchString] = useState("");

  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <TextField
          size="small"
          fullWidth
          placeholder="Search..."
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  size="small"
                  onClick={() =>
                    setJobApplicantFilter({
                      ...jobApplicantFilter,
                      candidate__firstName__icontains: searchString,
                      candidate__lastName__icontains: searchString,
                    })
                  }
                >
                  <SearchOutlined fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            backgroundColor: "#F7F7F7",
          }}
        />
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Autocomplete
          id="combo-box-demo"
          autoComplete
          disableClearable
          options={statuses}
          getOptionLabel={(option) => option.name}
          onChange={(event, value) =>
            setJobApplicantFilter({ ...jobApplicantFilter, status: value.id })
          }
          size="small"
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder="Choose Status"
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                backgroundColor: "#F7F7F7",
              }}
            />
          )}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Grid
          container
          spacing={0.5}
          sx={{
            justifyContent: { xs: "flex-start", md: "flex-end" },
            alignItems: "center",
          }}
        >
          <Grid item>
            <Typography fontSize="14px" color={colors.gray}>
              Sort by :
            </Typography>
          </Grid>
          <Grid item>
            <NoBorderDropdown
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              bgColor={colors.secondary}
              textColor={colors.gray}
              fontSize="14px"
              dropdownArr={[
                { id: "latest", name: "Latest" },
                { id: "oldest", name: "Oldest" },
              ]}
            />
          </Grid>
          <Grid item>
            <Button
              id="demo-customized-button"
              aria-controls={
                openExportMenu ? "demo-customized-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={openExportMenu ? "true" : undefined}
              size="small"
              disableElevation
              onClick={handleExportClick}
              endIcon={
                anchorEl ? (
                  <KeyboardArrowUp fontSize="small" color="primary" />
                ) : (
                  <KeyboardArrowDown fontSize="small" color="primary" />
                )
              }
              sx={{ fontSize: "14px" }}
            >
              Export
            </Button>
            <Menu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openExportMenu}
              onClose={handleExportMenuClose}
            >
              <MenuItem onClick={handleExportMenuClose}>Excel</MenuItem>
              <MenuItem onClick={handleExportMenuClose}>CSV</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Filters;
