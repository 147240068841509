const colors = {
  primary: "#006DD1",
  secondary: "#FFFFFF",
  gray: "#444444",
  purple: "#7D5FFF",
  purple2: "#6542FF",
  purple3: "#5232DC",
  borderColor: "#EBEBEB",
  dark: "#222222",
  lightGray: "#898989",
  warning: "#ECA51D",
  success: "#2ABEA1",
  primaryFill: "rgba(0, 109, 209, 0.09)",
  strokeGray: "#E0E0E0",
  primaryLight: "#D2EAFF",
  danger: "#FF8381",
  darkGray: "#585858",

  fontPrimary: "#000000",
  fontLight: "#555555",
  fontLighter: "rgba(85, 85, 85, 0.5)",
  successFill: "rgba(17, 153, 142, 0.15)",
  warningFill: "rgba(240, 152, 25, 0.15)",
  dangerFill: "rgba(255, 94, 98, 0.15)",
  chartTitle: "#464255",
  yellowFill: "#FBFF65",
  orange: "#FF9600",
};

export default colors;
