export function handlePostJob(addJob, values, screeningQuestions) {
  if (values && values.id) {
    addJob({
      variables: {
        payload: {
          ...values,
          skills: values.skills.join(","),
          perks: JSON.stringify(values.perks),
          languages: JSON.stringify(values.languages),
          screeningQuestions,
          confidential: false,
          noticePeriod: 0,
        },
      },
    });
  } else {
    delete values["id"];
    addJob({
      variables: {
        payload: {
          ...values,
          skills: values.skills.join(","),
          perks: JSON.stringify(values.perks),
          languages: JSON.stringify(values.languages),
          screeningQuestions,
          confidential: false,
          noticePeriod: 0,
        },
      },
    });
  }
}
