import { gql } from "@apollo/client";

export const GET_COMMUNITIES = gql`
query communities($filter: CommunityFilterInput, $limit: Int, $page: Int) {
  allCommunities(filter: $filter, limit: $limit, page: $page) {
    count
    communities {
      title
      id
      threads
      posts
      description
      users{
        id
        firstName
        lastName
        mobile
        country{
          id
          name
        }
        state{
          id
          name
        }
        city{
          id
          name
        }
      }
    }
  }
}
`;


export const GET_COMMUNITY = gql`
query community($id: String) {
  community(id: $id) {
    id
    name
    description
    title
    threads
    posts
    users{
      id
      firstName
    }
  }
}
`;


export const ADD_COMMUNITY = gql`
  mutation createCommunity($payload: CommunityCreateInput!) {
    createCommunity(payload: $payload) {
      status
      error
    }
  }
`;

export const UPDATE_COMMUNITY= gql`
  mutation updateCommunity($payload: CommunityUpdateInput!) {
    updateCommunity(payload: $payload) {
      status
      error
    }
  }
`;

export const DELETE_COMMUNITY = gql`
  mutation deleteCommunity($id: String!) {
    deleteCommunity(id: $id) {
      status
      error
    }
  }
`;


export const ADD_POST = gql`
mutation createPost($payload: PostCreateInput!, $files:[Upload!]) {
  createPost(payload: $payload, files: $files) {
    status
     error
     __typename
   }
 }
`;

export const UPDATE_POST= gql`
  mutation updatePost($payload: PostUpdateInput!) {
    updatePost(payload: $payload) {
      status
      error
    }
  }
`;

export const DELETE_POST = gql`
  mutation deletePost($id: String!) {
    deletePost(id: $id) {
      status
      error
    }
  }
`;



export const ADD_THREAD = gql`
  mutation createThread($payload: ThreadCreateInput!) {
    createThread(payload: $payload) {
      status
      error
    }
  }
`;

export const UPDATE_THREAD= gql`
  mutation updateThread($payload: ThreadUpdateInput!) {
    updateThread(payload: $payload) {
      status
      error
    }
  }
`;

export const DELETE_THREAD = gql`
  mutation deleteThread($id: String!) {
    deleteThread(id: $id) {
      status
      error
    }
  }
`;


export const GET_THREADS = gql`
query threads {
  allThreads {
    count
    threads {
      id
      title
      postCount
      posts {
        id
        body
        likesCount
        postLiked
        time
        author {
          id
          firstName
          lastName
          __typename
        }
        postImages{
          id
          image
        }
        __typename
      }
      community {
        id
        posts
        threads
        description
        title
        __typename
      }
      __typename
    }
    __typename
  }
}



`