import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  Grid,
  TextField,
  Autocomplete,
  InputAdornment,
  Checkbox,
  Typography,
  Button,
  Menu,
  MenuItem,
  Box,
  IconButton,
} from "@mui/material";
import {
  SearchOutlined,
  CheckBoxOutlineBlank,
  CheckBox,
  Clear,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import NoBorderDropdown from "../../common/NoBorderDropdown";
import { statuses } from "../../constants";
import colors from "../../styles/colors";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" color="primary" />;

const Filters = (props) => {
  const { setFilters, filters, search, setSearch, sortBy, setSortBy, jobs } =
    props;

  const location = useLocation();

  const [statusFilter, setStatusFilter] = useState({});

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const openExportMenu = Boolean(anchorEl);

  const setFilter = (key, val) => {
    setFilters({ ...filters, [key]: val });
  };

  // const handleExportClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleExportMenuClose = () => {
  //   setAnchorEl(null);
  // };

  useEffect(() => {
    if (location && location.search) {
      const parsedQuery = queryString.parse(location.search);
      let { filter } = parsedQuery;
      let status = 0;
      if (filter) {
        status = filter === "applied" ? 4 : 5;
        setFilters({ status });
      }
    }
  }, [location, setFilters]);

  useEffect(() => {
    if (filters && filters.status) {
      setStatusFilter(statuses.find((x) => x.id === filters["status"]) || {});
    }
  }, [filters]);
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <TextField
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          size="small"
          fullWidth
          placeholder="Search by name..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {search && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      const tempFilter = { ...filters };
                      delete tempFilter["candidate__firstName__icontains"];
                      delete tempFilter["candidate__lastName__icontains"];
                      setFilters({
                        ...tempFilter,
                      });
                      setSearch("");
                    }}
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                )}
                <Box
                  sx={{
                    borderLeft: `1px solid ${colors.strokeGray}`,
                    pl: 1,
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      setFilters({
                        ...filters,
                        candidate__firstName__icontains: search,
                        candidate__lastName__icontains: search,
                      });
                    }}
                  >
                    <SearchOutlined fontSize="small" />
                  </IconButton>
                </Box>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            backgroundColor: "#F7F7F7",
          }}
        />
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Autocomplete
          id="combo-box-demo"
          autoComplete
          limitTags={1}
          options={jobs || []}
          onChange={(_, value) => {
            if (value.length > 0) {
              const tempArr = value.map((x) => x.id);
              setFilter("job__id__in", tempArr);
            } else {
              const tempF = { ...filters };
              delete tempF["job__id__in"];
              setFilters({ ...tempF });
            }
          }}
          value={
            jobs
              ? jobs.filter((x) =>
                  filters["job__id__in"]
                    ? filters["job__id__in"].includes(x.id)
                    : false
                )
              : []
          }
          getOptionLabel={(option) => option.title || ""}
          size="small"
          multiple
          fullWidth
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.title}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder="Choose Job"
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                backgroundColor: "#F7F7F7",
              }}
            />
          )}
          ChipProps={{
            sx: { backgroundColor: colors.primary, color: colors.secondary },
          }}
        />
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Autocomplete
          id="combo-box-demo"
          autoComplete
          options={statuses}
          onChange={(_, value) => {
            if (value) {
              setFilter("status", value.id);
            } else {
              const tempF = { ...filters };
              delete tempF["status"];
              setFilters({ ...tempF });
              setStatusFilter({});
            }
          }}
          value={statusFilter}
          getOptionLabel={(option) => option.name || ""}
          size="small"
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder="Choose Status"
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                backgroundColor: "#F7F7F7",
              }}
            />
          )}
        />
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Grid
          container
          spacing={0.5}
          sx={{
            justifyContent: { xs: "flex-start", sm: "flex-end" },
            alignItems: "center",
          }}
        >
          <Grid item>
            <Typography fontSize="14px" color={colors.gray}>
              Sort by :
            </Typography>
          </Grid>
          <Grid item>
            <NoBorderDropdown
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              bgColor={colors.secondary}
              textColor={colors.gray}
              fontSize="14px"
              dropdownArr={[
                { id: "latest", name: "Latest" },
                { id: "oldest", name: "Oldest" },
              ]}
            />
          </Grid>
          {/* <Grid item>
            <Button
              id="demo-customized-button"
              aria-controls={
                openExportMenu ? "demo-customized-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={openExportMenu ? "true" : undefined}
              size="small"
              disableElevation
              onClick={handleExportClick}
              endIcon={
                anchorEl ? (
                  <KeyboardArrowUp fontSize="small" color="primary" />
                ) : (
                  <KeyboardArrowDown fontSize="small" color="primary" />
                )
              }
              sx={{ fontSize: "14px" }}
            >
              Export
            </Button>
            <Menu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openExportMenu}
              onClose={handleExportMenuClose}
            >
              <MenuItem onClick={handleExportMenuClose}>Excel</MenuItem>
              <MenuItem onClick={handleExportMenuClose}>CSV</MenuItem>
            </Menu>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Filters;
