import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Menu, Login } from "@mui/icons-material";
import SearchResults from "./SearchResults";
import Footer from "../HomePage/Footer";
import LoginDrawer from "../HomePage/LoginDrawer";
import ciel_logo_colored from "../../assets/imagesNew/ciel_logo_colored.png";
import theme from "../../styles/theme";
import colors from "../../styles/colors";

import queryString from "query-string";
import { useQuery } from "@apollo/client";
import { GET_ALL_JOB_CANDIDATES } from "../../graphql/jobs";
import { GET_CITIES_JOBTYPES } from "../../graphql/meta";

const drawerWidth = 240;
const navItems = [
  { title: "Employers", to: "/" },
  { title: "About Us", to: "https://www.cielhr.com/about-us/", external: true },
];

function HomePageSearchCandidates(props) {
  const { window } = props;

  const location = useLocation();
  const [selectedCity, setSelectedCity] = useState(undefined);
  const [selectedJobType, setSelectedJobType] = useState(undefined);
  const [searchText, setSearchText] = useState("");
  const [candidatesList, setCandidatesList] = useState([]);
  const [candidatesCount, setCandidatesCount] = useState(0);
  const [queryText, setQueryText] = useState("");

  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [loginDrawer, setLoginDrawer] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { data: dropDownData } = useQuery(GET_CITIES_JOBTYPES, {
    variables: {},
  });

  useEffect(() => {
    if (location.search) {
      const parsedQuery = queryString.parse(location.search);
      let { city, job, search } = parsedQuery;

      if (city === "0") {
        city = undefined;
      }

      if (job === "0") {
        job = undefined;
      }

      setSelectedCity(city);
      setSelectedJobType(job);
      setSearchText(search);
    } else {
      history.push("/");
    }
  }, [history, location.search]);

  useEffect(() => {
    if (dropDownData) {
      const { allCities, jobTypes } = dropDownData;
      let city = "";
      let jobType = "";
      if (selectedCity) {
        const cities = allCities.filter((c) => c.id === selectedCity);
        city = cities?.length > 0 ? cities[0].name : "";
      }

      if (selectedJobType) {
        const types = jobTypes.filter((c) => c.id === selectedJobType);
        jobType = types?.length > 0 ? types[0].name : "";
      }

      let query = [];
      if (jobType) {
        query = [...query, jobType];
      }

      if (city) {
        query = [...query, city];
      }

      setQueryText(query.join(" | "));
    }
  }, [dropDownData]);

  const { data: candidates } = useQuery(GET_ALL_JOB_CANDIDATES, {
    variables: {
      job: selectedJobType,
      city: selectedCity,
      search: searchText,
    },
    skip: !selectedCity && !selectedJobType,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (candidates?.getJobCandidates?.count > 0) {
      let jobCandidates = candidates?.getJobCandidates?.jobcandidates || [];

      jobCandidates = jobCandidates.map((jc) => {
        const { candidate, createdDate } = jc;
        const { firstName, qualification, experience, city, id, skills } =
          candidate;

        const { name: cityName } = city;

        const { name: qualificationName } = qualification || {};

        return {
          id,
          firstName,
          lastName: "***",
          experience,
          city: cityName,
          createdDate,
          skills,
          qualification: qualificationName,
        };
      });

      setCandidatesCount(candidates?.getJobCandidates?.count);
      setCandidatesList(jobCandidates);
    }
  }, [candidates]);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img
        src={ciel_logo_colored}
        alt="logo"
        style={{ height: 40, marginBottom: 15, marginTop: 15 }}
      />
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem
            key={item.title}
            disablePadding
            sx={{
              borderLeft: index === 0 ? `3px solid ${colors.primary}` : "none",
            }}
          >
            <ListItemButton onClick={() => history.push("/")}>
              <ListItemText
                primary={item.title}
                sx={{ color: index === 0 ? colors.primary : colors.gray }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => setLoginDrawer(true)}>
            <ListItemIcon>
              <Login fontSize="small" sx={{ color: colors.gray }} />
            </ListItemIcon>
            <ListItemText
              primary="Login"
              sx={{ color: colors.gray, fontSize: "15px" }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        color="secondary"
        elevation={0}
        sx={{ px: { xs: 0, sm: 3 } }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <Menu />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <img
              src={ciel_logo_colored}
              alt="logo"
              style={{ height: matchesSm ? 30 : 50 }}
            />
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" }, flexGrow: 1 }}>
            {navItems.map((item, index) =>
              item.external ? (
                <a
                  key={item.title}
                  href={item.to}
                  target="_blank"
                  style={{
                    color: index === 0 ? colors.primary : colors.gray,
                    fontSize: "15px",
                    fontWeight: 500,
                    marginRight: index === 0 || index === 1 ? 15 : 0,
                  }}
                >
                  {item.title}
                </a>
              ) : (
                <NavLink
                  key={item.title}
                  to={item.external ? { pathname: item.to } : item.to}
                  style={{
                    color: index === 0 ? colors.primary : colors.gray,
                    fontSize: "15px",
                    fontWeight: 500,
                    marginRight: index === 0 || index === 1 ? 15 : 0,
                  }}
                >
                  {item.title}
                </NavLink>
              )
            )}
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button size="small" onClick={() => setLoginDrawer(true)}>
              Login
            </Button>
            <Button
              size="small"
              onClick={() => history.push("/employer-registration")}
            >
              Sign Up
            </Button>
          </Box>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Button
              size="small"
              onClick={() => history.push("/employer-registration")}
            >
              Sign Up
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ width: "100%" }}>
        <Toolbar />
        <SearchResults
          candidates={candidatesList}
          candidatesCount={candidatesCount}
          query={queryText}
          setLoginDrawer={setLoginDrawer}
        />
        <Box
          sx={{
            backgroundColor: colors.purple2,
            px: { xs: 3, sm: 15 },
            py: { xs: 5, sm: 10 },
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item lg={10} md={10} sm={10} xs={12}>
              <Typography color="secondary" fontSize="50px" fontWeight={700}>
                Explore all the things in CIEL Jobs
              </Typography>
            </Grid>
            <Grid
              item
              lg={2}
              md={2}
              sm={2}
              xs={12}
              display="flex"
              justifyContent={{ xs: "flex-start", sm: "flex-end" }}
            >
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                sx={{ color: colors.purple2 }}
                onClick={() => history.push("/employer-registration")}
              >
                Sign Up
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Footer />
        {loginDrawer && (
          <LoginDrawer open={loginDrawer} setOpen={setLoginDrawer} />
        )}
      </Box>
    </Box>
  );
}

export default HomePageSearchCandidates;
