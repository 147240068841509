import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import {
  APPROVE_CANDIDATE,
  GET_APPLICANT_RESUME,
  GET_QR_APPLICANTS,
  GET_RESUME,
} from "../../graphql/users";
import moment from "moment";
import { Modal, ModalFooter } from "react-bootstrap";
import { downloadAny } from "../../utils";

function ApproveCandidate() {
  const [bulkSelectedCandidates, setBulkSelectedCandidates] = useState([]);
  const [cancelModal, setCancelModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [user, setUser] = useState([]);
  const { data: candidates, refetch, loading:loadingApprove } = useQuery(GET_QR_APPLICANTS, {
    payload: {
      variables: {},
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });
  const [updateStatusToApproved] = useMutation(APPROVE_CANDIDATE, {
    onCompleted: (data) => {
      const { updateStatusApplicant } = data || {};
      const { status, error } = updateStatusApplicant;
      if (status) {
        console.log("Success Approved");
        refetch();
      } else {
        setErrorMsg(error);
        setErrorModal(true);
        console.log("error", error);
      }
    },
  });
  function candidateAction(id, status) {
    console.log("id------------->", id, status);
    if (status === 14)
      updateStatusToApproved({
        variables: {
          payload: {
            id: [id],
            status: 14,
          },
        },
      });
    else {
      updateStatusToApproved({
        variables: {
          payload: {
            id: [id],
            status: 9,
          },
        },
      });
    }
    // refetch();
  }

  const handleBulkCandidate = () => {
    updateStatusToApproved({
      variables: {
        payload: {
          id: bulkSelectedCandidates,
          status: 14,
        },
      },
    });
    // refetch();
  };
  console.log(candidates);

  const CandidatesList = ({ user }) => {
    console.log(user);

    const [getApplicantResume, { loading, data }] = useLazyQuery(
      GET_APPLICANT_RESUME,
      {
        nextFetchPolicy: "network-only",
        fetchPolicy: "network-only",
      }
    );

    const downloadResume = async (userId) => {
      let temp = await getApplicantResume({
        variables: {
          userId: userId,
        },
      });
      let data = temp.data.getApplicantResume;
      downloadAny(data.file, data.fileName, data.fileType);
    };

    return (
      <>
        <tr>
          <td className="w40">
            <label className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                name="example-checkbox1"
                // defaultValue="option1"
                value={user?.id}
                checked={
                  bulkSelectedCandidates.indexOf(user?.id) !== -1 ? true : false
                }
                onChange={(event) => {
                  let arr = [...bulkSelectedCandidates];
                  if (event.target.checked) {
                    arr.push(event.target.value);
                  } else if (!event.target.checked) {
                    arr = arr.filter((itm) => itm !== event.target.value);
                  }
                  setBulkSelectedCandidates(arr);
                }}
              />
              <span className="custom-control-label">&nbsp;</span>
            </label>
          </td>
          <td>{user.firstName}</td>
          <td>{user.lastName}</td>
          <td>
            {user.gender === "M"
              ? "Male"
              : user.gender === "F"
              ? "Female"
              : "Others"}
          </td>
          <td>{user.qualification.name}</td>
          <td>{user.location.name}</td>
          <td>{user.dob ? moment(user.dob).format("DD MMM, YYYY") : ""}</td>
          <td>
            <div className="d-flex">
              {user?.resume && (
                <>
                  <button
                    type="button"
                    className="btn btn-icon"
                    title="Resume"
                    onClick={() => {
                      downloadResume(user.id);
                    }}
                  >
                    <i className="fa fa-download" />
                  </button>
                </>
              )}
              <button
                type="button"
                className="btn btn-icon"
                title="Approve"
                onClick={() => candidateAction(user.id, 14)}
              >
                <i className="fa fa-thumbs-up" color="green" />
              </button>
              <button
                type="button"
                className="btn btn-icon"
                title="Reject"
                onClick={() => setCancelModal(true)}
              >
                <i className="fa fa-thumbs-down" color="red" />
              </button>
            </div>
          </td>
        </tr>
        <Modal show={cancelModal}>
          <Modal.Header>Reject</Modal.Header>
          <Modal.Body>Are you sure?</Modal.Body>
          <ModalFooter>
            <button
              className="btn btn-primary"
              onClick={() => {
                candidateAction(user.id, 9);
                setCancelModal(false);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => setCancelModal(false)}
            >
              No
            </button>
          </ModalFooter>
        </Modal>
      </>
    );
  };

  return (
    <div className="container-fluid">
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <div className="card">
          <div className="card-body">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="col w40">#</th>
                  <th className="col w100">First Name</th>
                  <th className="col w100">Last Name</th>
                  <th className="col w100">Gender</th>
                  <th className="col w100">Qualification</th>
                  <th className="col w100">Location</th>
                  <th className="col w100">Dob</th>
                  <th className="col w100">Actions</th>
                </tr>
              </thead>
              <tbody>
              {loadingApprove && (
                            <td colSpan={10} style={{ textAlign: "center" }}>
                              <p style={{ font: 15 }}>Loading... </p>
                              <div
                                class="spinner-border ml-auto"
                                role="status"
                                aria-hidden="true"
                              ></div>
                            </td>
                          )}
                {candidates && !loadingApprove &&
                  candidates?.getApplicantCandidate.count > 0 &&
                  candidates?.getApplicantCandidate?.users.map((user) => (
                    <CandidatesList
                      user={user}
                      key={user.Id}
                      candidateAction={candidateAction}
                    />
                  ))}
                {!loadingApprove && candidates && candidates?.getApplicantCandidate.count === 0 && (
                  <tr>
                    <td colSpan="8" style={{ textAlign: "centre" }}>
                      No candidates to approve
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleBulkCandidate}
              disabled={bulkSelectedCandidates.length <= 0 ? true : false}
            >
              Approve
            </button>
          </div>
        </div>
      </div>
      <Modal show={errorModal} onHide={() => setErrorModal(false)}>
        <Modal.Header>
          <Modal.Title>Fail</Modal.Title>
          <button
            type="button"
            class="close"
            aria-label="Close"
            onClick={() => setErrorModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>{errorMsg}</Modal.Body>
        <ModalFooter>
          <button
            className="btn btn-primary"
            onClick={() => {
              setErrorModal(false);
            }}
          >
            OK
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ApproveCandidate;
