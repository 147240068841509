import React, { useState } from "react";
import { OFFERED_STATUS_UPDATE } from "../../graphql/jobs";
import { Modal } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { Formik, withFormik } from "formik";
import * as Yup from "yup"

const OfferedModalForm=(props)=>{

  const {
    updateOffered,
    setupdateOffered,
    values,
    touched,
    errors,
    selectedJobPayload,
    handleSubmit,
    handleChange
}=props
  
return(
  <Modal show={updateOffered} hide={()=>setupdateOffered(false)} >
        <Modal.Header>
            <Modal.Title>Offer Details</Modal.Title>
            <button
            type="button"
            class="close"
            aria-label="Close"
            onClick={() => setupdateOffered(false)}
            >
          <span aria-hidden="true">&times;</span>
        </button>
        </Modal.Header>
        <Modal.Body>
          
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>Job Title</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Job Title"
                style={{ border: "1PX #7C7B7B solid" }}
                value={selectedJobPayload?.title}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>Employeer Name</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Employer name"
                style={{ border: "1PX #7C7B7B solid" }}
                value={selectedJobPayload?.organization?.name}
                disabled
              />
            </div>
          </div>
        </div>
        
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>Designation</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <input
                type="text"
                id="designation"
                className="form-control"
                placeholder="Designation"
                style={{ border: "1PX #7C7B7B solid" }}
                onChange={handleChange}
              />
            </div>
            {touched.designation && (
                <span style={{ color: "red", fontSize: 13 }}>
                  {errors.designation}
                </span>)}
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>CTC (Per annum)</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <input
                type="number"
                className="form-control"
                id="ctc"
                placeholder="CTC"
                style={{ border: "1PX #7C7B7B solid" }}
                onChange={handleChange}
              />
              {touched.ctc && (
                <span style={{ color: "red", fontSize: 13 }}>
                  {errors.ctc}
                </span>)}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>Work Location</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="location"
                style={{ border: "1PX #7C7B7B solid" }}
                value={selectedJobPayload?.location?.name}
                disabled

              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>Expected DOJ</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <input
                type="date"
                id="doj"
                className="form-control"
                placeholder="DOJ"
                style={{ border: "1PX #7C7B7B solid" }}
                onChange={handleChange}
              />
              
            </div>
            {touched.doj && (
                <span style={{ color: "red", fontSize: 13 }}>
                  {errors.doj}
                </span>)}
          </div>
        </div>
        
        </Modal.Body>
        <Modal.Footer>
        <button type="button" class="btn btn-primary" onClick={handleSubmit}>
          Update
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          onClick={() => setupdateOffered(false)}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}




const OfferedModal=(props)=>{

    const {
        setupdateOffered,
        updateOffered,
        selectedJobPayload,
        selectedCandidate,
        selectedCandidateData,
        refreshJobCandidates

    }=props
    console.log(selectedJobPayload?.location?.name)
    console.log({selectedCandidate})
    //graphQL
    const [changeStatusOffered]=useMutation(OFFERED_STATUS_UPDATE,{
      onCompleted:(data)=>{
        const {updateStatusToOffered}=data||{};
        const {status,error}=updateStatusToOffered;
        console.log("OFFERED",status,error)
        if(status)
        {
          console.log("OFFERED SUCCESSFULL")
          setupdateOffered(false)
        }
      },
      fetchPolicy:"network-only",
      nextFetchPolicy:"network-only"
    });
    

    const OfferedModalWrapped = withFormik({
      mapPropsToValues: () => {
        return {
         ctc:"",
         doj:"",
         designation:"",
         location:""
        };
      },
    
      validationSchema: Yup.object().shape({
        
        designation: Yup.string().required("Please enter the designation"),
        ctc:Yup.number().required("Please Enter the CTC").positive("CTC can't be negative"),
        doj: Yup.date()
          .required("Please enter the date of join")
          .min(moment().format("YYYY-MM-DD")),
      }),
    
      handleSubmit(values,{props}){
        console.log(">>>>>>>>>>>>>valuesofferd",props);
    
        changeStatusOffered({
          variables: {
           payload: [
              {
                id:selectedCandidate,
                    ...values
              },
            ],
          },
        })
        console.log("refreshing")
        refreshJobCandidates()
        setupdateOffered(false)
        
      },
    })(OfferedModalForm);
return(
    <OfferedModalWrapped
    setupdateOffered={setupdateOffered}
    updateOffered={updateOffered}
    selectedCandidate={selectedCandidate}
    selectedJobPayload={selectedJobPayload}
    refreshJobCandidates={refreshJobCandidates}
    />
)
}

export default OfferedModal