import React, { useEffect, useRef, useState } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { useQuery, useMutation } from '@apollo/client';
import { UPDATE_COMMUNITY, ADD_COMMUNITY } from '../../graphql/community';
import { Modal } from 'react-bootstrap';

const AddCommunityForm = (props) => {

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        onSubmit,
        handleBlur,
        selectedCommunityData,
        addCommunityView,
        setAddCommunityView
        // handleClose,
        // modalOpen
    } = props;

    console.log('>>>>>>>>>>>>>errors', selectedCommunityData);

    return (
        <div>
            <div className="container-fluid" style={{ marginLeft: 20 }}>
                <div className="d-flex justify-content-between align-items-center">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a onClick={() => setAddCommunityView(false)}><span style={{ fontSize: 14, cursor: 'pointer' }}>Communities</span></a></li>
                            <li class="breadcrumb-item"><a><span style={{ fontSize: 14 }}>{selectedCommunityData?.id ? `${selectedCommunityData.title || ''}` : 'New'}</span></a></li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="section-body mt-3">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-flex-start">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <span>Title <span style={{ color: 'red' }}>*</span></span>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="title"
                                            className="form-control"
                                            placeholder="Title"
                                            style={{ border: '1PX #7C7B7B solid' }}
                                            onChange={handleChange}
                                            value={values.title}
                                        // onBlur={handleBlur}
                                        />
                                        {touched.title && <span style={{ fontSize: 12, color: 'red' }}>{errors.title}</span>}
                                    </div>
                                </div>
                            </div>
                            < div className="d-flex justify-content-flex-start">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <span>Description <span style={{ color: 'red' }}>*</span></span>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="form-group">
                                        <textarea
                                            type="text"
                                            id="description"
                                            className="form-control"
                                            placeholder="Description"
                                            style={{ border: '1PX #7C7B7B solid' }}
                                            onChange={handleChange}
                                            value={values.description}
                                        // onBlur={handleBlur}
                                        />
                                        {touched.description && <span style={{ fontSize: 12, color: 'red' }}>{errors.description}</span>}
                                    </div>
                                </div>
                            </div>
                            {!selectedCommunityData?.id && <>
                                <div className="col-12">
                                    <button type="button" className="btn btn-primary" onClick={handleSubmit} style={{ marginTop: 15 }}>Add</button>
                                    <button type="button" className="btn btn-secondary" onClick={() => setAddCommunityView(false)} style={{ marginTop: 15, marginLeft: 5 }}>Cancel</button>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            {selectedCommunityData?.id && <div className="section-body mt-3">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group">
                                <label className='font-weight-bold' style={{ fontSize: 20 }}>JOINEES</label>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Mobile no</th>
                                            <th>Location</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedCommunityData && selectedCommunityData?.users && selectedCommunityData?.users.map((user) => (
                                            <tr>
                                                <td>
                                                    <div className='d-flex justfy-content-between align-items-center'>
                                                        <span className='avatar avatar-cyan'>{user?.firstName?.substring(0, 1) || '-'}</span>
                                                        <h6 className='mb-0' style={{ marginLeft: 5 }}>{user?.firstName || ""} {user?.lastName || ""}</h6>
                                                    </div>
                                                </td>
                                                <td>{user?.mobile || '-'}</td>
                                                <td>{user?.city?.name || '-'}, {user?.state?.name || '-'}, {user?.country?.name || '-'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {/* <Pagination page={page} pageSize={pageSize} rowCount={rowCount} setPage={setPage} setPageSize={setPageSize} /> */}
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={() => setAddCommunityView(false)} style={{ marginLeft: 5 }}>Cancel</button>
                    </div>
                </div>
            </div>}
        </div >
    );
}

const AddCommunity = (props) => {

    const { selectedCommunityData, handleClose, openModal, refetchCommunities, addCommunityView, setAddCommunityView } = props;

    const [addCommunity] = useMutation(ADD_COMMUNITY, {
        onCompleted: (data) => {
            const { createCommunity } = data || {};
            const { status, error } = createCommunity;
            console.log({ status, error });
            if (status) {
                refetchCommunities()
                setAddCommunityView(false)
            }
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    })

    const [updateCommunity] = useMutation(UPDATE_COMMUNITY, {
        onCompleted: (data) => {
            const { updateCommunity } = data || {};
            const { status, error } = updateCommunity;
            console.log({ status, error })
            if (status) {
                refetchCommunities()
                setAddCommunityView(false)
            }
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    })

    const AddCommunityWrapped = withFormik({
        mapPropsToValues: (props) => {

            const { selectedCommunityData } = props;

            return {
                title: selectedCommunityData?.title || '',
                id: selectedCommunityData?.id || null,
                description: selectedCommunityData?.description || '',
            }
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().required("Please enter a title"),
            description: Yup.string().required("Please enter what this community is about"),

        }),

        handleSubmit(values, { props }) {
            console.log('>>>>>>>>>>>>>values', values, props);

            const { selectedCommunityData } = props;

            if (selectedCommunityData?.id) {
                updateCommunity({
                    variables: {
                        payload: {
                            ...values,
                        }
                    }
                })
            }
            else {
                const { id, ...rest } = values;
                addCommunity({
                    variables: {
                        payload: {
                            ...rest,

                        }
                    }
                })
            }
        },
    })(AddCommunityForm);

    return <AddCommunityWrapped selectedCommunityData={selectedCommunityData} addCommunityView={addCommunityView} setAddCommunityView={setAddCommunityView} />;

}

export default AddCommunity;