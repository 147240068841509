import { gql } from "@apollo/client";

export const REGISTER_USER = gql`
  mutation registerUser($payload: RegisterUser!) {
    upsertRegisterUser(payload: $payload) {
      status
      error
    }
  }
`;

export const REGISTER_EMPLOYER = gql`
  mutation registerEmployer($payload: UserRegisterInput!) {
    registerEmployerUser(payload: $payload) {
      token
      status
      error
      me {
        id
        email
        firstName
        organization {
          id
          name
        }
      }
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation verifyOtp($payload: MultiFactorAuthInput!) {
    multiFactorLoginCandidate(payload: $payload) {
      token
      status
      error
      me {
        id
        firstName
        lastName
      }
    }
  }
`;

export const RESEND_OTP = gql`
  mutation registerUser($payload: RegisterUser!) {
    upsertRegisterUser(payload: $payload) {
      status
      error
    }
  }
`;
