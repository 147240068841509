import { gql } from "@apollo/client";

export const CREATE_CENTRE = gql`
mutation createCentre($payload: CentreCreateInput!){
    createCentre(payload: $payload){
      status
      error
    }
  }
`

export const UPDATE_CENTRE = gql`
mutation updateCentre($payload: CentreUpdateInput!){
    updateCentre(payload: $payload){
      status
      error
    }
  }
`

export const DELETE_CENTRE = gql`
mutation deleteCentre($ids: [String!]){
    deleteCentre(ids: $ids){
      status
      error
    }
  }
`