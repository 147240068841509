import { gql } from "@apollo/client";

export const GET_ORGANIZATIONS = gql`
query organizations($filter: OrganizationFilterInput) {
  organizations(filter: $filter) {
      name
      id
      description
      city {
        id 
        name
      }
      state  {
        id
        name
      }
      country{
        id 
        name
      }
      organizationType
      profile
      jobs
  }
}
`;


export const GET_ORGANIZATION = gql`
query organization($id: String) {
  organization(id: $id) {
    id
    name
    description
    website
    address
    organizationType
    location {
      id
      name
    }
    city {
      id
      name
    }
    state {
      id
      name
    }
    country {
      id
      name
    }
    industry {
      id
      name
    }
    pincode 
    employees
    contact
    designation
    contact_email
    contact_phone
    billing_address
    shipping_address
    gst_number
    lob
    rate_mobilization
    rate_recruitment
    rate_placement
    percentage_recruitment
    profile
  }
}
`;


export const ADD_ORGANIZATION = gql`
  mutation createOrganization($payload: OrganizationCreateInput!) {
    createOrganization(payload: $payload) {
      status
      error
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($payload: OrganizationUpdateInput!) {
    updateOrganization(payload: $payload) {
      status
      error
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation deleteOrganization($ids: [String!]) {
    deleteOrganization(ids: $ids) {
      status
      error
    }
  }
`;

export const ARCHIVE_ORGANIZATION = gql`
  mutation archiveOrganization($ids: [String!]) {
    archiveOrganization(ids: $ids) {
      status
      error
    }
  }
`;

export const UNARCHIVE_ORGANIZATION = gql`
  mutation unarchiveOrganization($ids: [String!]) {
    unarchiveOrganization(ids: $ids) {
      status
      error
    }
  }
`;