import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import Modal from "../../common/CommonModal";
import { clearSession } from "../../utils";
import logout_img from "../../assets/imagesNew/logout-img.svg";
import colors from "../../styles/colors";

const LogoutModal = (props) => {
  const { open, setOpen } = props;

  const history = useHistory();
  return (
    <Modal open={open} setOpen={setOpen}>
      <Grid container spacing={2}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <img src={logout_img} alt="logout" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            size="26px"
            fontWeight={700}
            color={colors.dark}
            textAlign="center"
          >
            Oops!
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography textAlign="center">
            Are you sure you want to logout ?
          </Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={6}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            size="small"
            variant="outlined"
            sx={{ width: "60%" }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={6}
          onClick={() => {
            clearSession();
            history.push("/");
          }}
        >
          <Button size="small" variant="contained" sx={{ width: "60%" }}>
            Logout
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default LogoutModal;
