import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { UPDATE_REVENUE } from "../../graphql/jobs";
import { useMutation } from "@apollo/client";
import { withFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { UPDATE_JOB_CANDIDATE } from "../../graphql/jobs";

const PlacedModalForm = (props) => {
  const {
    placedModal,
    setPlacedModal,
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    selectedJobPayload,
    selectedCandidateData,
  } = props;

  useEffect(() => {
    console.log({ values });
  }, [values]);

  
  return (
    <Modal show={placedModal} onHide={() => setPlacedModal(false)}>
      <Modal.Header>
        <Modal.Title>Complete Booking</Modal.Title>
        <button
          type="button"
          class="close"
          aria-label="Close"
          onClick={() => setPlacedModal(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: 500, overflowY: "auto" }}>
        <div className="d-flex justify-content-between">
          <label 
            className="col-lg-12 col-md-12 col-sm-12 font-italic"
          >
            <small>
              Instructions: Please complete the booking process for the candidate who joined the employer
              </small>
          </label>
        </div>
        <div className="d-flex justify-content-between">
          <label 
            className="col-lg-12 col-md-12 col-sm-12 pt-1 pb-1 font-weight-bold"
            style={{
              background: "rgba(58, 34, 85, 0.1)",
              marginBottom: "1rem",
            }}
          >
            Job Details
          </label>
        </div>
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>Job Title</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Job Title"
                style={{ border: "1PX #7C7B7B solid" }}
                value={selectedJobPayload?.title}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>Work Location</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Work Location"
                style={{ border: "1PX #7C7B7B solid" }}
                value={`${selectedJobPayload?.city?.name}, ${selectedJobPayload?.state?.name}, ${selectedJobPayload?.country?.name}`}
                disabled
              />
            </div>
          </div>
        </div>
        {/* <div className="d-flex justify-content-flex-start">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <span>Work Location</span>
                    </div>
                    <span className='font-16' style={{ marginLeft: 5, marginBottom: 15 }}>{selectedJobPayload?.city?.name}, {selectedJobPayload?.state?.name}, {selectedJobPayload?.country?.name}</span>
                </div> */}
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>CTC Offered</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group d-flex justify-content-flex-start align-items-center">
              <input
                type="number"
                className="form-control"
                placeholder="CTC"
                style={{ border: "1PX #7C7B7B solid" }}
                id="ctc"
                onChange={handleChange}
                value={values.ctc}
              />
              {touched.ctc && (
                <span style={{ fontSize: 12, color: "red" }}>{errors.ctc}</span>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>Date of Joining</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <input
                type="date"
                className="form-control"
                placeholder="DOJ"
                style={{ border: "1PX #7C7B7B solid" }}
                id="doj"
                onChange={handleChange}
                value={values.doj}
              />
              {touched.doj && (
                <span style={{ fontSize: 12, color: "red" }}>{errors.doj}</span>
              )}
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-flex-start"
          style={{ marginTop: 10 }}
        >
          <div className="form-group col-lg-4 col-md-4 col-sm-12">
            <span>
              Which booking would you like to do?
            </span>
          </div>
          <div className="form-group col-lg-8 col-md-8 col-sm-12" style={{ marginLeft: 10 }}>
            <form
              id="billingChoiceType"
              onChange={(e) => {
                if(e.target.name === "both"){
                  setFieldValue("placement", JSON.parse(e.target.checked));
                  setFieldValue("recruitment", JSON.parse(e.target.checked));
                }
                else if(!e.target.checked){
                  
                  setFieldValue("both", JSON.parse(e.target.checked));
                }
                setFieldValue(e.target.name, JSON.parse(e.target.checked));

                // if (e.target.value === 'percent') {
                //     setFieldValue('rateRecruitment', 0)
                // }
                // else if (e.target.value === 'value') {
                //     setFieldValue('percentageRecruitment', 0)
                // }
              }}
            >
              <input
                type="checkbox"
                id="placement"
                name="placement"
                checked={values.placement === true}
              />
              <label for="placement" style={{ marginLeft: 5 }}>
                Placement
              </label>
              <input
                type="checkbox"
                id="recruitment"
                name="recruitment"
                style={{ marginLeft: 10 }}
                checked={values.recruitment === true}
              />
              <label for="recruitment" style={{ marginLeft: 5 }}>
                Selection
              </label>
              {/* <input
                type="checkbox"
                id="both"
                name="both"
                checked={values.both === true}
                style={{ marginLeft: 10 }}
              />
              <label for="both" style={{ marginLeft: 5 }}>
                Both
              </label> */}
            </form>
          </div>
        </div>
        {(values.placement) && (
          <>
            <div className="d-flex justify-content-between">
              <label 
                className="col-lg-12 col-md-12 col-sm-12 pt-1 pb-1 font-weight-bold"
                style={{
                  background: "rgba(58, 34, 85, 0.1)",
                  marginBottom: "1rem",
                }}
              >
                Placement Booking
              </label>
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <span>Sourced By</span>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Job Title"
                    style={{ border: "1PX #7C7B7B solid" }}
                    value={selectedCandidateData?.candidate?.source === 1
                      ? "PIA"
                      : selectedCandidateData?.candidate?.source === 2
                      ? "Mobilization for PIA"
                      : selectedCandidateData?.candidate?.source === 3
                      ? "Mobile App"
                      : "Others"}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <span>PIA Name</span>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Job Title"
                    style={{ border: "1PX #7C7B7B solid" }}
                    value={selectedCandidateData?.candidate?.organization?.name || ""}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <span>Service</span>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Job Title"
                    style={{ border: "1PX #7C7B7B solid" }}
                    value={"Placement"}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <span>PIA Rate/Candidate (Rs.)</span>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Job Title"
                    style={{ border: "1PX #7C7B7B solid" }}
                    value={
                      selectedCandidateData?.candidate?.organization
                        ?.rate_placement || 0
                    }
                    disabled
                  />
                </div>
              </div>
            </div>
            {/*
            <div
              className="d-flex justify-content-flex-start"
              style={{ marginLeft: 8, marginTop: 10 }}
            >
              <div className="form-group">
                <span style={{ fontSize: 14 }}>
                  Do you want to bill the employer as well?
                </span>
              </div>
              <div className="form-group" style={{ marginLeft: 10 }}>
                <form
                  id="billingChoice"
                  onChange={(e) => {
                    setFieldValue("billingChoice", JSON.parse(e.target.value));
                    // if (e.target.value === 'percent') {
                    //     setFieldValue('rateRecruitment', 0)
                    // }
                    // else if (e.target.value === 'value') {
                    //     setFieldValue('percentageRecruitment', 0)
                    // }
                  }}
                >
                  <input
                    type="radio"
                    id="yes"
                    name="billingChoice"
                    value="true"
                    checked={values.billingChoice === true}
                  />
                  <label for="yes" style={{ fontSize: 13, marginLeft: 5 }}>
                    Yes
                  </label>
                  <input
                    type="radio"
                    id="no"
                    name="billingChoice"
                    value="false"
                    style={{ marginLeft: 10 }}
                    checked={values.billingChoice === false}
                  />
                  <label for="no" style={{ fontSize: 13, marginLeft: 5 }}>
                    No
                  </label>
                </form>
              </div>
            </div>
            {values.billingChoice === true && (
              <>
                <div
                  className="d-flex justify-content-flex-start"
                  style={{ marginLeft: 8, marginTop: -5 }}
                >
                  <div className="form-group">
                    <span style={{ fontSize: 14 }}>Specify rate</span>
                  </div>
                  <div className="form-group" style={{ marginLeft: 10 }}>
                    <form
                      id="rate_format"
                      onChange={(e) => {
                        setFieldValue("rate_format", e.target.value);
                        if (e.target.value === "percent") {
                          setFieldValue("ratePlacement", 0);
                        } else if (e.target.value === "value") {
                          setFieldValue("percentagePlacement", 0);
                        }
                      }}
                    >
                      <input
                        type="radio"
                        id="value"
                        name="rate_format"
                        value="value"
                        checked={values.rate_format === "value"}
                      />
                      <label
                        for="value"
                        style={{ fontSize: 13, marginLeft: 5 }}
                      >
                        By Value
                      </label>
                      <input
                        type="radio"
                        id="percent"
                        name="rate_format"
                        value="percent"
                        style={{ marginLeft: 10 }}
                        checked={values.rate_format === "percent"}
                      />
                      <label
                        for="percent"
                        style={{ fontSize: 13, marginLeft: 5 }}
                      >
                        By Percentage
                      </label>
                    </form>
                  </div>
                </div>
                {values.rate_format === "value" && (
                  <div style={{ marginTop: -10 }}>
                    <div className="form-group d-flex justify-content-flex-start align-items-center">
                      <input
                        type="number"
                        className="form-control col-lg-6 col-md-6 col-sm-12"
                        placeholder="Employer Rate"
                        style={{ border: "1PX #7C7B7B solid", marginLeft: 10 }}
                        id="ratePlacement"
                        onChange={handleChange}
                        value={
                          values.ratePlacement === 0 ? "" : values.ratePlacement
                        }
                      />
                      <span style={{ marginLeft: 10, fontSize: 14 }}>
                        Rs. per Candidate
                      </span>
                    </div>
                  </div>
                )}
                {values.rate_format === "percent" && (
                  <div style={{ marginTop: -10 }}>
                    <div className="form-group d-flex justify-content-flex-start align-items-center">
                      <input
                        type="number"
                        className="form-control col-lg-6 col-md-6 col-sm-12"
                        placeholder="Employer Rate"
                        style={{ border: "1PX #7C7B7B solid", marginLeft: 10 }}
                        id="percentagePlacement"
                        onChange={handleChange}
                        value={
                          values.percentagePlacement === 0
                            ? ""
                            : values.percentagePlacement
                        }
                      />
                      <span style={{ marginLeft: 10, fontSize: 14 }}>
                        % of Candidate CTC
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
                      */}
          </>
        )}
        {selectedCandidateData?.candidate?.organization === null && values.placement && (
          <div class="alert alert-warning mt-2" role="alert">
            No PIA has been selected for this candidate, kindly select a PIA to
            know the placement rate of PIA.
          </div>
        )}
        {(values.recruitment) && (
          <>
            <div className="d-flex justify-content-between">
              <label 
                className="col-lg-12 col-md-12 col-sm-12 pt-1 pb-1 font-weight-bold"
                style={{
                  background: "rgba(58, 34, 85, 0.1)",
                  marginBottom: "1rem",
                }}
              >
                Selection Booking
              </label>
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <span>Sourced By</span>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="form-group">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Job Title"
                    style={{ border: "1PX #7C7B7B solid" }}
                    value={selectedCandidateData?.candidate?.source === 1
                      ? "PIA"
                      : selectedCandidateData?.candidate?.source === 2
                      ? "Mobilization for PIA"
                      : selectedCandidateData?.candidate?.source === 3
                      ? "Mobile App"
                      : "Others"}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <span>Employer Name</span>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Job Title"
                    style={{ border: "1PX #7C7B7B solid" }}
                    value={(selectedCandidateData?.job?.createdBy?.firstName || "") + (` ${selectedCandidateData?.job?.createdBy?.lastName}`  || "")}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <span>Service</span>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Job Title"
                    style={{ border: "1PX #7C7B7B solid" }}
                    value={"Selection"}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div
              className="d-flex justify-content-flex-start"
            >
              <div className="form-group col-lg-4 col-md-4 col-sm-12">
                <span>Employer Rate</span>
              </div>
              <div className="form-group col-lg-8 col-md-8 col-sm-12" style={{ marginLeft: 10 }}>
                <form
                  id="rate_format"
                  onChange={(e) => {
                    setFieldValue("rate_format", e.target.value);
                    if (e.target.value === "percent") {
                      setFieldValue("rateRecruitment", 0);
                    } else if (e.target.value === "value") {
                      setFieldValue("percentageRecruitment", 0);
                    }
                  }}
                >
                  <input
                    type="radio"
                    id="value"
                    name="rate_format"
                    value="value"
                    checked={values.rate_format === "value"}
                  />
                  <label for="value" style={{ fontSize: 13, marginLeft: 5 }}>
                    By Value
                  </label>
                  <input
                    type="radio"
                    id="percent"
                    name="rate_format"
                    value="percent"
                    style={{ marginLeft: 10 }}
                    checked={values.rate_format === "percent"}
                  />
                  <label for="percent" style={{ fontSize: 13, marginLeft: 5 }}>
                    By Percentage
                  </label>
                </form>
              </div>
            </div>
            {values.rate_format === "value" && (
              <div style={{ marginTop: -10 }}>
                <div className="form-group d-flex justify-content-flex-start align-items-center">
                  <div className="form-group col-lg-4 col-md-4 col-sm-12 " style={{ textAlign: "right" }}>
                    <span style={{ fontSize: 13 }}>Amount</span>
                  </div>
                  <div className="form-group col-lg-8 col-md-8 col-sm-12">
                    <input
                      type="number"
                      className="form-control col-lg-6 col-md-6 col-sm-12"
                      placeholder="Selection Rate"
                      style={{ border: "1PX #7C7B7B solid", marginLeft: 10 }}
                      id="rateRecruitment"
                      onChange={handleChange}
                      value={
                        values.rateRecruitment === 0 ? "" : values.rateRecruitment
                      }
                    />
                  </div>
                  
                </div>
              </div>
            )}
            {values.rate_format === "percent" && (
              <div style={{ marginTop: -10 }}>
                <div className="d-flex justify-content-flex-start align-items-center">
                  <div className="form-group col-lg-4 col-md-4 col-sm-12" style={{ textAlign: "right" }}>
                    <span style={{ fontSize: 13 }}>% of Candidate CTC</span>
                  </div>
                  <div className="form-group col-lg-8 col-md-8 col-sm-12">
                    <input
                      type="number"
                      className="form-control col-lg-6 col-md-6 col-sm-12"
                      placeholder="Selection Rate"
                      style={{ border: "1PX #7C7B7B solid", marginLeft: 10 }}
                      id="percentageRecruitment"
                      onChange={handleChange}
                      value={
                        values.percentageRecruitment === 0
                          ? ""
                          : values.percentageRecruitment
                      }
                    />
                  </div>
                  
                </div>
                {values.percentageRecruitment > 0 && values.ctc > 0 &&
                  <>
                  <div className="d-flex justify-content-flex-start align-items-center">
                    <div className="form-group col-lg-4 col-md-4 col-sm-12" style={{ textAlign: "right" }}>
                      <span style={{ fontSize: 13 }}>Selection Amount</span>
                    </div>
                    <div className="form-group col-lg-8 col-md-8 col-sm-12">
                      <span 
                        className="form-control col-lg-6 col-md-6 col-sm-12"
                        style={{ border: "1PX #7C7B7B solid", marginLeft: 10 }}
                      >
                        {values.percentageRecruitment * (values.ctc/100)}
                      </span>
                    </div>
                    
                  </div>
                  </>
                }
              </div>
            )}
          </>
        )}
        
      </Modal.Body>
      <Modal.Footer>
        <button type="button" class="btn btn-primary" onClick={handleSubmit} disabled={(!values.placement && !values.recruitment) || (selectedCandidateData?.candidate?.organization === null && values.placement)}>
          Book Now
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          onClick={() => setPlacedModal(false)}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const PlacedModal = (props) => {
  const {
    placedModal,
    setPlacedModal,
    selectedCandidate,
    selectedJobPayload,
    selectedCandidateData,
    refetchJobCandidates
  } = props;
  console.log({ selectedCandidate });

  const [updateRevenue] = useMutation(UPDATE_REVENUE, {
    onCompleted: (data) => {
      const { updateRevenue } = data || {};
      const { status, error } = updateRevenue;
      console.log("PLACED", status, error);
      if (status) {
        setPlacedModal(false);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [updateJobCandidate] = useMutation(UPDATE_JOB_CANDIDATE, {
    onCompleted: (data) => {
      const { updateJobCandidate } = data || {};
      const { status, error } = updateJobCandidate;
      console.log({ status, error });
      if (status) {
        console.log("refreshing")
        refetchJobCandidates()
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });


  const PlacedModalWrapped = withFormik({
    mapPropsToValues: () => {
      return {
        ctc: "",
        doj: "",
        billingChoice: false,
        rate_format: "value",
        rateRecruitment: 0,
        percentageRecruitment: 0,
        ratePlacement: 0,
        percentagePlacement: 0,
        placement: false,
        recruitment: false,
        both: false
      };
    },

    validationSchema: Yup.object().shape({
      ctc: Yup.string().required("Please enter the CTC offered"),
      doj: Yup.string().required("Please select a DOJ"),
      // billingChoice: Yup.string().required('Please select a billing choice'),
      // rate_format
    }),

    enableReinitialize: true,
    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);

      const profileObj = {
        jobTitle: selectedJobPayload.title,
        workLocation: {
          country: selectedJobPayload.country,
          state: selectedJobPayload.state,
          city: selectedJobPayload.city,
          location: selectedJobPayload.location,
        },
        ctc: values.ctc,
        doj: values.doj,
      };

      console.log({ profileObj });

      // updateUser({
      //     variables: {
      //         payload: {
      //             id: selectedCandidate,
      //             profile: JSON.stringify(profileObj)
      //         }
      //     }
      // })

      // addEvent({
      //     variables: {
      //         payload: {
      //             userId: selectedCandidate,
      //             eventId: "15",
      //             eventDate: moment().format('YYYY-MM-DD'),
      //             comments: `Job Title: ${selectedJobPayload.title} @ ${selectedJobPayload.organization.name}` + " | " + `Work Location: ${selectedJobPayload.city.name}, ${selectedJobPayload.state.name}, ${selectedJobPayload.country.name}` + " | " + `CTC offered: ₹${values.ctc}` + " | " + `DOJ: ${moment(values.doj).format('DD MMM YYYY')}`
      //         }
      //     }
      // })

      updateRevenue({
        variables: {
          payload: [
            {
              id: selectedCandidate,
              ctc: parseFloat(values.ctc),
              doj: values.doj,
              comments:
                `Job Title: ${selectedJobPayload.title} @ ${selectedJobPayload.organization.name}` +
                " | " +
                `Work Location: ${selectedJobPayload.city.name}, ${selectedJobPayload.state.name}, ${selectedJobPayload.country.name}` +
                " | " +
                `CTC offered: ₹${values.ctc}` +
                " | " +
                `DOJ: ${moment(values.doj).format("DD MMM YYYY")}`,
              rateRecruitment: values.rateRecruitment,
              percentageRecruitment: values.percentageRecruitment,
              ratePlacement: values.ratePlacement,
              percentagePlacement: values.percentagePlacement,
              billingChoice: values.billingChoice,
              placement: values.placement,
              recruitment: values.recruitment,
              both: values.both
            },
          ],
        },
      });
      updateJobCandidate({
        variables: {
          payload: {
            id: [selectedCandidate],
            status:13,
          },
        },
      })
    },
  })(PlacedModalForm);

  return (
    <PlacedModalWrapped
      placedModal={placedModal}
      setPlacedModal={setPlacedModal}
      selectedJobPayload={selectedJobPayload}
      selectedCandidateData={selectedCandidateData}
    />
  );
};

export default PlacedModal;
