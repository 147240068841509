import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import {
  GET_COUNTRY,
  GET_STATES,
  GET_CITIES,
  GET_LOCATIONS,
} from "../../graphql/meta";
import { CREATE_CENTRE, UPDATE_CENTRE } from "../../graphql/centres";
import { useQuery, useMutation } from "@apollo/client";
import { Modal } from "react-bootstrap";

const AddNewCentreForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setAddCentreView,
    selectedCentreData,
    handleBlur,
  } = props;

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [errorModal, setErrorModal] = useState(false);

  const { data: countriesDropDown } = useQuery(GET_COUNTRY, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: statesDropDown } = useQuery(GET_STATES, {
    variables: {
      country: selectedCountry,
    },
    skip: !selectedCountry,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: citiesDropDown } = useQuery(GET_CITIES, {
    variables: {
      state: selectedState,
    },
    skip: !selectedState,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: locationsDropDown } = useQuery(GET_LOCATIONS, {
    variables: {
      city: selectedCity,
    },
    skip: !selectedCity,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { cityId, stateId, countryId } = values;

  useEffect(() => {
    if (cityId) {
      setSelectedCity(cityId);
    }
  }, [cityId]);

  useEffect(() => {
    if (countryId) {
      setSelectedCountry(countryId);
    }
  }, [countryId]);

  useEffect(() => {
    if (stateId) {
      setSelectedState(stateId);
    }
  }, [stateId]);

  useEffect(() => {
    console.log({ values });
  }, [values]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (values?.error !== "") {
        setErrorModal(true);
      }
    }, 200)
    return () => clearTimeout(timeout)
  }, [values]);

  return (
    <>
      <div>
      <Modal show={errorModal} onHide={() => setErrorModal(false)}>
          <Modal.Header>
            <Modal.Title>{selectedCentreData?.id ? "Save Fail" : "Add Fail"}</Modal.Title>
            <button
              type="button"
              class="close"
              aria-label="Close"
              onClick={() => setErrorModal(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>{values.error}</Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              class="btn btn-secondary"
              onClick={() => {
                setFieldValue("error", "");
                setErrorModal(false);
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <div className="container-fluid" style={{ marginLeft: 20 }}>
          <div className="d-flex justify-content-between align-items-center">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => setAddCentreView(false)}>
                    <span style={{ fontSize: 14, cursor: "pointer" }}>
                      Centres
                    </span>
                  </a>
                </li>
                <li class="breadcrumb-item">
                  <a>
                    <span style={{ fontSize: 14 }}>
                      {selectedCentreData?.id
                        ? `${selectedCentreData.name || ""}`
                        : "New"}
                    </span>
                  </a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="section-body mt-3">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="form-group">
                    <label>Centre Details</label>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: -20 }}
                >
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name *"
                        style={{ border: "1px #7C7B7B solid" }}
                        id="name"
                        onChange={handleChange}
                        value={values.name}
                        onBlur={handleBlur}
                      />
                      {touched.name && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.name}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Description"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="description"
                        onChange={handleChange}
                        value={values.description}
                      />
                      {touched.description && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.description}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Latitude*"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="lat"
                        onChange={(event) => {
                          let mob = parseFloat(event.target.value);
                          setFieldValue("lat", mob);
                        }}
                        value={values.lat}
                        onBlur={handleBlur}
                      />
                      {touched.lat && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.lat}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Longitude*"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="long"
                        onChange={(event) => {
                          let mob = parseFloat(event.target.value);
                          setFieldValue("long", mob);
                        }}
                        value={values.long}
                        onBlur={handleBlur}
                      />
                      {touched.long && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.long}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="countryId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.countryId ? true : false}
                        >
                          {" "}
                          -- Select Country* --{" "}
                        </option>
                        {countriesDropDown &&
                          countriesDropDown.countries &&
                          countriesDropDown?.countries.map((country) => (
                            <option
                              value={country.id}
                              selected={
                                values.countryId === country.id ? true : false
                              }
                            >
                              {country.name}
                            </option>
                          ))}
                      </select>
                      {touched.countryId && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.countryId}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="stateId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.stateId ? true : false}
                        >
                          {" "}
                          -- Select State* --{" "}
                        </option>
                        {statesDropDown &&
                          statesDropDown.states &&
                          statesDropDown?.states.map((state) => (
                            <option
                              value={state.id}
                              selected={
                                values.stateId === state.id ? true : false
                              }
                            >
                              {state.name}
                            </option>
                          ))}
                      </select>
                      {touched.stateId && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.stateId}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="cityId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.cityId ? true : false}
                        >
                          {" "}
                          -- Select City* --{" "}
                        </option>
                        {citiesDropDown &&
                          citiesDropDown.cities &&
                          citiesDropDown?.cities.map((city) => (
                            <option
                              value={city.id}
                              selected={
                                values.cityId === city.id ? true : false
                              }
                            >
                              {city.name}
                            </option>
                          ))}
                      </select>
                      {touched.cityId && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.cityId}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="locationId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.locationId ? true : false}
                        >
                          {" "}
                          -- Select Location* --{" "}
                        </option>
                        {locationsDropDown &&
                          locationsDropDown.locations &&
                          locationsDropDown?.locations.map((location) => (
                            <option
                              value={location.id}
                              selected={
                                values.locationId === location.id ? true : false
                              }
                            >
                              {location.name}
                            </option>
                          ))}
                      </select>
                      {touched.locationId && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.locationId}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ marginTop: 15 }}
                    onClick={handleSubmit}
                  >
                    {selectedCentreData?.id ? "Save" : "Add"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ marginTop: 15, marginLeft: 5 }}
                    onClick={() => setAddCentreView(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddNewCentre = (props) => {
  const { setAddCentreView, selectedCentreData, refetchCentres } = props;
  const [error, setError] = useState("");

  const [addCentre] = useMutation(CREATE_CENTRE, {
    onCompleted: (data) => {
      const { createCentre } = data || {};
      const { status, error } = createCentre;
      console.log({ status, error });
      if (status) {
        setAddCentreView(false);
        refetchCentres();
        setError("");
      }
      else{
        setError(error);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [updateCentre] = useMutation(UPDATE_CENTRE, {
    onCompleted: (data) => {
      const { updateCentre } = data || {};
      const { status, error } = updateCentre;
      console.log({ status, error });
      if (status) {
        setAddCentreView(false);
        refetchCentres();
        setError("");
      }
      else{
        setError(error);        
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const AddNewCentreWrapped = withFormik({
    mapPropsToValues: (props) => {
      const { selectedCentreData } = props;

      return {
        name: selectedCentreData?.name || "",
        description: selectedCentreData?.description || "",
        lat: selectedCentreData?.lat || "",
        long: selectedCentreData?.long || "",
        // type: selectedCentreData?.type || '',
        countryId: selectedCentreData?.country?.id || "",
        stateId: selectedCentreData?.state?.id || "",
        cityId: selectedCentreData?.city?.id || "",
        locationId: selectedCentreData?.location?.id || "",
        error: error,
      };
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please enter centre name"),
      lat: Yup.number().required("Please enter latitude"),
      long: Yup.number().required("Please enter longitude"),
      countryId: Yup.string().required("Please select a country"),
      stateId: Yup.string().required("Please select a state"),
      cityId: Yup.string().required("Please select a city"),
      locationId: Yup.string().required("Please select a locatioin"),
      // type: Yup.string().required('Please select a type')
    }),

    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);

      const { selectedCentreData } = props;
      delete values["error"];
      if (selectedCentreData?.id) {
        updateCentre({
          variables: {
            payload: {
              id: selectedCentreData.id,
              ...values,
              name: values.name.trim()
            },
          },
        });
      } else {
        addCentre({
          variables: {
            payload: {
              ...values,
              name: values.name.trim()
            },
          },
        });
      }
      // delete values['error']

      // if (selectedCentreData?.id) {
      //     updateUser({
      //         variables: {
      //             payload: {
      //                 ...values,
      //                 profile: JSON.stringify(values.profile),
      //                 id: selectedCentreData?.id,
      //             }
      //         }
      //     })
      // }
      // else {
      //     addUser({
      //         variables: {
      //             payload: {
      //                 ...values,
      //                 roles: [values.roles],
      //                 profile: JSON.stringify(values.profile),
      //                 centreId: '1'
      //             }
      //         }
      //     })
      // }
    },
  })(AddNewCentreForm);

  return (
    <AddNewCentreWrapped
      setAddCentreView={setAddCentreView}
      selectedCentreData={selectedCentreData}
    />
  );
};

export default AddNewCentre;
