import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { withFormik } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { SIGNUP_USER } from "../../graphql/users";
import { useMutation } from "@apollo/client";

const FormModalFields = ({
  formModal,
  setFormModal,
  role,
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  errors,
  touched,
  successModal,
  setSuccessModal,
  apiResponse,
  setApiResponse,
  apiStatus,
  setApiStatus,
}) => {
  const [captcha, setCaptcha] = useState(false);
  const captchaValue = (value) => {
    console.log(value);
    setCaptcha(true);
  };
  return (
    <div>
      <Modal show={formModal} onHide={() => setFormModal(false)}>
        <Modal.Header style={{ border: 0 }}>
          <button
            type="button"
            class="close"
            aria-label="Close"
            onClick={() => setFormModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body style={{ marginBottom: 10 }}>
          <Modal.Title
            style={{
              textAlign: "center",
              fontFamily: "Bebas Neue",
              fontSize: 24,
            }}
          >
            {`SIGN UP AS ${
              role
                ? role === "employer"
                  ? "AN"
                  : role === "pia"
                  ? "A"
                  : ""
                : ""
            } ${role ? role.toUpperCase() : "USER"}`}
          </Modal.Title>
          <div class="mt-4">
            <div
              class=" pr-4 pl-4"
              style={{ maxHeight: 400, overflow: "auto" }}
            >
              <div class="mb-1" style={{ fontSize: 14 }}>
                Full Name <span style={{ color: "red" }}>*</span>
              </div>
              <div className="form-group">
                <input
                  id="fullName"
                  type="text"
                  className="form-control"
                  style={{ border: "1PX #7C7B7B solid" }}
                  value={values.fullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.fullName && (
                  <span style={{ fontSize: 12, color: "red" }}>
                    {errors.fullName}
                  </span>
                )}
              </div>
              <div class="mb-1 mt-2" style={{ fontSize: 14 }}>
                Contact Number <span style={{ color: "red" }}>*</span>
              </div>
              <div className="form-group">
                <input
                  id="mobile"
                  type="number"
                  className="form-control"
                  style={{ border: "1PX #7C7B7B solid" }}
                  value={values.mobile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.mobile && (
                  <span style={{ fontSize: 12, color: "red" }}>
                    {errors.mobile}
                  </span>
                )}
              </div>
              <div class="mb-1 mt-2" style={{ fontSize: 14 }}>
                Email Address <span style={{ color: "red" }}>*</span>
              </div>
              <div className="form-group">
                <input
                  id="email"
                  type="text"
                  className="form-control"
                  style={{ border: "1PX #7C7B7B solid" }}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.email && (
                  <span style={{ fontSize: 12, color: "red" }}>
                    {errors.email}
                  </span>
                )}
              </div>
              <div class="mb-1 mt-2" style={{ fontSize: 14 }}>
                Organization Name <span style={{ color: "red" }}>*</span>
              </div>
              <div className="form-group">
                <input
                  id="organization"
                  type="text"
                  className="form-control"
                  style={{ border: "1PX #7C7B7B solid" }}
                  value={values.organization}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.organization && (
                  <span style={{ fontSize: 12, color: "red" }}>
                    {errors.organization}
                  </span>
                )}
              </div>
              <div class="mb-1 mt-2" style={{ fontSize: 14 }}>
                Message
              </div>
              <div className="form-group">
                <textarea
                  id="message"
                  type="text"
                  className="form-control"
                  style={{ border: "1PX #7C7B7B solid" }}
                  value={values.message}
                  onChange={handleChange}
                  rows={4}
                />
              </div>
              <div>
                <ReCAPTCHA
                  sitekey="6Lf7n6EfAAAAAFf67IB2La6apPcPvvnGcFey4ks_"
                  onChange={() => captchaValue()}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <button
                type="button"
                className="btn btn-primary mt-5"
                style={{
                  backgroundColor: "#1B7EB3",
                  paddingRight: 45,
                  paddingLeft: 45,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
                onClick={handleSubmit}
                disabled={
                  errors?.fullName ||
                  errors?.mobile ||
                  errors?.email ||
                  errors.organization ||
                  !values.fullName ||
                  !values.mobile ||
                  !values.email ||
                  !values.organization ||
                  !captcha
                }
              >
                <span>Signup Now</span>
              </button>
            </div>
            <div
              style={{
                fontSize: 14,
                marginTop: 20,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <div>
                If you already have an account,{" "}
                <Link
                  style={{ textDecoration: "underline" }}
                  to="/login"
                  onClick={() => setFormModal(false)}
                >
                  Sign in
                </Link>{" "}
                here
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={successModal} onHide={() => setSuccessModal(false)}>
        <Modal.Header style={{ border: 0 }}>
          <button
            type="button"
            class="close"
            aria-label="Close"
            onClick={() => {
              setSuccessModal(false);
              setApiStatus(false);
              setApiResponse("");
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom: 50 }}>
          {apiStatus && (
            <Modal.Title
              style={{
                textAlign: "center",
                fontFamily: "Bebas Neue",
                fontSize: 24,
              }}
            >
              <span style={{ color: "green" }}>
                <i className="fa fa-check" />
              </span>
              &nbsp;&nbsp;
              {apiResponse ||
                "Thank you for registering. Your details have been submitted. We will get back to you shortly."}
            </Modal.Title>
          )}
          {!apiStatus && (
            <Modal.Title
              style={{
                textAlign: "center",
                fontFamily: "Bebas Neue",
                fontSize: 24,
              }}
            >
              <span style={{ color: "red" }}>
                <i className="fa fa-exclamation" />
              </span>
              &nbsp;&nbsp;
              {apiResponse || (
                <>
                  <span>Some error occured while registering.</span>
                  <br />
                  <span>Please try again</span>
                </>
              )}
            </Modal.Title>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

const FormModal = ({ formModal, setFormModal, role }) => {
  const [successModal, setSuccessModal] = useState(false);
  const [apiStatus, setApiStatus] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [signupUser] = useMutation(SIGNUP_USER, {
    onCompleted: (data) => {
      const { registerUser } = data;
      const { status, error } = registerUser;
      setApiStatus(status);
      setApiResponse(error);
      setSuccessModal(true);
    },
  });
  const FormModalWrapped = withFormik({
    mapPropsToValues: () => {
      return {
        fullName: "",
        mobile: "",
        email: "",
        organization: "",
        message: "",
      };
    },

    validationSchema: Yup.object().shape({
      fullName: Yup.string().required("Please enter your full name"),
      mobile: Yup.string()
        .required("Please enter your  mobile number")
        .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
      email: Yup.string()
        .required("Please enter your  email id")
        .email("Enter a valid email ID"),
      organization: Yup.string().required(
        "Please specify your organization name"
      ),
    }),

    enableReinitialize: true,
    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);
      signupUser({
        variables: {
          payload: {
            name: values.fullName,
            mobile: values && values.mobile ? values.mobile.toString() : "",
            email: values.email,
            organization: values.organization,
            message: values.message,
            role: role === "pia" ? 1 : 2,
          },
        },
      });
      setFormModal(false);
    },
  })(FormModalFields);

  return (
    <FormModalWrapped
      formModal={formModal}
      setFormModal={setFormModal}
      role={role}
      successModal={successModal}
      setSuccessModal={setSuccessModal}
      apiStatus={apiStatus}
      setApiStatus={setApiStatus}
      apiResponse={apiResponse}
      setApiResponse={setApiResponse}
    />
  );
};

export default FormModal;
