import React, { useState, useEffect } from "react";
import { GET_JOBS, GET_JOB, UPDATE_JOB_STATUS } from "../../graphql/jobs";
import { GET_ALL_CITIES, GET_ALL_STATES } from "../../graphql/meta";
import AddNewJob from "./JobForm";
import JobDetails from "./JobDetails";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ORGANIZATIONS } from "../../graphql/organizations";
import Pagination from "../Shared/Pagination";
import { isJwtTokenValid } from "../../utils";
import { Redirect } from "react-router-dom";
import accessControlMenu from "../Shared/AccessControl";
import { Modal } from "react-bootstrap";

const Jobs = (props) => {
  const [addJobsView, setAddJobsView] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [clone, setClone] = useState(false);
  const [selectedJobPayload, setSelectedJobPayload] = useState({});
  const [jobDetailsView, setJobDetailsView] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowCount, setRowCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [jobFilter, setJobFilter] = useState({});
  const [organizations, setOrganizations] = useState([]);
  //   const [timer, setTimer] = useState(null);
  const [editJobs, setEditJobs] = useState(true);
  const [addApplicants, setAddApplicants] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);
  const [jobStatus, setJobStatus] = useState(0);
  const [statusName, setStatusName] = useState("");
  const { roles, hasExpired } = isJwtTokenValid();

  const { data: orgs, loading: loadingOrganization } = useQuery(
    GET_ORGANIZATIONS,
    {
      variables: {
        page: page + 1,
        limit: pageSize,
        filter: {
          organizationType: 2,
        },
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (orgs && orgs.organizations) {
      setOrganizations(orgs.organizations);
    }
  }, [orgs]);

  useEffect(() => {
    accessControlMenu.map((item) => {
      if (item.role === roles[0]) {
        setEditJobs(item.editJobs);
        setAddApplicants(item.addApplicants);
      }
    });
  }, [roles]);

  const {
    data,
    refetch,
    loading: loadingJobs,
  } = useQuery(GET_JOBS, {
    variables: {
      page: page + 1,
      limit: pageSize,
      filter: jobFilter,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: job } = useQuery(GET_JOB, {
    variables: {
      id: selectedJob,
    },
    skip: !selectedJob,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [updateJobStatus] = useMutation(UPDATE_JOB_STATUS, {
    onCompleted: (data) => {
      const { updateJobStatus } = data;
      const { status, error } = updateJobStatus;
      if (status) {
        setConfirmModal(false);
        refetch();
      }
    },
  });

  const { data: allStates } = useQuery(GET_ALL_STATES, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: allCities } = useQuery(GET_ALL_CITIES, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  // const changeDelay = (e) => {
  // 	if (timer) {
  // 		clearTimeout(timer);
  // 		setTimer(null);
  // 	}
  // 	setTimer(
  // 		setTimeout(() => {
  // 			console.log(e);
  // 			let { title__icontains, ...mutableFilter } = jobFilter;
  // 			if (e) {
  // 				mutableFilter = { ...jobFilter, title__icontains: e };
  // 			}
  // 			setJobFilter(mutableFilter);
  // 		}, 1000)
  // 	);
  // }

  //   const changeDelay1 = (e) => {
  //     if (timer) {
  //       clearTimeout(timer);
  //       setTimer(null);
  //     }
  //     setTimer(
  //       setTimeout(() => {
  //         console.log(e);
  //         let { organization__name__icontains, ...mutableFilter } = jobFilter;
  //         if (e) {
  //           mutableFilter = { ...jobFilter, organization__name__icontains: e };
  //         }
  //         setJobFilter(mutableFilter);
  //       }, 1000)
  //     );
  //   };

  useEffect(() => {
    if (data && data.jobs && data.jobs.jobs) {
      setRowCount(data.jobs.count);
      setJobs(data.jobs.jobs);
    }
  }, [data]);

  useEffect(() => {
    if (job?.job) {
      setSelectedJobPayload(job.job);
    } else {
      setSelectedJobPayload({});
    }
  }, [job]);

  useEffect(() => {
    if (addJobsView === false) {
      setSelectedJob(null);
      setClone(false);
      setSelectedJobPayload({});
      refetch();
    }
  }, [addJobsView, refetch]);

  useEffect(() => {
    if (jobDetailsView === false) {
      setSelectedJob(null);
      setClone(false);
      setSelectedJobPayload({});
      refetch();
    }
  }, [jobDetailsView, refetch]);

  const Job = ({ o }) => {
    // const [jobStatus, setJobStatus] = useState('')

    // useEffect(() => {
    // 	if (o?.status >= 0) {
    // 		for (let i = 0; i < trackingStatus.length; i++) {
    // 			if (trackingStatus[i].id === o.status) {
    // 				setJobStatus(trackingStatus[i].name)
    // 				break
    // 			}
    // 		}
    // 	}
    // }, [o])

    return (
      <tr
        style={{
          backgroundColor:
            o?.status === 15 ? "#FFF1DB" : o?.status === 16 ? "#FFE1DB" : "",
        }}
      >
        <td>
          <span>{o?.jobId}</span>
        </td>
        <td>
          <div className="d-flex justify-content-flex-start align-items-center">
            {/* <span
              className="avatar avatar-blue"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Avatar Name"
            >
              {o.organization?.name.substring(0, 1) || "-"}
            </span> */}
            <div className="font-15" style={{ marginLeft: 10 }}>
              {o.organization?.name}
            </div>
          </div>
        </td>
        <td>
          {o.title || <hr style={{ backgroundColor: "black", width: 15 }} />}
        </td>
        {/* <td>
					<span className='tag tag-warning'><strong>45</strong></span>
				</td> */}
        <td>
          <span>
            {o.city?.name || "-"}, {o.state?.name || "-"},{" "}
            {o.country?.name || "-"}
          </span>
        </td>
        <td>
          <span
            className="tag tag-lime"
            style={{ position: "relative", left: "40%" }}
          >
            {o.positions ? <strong>{o.positions}</strong> : "-"}
          </span>
        </td>
        <td>
          <span>
            {o?.createdBy?.firstName ? (
              <>
                {o?.createdBy?.firstName || "-"} {o?.createdBy?.lastName || "-"}
              </>
            ) : (
              "-"
            )}
          </span>
        </td>
        <td>
          {o?.accountManager ? (
            <span>
              {o?.accountManager?.firstName || ""}{" "}
              {o?.accountManager?.lastName || ""}
            </span>
          ) : (
            "---"
          )}
        </td>
        <td className="d-flex justify-content-between align-items-center">
          <span style={{ color: "green" }}>
            <i className="fa fa-check" title="Applied" />
          </span>{" "}
          {o?.AppliedCount || 0}
          <i
            className="fa fa-star text-yellow"
            style={{ marginLeft: 10 }}
            title="Shortlisted"
          />{" "}
          {o?.ShortlistedCount || 0}
          <span style={{ color: "blue", marginLeft: 10 }}>
            <i className="fa fa-clipboard" title="Offered" />
          </span>{" "}
          {o?.OfferedCount || 0}
          <i
            className="fa fa-ban text-red"
            style={{ marginLeft: 10 }}
            title="Rejected"
          />{" "}
          {o?.RejectedCount || 0}
          <i
            className="fa fa-user"
            style={{ marginLeft: 10 }}
            title="Total Candidates"
          />{" "}
          {o?.totalApplicants || 0}
        </td>
        {/* <td>{jobStatus || <hr style={{ backgroundColor: 'black', width: 15 }} />}</td> */}
        <td>
          {o?.genderPreference === "M"
            ? "Male"
            : o?.genderPreference === "F"
            ? "Female"
            : o?.genderPreference === "O"
            ? "Others"
            : "Both"}
        </td>
        {/* <td>{o?.skill?.skill?.name || <hr style={{ backgroundColor: 'black', width: 15 }} />}</td> */}
        <td>
          <>
            {o.status === 1 && (
              <span style={{ color: "green" }}>
                <i className="fa fa-play-circle-o fa-2x" title="Active" />
              </span>
            )}
            {o.status === 15 && (
              <span style={{ color: "orange" }}>
                <i className="fa fa-pause-circle-o fa-2x" title="On Hold" />
              </span>
            )}
            {o.status === 16 && (
              <span style={{ color: "red" }}>
                <i className="fa fa-times-circle-o fa-2x" title="Closed" />
              </span>
            )}
          </>
        </td>
        <td className="d-flex justify-content-between">
          {editJobs && (
            <>
              {o.status === 1 && (
                <button
                  type="button"
                  className="btn btn-icon"
                  title="Edit"
                  onClick={() => {
                    setAddJobsView(true);
                    setSelectedJob(o.id);
                  }}
                >
                  <i className="fa fa-edit" />
                </button>
              )}
              {(o.status === 1 || o.status === 16) && (
                <button
                  type="button"
                  className="btn btn-icon"
                  title="Clone"
                  onClick={() => {
                    setAddJobsView(true);
                    setClone(true);
                    setSelectedJob(o.id);
                  }}
                >
                  <i className="fa fa-clone" />
                </button>
              )}
            </>
          )}
          {o.status === 1 && (
            <button
              type="button"
              className="btn btn-icon"
              title="Put on Hold"
              onClick={() => {
                setConfirmModal(true);
                setJobStatus(15);
                setSelectedJob(o.id);
              }}
            >
              <i className="fa fa-pause-circle-o fa-lg" />
            </button>
          )}
          {o.status === 15 && (
            <button
              type="button"
              className="btn btn-icon"
              title="Activate Job"
              onClick={() => {
                setConfirmModal(true);
                setJobStatus(1);
                setSelectedJob(o.id);
              }}
            >
              <i className="fa fa-play-circle-o fa-lg" />
            </button>
          )}
          {o.status !== 16 && (
            <button
              type="button"
              className="btn btn-icon"
              title="Close Vacancy"
              onClick={() => {
                setConfirmModal(true);
                setJobStatus(16);
                setSelectedJob(o.id);
              }}
            >
              <i className="fa fa-times-circle-o fa-lg" />
            </button>
          )}
          <button
            type="button"
            className="btn btn-icon"
            title="Applicants"
            onClick={() => {
              setJobDetailsView(true);
              setSelectedJob(o.id);
            }}
          >
            <i className="fa fa-users" />
          </button>
        </td>
      </tr>
    );
  };

  return (
    <>
      {hasExpired ? (
        <Redirect to="/login" />
      ) : (
        <>
          {!addJobsView && !jobDetailsView && (
            <div>
              <div className="container-fluid">
                <div style={{ marginRight: 40, marginLeft: 40 }}>
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search Job..."
                              onChange={(e) => {
                                let { title__icontains, ...mutableFilter } =
                                  jobFilter;
                                if (e) {
                                  mutableFilter = {
                                    ...jobFilter,
                                    title__icontains: e.target.value,
                                  };
                                }
                                setJobFilter(mutableFilter);
                              }}
                              value={
                                Object.keys(jobFilter).length === 0
                                  ? ""
                                  : jobFilter &&
                                    !("title__icontains" in jobFilter)
                                  ? ""
                                  : jobFilter.title__icontains
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                          <div className="input-group">
                            <select
                              className="custom-select"
                              onChange={(e) => {
                                let mutableFilter = {
                                  ...jobFilter,
                                  stateId: e.target.value,
                                };
                                if (e.target.value === "null") {
                                  const { stateId, ...restFilter } = jobFilter;
                                  mutableFilter = restFilter;
                                }
                                setJobFilter(mutableFilter);
                              }}
                            >
                              <option
                                selected={
                                  Object.keys(jobFilter).length === 0 ||
                                  (jobFilter && !("stateId" in jobFilter))
                                    ? true
                                    : false
                                }
                                disabled
                              >
                                {" "}
                                -- Select State --{" "}
                              </option>
                              <option value={"null"} key={"state0"}>
                                None
                              </option>
                              {allStates &&
                                allStates.allStates.map((c) => (
                                  <option key={c.id} value={c.id}>
                                    {" "}
                                    {c.name}{" "}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                          <div className="input-group">
                            <select
                              className="custom-select"
                              onChange={(e) => {
                                let mutableFilter = {
                                  ...jobFilter,
                                  cityId: e.target.value,
                                };
                                if (e.target.value === "null") {
                                  const { cityId, ...restFilter } = jobFilter;
                                  mutableFilter = restFilter;
                                }
                                setJobFilter(mutableFilter);
                              }}
                            >
                              <option
                                selected={
                                  Object.keys(jobFilter).length === 0 ||
                                  (jobFilter && !("cityId" in jobFilter))
                                    ? true
                                    : false
                                }
                                disabled
                              >
                                {" "}
                                -- Select City --{" "}
                              </option>
                              <option value={"null"} key={"state0"}>
                                None
                              </option>
                              {allCities &&
                                allCities.allCities.map((c) => (
                                  <option key={c.id} value={c.id}>
                                    {" "}
                                    {c.name}{" "}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6">
                          <div className="input-group">
                            <select
                              className="custom-select"
                              onChange={(e) => {
                                let mutableFilter = {
                                  ...jobFilter,
                                  organizationId: e.target.value,
                                };
                                if (e.target.value === "null") {
                                  const { organizationId, ...restFilter } =
                                    jobFilter;
                                  mutableFilter = restFilter;
                                }
                                setJobFilter(mutableFilter);
                              }}
                            >
                              <option
                                selected={
                                  Object.keys(jobFilter).length === 0 ||
                                  (jobFilter &&
                                    !("organizationId" in jobFilter))
                                    ? true
                                    : false
                                }
                                disabled
                              >
                                {" "}
                                -- Select Organization --{" "}
                              </option>
                              <option value={"null"} key={"state0"}>
                                None
                              </option>
                              {organizations &&
                                organizations.map((c) => (
                                  <option key={c.id} value={c.id}>
                                    {" "}
                                    {c.name}{" "}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: 10 }}>
                        {/* <div className="col-lg-3 col-md-6 col-sm-6">
										<div className="input-group">
											<input
												type="text"
												className="form-control"
												placeholder="Search Org..."
												onChange={(e) => changeDelay1(e.target.value)}
											/>
										</div>
									</div> */}
                        {/* <div className="col-lg-3 col-md-6 col-sm-6">
										<div className="input-group">
											<select
												className="custom-select"
												onChange={(e) => {
													let mutableFilter = { ...jobFilter, status: parseInt(e.target.value) };
													if (e.target.value === 'null') {
														const { status, ...restFilter } = jobFilter;
														mutableFilter = restFilter;
													}
													setJobFilter(mutableFilter);
												}}
											>
												<option selected disabled> -- Select Status -- </option>
												<option value={'null'} key={'state0'}>None</option>
												{trackingStatus.map((c) => <option key={c.id} value={c.id}> {c.name} </option>)}
											</select>
										</div>
									</div> */}
                        <div className="col-lg-3 col-md-6 col-sm-6">
                          <div className="input-group">
                            <select
                              className="custom-select"
                              onChange={(e) => {
                                let mutableFilter = {
                                  ...jobFilter,
                                  gender_preference: e.target.value,
                                };
                                if (e.target.value === "null") {
                                  const { gender_preference, ...restFilter } =
                                    jobFilter;
                                  mutableFilter = restFilter;
                                }
                                setJobFilter(mutableFilter);
                              }}
                            >
                              <option
                                selected={
                                  Object.keys(jobFilter).length === 0 ||
                                  (jobFilter &&
                                    !("gender_preference" in jobFilter))
                                    ? true
                                    : false
                                }
                                disabled
                              >
                                {" "}
                                -- Select Gender --{" "}
                              </option>
                              <option value={"null"} key={"state0"}>
                                None
                              </option>
                              <option value="M">Male</option>
                              <option value="F">Female</option>
                              <option value="O">Other</option>
                              <option value="NA">NA</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                          <div className="input-group">
                            <select
                              className="custom-select"
                              onChange={(e) => {
                                let mutableFilter = {
                                  ...jobFilter,
                                  status: parseInt(e.target.value),
                                };
                                if (e.target.value === "null") {
                                  const { status, ...restFilter } = jobFilter;
                                  mutableFilter = restFilter;
                                }
                                setJobFilter(mutableFilter);
                              }}
                            >
                              <option
                                selected={
                                  Object.keys(jobFilter).length === 0 ||
                                  (jobFilter && !("status" in jobFilter))
                                    ? true
                                    : false
                                }
                                disabled
                              >
                                -- Select Job Status --
                              </option>
                              <option value={"null"} key={"state0"}>
                                None
                              </option>
                              <option value="1">Active</option>
                              <option value="15">On Hold</option>
                              <option value="16">Closed</option>
                            </select>
                          </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6 col-sm-6">
										<div className="input-group">
											<select
												className="custom-select"
												onChange={(e) => {
													let mutableFilter = { ...jobFilter, skills: e.target.value };
													if (e.target.value === 'null') {
														const { skills, ...restFilter } = jobFilter;
														mutableFilter = restFilter;
													}
													setJobFilter(mutableFilter);
												}}
											>
												<option selected disabled> -- Select Skill -- </option>
												<option value={'null'} key={'state0'}>None</option>
												{allSkills && allSkills.skills.map((c) => <option key={c.id} value={c.id}> {c.name} </option>)}
											</select>
										</div>
									</div> */}
                      </div>
                      <div
                        className="header-action float-right"
                        style={{ marginTop: 10 }}
                      >
                        <button
                          type="button"
                          className="btn btn-default"
                          onClick={() => setJobFilter({})}
                        >
                          <i className="fa fa-times mr-2" />
                          Clear
                        </button>
                      </div>
                      {/* <div className='row' style={{ marginTop: 10 }}>
									<div className="col-lg-3 col-md-6 col-sm-6">
										<div className="input-group">
											<select
												className="custom-select"
												onChange={(e) => {
													let mutableFilter = { ...jobFilter, organizationId: e.target.value };
													if (e.target.value === 'null') {
														const { organizationId, ...restFilter } = jobFilter;
														mutableFilter = restFilter;
													}
													setJobFilter(mutableFilter);
												}}
											>
												<option selected disabled> -- select organization -- </option>
												<option value={'null'} key={'state0'}>None</option>
												{organizations.map((c) => <option key={c.id} value={c.id}> {c.name} </option>)}
											</select>
										</div>
									</div>
								</div> */}
                    </div>
                  </div>
                  {editJobs && (
                    <div className="header-action float-right">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => setAddJobsView(true)}
                        disabled={organizations && organizations.length === 0}
                      >
                        <i className="fe fe-plus mr-2" />
                        Add Job
                      </button>
                    </div>
                  )}
                </div>
                <div className="section-body mt-3">
                  <div className="container-fluid">
                    <div className="table-responsive">
                      <table className="table table-hover table-vcenter table_custom spacing5 mb-0">
                        <thead>
                          <tr>
                            <th>Job Id</th>
                            <th>Organization</th>
                            <th>Job Title</th>
                            {/* <th>Applicants</th> */}
                            <th>Location</th>
                            <th>Openings</th>
                            <th>Created By</th>
                            <th>Account Manager</th>
                            <th>Candidate Count</th>
                            {/* <th>Status</th> */}
                            <th>Gender Pref</th>
                            <th>Job Status</th>
                            <th className="w100">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loadingJobs && (
                            <td colSpan={10} style={{ textAlign: "center" }}>
                              <p style={{ font: 15 }}>Loading... </p>
                              <div
                                class="spinner-border ml-auto"
                                role="status"
                                aria-hidden="true"
                              ></div>
                            </td>
                          )}
                          {!loadingJobs &&
                            jobs &&
                            jobs.length > 0 &&
                            jobs.map((o) => <Job key={o.id} o={o} />)}
                          {!jobs && !loadingJobs && (
                            <tr>
                              <td colSpan={9} style={{ textAlign: "center" }}>
                                {!loadingOrganization &&
                                organizations &&
                                organizations.length > 0
                                  ? "No Jobs Added"
                                  : "Please add an organization for adding a new job"}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      page={page}
                      pageSize={pageSize}
                      rowCount={rowCount}
                      setPage={setPage}
                      setPageSize={setPageSize}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {jobDetailsView && (
            <JobDetails
              setJobDetailsView={setJobDetailsView}
              selectedJobPayload={selectedJobPayload}
              addApplicants={addApplicants}
            />
          )}
          {addJobsView && (
            <AddNewJob
              setAddJobsView={setAddJobsView}
              selectedJobPayload={selectedJobPayload}
              clone={clone}
            />
          )}
          <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
            <Modal.Header>
              <Modal.Title>Confirm</Modal.Title>
              <button
                type="button"
                class="close"
                aria-label="Close"
                onClick={() => setConfirmModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>{`Are you sure you want to ${
              jobStatus === 1
                ? "activate the job"
                : jobStatus === 15
                ? "put vacancies on hold"
                : "close the vacancy"
            }?`}</Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-outline-success"
                onClick={() => {
                  updateJobStatus({
                    variables: {
                      payload: {
                        ids: [selectedJob],
                        status: jobStatus,
                      },
                    },
                  });
                }}
              >
                Yes
              </button>
              <button
                type="button"
                class="btn btn-outline-danger"
                onClick={() => setConfirmModal(false)}
              >
                No
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default Jobs;
