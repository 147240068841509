import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper } from "@mui/material";
import StackedGraphSection from "./StackedGraphSection";
import DonutPieChart from "../../common/DonutPieChart";
import { prepareAutoCompleteOptions, getDateInFormat } from "../../helpers";
import colors from "../../styles/colors";

import { useQuery } from "@apollo/client";
import { setDonutPieChartData } from "./utils";
import { GET_CHARTS_DATA } from "../../graphql/EmployerDashboard";
import getStore from "../../zustandStore";
import moment from "moment";

const GraphSection = (props) => {
  const { jobsData } = props;
  const setLoading = getStore((state) => state.setLoading);

  const [jobsDropdownArr, setJobsDropdownArr] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [shortlistedRatio, setShortlistedRatio] = useState([]);
  const [interviewedRatio, setInterviewedRatio] = useState([]);
  const [rejectedRatio, setRejectedRatio] = useState([]);
  const [genderRatio, setGenderRatio] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(6, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [spanOfData, setSpanOfData] = useState("WEEKLY");

  const { data: chartsData, loading: loadingCharts } = useQuery(
    GET_CHARTS_DATA,
    {
      variables: {
        recent_applicants: false,
        payload: {
          ...(selectedJob && selectedJob.id && { jobId: selectedJob.id }),
          startDate: getDateInFormat(startDate, "YYYY-MM-DD"),
          endDate: getDateInFormat(endDate, "YYYY-MM-DD"),
          dataType: spanOfData === "custom" ? "MONTHLY" : spanOfData,
        },
      },
    }
  );

  useEffect(() => {
    if (spanOfData === "MONTHLY") {
      setStartDate(moment().startOf("month"));
      setEndDate(moment().endOf("month"));
    }
  }, [spanOfData]);

  useEffect(() => {
    if (jobsData && jobsData.jobs && jobsData.jobs.jobs) {
      setJobsDropdownArr(
        prepareAutoCompleteOptions(jobsData.jobs.jobs, "id", "title", true)
      );
    }
  }, [jobsData]);

  useEffect(() => {
    if (
      chartsData &&
      chartsData.getEmployerDashboard &&
      chartsData.getEmployerDashboard.totalCandidates
    ) {
      setTotalCandidates(chartsData.getEmployerDashboard.totalCandidates);
    }
  }, [chartsData]);

  useEffect(() => {
    setDonutPieChartData(
      totalCandidates,
      chartsData,
      setShortlistedRatio,
      setInterviewedRatio,
      setRejectedRatio,
      setGenderRatio
    );
  }, [chartsData, totalCandidates]);

  useEffect(() => {
    setLoading(loadingCharts);
  }, [loadingCharts, setLoading]);
  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ my: 1 }}>
        <StackedGraphSection
          selectedJob={selectedJob}
          setSelectedJob={setSelectedJob}
          chartsData={chartsData}
          spanOfData={spanOfData}
          setSpanOfData={setSpanOfData}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          jobsDropdownArr={jobsDropdownArr}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Paper elevation={1}>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2, mx: 2 }}>
              <Typography fontSize="16px" fontWeight={500} color={colors.dark}>
                Shortlisted Ratio
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <DonutPieChart
                title={totalCandidates}
                subtitle="Total Applied"
                data={shortlistedRatio}
                partColors={["#EFEFEF", colors.warning]}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Paper elevation={1}>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2, mx: 2 }}>
              <Typography fontSize="16px" fontWeight={500} color={colors.dark}>
                Interviewed Ratio
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <DonutPieChart
                title={totalCandidates}
                subtitle="Total Applied"
                data={interviewedRatio}
                partColors={["#EFEFEF", colors.success]}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* <Grid item lg={4} md={4} sm={12} xs={12}>
        <Paper elevation={1}>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2, mx: 2 }}>
              <Typography fontSize="16px">Rejected Ratio</Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <DonutPieChart
                title={totalCandidates}
                subtitle="Total Applied"
                data={rejectedRatio}
                partColors={["#EFEFEF", colors.success]}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid> */}
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Paper elevation={1}>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2, mx: 2 }}>
              <Typography fontSize="16px" fontWeight={500} color={colors.dark}>
                Applicants by Gender
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <DonutPieChart
                title={totalCandidates}
                subtitle="Total Applied"
                data={genderRatio}
                partColors={[colors.primary, "#EEBA3A"]}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default GraphSection;
