import React, { useState, useEffect } from "react";
import Pagination from "../Shared/Pagination";
import ClusterForm from "./ClusterForm";
import ClusterDetails from "./ClusterDetails";
import {
  GET_CLUSTERS,
  GET_CLUSTER,
  DELETE_CLUSTER,
} from "../../graphql/clusters";
import { useQuery, useMutation } from "@apollo/client";
import { Modal } from "react-bootstrap";

const ManageClusters = () => {
  const [addClusterView, setAddClusterView] = useState(false);
  // const [selectedCluster, setSelectedCluster] = useState({});
  const [clusterDetailsView, setClusterDetailsView] = useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [filter, setFilter] = useState({});
  const [rowCount, setRowCount] = React.useState(0);
  const [selectedClusterId, setSelectedClusterId] = useState("");
  const [selectedClusterData, setSelectedClusterData] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);

  const { data: clusters, refetch,loading:loadingClusters} = useQuery(GET_CLUSTERS, {
    variables: {
      page: page + 1,
      limit: pageSize,
      filter: filter,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: cluster } = useQuery(GET_CLUSTER, {
    variables: {
      id: selectedClusterId,
    },
    skip: !selectedClusterId,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [deleteCluster] = useMutation(DELETE_CLUSTER, {
    onCompleted: (data) => {
      const { deleteCluster } = data;
      const { status, error } = deleteCluster;
      console.log("DELETE CLUSTER", status, error);
      if (status) {
        setConfirmModal(false);
        refetch();
      }
    },
  });

  useEffect(() => {
    if (clusters && clusters.clusters && clusters.clusters.count) {
      setRowCount(clusters.clusters.count);
    }
  }, [clusters]);

  useEffect(() => {
    if (cluster && cluster.cluster) {
      setSelectedClusterData(cluster.cluster);
    }
  }, [cluster]);

  useEffect(() => {
    if (addClusterView === false)
      // setSelectedCluster({})
      setSelectedClusterId("");
    setSelectedClusterData({});
    refetch();
  }, [addClusterView, refetch]);

  useEffect(() => {
    if (clusterDetailsView === false)
      // setSelectedCluster({})
      setSelectedClusterId("");
    setSelectedClusterData({});
    refetch();
  }, [clusterDetailsView, refetch]);

  const Cluster = ({ o }) => {
    return (
      <tr>
        <td>
          <div className="d-flex justify-content-flex-start align-items-center">
            <span
              className="avatar avatar-blue"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Avatar Name"
            >
              {o.name.substring(0, 1) || "-"}
            </span>
            <div className="font-15" style={{ marginLeft: 10 }}>
              {o.name}
            </div>
          </div>
        </td>
        <td>{o?.description || "-"}</td>
        <td>
          {/* <span>{o.noOfCentres || '-'}</span> */}
          <span
            className="tag tag-lime"
            style={{ position: "relative", left: "18%" }}
          >
            {o.centresCount ? <strong>{o.centresCount}</strong> : "-"}
          </span>
        </td>
        <td className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-icon"
            title="Edit"
            onClick={() => {
              setAddClusterView(true);
              setSelectedClusterId(o.id);
              // setSelectedCluster(o)
            }}
          >
            <i className="fa fa-edit" />
          </button>
          <button
            type="button"
            className="btn btn-icon"
            title="Centres"
            onClick={() => {
              setClusterDetailsView(true);
              setSelectedClusterId(o.id);
              // setSelectedCluster(o)
            }}
          >
            <i className="fa fa-building" />
          </button>
          <button
            type="button"
            className="btn btn-icon"
            title="Delete"
            onClick={() => {
              // setClusterDetailsView(true);
              setSelectedClusterId(o.id);
              setConfirmModal(true);
              // setSelectedCluster(o)
            }}
          >
            <i className="fa fa-trash" />
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div>
      {!addClusterView && !clusterDetailsView && (
        <div className="container-fluid">
          <div style={{ marginRight: 40, marginLeft: 40 }}>
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => {
                          let { name__icontains, ...mutableFilter } = filter;
                          if (e) {
                            mutableFilter = {
                              ...filter,
                              name__icontains: e.target.value,
                            };
                          }
                          setFilter(mutableFilter);
                        }}
                        value={
                          Object.keys(filter).length === 0
                            ? ""
                            : filter && !("name__icontains" in filter)
                            ? ""
                            : filter.name__icontains
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-2 col-md-4 col-sm-6"
                    style={{ textAlign: "right" }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      // style={{ height: 30, marginBottom: 10 }}
                      onClick={() => setAddClusterView(true)}
                    >
                      <i className="fe fe-plus mr-2" />
                      Add Cluster
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="card">
                        <div className="card-body">
                            <div className='row'>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            onChange={(e) => {
                                                let { name__icontains, ...mutableFilter } = filter;
                                                if (e) {
                                                    mutableFilter = { ...filter, name__icontains: e.target.value };
                                                }
                                                setFilter(mutableFilter);
                                            }}
                                            value={(Object.keys(filter).length === 0) ? "" : (filter && !("name__icontains" in filter)) ? "" : filter.name__icontains}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="input-group">
                                        <select
                                            className="custom-select"
                                            onChange={(e) => {
                                                let mutableFilter = { ...jobFilter, stateId: e.target.value };
                                                if (e.target.value === 'null') {
                                                    const { stateId, ...restFilter } = jobFilter;
                                                    mutableFilter = restFilter;
                                                }
                                                setJobFilter(mutableFilter);
                                            }}
                                        >
                                            <option selected={((Object.keys(jobFilter).length === 0) || (jobFilter && !("stateId" in jobFilter))) ? true : false} disabled> -- Select State -- </option>
                                            <option value={'null'} key={'state0'}>None</option>
                                            {allStates && allStates.allStates.map((c) => <option key={c.id} value={c.id}> {c.name} </option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="input-group">
                                        <select
                                            className="custom-select"
                                            onChange={(e) => {
                                                let mutableFilter = { ...jobFilter, cityId: e.target.value };
                                                if (e.target.value === 'null') {
                                                    const { cityId, ...restFilter } = jobFilter;
                                                    mutableFilter = restFilter;
                                                }
                                                setJobFilter(mutableFilter);
                                            }}
                                        >
                                            <option selected={((Object.keys(jobFilter).length === 0) || (jobFilter && !("cityId" in jobFilter))) ? true : false} disabled> -- Select City -- </option>
                                            <option value={'null'} key={'state0'}>None</option>
                                            {allCities && allCities.allCities.map((c) => <option key={c.id} value={c.id}> {c.name} </option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="input-group">
                                        <select
                                            className="custom-select"
                                            onChange={(e) => {
                                                let mutableFilter = { ...jobFilter, locationId: e.target.value };
                                                if (e.target.value === 'null') {
                                                    const { locationId, ...restFilter } = jobFilter;
                                                    mutableFilter = restFilter;
                                                }
                                                setJobFilter(mutableFilter);
                                            }}
                                        >
                                            <option selected={((Object.keys(jobFilter).length === 0) || (jobFilter && !("locationId" in jobFilter))) ? true : false} disabled> -- Select Location -- </option>
                                            <option value={'null'} key={'state0'}>None</option>
                                            {allLocations && allLocations.allLocations.map((c) => <option key={c.id} value={c.id}> {c.name} </option>)}
                                        </select>
                                    </div>
                                </div> 
                            </div>
                            <div className='row' style={{ marginTop: 10 }}>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="input-group">
                                        <select
                                            className="custom-select"
                                            onChange={(e) => {
                                                let mutableFilter = { ...jobFilter, organizationId: e.target.value };
                                                if (e.target.value === 'null') {
                                                    const { organizationId, ...restFilter } = jobFilter;
                                                    mutableFilter = restFilter;
                                                }
                                                setJobFilter(mutableFilter);
                                            }}
                                        >
                                            <option selected={((Object.keys(jobFilter).length === 0) || (jobFilter && !("organizationId" in jobFilter))) ? true : false} disabled> -- Select Organization -- </option>
                                            <option value={'null'} key={'state0'}>None</option>
                                            {organizations.map((c) => <option key={c.id} value={c.id}> {c.name} </option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="input-group">
                                        <select
                                            className="custom-select"
                                            onChange={(e) => {
                                                let mutableFilter = { ...jobFilter, gender_preference: e.target.value };
                                                if (e.target.value === 'null') {
                                                    const { gender_preference, ...restFilter } = jobFilter;
                                                    mutableFilter = restFilter;
                                                }
                                                setJobFilter(mutableFilter);
                                            }}
                                        >
                                            <option selected={((Object.keys(jobFilter).length === 0) || (jobFilter && !("gender_preference" in jobFilter))) ? true : false} disabled> -- Select Gender -- </option>
                                            <option value={'null'} key={'state0'}>None</option>
                                            <option value='M'>Male</option>
                                            <option value='F'>Female</option>
                                            <option value='O'>Other</option>
                                            <option value='NA'>NA</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="header-action float-right" style={{ marginTop: 10 }}>
                                <button type="button" className="btn btn-default"
                                    onClick={() => setFilter({})}
                                >
                                    <i className="fa fa-times mr-2" />
                                    Clear
                                </button>
                            </div> 
                        </div>
                    </div> */}
            {/* <div className="header-action float-right">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setAddClusterView(true)}
                        >
                            <i className="fe fe-plus mr-2" />
                            Add Cluster
                        </button>
                    </div> */}
          </div>
          <div className="section-body mt-3">
            <div className="container-fluid">
              <div className="table-responsive">
                <table className="table table-hover table-vcenter table_custom spacing5 mb-0">
                  <thead>
                    <tr>
                      <th>Cluster Name</th>
                      <th>Cluster Description</th>
                      <th>No. of Centres</th>
                      <th className="w100">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loadingClusters && clusters &&
                      clusters?.clusters &&
                      clusters?.clusters?.clusters &&
                      clusters?.clusters?.clusters.map((o) => (
                        <Cluster key={o.id} o={o} />
                      ))}
                      {loadingClusters && <td
                                    colSpan={10}
                                    style={{ textAlign: "center" }}
                                  >
                                    <p style={{ font: 15 }}>Loading... </p>
                                    <div
                                      class="spinner-border ml-auto"
                                      role="status"
                                      aria-hidden="true"
                                    ></div>
                                  </td>}
                  </tbody>
                </table>
              </div>
              <Pagination
                page={page}
                pageSize={pageSize}
                rowCount={rowCount}
                setPage={setPage}
                setPageSize={setPageSize}
              />
            </div>
          </div>
          <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
            <Modal.Header>
              <Modal.Title>Confirm</Modal.Title>
              <button
                type="button"
                class="close"
                aria-label="Close"
                onClick={() => setConfirmModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete the cluster?</Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-outline-success"
                onClick={() =>
                  deleteCluster({
                    variables: {
                      ids: [selectedClusterId],
                    },
                  })
                }
              >
                Yes
              </button>
              <button
                type="button"
                class="btn btn-outline-danger"
                onClick={() => setConfirmModal(false)}
              >
                No
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      {addClusterView && (
        <ClusterForm
          setAddClusterView={setAddClusterView}
          selectedClusterData={selectedClusterData}
        />
      )}
      {clusterDetailsView && (
        <ClusterDetails
          setClusterDetailsView={setClusterDetailsView}
          selectedClusterData={selectedClusterData}
          selectedClusterId={selectedClusterId}
        />
      )}
    </div>
  );
};

export default ManageClusters;
