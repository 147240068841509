import React from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";
import Carousel from "react-material-ui-carousel";
import colors from "../../styles/colors";

const teamMembers = [
  {
    id: 1,
    name: "K. Pandia Rajan (KPR)",
    description:
      "K Pandiarajan (KPR) is the Founder of Ma Foi and is one of the most well-respected business leaders in India with over 30 years of experience in Management and Human Resources. He was at the forefront of the transformational changes that swept the HR landscape over the last three decades and has pioneered the HR outsourcing concept in India. KPR was conferred the Eisenhower Fellowship in 2000 and is actively involved in the development activities of the Ma Foi Foundation that focuses on empowering children, women and youth through education, healthcare and livelihood support. He was the Founding Chairman of the Indian Staffing Federation among several other pioneering industry bodies he founded.",
    image: "",
    designation: "Chairperson",
  },
  {
    id: 2,
    name: "Hemalatha Rajan",
    description:
      "Chartered Accountant with over 30 years of experience in HR, Finance, Auditing and Executive Search. She started with S B Billimoria (now Deloitte) and in a few years, co-founded Ma Foi that grew to be the largest in India and operated from multiple countries in the world. Through CIEL HR, she is recreating a market leader. Hemalatha Rajan is a respected woman leader in India who has built businesses, transitioned them successfully into the hands of new owners, and passionately created sustainable livelihood for poor women and their families. She is on the Board of several companies and NGOs that are changing lives of the under-privileged. She oversees the company with day to day operations being handled by the management team of Aditya and Santosh.",
    image: "",
    designation: "Director of CIEL HR",
  },
  {
    id: 3,
    name: "Aditya Narayan Mishra",
    description:
      "Leads the management team. Engineer and an MBA with 27 years of overall experience : 20 years in HR Services. He started his career with Larsen & Toubro. Over the last 2 decades, Aditya has led some of the largest Recruitment assignments in India, spread across verticals of Engineering, Consumer products, Financial Services, IT and Outsourcing. He has been an Executive Board Member of the industry body; continues to shape industry opinions by his weekly blogging. He is often quoted by various national print media and online platforms on HR and employment subjects.",
    image: "",
    designation: "Managing Director & CEO of CIEL HR",
  },
  {
    id: 4,
    name: "Santhosh Nair",
    description:
      "Anchors operations of CIEL. MBA with 20+ years of work experience, 17+ years in HR Services industry. Under his leadership, businesses have grown manifold and penetrated deep into the Tier 2 and Tier 3 cities in India breaking many new grounds. He has handled Search and Selection business, General Staffing and Professional Staffing businesses pan India in the past. He has integrated staffing businesses of three companies to build one entity under one brand and the people of the 3 companies to work together in the new entity. He has the experience of having set up a Shared Services Centre within the merged entity to drive efficiencies and standardisation.",
    image: "",
    designation: "Director and COO of CIEL HR",
  },
  {
    id: 5,
    name: "Rajiv Krishnan",
    description:
      "A Non-Executive director – An Engineer with a post-graduate Degree from XLRI. Rajiv has worked in reputed organizations like Korn Ferry, E & Y, Mercer Consulting and DDI India in leadership roles.",
    image: "",
    designation: "MD & CEO for the Ma Foi Group",
  },
  {
    id: 6,
    name: "NT Arun Kumar",
    description:
      "A Non-Executive director – A Graduate with PG diploma from XLRI. He comes as an independent board member and advisor for the large established entity.",
    image: "",
    designation: "MD for Telstra India global business services.",
  },
];

const TeamMember = (props) => {
  const { name, description, image, designation } = props;
  return (
    <Box sx={{ py: 2, px: 15 }}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography textAlign="center" fontSize="20px">
            {name}
          </Typography>
        </Grid>
        <Grid
          item
          lg={2}
          md={2}
          sm={12}
          xs={12}
          display="flex"
          alignItems={{ xs: "center", md: "flex-start" }}
        >
          <Avatar variant="rounded" sx={{ height: 150, width: 150 }} />
        </Grid>
        <Grid item lg={10} md={10} sm={12} xs={12}>
          <Typography color="secondary">{description}</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2.5 }}>
          <Typography textAlign="center" fontSize="16px" color="secondary">
            {name}
          </Typography>
          <Typography textAlign="center" fontSize="16px" color="secondary">
            {designation}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const Team = (props) => {
  return (
    <Box
      sx={{
        px: { xs: 2, sm: 5, md: 7, lg: 10 },
        py: 4,
        backgroundColor: colors.primary,
      }}
    >
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            textAlign="center"
            fontSize="30px"
            fontWeight={600}
            color="secondary"
          >
            Our Team
          </Typography>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginTop: 15,
            }}
          >
            <div
              style={{
                backgroundColor: colors.secondary,
                height: 3,
                borderRadius: "3px",
                width: 100,
              }}
            />
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Carousel
            animation="slide"
            interval={8000}
            navButtonsAlwaysVisible
            NextIcon={
              <KeyboardArrowRight sx={{ fontSize: { xs: 20, md: 30 } }} />
            }
            PrevIcon={
              <KeyboardArrowLeft sx={{ fontSize: { xs: 20, md: 30 } }} />
            }
            navButtonsProps={{ style: { background: "none" } }}
          >
            {teamMembers.map((mem) => (
              <TeamMember
                key={mem.id}
                name={mem.name}
                description={mem.description}
                designation={mem.designation}
              />
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Team;
