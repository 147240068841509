import React, { useEffect } from "react";
import { capitalize, getPrettyDate } from "../../utils";
import moment from "moment";

const PIA_Report = (props) => {
  const {
    summaryTableData,
    transactionData,
    enrolledUsers,
    startDate,
    endDate,
    loadingTableData,
    loadingTransactionData,
    loadingEnrolledUsers
  } = props;

  useEffect(() => {
    console.log({ transactionData, summaryTableData, enrolledUsers });
  }, [transactionData, summaryTableData, enrolledUsers]);

  return (
    <div>
       {loadingEnrolledUsers && loadingTableData && loadingTransactionData && <div className="d-flex justify-content-center">
        <div className="d-flex flex-column align-items-center">
          <p style={{ font: 15 }}>Loading... </p>
        <div
         class="spinner-border m-auto"
         role="status"
         aria-hidden="true"
      ></div>
      </div>
        </div>}
        {!loadingEnrolledUsers && !loadingTableData && !loadingTransactionData && 
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="summary"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th
                            colSpan={5}
                            style={{
                              textAlign: "center",
                              fontSize: 17,
                              fontWeight: "bold",
                              background: "rgba(58, 34, 85, 0.1)",
                            }}
                          >
                            PIA Centre Report (
                            {/* {moment(startDate).format("DD.MM.YYYY")} -{" "}
                            {moment(endDate).format("DD.MM.YYYY")}) */}
                          </th>
                        </tr>
                        <tr>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Centre
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Mobilization Revenue
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            No
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Placement Revenue
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            No
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {summaryTableData &&
                        summaryTableData?.consolidatedReport.length > 0 ? (
                          summaryTableData?.consolidatedReport.map((sum, i) => (
                            <tr
                              style={{
                                borderBottom:
                                  i ===
                                  summaryTableData.consolidatedReport.length - 1
                                    ? "1px #D6D6D6 solid"
                                    : "",
                              }}
                            >
                              <td style={{ borderRight: "1px #D6D6D6 solid" }}>
                                {sum?.centre || ""}
                              </td>
                              <td style={{ borderRight: "1px #D6D6D6 solid" }}>
                                {sum?.mobilizationRevenue > 0
                                  ? `₹${sum?.mobilizationRevenue.toLocaleString(
                                      "en-IN"
                                    )}`
                                  : "₹0"}
                              </td>
                              <td style={{ borderRight: "1px #D6D6D6 solid" }}>
                                {sum?.mobilizationCount || 0}
                              </td>
                              <td style={{ borderRight: "1px #D6D6D6 solid" }}>
                                {sum?.placementRevenue > 0
                                  ? `₹${sum?.placementRevenue.toLocaleString(
                                      "en-IN"
                                    )}`
                                  : "₹0"}
                              </td>
                              <td style={{ borderRight: "1px #D6D6D6 solid" }}>
                                {sum?.placementCount || 0}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={10}
                              style={{ textAlign: "center", fontSize: 14 }}
                            >
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="transaction" role="tabpanel">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th
                            colSpan={10}
                            style={{
                              textAlign: "center",
                              fontSize: 17,
                              fontWeight: "bold",
                              background: "rgba(58, 34, 85, 0.1)",
                            }}
                          >
                            Transaction Report
                          </th>
                        </tr>
                        <tr>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Transaction Date &#38; Time
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Event Date
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Centre Name
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Transaction Type
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Beneficiary Org Name
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Candidate Name
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Candidate Mobile
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Agreed Commission Rate
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Transaction Amount
                          </th>
                          <th style={{ fontWeight: 550 }}>Posted By</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactionData &&
                        transactionData?.transactions &&
                        JSON.parse(transactionData?.transactions?.transactions).length >
                          0 ? (
                            JSON.parse(transactionData?.transactions?.transactions).map(
                            (tran, i) => {
                              let commissionRate = 0;
                              // debugger
                              if (tran?.commissionType === "Fixed Rate") {
                                commissionRate =
                                  tran?.organization?.rate_placement || 0;
                              } else {
                                let profile = JSON.parse(tran?.profile);
                                commissionRate =
                                  profile?.percentage_recruitment || 0;
                              }
                              console.log({ commissionRate });
                              return (
                                <tr
                                  style={{
                                    borderBottom:
                                      i ===
                                      JSON.parse(transactionData.transactions.transactions)
                                        .length -
                                        1
                                        ? "1px #D6D6D6 solid"
                                        : "",
                                  }}
                                >
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {getPrettyDate(tran?.createdDate) || ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {getPrettyDate(tran?.commissionDate) || ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {tran?.user?.centre?.name || ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {tran?.transactionType
                                      ? capitalize(tran?.transactionType)
                                      : ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    Mafoi
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {tran?.user?.firstName || ""}{" "}
                                    {tran?.user?.lastName || ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {tran?.user?.mobile || ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {tran?.commissionType === "Fixed Rate"
                                      ? commissionRate > 0
                                        ? `₹${commissionRate.toLocaleString(
                                            "en-IN"
                                          )}`
                                        : "₹0"
                                      : `${commissionRate}%`}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {tran?.commission || ""}
                                  </td>
                                  <td>{tran?.createdBy?.firstName || ""}</td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td
                              colSpan={10}
                              style={{ textAlign: "center", fontSize: 14 }}
                            >
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="newCandidates" role="tabpanel">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th
                            colSpan={7}
                            style={{
                              textAlign: "center",
                              fontSize: 17,
                              fontWeight: "bold",
                              background: "rgba(58, 34, 85, 0.1)",
                            }}
                          >
                            New Candidates Enrolled
                          </th>
                        </tr>
                        <tr>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Transaction Date &#38; Time
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Centre Name
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Sourced By
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Sourced For
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Candidate Name
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Candidate Mobile
                          </th>
                          <th style={{ fontWeight: 550 }}>Posted By</th>
                        </tr>
                      </thead>
                      <tbody>
                        {enrolledUsers &&
                        enrolledUsers?.enrolledUsers &&
                        JSON.parse(enrolledUsers?.enrolledUsers?.users).length > 0 ? (
                          JSON.parse(enrolledUsers?.enrolledUsers?.users).map((user, i) => (
                            <tr
                              style={{
                                borderBottom:
                                  i ===
                                  JSON.parse(enrolledUsers.enrolledUsers.users).length - 1
                                    ? "1px #D6D6D6 solid"
                                    : "",
                              }}
                            >
                              <td style={{ borderRight: "1px #D6D6D6 solid" }}>
                                {getPrettyDate(user?.createdDate) || ""}
                              </td>
                              <td style={{ borderRight: "1px #D6D6D6 solid" }}>
                                {user?.centre?.name || ""}
                              </td>
                              <td style={{ borderRight: "1px #D6D6D6 solid" }}>
                                {user?.source === 1
                                  ? "PIA"
                                  : user?.source === 2
                                  ? "MCC"
                                  : user?.source === 3
                                  ? "Mobile App"
                                  : "Others"}
                              </td>
                              <td style={{ borderRight: "1px #D6D6D6 solid" }}>
                                {user?.forSource === 1
                                  ? "PIA"
                                  : ""}
                              </td>
                              <td style={{ borderRight: "1px #D6D6D6 solid" }}>
                                {user?.firstName || ""} {user?.lastName || ""}
                              </td>
                              <td style={{ borderRight: "1px #D6D6D6 solid" }}>
                                {user?.mobile || ""}
                              </td>
                              <td>{user?.createdBy || ""}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={10}
                              style={{ textAlign: "center", fontSize: 14 }}
                            >
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default PIA_Report;
