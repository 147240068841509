import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_STATES,
  GET_ALL_CITIES,
  GET_ALL_LOCATIONS,
} from "../../graphql/meta";
import {
  GET_CLUSTER_CENTRES,
  GET_OTHER_CENTRES,
  ADD_CENTRE_TO_CLUSTER,
  DELETE_CENTRE_FROM_CLUSTER,
} from "../../graphql/clusters";
import { Modal } from "react-bootstrap";
import Pagination from "../Shared/Pagination";

const ClusterDetails = (props) => {
  const { setClusterDetailsView, selectedClusterData, selectedClusterId } =
    props;
  const [centrePage, setCentrePage] = useState(0);
  const [centrePageSize, setCentrePageSize] = useState(10);
  const [centreFilter, setCentreFilter] = useState({});
  const [centreRowCount, setCentreRowCount] = useState(0);
  const [clusterPage, setClusterPage] = useState(0);
  const [clusterPageSize, setClusterPageSize] = useState(10);
  const [clusterFilter, setClusterFilter] = useState({});
  const [clusterRowCount, setClusterRowCount] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedCentre, setSelectedCentre] = useState("");

  const { data: states } = useQuery(GET_ALL_STATES, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: cities } = useQuery(GET_ALL_CITIES, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });
  const { data: locations } = useQuery(GET_ALL_LOCATIONS, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: otherCentres, refetch: getRemainingCentres } = useQuery(
    GET_OTHER_CENTRES,
    {
      variables: {
        clusterId: selectedClusterId,
        filter: centreFilter,
        page: centrePage + 1,
        limit: centrePageSize,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const { data: clusterCentres, refetch } = useQuery(GET_CLUSTER_CENTRES, {
    variables: {
      filter: { clusterId: selectedClusterId, ...clusterFilter },
      page: clusterPage + 1,
      limit: clusterPageSize,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [addCentreToCluster] = useMutation(ADD_CENTRE_TO_CLUSTER, {
    onCompleted: (data) => {
      const { createClusterCentre } = data;
      const { status, error } = createClusterCentre;
      console.log("CENTRE ADDED", status, error);
      if (status) {
        refetch();
        getRemainingCentres();
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [deleteCentreFromCentre] = useMutation(DELETE_CENTRE_FROM_CLUSTER, {
    onCompleted: (data) => {
      const { deleteClusterCentre } = data;
      const { status, error } = deleteClusterCentre;
      console.log("CENTRE DELETED", status, error);
      if (status) {
        setConfirmModal(false);
        refetch();
        getRemainingCentres();
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      otherCentres &&
      otherCentres.centresNotAssociatedWithCluster &&
      otherCentres.centresNotAssociatedWithCluster.count
    ) {
      setCentreRowCount(otherCentres.centresNotAssociatedWithCluster.count);
    }
  }, [otherCentres]);

  useEffect(() => {
    if (
      clusterCentres &&
      clusterCentres.clusterCentres &&
      clusterCentres.clusterCentres.count
    ) {
      console.log(clusterCentres.clusterCentres.count);
      setClusterRowCount(clusterCentres.clusterCentres.count);
    }
  }, [clusterCentres]);

  const ClusterCentres = ({ centre }) => {
    return (
      <tr>
        <td>
          <div className="d-flex justify-content-flex-start align-items-center">
            <span
              className="avatar avatar-cyan"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Avatar Name"
            >
              {centre.centre.name.substring(0, 1) || "-"}
            </span>
            <div className="font-15" style={{ marginLeft: 10 }}>
              {centre.centre.name}
            </div>
          </div>
        </td>
        <td>
          {centre?.centre?.state?.name || ""},{" "}
          {centre?.centre?.country?.name || ""}
        </td>
        <td>
          <button
            type="button"
            className="btn btn-icon"
            title="Delete"
            style={{ position: "relative", left: "15%" }}
            onClick={() => {
              setSelectedCentre(centre.id);
              setConfirmModal(true);
            }}
          >
            <i className="fa fa-trash" />
          </button>
        </td>
      </tr>
    );
  };

  const Centres = ({ centre }) => {
    return (
      <tr>
        <td>
          <div className="d-flex justify-content-flex-start align-items-center">
            <span
              className="avatar avatar-cyan"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Avatar Name"
            >
              {centre.name.substring(0, 1) || "-"}
            </span>
            <div className="font-15" style={{ marginLeft: 10 }}>
              {centre.name}
            </div>
          </div>
        </td>
        <td>
          {centre?.state?.name || ""}, {centre?.country?.name || ""}
        </td>
        <td>
          <button
            type="button"
            className="btn btn-icon"
            title="Add"
            style={{ position: "relative", left: "23%" }}
            onClick={() => {
              addCentreToCluster({
                variables: {
                  payload: {
                    clusterId: selectedClusterId,
                    centreId: centre.id,
                  },
                },
              });
            }}
          >
            <i className="fa fa-plus" />
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a onClick={() => setClusterDetailsView(false)}>
                  <span style={{ fontSize: 14, cursor: "pointer" }}>
                    Clusters
                  </span>
                </a>
              </li>
              <li class="breadcrumb-item">
                <span style={{ fontSize: 14 }}>
                  {selectedClusterData?.id ? selectedClusterData?.name : "New"}
                </span>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center ml-30">
        <ul className="nav nav-tabs page-header-tab">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="user-tab"
              data-toggle="tab"
              href="#centres"
            >
              Centres
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="user-tab"
              data-toggle="tab"
              href="#add-centres"
            >
              Search and Add Centres
            </a>
          </li>
        </ul>
      </div>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className={"tab-pane fade show active"}
              id="centres"
              role="tabpanel"
            >
              <div className="table-responsive">
                <table className="table table-hover table-vcenter table_custom spacing5 mb-0">
                  <thead>
                    <tr>
                      <th>Centre Name</th>
                      <th>Location</th>
                      <th className="w100">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clusterCentres?.clusterCentres?.clustercentres &&
                    clusterCentres?.clusterCentres?.clustercentres.length >
                      0 ? (
                      clusterCentres?.clusterCentres?.clustercentres.map(
                        (o) => {
                          console.log({ o });
                          return <ClusterCentres key={o.id} centre={o} />;
                        }
                      )
                    ) : (
                      <tr>
                        <td colSpan={3} style={{ textAlign: "center" }}>
                          No centre has been added to this cluster
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  page={clusterPage}
                  pageSize={clusterPageSize}
                  rowCount={clusterRowCount}
                  setPage={setClusterPage}
                  setPageSize={setClusterPageSize}
                />
              </div>
              <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
                <Modal.Header>
                  <Modal.Title>Confirm</Modal.Title>
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    onClick={() => setConfirmModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete the centre from this cluster?
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    onClick={() => {
                      deleteCentreFromCentre({
                        variables: {
                          ids: [selectedCentre],
                        },
                      });
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    onClick={() => setConfirmModal(false)}
                  >
                    No
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className={"tab-pane fade"} id="add-centres" role="tabpanel">
              <div className="d-flex justify-content-between">
                <div
                  className="col-lg-4 col-md-4 col-sm-12"
                  style={{ marginTop: 70 }}
                >
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="description"
                        className="form-control"
                        placeholder="Search"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={(e) => {
                          let mutableFilter = {
                            ...centreFilter,
                            name__icontains: e.target.value,
                          };
                          if (e.target.value === "null") {
                            const { name__icontains, ...restFilter } =
                              centreFilter;
                            mutableFilter = restFilter;
                          }
                          setCentreFilter(mutableFilter);
                        }}
                        // value={values.description}
                        // onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id={"organizationId"}
                        onChange={(e) => {
                          let mutableFilter = {
                            ...centreFilter,
                            stateId: e.target.value,
                          };
                          if (e.target.value === "null") {
                            const { stateId, ...restFilter } = centreFilter;
                            mutableFilter = restFilter;
                          }
                          setCentreFilter(mutableFilter);
                        }}
                        // onBlur={handleBlur}
                      >
                        <option selected disabled>
                          -- Select State --
                        </option>
                        <option value="null">None</option>
                        {states?.allStates &&
                          states?.allStates.map((s) => (
                            <option value={s.id}>{s.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id={"organizationId"}
                        onChange={(e) => {
                          let mutableFilter = {
                            ...centreFilter,
                            cityId: e.target.value,
                          };
                          if (e.target.value === "null") {
                            const { cityId, ...restFilter } = centreFilter;
                            mutableFilter = restFilter;
                          }
                          setCentreFilter(mutableFilter);
                        }}
                        // onBlur={handleBlur}
                      >
                        <option selected disabled>
                          -- Select City --
                        </option>
                        <option value="null">None</option>
                        {cities?.allCities &&
                          cities?.allCities.map((s) => (
                            <option value={s.id}>{s.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id={"organizationId"}
                        onChange={(e) => {
                          let mutableFilter = {
                            ...centreFilter,
                            locationId: e.target.value,
                          };
                          if (e.target.value === "null") {
                            const { locationId, ...restFilter } = centreFilter;
                            mutableFilter = restFilter;
                          }
                          setCentreFilter(mutableFilter);
                        }}
                        // onBlur={handleBlur}
                      >
                        <option selected disabled>
                          -- Select Location --
                        </option>
                        <option value="null">None</option>
                        {locations?.allLocations &&
                          locations?.allLocations.map((s) => (
                            <option value={s.id}>{s.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="table-responsive col-lg-8 col-md-8 col-sm-12">
                  <table className="table table-hover table-vcenter table_custom spacing5 mb-0">
                    <thead>
                      <tr>
                        <th>Centre Name</th>
                        <th>Location</th>
                        <th className="w100">Add Centre</th>
                      </tr>
                    </thead>
                    <tbody>
                      {otherCentres &&
                        otherCentres?.centresNotAssociatedWithCluster &&
                        otherCentres?.centresNotAssociatedWithCluster
                          ?.centres &&
                        otherCentres?.centresNotAssociatedWithCluster?.centres.map(
                          (centre) => (
                            <Centres centre={centre} key={centre.id} />
                          )
                        )}
                    </tbody>
                  </table>
                  <Pagination
                    page={centrePage}
                    pageSize={centrePageSize}
                    rowCount={centreRowCount}
                    setPage={setCentrePage}
                    setPageSize={setCentrePageSize}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClusterDetails;
