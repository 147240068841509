import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { CREATE_CLUSTER, UPDATE_CLUSTER } from "../../graphql/clusters";

const AddNewClusterForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setAddClusterView,
    selectedClusterData,
  } = props;

  console.log({ values });

  return (
    <>
      <div>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ml-20">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a onClick={() => setAddClusterView(false)}>
                    <span style={{ fontSize: 14, cursor: "pointer" }}>
                      Clusters
                    </span>
                  </a>
                </li>
                <li class="breadcrumb-item">
                  <span style={{ fontSize: 14 }}>
                    {selectedClusterData?.id
                      ? selectedClusterData?.name
                      : "New"}
                  </span>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="section-body mt-3">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                {/* <label style={{ padding: 8, backgroundColor: 'rgba(58, 34, 85, 0.1)', borderRadius: 5, fontWeight: 'bold' }}>Cluster Details</label> */}
                <div className="d-flex justify-content-flex-start">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <span>
                      Name <span style={{ color: "red" }}>*</span>
                    </span>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        placeholder="Name"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        value={values.name}
                        onBlur={handleBlur}
                      />
                      {touched.name && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.name}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/* <label style={{ padding: 8, backgroundColor: 'rgba(58, 34, 85, 0.1)', borderRadius: 5, fontWeight: 'bold' }}>Cluster Manager Details</label> */}
                <div className="d-flex justify-content-flex-start">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <span>
                      Description <span style={{ color: "red" }}>*</span>
                    </span>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="description"
                        className="form-control"
                        placeholder="Description"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        value={values.description}
                        onBlur={handleBlur}
                      />
                      {touched.description && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.description}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    style={{ marginTop: 15 }}
                  >
                    {selectedClusterData?.id ? "Save" : "Add"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setAddClusterView(false)}
                    style={{ marginTop: 15, marginLeft: 5 }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddNewCluster = (props) => {
  const { setAddClusterView, selectedClusterData } = props;

  const [createCluster] = useMutation(CREATE_CLUSTER, {
    onCompleted: (data) => {
      const { createCluster } = data;
      const { status, error } = createCluster;
      console.log("CLUSTER CREATED", status, error);
      if (status) {
        setAddClusterView(false);
      }
    },
  });

  const [updateCluster] = useMutation(UPDATE_CLUSTER, {
    onCompleted: (data) => {
      const { updateCluster } = data;
      const { status, error } = updateCluster;
      console.log("CLUSTER UPDATED", status, error);
      if (status) {
        setAddClusterView(false);
      }
    },
  });

  const AddNewClusterWrapped = withFormik({
    mapPropsToValues: (props) => {
      const { selectedClusterData } = props;
      return {
        name: selectedClusterData?.name || "",
        description: selectedClusterData?.description || "",
      };
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please enter the name of the cluster"),
      description: Yup.string().required(
        "Please enter a description for the cluster"
      ),
    }),

    enableReinitialize: true,
    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);

      const { selectedClusterData } = props;
      if (selectedClusterData?.id) {
        updateCluster({
          variables: {
            payload: {
              id: selectedClusterData.id,
              ...values,
              name: values.name.trim()
            },
          },
        });
      } else {
        createCluster({
          variables: {
            payload: {
              ...values,
              name: values.name.trim()
            },
          },
        });
      }
    },
  })(AddNewClusterForm);

  return (
    <AddNewClusterWrapped
      setAddClusterView={setAddClusterView}
      selectedClusterData={selectedClusterData}
    />
  );
};

export default AddNewCluster;
