import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import ciel_logo from "../../assets/imagesNew/ciel_logo_colored.png";
import login_img from "../../assets/imagesNew/login-img.svg";
import colors from "../../styles/colors";

import { useSnackbar } from "notistack";
import { withFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { LOGIN } from "../../graphql/users";
import getStore from "../../zustandStore";
import { isJwtTokenValid, getDefaultRoute } from "../../utils";

const LoginForm = (props) => {
  const {
    setForgotPasswordView,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;

  const history = useHistory();

  const [showPassowrd, setShowPassowrd] = useState(false);

  return (
    <Grid container spacing={2} sx={{ p: { xs: 3, sm: 10 } }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <img src={ciel_logo} alt="logo" />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
        sx={{ my: 2 }}
      >
        <img src={login_img} alt="login" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: 2.5 }}>
        <Typography fontSize="26px" fontWeight={500} color={colors.dark}>
          Login
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography>Email Address</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
        <TextField
          id="username"
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
        />
        {touched.username && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.username}
          </Typography>
        )}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography>Password</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
        <TextField
          type={showPassowrd ? "text" : "password"}
          id="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => setShowPassowrd(!showPassowrd)}
                >
                  {showPassowrd ? (
                    <VisibilityOutlined size="small" />
                  ) : (
                    <VisibilityOffOutlined size="small" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {touched.password && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.password}
          </Typography>
        )}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
        <Typography fontSize="14px" color="primary">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setForgotPasswordView(true)}
          >
            Forgot Password?
          </span>
        </Typography>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ mt: 3.5, display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{ width: "50%" }}
          onClick={handleSubmit}
        >
          Login
        </Button>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ mt: -1, display: "flex", justifyContent: "center" }}
      >
        <Typography fontSize="14px">
          Don&apos;t have an account?{" "}
          <span
            style={{
              color: colors.primary,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => history.push("/employer-registration")}
          >
            Register
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};

const LoginFormFormik = (props) => {
  const history = useHistory();
  const setLoading = getStore((state) => state.setLoading);
  const { enqueueSnackbar } = useSnackbar();

  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted: (data) => {
      const { loginUser } = data;
      const { status, token, me } = loginUser;
      if (status) {
        localStorage.setItem("token", token);
        localStorage.setItem("me", JSON.stringify(me));
        const { roles } = isJwtTokenValid();
        if (roles[0] === "pia_user") {
          history.push("/candidates");
        } else if (roles[0] === "employer_user") {
          history.push("/employer-dashboard");
        } else {
          history.push("/dashboard");
        }
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("me");
        enqueueSnackbar("Invalid username or password", {
          variant: "error",
          sx: { "& .SnackbarContent-root": { backgroundColor: colors.danger } },
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    const defaultRoute = getDefaultRoute();

    if (defaultRoute !== "/") {
      history.push(defaultRoute);
    }
  }, [history]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const LoginWrapped = withFormik({
    mapPropsToValues: () => ({
      username: "",
      password: "",
    }),

    validationSchema: Yup.object().shape({
      username: Yup.string()
        .required("Please enter your email address")
        .email("Please enter a valid email address"),
      password: Yup.string().required("Please enter your password"),
    }),

    handleSubmit(values, { props }) {
      login({
        variables: {
          ...values,
        },
      });
    },
  })(LoginForm);

  return <LoginWrapped {...props} />;
};

export default LoginFormFormik;
