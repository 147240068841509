import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import no_jobs_dashboard_employer from "../../assets/imagesNew/no-jobs-dashboard-employer.svg";
import colors from "../../styles/colors";

const NoJobsView = (props) => {
  const { jobsData } = props;

  const history = useHistory();

  const [org, setOrg] = useState("");

  useEffect(() => {
    if (jobsData && jobsData.jobs && jobsData.jobs.jobs) {
      if (
        jobsData.jobs.jobs[0] &&
        jobsData.jobs.jobs[0].organization &&
        jobsData.jobs.jobs[0].organization.name
      ) {
        setOrg(jobsData.jobs.jobs[0].organization.name);
      }
    }
  }, [jobsData]);
  return (
    <Grid container spacing={2} sx={{ py: 10 }}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
      >
        <img src={no_jobs_dashboard_employer} alt="no jobs" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography textAlign="center">Welcome!</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography
          textAlign="center"
          color={colors.dark}
          fontSize="28px"
          fontWeight={500}
        >
          {org}
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography textAlign="center">
          Your dashboard is lonely. Post a job to cheer it up
        </Typography>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
      >
        <Button
          variant="contained"
          onClick={() => history.push("/employer-add-job/new")}
        >
          Post Your First Job
        </Button>
      </Grid>
    </Grid>
  );
};

export default NoJobsView;
