import React from "react";
import { Grid } from "@mui/material";
import LeftPartInfo from "./LeftPartInfo";
import RightPartForm from "./RightPartForm";
import colors from "../../styles/colors";

const EmployerSelfRegistration = (props) => {
  return (
    <Grid container>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={6}
        sx={{
          display: { xs: "none", sm: "block" },
          backgroundColor: colors.primary,
          minHeight: "100vh",
        }}
      >
        <LeftPartInfo />
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={12}
        sx={{ maxHeight: "100vh", overflow: "auto" }}
      >
        <RightPartForm />
      </Grid>
    </Grid>
  );
};

export default EmployerSelfRegistration;
