import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Paper,
  Autocomplete,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { gendersArr } from "../../constants";
import colors from "../../styles/colors";

const GeneralDetails = (props) => {
  const { values, setFieldValue, handleBlur, touched, errors } = props;

  const [minGradYear, setMinGradYear] = useState(
    values.minimumYearOfPassing || moment()
  );
  const [maxGradYear, setMaxGradYear] = useState(
    values.maximumYearOfPassing || moment()
  );
  const [lastDateApply, setLastDateApply] = useState(
    values.closure || moment().add(1, "days")
  );

  const handleChangeMinGradYear = (newValue) => {
    setMinGradYear(moment(newValue).startOf("year"));
  };

  const handleChangeMaxGradYear = (newValue) => {
    setMaxGradYear(moment(newValue).startOf("year"));
  };

  const handleChangeLastDateApply = (newValue) => {
    setLastDateApply(newValue);
  };

  useEffect(() => {
    setFieldValue(
      "minimumYearOfPassing",
      moment(minGradYear).format("YYYY-MM-DD")
    );
  }, [minGradYear, setFieldValue]);

  useEffect(() => {
    setFieldValue(
      "maximumYearOfPassing",
      moment(maxGradYear).format("YYYY-MM-DD")
    );
  }, [maxGradYear, setFieldValue]);

  useEffect(() => {
    setFieldValue("closure", moment(lastDateApply).format("YYYY-MM-DD"));
  }, [lastDateApply, setFieldValue]);

  return (
    <Paper elevation={1} sx={{ py: { xs: 2, sm: 3 }, px: 4 }}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography color={colors.dark} fontSize="14px">
            Work Experience <span style={{ fontSize: "11px" }}>(in years)</span>
          </Typography>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">Minimum</Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: { xs: "none", lg: "block" } }}
        >
          <Typography fontSize="13px">Maximum</Typography>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <TextField
            id="minimumExperience"
            value={values.minimumExperience}
            onChange={(e) =>
              setFieldValue("minimumExperience", parseFloat(e.target.value))
            }
            onBlur={handleBlur}
            size="small"
            type="number"
            fullWidth
          />
          {touched.minimumExperience && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.minimumExperience}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: { xs: "block", lg: "none" } }}
        >
          <Typography fontSize="13px">Maximum</Typography>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <TextField
            id="maximumExperience"
            value={values.maximumExperience}
            onChange={(e) =>
              setFieldValue("maximumExperience", parseFloat(e.target.value))
            }
            onBlur={handleBlur}
            size="small"
            type="number"
            fullWidth
          />
          {touched.maximumExperience && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.maximumExperience}
            </Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography color={colors.dark} fontSize="14px">
            Year of Passing
          </Typography>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">From</Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: { xs: "none", lg: "block" } }}
        >
          <Typography fontSize="13px">To</Typography>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={minGradYear}
              onChange={handleChangeMinGradYear}
              views={["year"]}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: { xs: "block", lg: "none" } }}
        >
          <Typography fontSize="13px">To</Typography>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={maxGradYear}
              onChange={handleChangeMaxGradYear}
              views={["year"]}
              renderInput={(params) => (
                <TextField {...params} size="small" onBlur={handleBlur} />
              )}
            />
          </LocalizationProvider>
          {touched.maximumYearOfPassing && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.maximumYearOfPassing}
            </Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
          <Typography color={colors.dark} fontSize="14px">
            Gender Preference
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Autocomplete
            id="genderPreference"
            autoComplete
            disableClearable
            options={gendersArr}
            getOptionLabel={(option) => option.name}
            value={gendersArr.find((x) => x.id === values.genderPreference)}
            onChange={(event, value) =>
              setFieldValue("genderPreference", value.id)
            }
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Choose..."
                onBlur={handleBlur}
              />
            )}
          />
          {touched.genderPreference && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.genderPreference}
            </Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">Languages Known</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <Autocomplete
            id="languages"
            autoComplete
            disableClearable
            multiple
            freeSolo
            options={[]}
            value={values.languages}
            onChange={(event, newArr) => setFieldValue("languages", newArr)}
            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Add here" />
            )}
            ChipProps={{
              sx: { backgroundColor: colors.primary, color: colors.secondary },
            }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">Last date to apply</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={lastDateApply}
              onChange={handleChangeLastDateApply}
              inputFormat="DD/MM/YYYY"
              Format="DD/MM/YYYY"
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  onBlur={handleBlur}
                />
              )}
              disablePast
            />
          </LocalizationProvider>
          {touched.closure && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.closure}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default GeneralDetails;
