import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import timeline from "../../assets/imagesNew/aboutUs_timeline.svg";
import colors from "../../styles/colors";

const Timeline = (props) => {
  return (
    <Box
      sx={{
        px: { xs: 2, sm: 5, md: 7, lg: 10 },
        py: 4,
        backgroundColor: colors.secondary,
      }}
    >
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography textAlign="center" fontSize="30px" fontWeight={600}>
            Our Timeline
          </Typography>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginTop: 15,
            }}
          >
            <div
              style={{
                backgroundColor: colors.primary,
                height: 3,
                borderRadius: "3px",
                width: 100,
              }}
            />
          </div>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <img src={timeline} alt="timeline" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Timeline;
