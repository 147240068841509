import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import colors from "../styles/colors";

const DonutPieChart = (props) => {
  const { title, subtitle, data, partColors } = props;
  const options = {
    title: {
      text: title,
      align: "center",
      verticalAlign: "middle",
      y: -10,
      style: { color: colors.dark, fontSize: "24px", fontWeight: 700 },
    },
    subtitle: {
      text: subtitle,
      align: "center",
      verticalAlign: "middle",
      style: { color: colors.gray, fontSize: "14px", fontWeight: 500 },
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    chart: {
      type: "pie",
      marginTop: 0,
      spacingTop: 0,
    },
    legend: {
      labelFormatter: function () {
        return this.name + " (" + Math.round(this.percentage) + "%)";
      },
    },
    plotOptions: {
      pie: {
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        colors: partColors,
      },
    },
    series: [
      {
        type: "pie",
        size: 200,
        innerSize: "80%",
        data: data,
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default DonutPieChart;
