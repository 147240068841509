import React, { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Button,
  useMediaQuery,
} from "@mui/material";
import { Menu, Login } from "@mui/icons-material";
import MissonVision from "./MissonVision";
import Team from "./Team";
import Credo from "./Credo";
import Timeline from "./Timeline";
import Footer from "../HomePage/Footer";
import LoginDrawer from "../HomePage/LoginDrawer";
import ciel_logo_colored from "../../assets/imagesNew/ciel_logo_colored.png";
import theme from "../../styles/theme";
import colors from "../../styles/colors";

const drawerWidth = 240;
const navItems = [
  { title: "Employers", to: "/" },
  { title: "About Us", to: "/about-us" },
];

function DrawerAppBar(props) {
  const { window } = props;

  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const location = useLocation();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [loginDrawer, setLoginDrawer] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img
        src={ciel_logo_colored}
        alt="logo"
        style={{ height: 40, marginBottom: 15, marginTop: 15 }}
      />
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem
            key={item.title}
            disablePadding
            sx={{
              borderLeft: index === 0 ? `3px solid ${colors.primary}` : "none",
            }}
          >
            <ListItemButton onClick={() => history.push("/")}>
              <ListItemText
                primary={item.title}
                sx={{ color: index === 0 ? colors.primary : colors.gray }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => setLoginDrawer(true)}>
            <ListItemIcon>
              <Login fontSize="small" sx={{ color: colors.gray }} />
            </ListItemIcon>
            <ListItemText
              primary="Login"
              sx={{ color: colors.gray, fontSize: "15px" }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        color="secondary"
        elevation={0}
        sx={{ px: { xs: 0, sm: 3 } }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <Menu />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <img
              src={ciel_logo_colored}
              alt="logo"
              style={{ height: matchesSm ? 30 : 50 }}
            />
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" }, flexGrow: 1 }}>
            {navItems.map((item, index) => (
              <NavLink
                key={item.title}
                to={item.to}
                style={{
                  color:
                    location.pathname === item.to
                      ? colors.primary
                      : colors.gray,
                  fontSize: "15px",
                  fontWeight: 500,
                  marginRight: index === 0 || index === 1 ? 15 : 0,
                }}
              >
                {item.title}
              </NavLink>
            ))}
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button size="small" onClick={() => setLoginDrawer(true)}>
              Login
            </Button>
            <Button
              size="small"
              onClick={() => history.push("/employer-registration")}
            >
              Sign Up
            </Button>
          </Box>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Button
              size="small"
              onClick={() => history.push("/employer-registration")}
            >
              Sign Up
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ width: "100%" }}>
        <Toolbar />
        <MissonVision />
        <Team />
        <Credo />
        <Timeline />
        <Footer />
        {loginDrawer && (
          <LoginDrawer open={loginDrawer} setOpen={setLoginDrawer} />
        )}
      </Box>
    </Box>
  );
}

export default DrawerAppBar;
