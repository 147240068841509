import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import UploadWrapper from "../../common/UploadWrapper";
import HorizontalDivider from "../../common/HorizontalDivider";
import { getMIMETypeOfFile } from "../../helpers";
import colors from "../../styles/colors";

import { withFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useQuery, useMutation } from "@apollo/client";
import { GET_COUNTRY, GET_STATES, GET_CITIES } from "../../graphql/MasterData";
import {
  UPSERT_COMPANY_DETAILS,
  GET_COMPANY_DETAILS,
  GET_COMPANY_LOGO,
} from "../../graphql/EmployerProfile";
import getStore from "../../zustandStore";
import { getLoggedInUserId } from "../../helpers";

function ViewLogo({ alreadyUploadedFile }) {
  return (
    <>
      {!alreadyUploadedFile ? (
        <Box
          sx={{
            minHeight: 85,
            border: `1px solid ${colors.strokeGray}`,
            borderRadius: "4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>LOGO</Typography>
        </Box>
      ) : (
        <img
          src={`data:image/${getMIMETypeOfFile(
            alreadyUploadedFile.type
          )};base64,${alreadyUploadedFile.base64}`}
          style={{ maxHeight: 90 }}
          alt="logo"
        />
      )}
    </>
  );
}

const CompanyDetails = (props) => {
  const {
    editMode,
    setEditMode,
    setSelectedFile,
    selectedFile,
    uploadedFile,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched,
    handleSubmit,
  } = props;

  const [countryArr, setCountryArr] = useState([]);
  const [stateArr, setStateArr] = useState([]);
  const [cityArr, setCityArr] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({ id: "", name: "" });
  const [selectedState, setSelectedState] = useState({ id: "", name: "" });
  const [selectedCity, setSelectedCity] = useState({ id: "", name: "" });

  const { data: countries } = useQuery(GET_COUNTRY, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: states } = useQuery(GET_STATES, {
    variables: {
      country: values.countryId,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    skip: !values.countryId,
  });

  const { data: cities } = useQuery(GET_CITIES, {
    variables: {
      state: values.stateId,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    skip: !values.stateId,
  });

  useEffect(() => {
    if (countries && countries.countries) {
      setCountryArr(countries.countries);
    }
  }, [countries]);

  useEffect(() => {
    if (states && states.states) {
      setStateArr(states.states);
    }
  }, [states]);

  useEffect(() => {
    if (cities && cities.cities) {
      setCityArr(cities.cities);
    }
  }, [cities]);

  useEffect(() => {
    if (values && values.countryId) {
      setSelectedCountry(
        countryArr.find((x) => x.id === values.countryId) || {
          id: "",
          name: "",
        }
      );
    }
  }, [countryArr, values]);

  useEffect(() => {
    if (values && values.stateId) {
      setSelectedState(
        stateArr.find((x) => x.id === values.stateId) || { id: "", name: "" }
      );
    }
  }, [stateArr, values]);

  useEffect(() => {
    if (values && values.cityId) {
      setSelectedCity(
        cityArr.find((x) => x.id === values.cityId) || { id: "", name: "" }
      );
    }
  }, [cityArr, values]);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Typography color={colors.dark} fontSize="20px">
          Company Details
        </Typography>
      </Grid>
      {!editMode && (
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={6}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            size="small"
            variant="contained"
            onClick={() => setEditMode(true)}
          >
            Edit
          </Button>
        </Grid>
      )}
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Typography fontSize="14px">Company Logo</Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        {editMode ? (
          <UploadWrapper
            handleUpload={(file) => setSelectedFile(file)}
            newUploadedFile={selectedFile}
            alreadyUploadedFile={uploadedFile}
          />
        ) : (
          <ViewLogo alreadyUploadedFile={uploadedFile} />
        )}
      </Grid>
      <Grid item lg={9} md={9} display={{ xs: "none", md: "block" }} />
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px">Company Name</Typography>
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12} sx={{ mt: -1 }}>
        <TextField
          id="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
          disabled={!editMode}
        />
        {touched.name && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.name}
          </Typography>
        )}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px">Company Description</Typography>
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12} sx={{ mt: -1 }}>
        <TextField
          id="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
          multiline
          minRows={4}
          disabled={!editMode}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px">Website</Typography>
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12} sx={{ mt: -1 }}>
        <TextField
          id="website"
          value={values.website}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
          disabled={!editMode}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px">Address</Typography>
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12} sx={{ mt: -1 }}>
        <TextField
          id="address"
          value={values.address}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
          multiline
          disabled={!editMode}
        />
        {touched.address && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.address}
          </Typography>
        )}
      </Grid>
      <Grid item lg={4} md={4} display={{ xs: "none", md: "block" }} />
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Typography fontSize="14px">Country</Typography>
      </Grid>
      <Grid
        item
        lg={4}
        md={4}
        sm={12}
        xs={12}
        display={{ xs: "none", md: "block" }}
      >
        <Typography fontSize="14px">State</Typography>
      </Grid>
      <Grid item lg={4} md={4} display={{ xs: "none", md: "block" }} />
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: -1 }}>
        <Autocomplete
          id="countryId"
          autoComplete
          disableClearable
          options={countryArr}
          getOptionLabel={(option) => option.name}
          value={selectedCountry}
          onChange={(event, value) => setFieldValue("countryId", value.id)}
          size="small"
          fullWidth
          disabled={!editMode}
          renderInput={(params) => (
            <TextField {...params} size="small" onBlur={handleBlur} />
          )}
        />
        {touched.countryId && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.countryId}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        lg={4}
        md={4}
        sm={12}
        xs={12}
        display={{ xs: "block", md: "none" }}
      >
        <Typography fontSize="14px">State</Typography>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: -1 }}>
        <Autocomplete
          id="stateId"
          autoComplete
          disableClearable
          options={stateArr}
          getOptionLabel={(option) => option.name}
          value={selectedState}
          onChange={(event, value) => setFieldValue("stateId", value.id)}
          size="small"
          fullWidth
          disabled={!editMode}
          renderInput={(params) => (
            <TextField {...params} size="small" onBlur={handleBlur} />
          )}
        />
        {touched.stateId && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.stateId}
          </Typography>
        )}
      </Grid>
      <Grid item lg={4} md={4} display={{ xs: "none", md: "block" }} />
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Typography fontSize="14px">City</Typography>
      </Grid>
      <Grid
        item
        lg={4}
        md={4}
        sm={12}
        xs={12}
        display={{ xs: "none", md: "block" }}
      >
        <Typography fontSize="14px">Pincode</Typography>
      </Grid>
      <Grid item lg={4} md={4} display={{ xs: "none", md: "block" }} />
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: -1 }}>
        <Autocomplete
          id="cityId"
          autoComplete
          disableClearable
          options={cityArr}
          getOptionLabel={(option) => option.name}
          value={selectedCity}
          onChange={(event, value) => setFieldValue("cityId", value.id)}
          size="small"
          fullWidth
          disabled={!editMode}
          renderInput={(params) => (
            <TextField {...params} size="small" onBlur={handleBlur} />
          )}
        />
        {touched.cityId && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.cityId}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        lg={4}
        md={4}
        sm={12}
        xs={12}
        display={{ xs: "block", md: "none" }}
      >
        <Typography fontSize="14px">Pincode</Typography>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: -1 }}>
        <TextField
          id="pincode"
          value={values.pincode}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
          disabled={!editMode}
        />
        {touched.pincode && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.pincode}
          </Typography>
        )}
      </Grid>
      {editMode && (
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ my: 1 }}>
          <HorizontalDivider />
        </Grid>
      )}
      {editMode && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setEditMode(false);
                  setSelectedFile(null);
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button size="small" variant="contained" onClick={handleSubmit}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const CompanyDetailsFormik = (props) => {
  const setLoading = getStore((state) => state.setLoading);
  const { enqueueSnackbar } = useSnackbar();

  const [editMode, setEditMode] = useState(false);
  const [userId, setUserId] = useState("");
  const [orgDetails, setOrgDetails] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  const {
    data: companyDetails,
    loading: gettingDetails,
    refetch,
  } = useQuery(GET_COMPANY_DETAILS, {
    variables: {
      id: userId,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    skip: !userId,
  });

  const { data: logo, refetch: refetchLogo } = useQuery(GET_COMPANY_LOGO, {
    variables: {},
  });

  const [updateCompanyDetails, { loading }] = useMutation(
    UPSERT_COMPANY_DETAILS,
    {
      onCompleted: (data) => {
        const { updateEmployerCompany } = data;
        const { status, error } = updateEmployerCompany;
        if (status) {
          refetch();
          refetchLogo();
          setEditMode(false);
          enqueueSnackbar(error, {
            variant: "success",
            sx: {
              "& .SnackbarContent-root": { backgroundColor: colors.success },
            },
          });
        } else {
          enqueueSnackbar(error, {
            variant: "error",
            sx: {
              "& .SnackbarContent-root": { backgroundColor: colors.danger },
            },
          });
        }
      },
    }
  );

  useEffect(() => {
    const me = getLoggedInUserId();
    if (me) {
      const temp = JSON.parse(me);
      if (temp && temp.id) {
        setUserId(temp.id);
      }
    }
  }, []);

  useEffect(() => {
    if (
      companyDetails &&
      companyDetails.user &&
      companyDetails.user.organization
    ) {
      setOrgDetails(companyDetails.user.organization);
    }
  }, [companyDetails]);

  useEffect(() => {
    if (
      logo &&
      logo.organizationDocuments &&
      logo.organizationDocuments.document
    ) {
      setUploadedFile({
        base64: logo.organizationDocuments.document,
        type: logo.organizationDocuments.documentType,
      });
    }
  }, [logo]);

  useEffect(() => {
    setLoading(loading || gettingDetails);
  }, [gettingDetails, loading, setLoading]);

  const CompanyDetailsWrapped = withFormik({
    mapPropsToValues: () => ({
      name: orgDetails?.name || "",
      description: orgDetails?.description || "",
      website: orgDetails?.website || "",
      address: orgDetails?.address || "",
      countryId: orgDetails?.country?.id || "",
      stateId: orgDetails?.state?.id || "",
      cityId: orgDetails?.city?.id || "",
      pincode: orgDetails?.pincode || "",
    }),

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please enter the company name"),
      address: Yup.string().required("Please enter the address"),
      pincode: Yup.string().matches(
        /^[0-9]{6}$/,
        "Pincode must be exactly 6 digits"
      ),
      countryId: Yup.string().required("Please select a country"),
      stateId: Yup.string().required("Please select a state"),
      cityId: Yup.string().required("Please select a city"),
    }),

    handleSubmit(values, { props }) {
      const { handleReset } = props;
      updateCompanyDetails({
        variables: {
          payload: {
            ...values,
          },
          file: selectedFile,
        },
      });
      handleReset(values);
    },
  })(CompanyDetails);

  return (
    <CompanyDetailsWrapped
      editMode={editMode}
      setEditMode={setEditMode}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
      uploadedFile={uploadedFile}
    />
  );
};

export default CompanyDetailsFormik;
