import colors from "../../styles/colors";

export function setDonutPieChartData(
  totalCandidates,
  chartsData,
  setShortlistedRatio,
  setInterviewedRatio,
  setRejectedRatio,
  setGenderRatio
) {
  if (totalCandidates > 0) {
    if (
      chartsData &&
      chartsData.getEmployerDashboard &&
      chartsData.getEmployerDashboard.shortlistedRatio
    ) {
      let temp = JSON.parse(chartsData.getEmployerDashboard.shortlistedRatio);
      for (let i = 0; i < temp.length; i++) {
        temp[i].y = (temp[i].y / totalCandidates) * 100;
        temp[i].name =
          temp[i].name.charAt(0).toUpperCase() + temp[i].name.slice(1);
        temp[i] = { ...temp[i], color: colors.warning };
      }
      temp.push({
        y: 100 - temp[0].y,
        name: "",
        color: "#EFEFEF",
      });
      setShortlistedRatio(temp);
    }
    if (
      chartsData &&
      chartsData.getEmployerDashboard &&
      chartsData.getEmployerDashboard.interviewedRatio
    ) {
      let temp = JSON.parse(chartsData.getEmployerDashboard.interviewedRatio);
      for (let i = 0; i < temp.length; i++) {
        temp[i].y = (temp[i].y / totalCandidates) * 100;
        temp[i].name =
          temp[i].name.charAt(0).toUpperCase() + temp[i].name.slice(1);
        temp[i] = { ...temp[i], color: colors.success };
      }
      temp.push({
        y: 100 - temp[0].y,
        name: "",
        color: "#EFEFEF",
      });
      setInterviewedRatio(temp);
    }
    if (
      chartsData &&
      chartsData.getEmployerDashboard &&
      chartsData.getEmployerDashboard.rejectedRatio
    ) {
      let temp = JSON.parse(chartsData.getEmployerDashboard.rejectedRatio);
      for (let i = 0; i < temp.length; i++) {
        temp[i].y = (temp[i].y / totalCandidates) * 100;
        temp[i].name =
          temp[i].name.charAt(0).toUpperCase() + temp[i].name.slice(1);
        temp[i] = { ...temp[i], color: colors.success };
      }
      temp.push({
        y: 100 - temp[0].y,
        name: "",
        color: "#EFEFEF",
      });
      setRejectedRatio(temp);
    }
    if (
      chartsData &&
      chartsData.getEmployerDashboard &&
      chartsData.getEmployerDashboard.genderRatio
    ) {
      let temp = JSON.parse(chartsData.getEmployerDashboard.genderRatio);
      for (let i = 0; i < temp.length; i++) {
        temp[i].y = (temp[i].y / totalCandidates) * 100;
        temp[i].name =
          temp[i].name.charAt(0).toUpperCase() + temp[i].name.slice(1);
        if (temp[i].name === "Male") {
          temp[i] = { ...temp[i], color: colors.warning };
        } else {
          temp[i] = { ...temp[i], color: colors.primary };
        }
      }
      setGenderRatio(temp);
    }
  }
}

export function setColumnLineChartData(
  chartsData,
  setXAxis,
  setGraphData,
  spanOfData
) {
  if (
    chartsData &&
    chartsData.getEmployerDashboard &&
    chartsData.getEmployerDashboard.chartData
  ) {
    const temp = JSON.parse(chartsData.getEmployerDashboard.chartData);
    let tempX = temp.x;
    let tempY = temp.y;
    if (spanOfData === "MONTHLY" || spanOfData === "custom") {
      for (let i = 0; i < tempX.length; i++) {
        tempX[i] = `Week ${tempX[i]}`;
      }
    }
    setXAxis(tempX);
    for (let i = 0; i < tempY.length; i++) {
      tempY[i] = {
        ...tempY[i],
        name: tempY[i].name.charAt(0).toUpperCase() + tempY[i].name.slice(1),
        type: tempY[i].name === "total" ? "line" : "column",
        showInLegend: tempY[i].name === "total" ? false : true,
      };
    }
    setGraphData(tempY);
  }
}
