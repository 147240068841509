import React, { useState } from "react";
import { Box } from "@mui/material";
import RegistrationForm from "./RegistrationForm";
import OtpForm from "./OtpForm";
import AccountVerified from "./AccountVerified";
import CompanyDetailsForm from "./CompanyDetailsForm";
import RegistrationComplete from "./RegistrationComplete";
import colors from "../../styles/colors";

const RightPartForm = (props) => {
  const [viewType, setViewType] = useState("registration");
  const [registeredUser, setRegisteredUser] = useState("");
  const [enteredMobileNumber, setEnteredMobileNumber] = useState("");
  const [enteredCompanyName, setEnteredCompanyName] = useState("");
  const [enteredValues, setEnteredValues] = useState({});

  return (
    <Box
      sx={{
        py: { xs: 5, sm: 3 },
        px: { xs: 5, sm: 10 },
        backgroundColor: { xs: colors.primary, sm: colors.secondary },
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: colors.secondary,
          borderRadius: "4px",
        }}
      >
        {viewType === "registration" && (
          <RegistrationForm
            setViewType={setViewType}
            setRegisteredUser={setRegisteredUser}
            setEnteredMobileNumber={setEnteredMobileNumber}
            setEnteredCompanyName={setEnteredCompanyName}
            setEnteredValues={setEnteredValues}
          />
        )}
        {viewType === "otp" && (
          <OtpForm
            setViewType={setViewType}
            registeredUser={registeredUser}
            enteredMobileNumber={enteredMobileNumber}
            enteredValues={enteredValues}
          />
        )}
        {viewType === "accountVerified" && (
          <AccountVerified setViewType={setViewType} />
        )}
        {viewType === "companyDetails" && (
          <CompanyDetailsForm
            setViewType={setViewType}
            enteredCompanyName={enteredCompanyName}
          />
        )}
        {viewType === "registrationSuccessful" && <RegistrationComplete />}
      </Box>
    </Box>
  );
};

export default RightPartForm;
