import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import Modal from "../../common/CommonModal";
import circle_tick from "../../assets/imagesNew/circle-tick.svg";
import colors from "../../styles/colors";

const SuccessModal = (props) => {
  const { open, setOpen } = props;
  return (
    <Modal open={open} setOpen={setOpen}>
      <Grid container spacing={2}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <img src={circle_tick} alt="tick" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            fontSize="24px"
            fontWeight={500}
            color={colors.dark}
            textAlign="center"
          >
            Candidate has been successfully added
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            fontSize="16px"
            color={colors.lightGray}
            textAlign="center"
          >
            To finalise the candidate&apos;s selection or rejection go to the
            “Applied Candidates”
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <Button variant="contained" onClick={() => setOpen(false)}>
            Done
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SuccessModal;
