import { gql } from "@apollo/client";

export const GET_CLUSTERS = gql`
  query clusters($filter: ClusterFilterInput, $limit: Int, $page: Int) {
    clusters(filter: $filter, limit: $limit, page: $page) {
      count
      clusters {
        id
        name
        description
        centresCount
      }
    }
  }
`;

export const GET_CLUSTER = gql`
  query cluster($id: String!) {
    cluster(id: $id) {
      id
      name
      description
    }
  }
`;

export const CREATE_CLUSTER = gql`
  mutation createCluster($payload: ClusterCreateInput!) {
    createCluster(payload: $payload) {
      status
      error
    }
  }
`;

export const UPDATE_CLUSTER = gql`
  mutation updateCluster($payload: ClusterUpdateInput!) {
    updateCluster(payload: $payload) {
      status
      error
    }
  }
`;

export const ADD_CENTRE_TO_CLUSTER = gql`
  mutation addCentreToCluster($payload: ClusterCentreCreateInput!) {
    createClusterCentre(payload: $payload) {
      status
      error
    }
  }
`;

export const GET_CLUSTER_CENTRES = gql`
  query clustersCentres(
    $filter: ClusterCentreFilterInput
    $limit: Int
    $page: Int
  ) {
    clusterCentres(filter: $filter, limit: $limit, page: $page) {
      count
      clustercentres {
        id
        cluster {
          id
          name
        }
        centre {
          id
          name
          country {
            id
            name
          }
          state {
            id
            name
          }
          city {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_OTHER_CENTRES = gql`
  query centresNotAssociatedWithCluster(
    $clusterId: String!
    $limit: Int
    $page: Int
    $filter: CentreFilterInput
  ) {
    centresNotAssociatedWithCluster(
      clusterId: $clusterId
      limit: $limit
      page: $page
      filter: $filter
    ) {
      count
      centres {
        id
        name
        country {
          id
          name
        }
        state {
          id
          name
        }
        city {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_CENTRE_FROM_CLUSTER = gql`
  mutation deleteClusterCentre($ids: [String!]) {
    deleteClusterCentre(ids: $ids) {
      status
      error
    }
  }
`;

export const DELETE_CLUSTER = gql`
  mutation deleteCluster($ids: [String!]) {
    deleteCluster(ids: $ids) {
      status
      error
    }
  }
`;
