const accessControlMenu = [
  {
    role: "mcc_ho_user",
    menuContent: [
      // {
      //     "id": "Directories"
      // },
      {
        id: 1,
        icon: "icon-rocket",
        label: "Dashboard",
        to: "/dashboard",
      },
      {
        id: 2,
        icon: "icon-user",
        label: "Candidates",
        to: "/candidates",
      },
      {
        id: 3,
        icon: "icon-briefcase",
        label: "Jobs",
        to: "/jobs-list",
      },
      {
        id: 5,
        icon: "icon-feed",
        label: "Community",
        to: "/community",
      },
      {
        id: 6,
        icon: "icon-bar-chart",
        label: "Reports",
        to: "/reports",
      },
      {
        id: 7,
        icon: "icon-settings",
        label: "Settings",
        // "to": "/settings",
        content: [
          {
            id: 8,
            label: "Manage Users",
            to: "/manage-users",
          },
          {
            id: 9,
            label: "Manage Centres",
            to: "/manage-centres",
          },
          {
            id: 10,
            label: "Manage Clusters",
            to: "/manage-clusters",
          },
          {
            id: 11,
            label: "Manage Orgs",
            to: "/organizations",
          },
        ],
      },
    ],
    editJobs: false,
    addApplicants: false,
    editCandidates: false,
    editTimeline: false,
    // editJobs: true,
    // editCandidates: true,
    // editTimeline: true
  },
  {
    role: "mcc_state_head",
    menuContent: [
      {
        id: 1,
        icon: "icon-rocket",
        label: "Dashboard",
        to: "/dashboard",
      },
      {
        id: 2,
        icon: "icon-user",
        label: "Candidates",
        to: "/candidates",
      },
      {
        id: 3,
        icon: "icon-briefcase",
        label: "Jobs",
        to: "/jobs-list",
      },
      {
        id: 5,
        icon: "icon-feed",
        label: "Community",
        to: "/community",
      },
      {
        id: 6,
        icon: "icon-bar-chart",
        label: "Reports",
        to: "/reports",
      },
      {
        id: 7,
        icon: "icon-settings",
        label: "Settings",
        // "to": "/settings",
        content: [
          {
            id: 8,
            label: "Manage Users",
            to: "/manage-users",
          },
          {
            id: 10,
            label: "Manage Orgs",
            to: "/organizations",
          },
        ],
      },
    ],
    editJobs: true,
    addApplicants: true,
    editCandidates: true,
    editTimeline: true,
  },
  {
    role: "mcc_cluster_manager",
    menuContent: [
      {
        id: 1,
        icon: "icon-rocket",
        label: "Dashboard",
        to: "/dashboard",
      },
      {
        id: 2,
        icon: "icon-user",
        label: "Candidates",
        to: "/candidates",
      },
      {
        id: 3,
        icon: "icon-briefcase",
        label: "Jobs",
        to: "/jobs-list",
      },
      {
        id: 5,
        icon: "icon-feed",
        label: "Community",
        to: "/community",
      },
      {
        id: 6,
        icon: "icon-bar-chart",
        label: "Reports",
        to: "/reports",
      },
      {
        id: 7,
        icon: "icon-settings",
        label: "Settings",
        // "to": "/settings",
        content: [
          {
            id: 8,
            label: "Manage Users",
            to: "/manage-users",
          },
          {
            id: 10,
            label: "Manage Orgs",
            to: "/organizations",
          },
        ],
      },
    ],
    editJobs: true,
    addApplicants: true,
    editCandidates: true,
    editTimeline: true,
  },
  {
    role: "mcc_coordinator",
    menuContent: [
      {
        id: 1,
        icon: "icon-rocket",
        label: "Dashboard",
        to: "/dashboard",
      },
      {
        id: 2,
        icon: "icon-user",
        label: "Candidates",
        to: "/candidates",
      },
      {
        id: 3,
        icon: "icon-briefcase",
        label: "Jobs",
        to: "/jobs-list",
      },
      {
        id: 5,
        icon: "icon-feed",
        label: "Community",
        to: "/community",
      },
      {
        id: 6,
        icon: "icon-bar-chart",
        label: "Reports",
        to: "/reports",
      },
      {
        id: 7,
        icon: "icon-settings",
        label: "Settings",
        // "to": "/settings",
        content: [
          {
            id: 10,
            label: "Manage Orgs",
            to: "/organizations",
          },
        ],
      },
    ],
    editJobs: true,
    addApplicants: true,
    editCandidates: true,
    editTimeline: true,
  },
  {
    role: "employer_user",
    menuContent: [
      // {
      //     "id": 1,
      //     "icon": "icon-rocket",
      //     "label": "Dashboard",
      //     "to": "/dashboard",
      // },
      {
        id: 3,
        icon: "icon-briefcase",
        label: "Jobs",
        to: "/jobs-list",
      },
      {
        id: 6,
        icon: "icon-bar-chart",
        label: "Reports",
        to: "/reports",
      },
    ],
    editJobs: true,
    addApplicants: false,
    editCandidates: false,
    editTimeline: false,
  },
  {
    role: "pia_user",
    menuContent: [
      // {
      //     "id": 1,
      //     "icon": "icon-rocket",
      //     "label": "Dashboard",
      //     "to": "/dashboard",
      // },
      {
        id: 3,
        icon: "icon-user",
        label: "Candidates",
        to: "/candidates",
      },
      {
        id: 6,
        icon: "icon-bar-chart",
        label: "Reports",
        to: "/reports",
      },
    ],
    editJobs: false,
    addApplicants: false,
    editCandidates: true,
    editTimeline: true,
  },
];

export default accessControlMenu;
