import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Button, Menu, MenuItem } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import NoBorderDropdown from "../../common/NoBorderDropdown";
import { statuses } from "../../constants";
import colors from "../../styles/colors";

const FooterActions = (props) => {
  const {
    selectedCandidates,
    updateStatus,
    selectedStatus,
    setSelectedStatus,
  } = props;

  const [statusDropdownArr, setStatusDropdownArr] = useState([]);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const openExportMenu = Boolean(anchorEl);

  // const handleExportClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleExportMenuClose = () => {
  //   setAnchorEl(null);
  // };

  useEffect(() => {
    let tempArr = [];
    tempArr.push({ id: 0, name: "Choose Status" });
    tempArr = [...tempArr, ...statuses];
    setStatusDropdownArr([...tempArr]);
  }, []);

  return (
    <AppBar position="fixed" color="secondary" sx={{ top: "auto", bottom: 0 }}>
      <Toolbar
        sx={{
          justifyContent: "flex-end",
          px: { md: 15, sm: 5, xs: 3 },
        }}
      >
        {/* <Button
          variant="contained"
          id="demo-customized-button"
          aria-controls={openExportMenu ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openExportMenu ? "true" : undefined}
          size="small"
          disableElevation
          onClick={handleExportClick}
          endIcon={
            anchorEl ? (
              <KeyboardArrowUp fontSize="small" color="secondary" />
            ) : (
              <KeyboardArrowDown fontSize="small" color="secondary" />
            )
          }
          sx={{ fontSize: "14px", mr: 1 }}
        >
          Export
        </Button>
        <Menu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={openExportMenu}
          onClose={handleExportMenuClose}
        >
          <MenuItem onClick={handleExportMenuClose}>Excel</MenuItem>
          <MenuItem onClick={handleExportMenuClose}>CSV</MenuItem>
        </Menu> */}
        <NoBorderDropdown
          value={selectedStatus}
          onChange={(e) => {
            setSelectedStatus(e.target.value);
            updateStatus({
              variables: {
                payload: {
                  id: selectedCandidates,
                  status: e.target.value,
                },
              },
            });
          }}
          bgColor={colors.primary}
          textColor={colors.secondary}
          fontSize="14px"
          dropdownArr={statusDropdownArr}
        />
      </Toolbar>
    </AppBar>
  );
};

export default FooterActions;
