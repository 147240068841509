import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Button,
} from "@mui/material";
import UploadWrapper from "../../common/UploadWrapper";
import colors from "../../styles/colors";

import { withFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useQuery, useMutation } from "@apollo/client";
import { GET_COUNTRY, GET_STATES, GET_CITIES } from "../../graphql/MasterData";
import { UPSERT_COMPANY_DETAILS } from "../../graphql/EmployerProfile";
import getStore from "../../zustandStore";

const CompanyDetailsForm = (props) => {
  const {
    selectedFile,
    setSelectedFile,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    touched,
    errors,
    handleSubmit,
  } = props;

  const { data: countries } = useQuery(GET_COUNTRY, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: states } = useQuery(GET_STATES, {
    variables: {
      country: values.countryId,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    skip: !values.countryId,
  });

  const { data: cities } = useQuery(GET_CITIES, {
    variables: {
      state: values.stateId,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    skip: !values.stateId,
  });

  return (
    <Grid container spacing={3} sx={{ p: { xs: 3, sm: 0 } }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="24px" fontWeight={500} color={colors.dark}>
          Company Details
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Typography fontSize="14px">Company Logo</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <UploadWrapper
          handleUpload={(file) => setSelectedFile(file)}
          newUploadedFile={selectedFile}
          alreadyUploadedFile={null}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px">Company Name</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
        <TextField
          id="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
        />
        {touched.name && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.name}
          </Typography>
        )}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px">Company Description</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
        <TextField
          id="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
          multiline
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px">Website</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
        <TextField
          id="website"
          value={values.website}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px">Address</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
        <TextField
          id="address"
          value={values.address}
          onChange={handleChange}
          onBlur={handleBlur}
          size="small"
          fullWidth
          multiline
        />
        {touched.address && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.address}
          </Typography>
        )}
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Typography fontSize="14px">Country</Typography>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <Typography fontSize="14px">State</Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -2 }}>
        <Autocomplete
          id="countryId"
          autoComplete
          disableClearable
          value={
            countries && countries.countries
              ? countries.countries.find((x) => x.id === values.countryId)
              : { id: "", name: "" }
          }
          onChange={(_, value) => setFieldValue("countryId", value.id)}
          options={countries?.countries || []}
          getOptionLabel={(option) => option.name}
          size="small"
          fullWidth
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        {touched.countryId && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.countryId}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        <Typography fontSize="14px">State</Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -2 }}>
        <Autocomplete
          id="stateId"
          autoComplete
          value={
            states && states.states
              ? states.states.find((x) => x.id === values.stateId)
              : { id: "", name: "" }
          }
          onChange={(_, value) => setFieldValue("stateId", value.id)}
          options={states?.states || []}
          getOptionLabel={(option) => option.name}
          size="small"
          fullWidth
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        {touched.stateId && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.stateId}
          </Typography>
        )}
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Typography fontSize="14px">City</Typography>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <Typography fontSize="14px">Pincode</Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -2 }}>
        <Autocomplete
          id="cityId"
          autoComplete
          value={
            cities && cities.cities
              ? cities.cities.find((x) => x.id === values.cityId)
              : { id: "", name: "" }
          }
          onChange={(_, value) => setFieldValue("cityId", value.id)}
          options={cities?.cities || []}
          getOptionLabel={(option) => option.name}
          size="small"
          fullWidth
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        {touched.cityId && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.cityId}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        <Typography fontSize="14px">Pincode</Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -2 }}>
        <TextField
          id="pincode"
          value={values.pincode}
          onChange={handleChange}
          onBlur={handleBlur}
          type="number"
          size="small"
          fullWidth
        />
        {touched.pincode && (
          <Typography fontSize="11px" color={colors.danger}>
            {errors.pincode}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Button variant="contained" onClick={handleSubmit}>
          Done
        </Button>
      </Grid>
    </Grid>
  );
};

const CompanyDetailsFormik = (props) => {
  const { enteredCompanyName, setViewType } = props;

  const setLoading = getStore((state) => state.setLoading);
  const { enqueueSnackbar } = useSnackbar();

  const [selectedFile, setSelectedFile] = useState(null);

  const [addCompanyDetails, { loading }] = useMutation(UPSERT_COMPANY_DETAILS, {
    onCompleted: (data) => {
      const { updateEmployerCompany } = data;
      const { status, error } = updateEmployerCompany;
      if (status) {
        setViewType("registrationSuccessful");
      } else {
        enqueueSnackbar(error, {
          variant: "error",
          sx: { "& .SnackbarContent-root": { backgroundColor: colors.danger } },
        });
      }
    },
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const CompanyDetailsWrapped = withFormik({
    mapPropsToValues: () => ({
      name: enteredCompanyName || "",
      description: "",
      website: "",
      address: "",
      countryId: "",
      stateId: "",
      cityId: "",
      pincode: "",
    }),

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please enter the company name"),
      address: Yup.string().required("Please enter the address"),
      pincode: Yup.string().matches(
        /^[0-9]{6}$/,
        "Pincode must be exactly 6 digits"
      ),
      countryId: Yup.string().required("Please select a country"),
      stateId: Yup.string().required("Please select a state"),
      cityId: Yup.string().required("Please select a city"),
    }),

    handleSubmit(values, { props }) {
      const { handleReset } = props;
      addCompanyDetails({
        variables: {
          payload: {
            ...values,
            pincode: values.pincode.toString(),
          },
          file: selectedFile,
        },
      });
      handleReset(values);
    },
  })(CompanyDetailsForm);

  return (
    <CompanyDetailsWrapped
      {...props}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
    />
  );
};

export default CompanyDetailsFormik;
