import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  BusinessOutlined,
  AccountCircleOutlined,
  LogoutOutlined,
} from "@mui/icons-material";
import colors from "../../styles/colors";

const ProfileSections = (props) => {
  const { setSelectedSection, selectedSection, setLogoutModal } = props;
  return (
    <Box
      sx={{
        border: `1px solid ${colors.strokeGray}`,
        borderRadius: "4px",
        p: 2,
      }}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => setSelectedSection("company")}>
            <ListItemIcon>
              <BusinessOutlined
                sx={{
                  color:
                    selectedSection === "company"
                      ? colors.primary
                      : colors.dark,
                }}
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                color={
                  selectedSection === "company" ? colors.primary : colors.dark
                }
              >
                Company Details
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => setSelectedSection("contact")}>
            <ListItemIcon>
              <AccountCircleOutlined
                sx={{
                  color:
                    selectedSection === "contact"
                      ? colors.primary
                      : colors.dark,
                }}
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                color={
                  selectedSection === "contact" ? colors.primary : colors.dark
                }
              >
                Contact Details
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => setLogoutModal(true)}>
            <ListItemIcon>
              <LogoutOutlined sx={{ color: colors.dark }} />
            </ListItemIcon>
            <ListItemText>
              <Typography color={colors.dark}>Logout</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

export default ProfileSections;
