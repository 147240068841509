import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Button, Avatar, Chip } from "@mui/material";
import {
  LocationOnOutlined,
  BusinessCenterOutlined,
  SchoolOutlined,
} from "@mui/icons-material";
import HorizontalDivider from "./HorizontalDivider";
import Chips from "./Chip";
import {
  getDateInFormat,
  kFormatterNumber,
  formArrayFromStringSkills,
  fitmentRatioMap,
} from "../helpers";
import help_icon from "../assets/imagesNew/help-icon.svg";
import colors from "../styles/colors";
import { statuses } from "../constants";

function getMaskedName(masked, firstName, lastName) {
  if (masked) {
    return firstName + " ******";
  }
  return firstName + " " + lastName || "";
}

function getMaskedSalary(masked, min, max) {
  if (masked) {
    return "₹ ** - **";
  }
  return `₹ ${kFormatterNumber(min)} - ${kFormatterNumber(max)}`;
}

function IconAndInfo(props) {
  const { icon, data } = props;
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>{icon}</Grid>
      <Grid item>
        <Typography fontSize="13px">{data}</Typography>
      </Grid>
    </Grid>
  );
}

const ResultCard = (props) => {
  const {
    masked,
    candidateData,
    fullWidth,
    recommended,
    addBtnAction,
    avatarClickAction,
  } = props;

  const [minSalary, setMinSalary] = useState(0);
  const [maxSalary, setMaxSalary] = useState(0);
  const [matchScore, setMatchScore] = useState(0);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    if (candidateData && candidateData.skills) {
      setSkills(formArrayFromStringSkills(candidateData.skills));
    }
  }, [candidateData]);

  useEffect(() => {
    if (candidateData && candidateData.hasOwnProperty("minimumRemuneration")) {
      setMinSalary(candidateData.minimumRemuneration);
    }
    if (candidateData && candidateData.hasOwnProperty("maximumRemuneration")) {
      setMaxSalary(candidateData.maximumRemuneration);
    }
    if (candidateData && candidateData.hasOwnProperty("score")) {
      setMatchScore(Math.ceil(candidateData.score));
    }
  }, [candidateData]);
  return (
    <Box
      sx={{
        borderRadius: "4px",
        border: `1px solid ${colors.strokeGray}`,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ p: { xs: 2, sm: 3 } }}
        alignItems="center"
      >
        <Grid item lg={fullWidth ? 0.5 : 0.75} md={1} sm={1.25} xs={2}>
          <Avatar>
            {candidateData?.firstName ? candidateData.firstName.charAt(0) : "-"}
          </Avatar>
        </Grid>
        <Grid
          item
          lg={fullWidth ? 11.5 : 11.25}
          md={11}
          sm={10.75}
          xs={10}
          sx={{
            cursor: masked
              ? "default"
              : avatarClickAction
              ? "pointer"
              : "default",
          }}
          onClick={() => {
            if (!masked && avatarClickAction) {
              avatarClickAction();
            }
          }}
        >
          <Typography color={colors.primary} fontSize="18px" fontWeight={500}>
            {getMaskedName(
              masked,
              candidateData?.firstName,
              candidateData?.lastName
            )}
            {!masked && recommended && (
              <span>
                <span
                  style={{
                    color: "#898989",
                    fontSize: "24px",
                    fontWeight: 700,
                  }}
                >
                  {" "}
                  .{" "}
                </span>
                {matchScore}%
                <span>
                  <Chip
                    variant="outlined"
                    label={fitmentRatioMap(matchScore).label}
                    // size="small"
                    sx={{
                      ml: 1,
                      backgroundColor: fitmentRatioMap(matchScore).fillColor,
                      border: `1px solid ${fitmentRatioMap(matchScore).color}`,
                      color: fitmentRatioMap(matchScore).color,
                      fontWeight: 800,
                    }}
                  />
                </span>
              </span>
            )}
          </Typography>
          <Typography fontSize="14px">
            {candidateData?.jobType?.name || "-"}
          </Typography>
        </Grid>
        {candidateData?.description && (
          <Grid item lg={10} md={10} sm={10} xs={12}>
            <Typography fontSize="13px">
              {candidateData?.description || "No description"}
            </Typography>
          </Grid>
        )}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <IconAndInfo
                icon={<LocationOnOutlined color="primary" fontSize="small" />}
                data={candidateData?.city || "-"}
              />
            </Grid>
            <Grid item>
              <IconAndInfo
                icon={
                  <BusinessCenterOutlined color="primary" fontSize="small" />
                }
                data={
                  candidateData.hasOwnProperty("experience") &&
                  candidateData.experience !== null
                    ? `${candidateData.experience} year(s)`
                    : "-"
                }
              />
            </Grid>
            <Grid item>
              <IconAndInfo
                icon={<SchoolOutlined color="primary" fontSize="small" />}
                data={candidateData?.qualification || "-"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={9} md={9} sm={fullWidth ? 9 : 12} xs={12}>
          <Grid container spacing={1} alignItems="center">
            {skills.length > 0 ? (
              skills.map((skill) => (
                <Grid item key={skill}>
                  <Chips label={skill} />
                </Grid>
              ))
            ) : (
              <Typography color={colors.dark} fontSize="14px" fontWeight={700}>
                No skills specified
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item lg={3} md={3} sm={fullWidth ? 3 : 12} xs={12}>
          <Typography
            fontSize="20px"
            color={colors.primary}
            textAlign="right"
            fontWeight={700}
          >
            {getMaskedSalary(masked, minSalary, maxSalary)}
            <span
              style={{
                color: colors.lightGray,
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {" "}
              / Month
            </span>
          </Typography>
        </Grid>
      </Grid>
      <HorizontalDivider />
      <Grid
        container
        spacing={2}
        sx={{ px: { xs: 2, sm: 3 }, py: 2 }}
        alignItems="center"
      >
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography color={colors.lightGray} fontSize="13px">
            Update on:{" "}
            {getDateInFormat(candidateData.createdDate, "DD MMM YYYY")}
          </Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          display="flex"
          justifyContent={{ xs: "flex-start", sm: "flex-end" }}
        >
          {!masked && recommended ? (
            candidateData?.jobStatus === 1 ? (
              <Button size="small" variant="contained" onClick={addBtnAction}>
                Add
              </Button>
            ) : (
              <Typography color={colors.primary}>
                {statuses.find((x) => x.id === candidateData?.jobStatus).name}
              </Typography>
            )
          ) : !recommended ? (
            <Button
              size="small"
              variant="contained"
              disableElevation
              onClick={addBtnAction}
            >
              Add
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResultCard;
