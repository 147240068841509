import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import colors from "../../styles/colors";

const MissonVision = (props) => {
  return (
    <Box
      sx={{
        px: { xs: 2, sm: 20, md: 30, lg: 40 },
        py: 4,
        backgroundColor: colors.secondary,
      }}
    >
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography textAlign="center" fontSize="30px" fontWeight={600}>
            Mission &amp; Vision
          </Typography>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginTop: 15,
            }}
          >
            <div
              style={{
                backgroundColor: colors.primary,
                height: 3,
                borderRadius: "3px",
                width: 100,
              }}
            />
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography>
            CIEL began its journey in Aug 2015 with the passion of rebuilding an
            HR services business anchored on the values that the founders deeply
            believe in, right from the time they started Ma Foi in 1992.
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography>
            The vision is to emerge as one of the most respected HR services
            companies in the world anchored on the values of growth,
            professionalism, dignity and diversity.
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography>
            CIEL promotes learning with humility, serving with dignity and
            growing with integrity. Members of CIEL care about customers deeply
            and deliver best-in-class solutions keeping the interest of all
            other stakeholders in mind. CIEL will combine the power of
            technology with the capability of its members to deliver value to
            its stakeholders through rigorous execution.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MissonVision;
