import { gql } from "@apollo/client";

export const TOP_FIVE_EMPLOYERS = gql `
    query topEmployers($page : Int, $limit : Int){
        topEmployers(page: $page, limit: $limit){
        count
        topemployers{
            id
            name
            closedPositions
            totalPositions
            openPositions
        }
        clientAcquisition{
            monthCount
            weekCount
            todaysCount
        }
        }
    }
`

export const REVENUE = gql `
    query revenue{
        revenue{
        mobilizationCount
        mobilizationRevenue
        placementCount
        placementRevenue
        recruitmentCount
        recruitmentRevenue
        
        }
    }
`