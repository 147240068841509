import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid, Typography, Button } from "@mui/material";
import colors from "../../styles/colors";

const SignUpAdAndIntro = (props) => {
  const history = useHistory();
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: colors.primary,
          px: { xs: 3, sm: 10 },
          py: { xs: 5, sm: 7 },
        }}
      >
        <Grid container spacing={8}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography fontSize="54px" fontWeight={700} color="secondary">
              We are a{" "}
              <span
                style={{
                  color: `${colors.secondary}70`,
                  borderBottom: `3px solid ${colors.secondary}70`,
                }}
              >
                FMCG Company
              </span>{" "}
              based in{" "}
              <span
                style={{
                  color: `${colors.secondary}70`,
                  borderBottom: `3px solid ${colors.secondary}70`,
                }}
              >
                Mumbai
              </span>{" "}
              .
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography fontSize="54px" fontWeight={700} color="secondary">
              Looking for{" "}
              <span
                style={{
                  color: `${colors.secondary}70`,
                  borderBottom: `3px solid ${colors.secondary}70`,
                }}
              >
                In-shop Promoters
              </span>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography fontSize="54px" fontWeight={700} color="secondary">
              with{" "}
              <span
                style={{
                  color: `${colors.secondary}70`,
                  borderBottom: `3px solid ${colors.secondary}70`,
                }}
              >
                Sales Skills
              </span>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography fontSize="22px" fontWeight={500} color="secondary">
              Help us set you up with the right-fit candidates for your company
              by getting started here
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -5 }}>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              sx={{ color: colors.primary }}
              onClick={() => history.push("/employer-registration")}
            >
              Sign Up
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: colors.secondary,
          px: { xs: 3, sm: 10 },
          py: { xs: 5, sm: 7 },
        }}
      >
        <Grid container spacing={8}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              fontSize="40px"
              fontWeight={700}
              color={colors.darkGray}
            >
              CIEL Jobs connects you to the potential candidates that match your
              requirements
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              fontSize="40px"
              fontWeight={700}
              color={colors.darkGray}
            >
              We find the Right People for Right Jobs
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SignUpAdAndIntro;
