export const statuses = [
  { id: 4, name: "Applied" },
  { id: 5, name: "Shortlisted" },
  { id: 6, name: "Interviewed" },
  { id: 7, name: "Offered" },
  { id: 13, name: "Joined" },
  { id: 9, name: "Rejected" },
];

export const genders = {
  M: "Male",
  F: "Female",
  O: "Others",
  B: "Both",
  NA: "No Preference",
};

export const gendersArr = [
  { id: "M", name: "Male" },
  { id: "F", name: "Female" },
  { id: "O", name: "Others" },
  { id: "B", name: "Both" },
  { id: "NA", name: "No Preference" },
];

export const jobModes = {
  FULL_TIME: "Full Time",
  PART_TIME: "Part Time",
};

export const jobModesArr = [
  { id: "FULL_TIME", name: "Full Time" },
  { id: "PART_TIME", name: "Part Time" },
];

export const jobTeamTypes = {
  IN_OFFICE: "In Office",
  WORK_FROM_HOME: "Remote",
  HYBRID: "Hybrid",
};

export const jobTeamTypesArr = [
  { id: "IN_OFFICE", name: "In Office" },
  { id: "WORK_FROM_HOME", name: "Remote" },
  { id: "HYBRID", name: "Hybrid" },
];

export const questionTypeArr = [
  { id: "YES_NO", name: "Yes/No" },
  // { id: "RANGE", name: "Range" },
  { id: "NUMERIC", name: "Numeric" },
  { id: "SHORT_ANSWER", name: "Short Answer" },
];

export const sortByArr = [
  { id: "latest", name: "Latest" },
  { id: "oldest", name: "Oldest" },
  // { id: "maximumRemuneration", name: "Salary: High to Low" },
  // { id: "minimumRemuneration", name: "Salary: Low to High" },
  // { id: "maximumScore", name: "Fitment Score : High to Low" },
  // { id: "minimumScore", name: "Fitment Score : Low to High" },
];
