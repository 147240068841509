import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { STAFFING_REVENUE_REPORT } from "../../graphql/reports";
import {
  getMonthAndYear,
  capitalize,
  isJwtTokenValid,
  getPrettyDate,
} from "../../utils";
import EmployeeRevenueForm from "./EmployeeRevenueForm";
import Pagination from "../Shared/Pagination";
import { CANCEL_REVENUE } from "../../graphql/jobs";

const EmployeeRevenue = () => {
  const [addRevenueView, setAddRevenueView] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedRevenue, setSelectedRevenue] = useState(null);
  // const [startDate, setStartDate] = useState(moment().format("YYYY-MM"));
  // const [endDate, setEndDate] = useState(
  //   moment().add(1, "M").format("YYYY-MM")
  // );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [loading, setLoading] = useState(true);
  const { roles } = isJwtTokenValid();

  const {
    data: staffingRevenueData,
    refetch,
    loading,
  } = useQuery(STAFFING_REVENUE_REPORT, {
    variables: {
      page: page + 1,
      limit: pageSize,
      // filter: {
      //   createdDate__gte:
      //     startDate === null
      //       ? moment().format("YYYY-MM-01").concat(" 00:00")
      //       : moment(startDate).format("YYYY-MM-DD").concat(" 00:00"),
      //   createdDate__lt:
      //     endDate === null
      //       ? moment().add(1, "M").format("YYYY-MM-01").concat(" 23:59")
      //       : moment(endDate).format("YYYY-MM-DD").concat(" 23:59"),
      // },
      filter:
        startDate === null || endDate === null
          ? {}
          : startDate === null && endDate !== null
          ? {
              createdDate__lt: moment(endDate)
                .format("YYYY-MM-DD")
                .concat(" 23:59"),
            }
          : startDate !== null && endDate === null
          ? {
              createdDate__gte: moment(startDate)
                .format("YYYY-MM-DD")
                .concat(" 00:00"),
            }
          : {
              createdDate__gte: moment(startDate)
                .format("YYYY-MM-DD")
                .concat(" 00:00"),
              createdDate__lt: moment(endDate)
                .format("YYYY-MM-DD")
                .concat(" 23:59"),
            },
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [cancelRevenue] = useMutation(CANCEL_REVENUE, {
    refetchQueries: [{ query: STAFFING_REVENUE_REPORT }],
    onCompleted: (data) => {
      const { cancelRevenue } = data || {};
      const { status, error } = cancelRevenue;
      console.log(">>>>>>error", error);
      if (status) {
        setSelectedRevenue(null);
        setConfirmModal(false);
        refetch();
      }
    },
  });

  useEffect(() => {
    if (
      staffingRevenueData &&
      staffingRevenueData?.staffingRevenue &&
      staffingRevenueData?.staffingRevenue?.transactions
    ) {
      setRowCount(staffingRevenueData.staffingRevenue.count);
      // setLoading(false);
    }
  }, [staffingRevenueData]);

  return (
    <>
      {!addRevenueView && (
        <div className="section-body mt-3">
          <div className="container-fluid">
            <div className="header-action d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex align-items-center">
                <span
                  style={{
                    color: "#A8A8A8",
                    cursor: "default",
                    marginRight: 5,
                    fontSize: 14,
                  }}
                >
                  Start Month
                </span>
                <input
                  type="month"
                  className="form-control"
                  placeholder="Date of Birth"
                  style={{
                    border: "1PX #7C7B7B solid",
                    marginRight: 10,
                    width: 170,
                  }}
                  // id="dob"
                  onChange={(e) => setStartDate(e.target.value)}
                  value={startDate}
                  // onBlur={handleBlur}
                />
                <span
                  style={{
                    color: "#A8A8A8",
                    cursor: "default",
                    marginRight: 5,
                    fontSize: 14,
                  }}
                >
                  End Month
                </span>
                <input
                  type="month"
                  className="form-control"
                  placeholder="Date of Birth"
                  style={{
                    border: "1PX #7C7B7B solid",
                    marginRight: 10,
                    width: 170,
                  }}
                  // id="dob"
                  onChange={(e) => setEndDate(e.target.value)}
                  value={endDate}
                  // onBlur={handleBlur}
                />
              </div>
              {roles[0] !== "mcc_ho_user" && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setAddRevenueView(true)}
                >
                  <i className="fe fe-plus mr-2" />
                  Add
                </button>
              )}
            </div>
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped table-hover table-vcenter mb-0">
                    <thead>
                      <th>Transaction Date &amp; Time</th>
                      <th>Cluster Name</th>
                      <th>Month &amp; Year</th>
                      <th>Transaction Type</th>
                      <th>No. of Deputees</th>
                      <th>Revenue for Month</th>
                      <th>Posted By</th>
                      <th>Action</th>
                    </thead>
                    <tbody>
                      {loading && (
                        <td colSpan={10} style={{ textAlign: "center" }}>
                          <p style={{ font: 15 }}>Loading... </p>
                          <div
                            class="spinner-border ml-auto"
                            role="status"
                            aria-hidden="true"
                          ></div>
                        </td>
                      )}
                      {!loading &&
                        staffingRevenueData &&
                        staffingRevenueData?.staffingRevenue &&
                        staffingRevenueData?.staffingRevenue?.transactions
                          .length > 0 &&
                        JSON.parse(staffingRevenueData?.staffingRevenue?.transactions).map(
                          (tran) => (
                            <tr>
                              <td>
                                {tran?.createdDate
                                  ? getPrettyDate(tran?.createdDate)
                                  : ""}
                              </td>
                              <td>{tran?.user?.cluster?.name || ""}</td>
                              <td>
                                {tran?.commissionDate
                                  ? getMonthAndYear(tran?.commissionDate)
                                  : ""}
                              </td>
                              <td>
                                {tran?.transactionType
                                  ? capitalize(
                                      tran?.transactionType.replaceAll("_", " ")
                                    )
                                  : ""}
                              </td>
                              <td> {tran?.numberOfDeputees || ""}</td>
                              <td>
                                {tran?.commission
                                  ? `₹${tran.commission.toLocaleString(
                                      "en-IN"
                                    )}`
                                  : "-"}
                              </td>
                              <td>
                                {tran?.createdBy?.firstName || ""}{" "}
                                {tran?.createdBy?.lastName || ""}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-icon "
                                  title="Cancel Revenue"
                                  onClick={() => {
                                    setSelectedRevenue(tran?.id);
                                    setConfirmModal(true);
                                  }}
                                >
                                  <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                              </td>
                            </tr>
                          )
                        )}
                      {!loading &&
                        (!staffingRevenueData ||
                          !staffingRevenueData?.staffingRevenue ||
                          !JSON.parse(staffingRevenueData?.staffingRevenue?.transactions)
                            .length > 0) && (
                          <tr>
                            <td
                              colSpan={6}
                              style={{ textAlign: "center", fontSize: 14 }}
                            >
                              No data available
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  page={page}
                  pageSize={pageSize}
                  rowCount={rowCount}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              </div>
            </div>
            <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
              <Modal.Header>
                <Modal.Title>Confirm</Modal.Title>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() => setConfirmModal(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to cancel the staffing transaction?
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-success"
                  onClick={() =>
                    cancelRevenue({
                      variables: {
                        payload: selectedRevenue,
                      },
                    })
                  }
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={() => setConfirmModal(false)}
                >
                  No
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      )}
      {addRevenueView && (
        <EmployeeRevenueForm
          setAddRevenueView={setAddRevenueView}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default EmployeeRevenue;
