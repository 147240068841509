import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography } from "@mui/material";
import circle_tick from "../../assets/imagesNew/circle-tick.svg";
import colors from "../../styles/colors";

const AccountVerified = (props) => {
  const { setViewType } = props;
  const history = useHistory();
  return (
    <Grid container spacing={3} sx={{ p: { xs: 3, sm: 0 } }}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <img src={circle_tick} alt="tick" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography
          color={colors.dark}
          fontWeight={500}
          fontSize="26px"
          textAlign="center"
        >
          Awesome !
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="16px" textAlign="center">
          Your account verified successfully
        </Typography>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", justifyContent: "center", mt: 5 }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={() => setViewType("companyDetails")}
        >
          Add your Company Details
        </Button>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px" textAlign="center">
          to attract the right candidate
        </Typography>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", justifyContent: "center", mt: 3 }}
      >
        <Button
          size="small"
          onClick={() => history.push("/employer-dashboard")}
        >
          Skip &amp; Explore Now
        </Button>
      </Grid>
    </Grid>
  );
};

export default AccountVerified;
