import React from "react";
import { TextField, MenuItem } from "@mui/material";

const NoBorderDropdown = (props) => {
  const { value, onChange, bgColor, textColor, dropdownArr, fontSize } = props;
  return (
    <TextField
      value={value}
      onChange={onChange}
      variant="outlined"
      select
      sx={{
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        backgroundColor: bgColor,
        borderRadius: "27px",
      }}
      size="small"
      inputProps={{
        sx: {
          color: textColor,
          fontSize: fontSize || "12px",
          fontWeight: 500,
          py: 0.75,
          px: 1.5,
        },
      }}
    >
      {dropdownArr &&
        dropdownArr.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default NoBorderDropdown;
