import React, { useEffect, useState } from "react";
import Qrcode from "qrcode";
import { useQuery } from "@apollo/client";
import { GET_CLUSTER, GET_CLUSTER_CENTRES } from "../../graphql/clusters";
import { GET_USER } from "../../graphql/users";
function MccQrCode() {
  const [userId, setUserId] = useState(null);
  const [clusterId, setClusterId] = useState(null);
  const [clusterFet, setcClusterFet] = useState(null);
  const [centers, setCenters] = useState(null);
  const [centersFet, setCentersFet] = useState([]);

  const { data: user } = useQuery(GET_USER, {
    variables: {
      id: userId,
    },
    skip: !userId,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  if (user) {
    setClusterId(user?.user?.cluster?.id);
    setUserId(null);
  }

  // const {data:cluster}=useQuery(GET_CLUSTER,{
  //   variables:{
  //     id:clusterId
  //   },
  //   skip:!clusterId,
  //   fetchPolicy: "network-only",
  //   nextFetchPolicy: "network-only",
  // });

  const { data: center } = useQuery(GET_CLUSTER_CENTRES, {
    variables: {
      filter: {
        clusterId: clusterId
      }
    },
    skip: !clusterId,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });
  console.log("rendering centre", center);
  if (center) {
    console.log(center?.clusterCentres?.clustercentres, "rendering");
    setCenters(center?.clusterCentres?.clustercentres);
    setcClusterFet(clusterId);
    setClusterId(null);
  }
  console.log(clusterId, "rendering");

  useEffect(() => {
    setCentersFet(centers);
  }, [centers]);

  useEffect(() => {
    const userMe = JSON.parse(localStorage.getItem("me"));
    console.log(userMe.id);
    setUserId(userMe.id);
  }, []);

  const Center = (c) => {
    const [qrImage, setQrImage] = useState(null);
    console.log(c);
    const generateQr = async (id, name, state) => {
      // console.log(id)
      const url =
        process.env.REACT_APP_FRONTEND+
        "#/qrForm?id=" +
        id +
        "&name=" +
        name +
        "&state=" +
        state;
      try {
        const response = await Qrcode.toDataURL(url);
        console.log(response);
        setQrImage(response);
      } catch (error) {
        console.log("error", error);
      }
    };
    generateQr(c.c.centre.id, c.c.centre.name, c.c.centre.state?.name);

    return (
      <div className="col-xl-3 col-lg-4 col-md-6">
        <div className="card">
          <a href="#" onClick="return false">
            <img src={qrImage} alt="" />
          </a>
          <div className="card-body">
            <p className="card-text">{c.c.centre.name}</p>
            <p className="card-text">{c.c.centre.id}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="w-100">
        {centersFet && centersFet.length > 0 ? (
          <>
          <div className="row">
            <span className="col-xl-12 col-lg-12 col-md-12">
              {"To download Qr code - Right click on QR code > select 'Save image as...' "
            }</span>
          </div>
          <div className="row row-deck w-100">
            {centersFet.map((center) => (
              <Center c={center} key={center.centre.id} />
            ))}
          </div>
          </>
        ) : (
          <p style={{ margin: 20 }}>No centres in the cluster</p>
        )}
      </div>
    </div>
  );
}

export default MccQrCode;
