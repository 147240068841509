import React, { useEffect } from "react";
import { capitalize, getPrettyDate } from "../../utils";
import moment from "moment";

const Employer_Report = (props) => {
  const { transactionData, summaryTableData, jobReport, startDate, endDate,loading } =
    props;

  useEffect(() => {
    console.log({ transactionData, summaryTableData, jobReport });
  }, [transactionData, summaryTableData, jobReport]);

  // const jobReport = {
  //     jobReport: [
  //         {
  //             candidates: [
  //                 {
  //                     id: "196",
  //                     candidate: {
  //                         id: "6ba4bbcc-1982-4c4d-b823-64449c318c8b",
  //                         firstName: "Mareeswari ",
  //                         lastName: "P",
  //                         mobile: "9787158273",
  //                         qualification: {
  //                             id: "3",
  //                             name: "Diploma"
  //                         },
  //                         experience: 0,
  //                         country: {
  //                             id: "1",
  //                             name: "India"
  //                         },
  //                         state: {
  //                             id: "17",
  //                             name: "Tamil Nadu"
  //                         },
  //                         city: {
  //                             id: "1151",
  //                             name: "Virudhunagar"
  //                         }
  //                     },
  //                     job: {
  //                         id: "d136a171-b91d-4ae3-871c-d622cc2dd1a2",
  //                         title: "Assembly Line Operator",
  //                         country: {
  //                             id: "1",
  //                             name: "India"
  //                         },
  //                         state: {
  //                             id: "17",
  //                             name: "Tamil Nadu"
  //                         },
  //                         city: {
  //                             id: "116",
  //                             name: "Chennai"
  //                         },
  //                         positions: 100
  //                     }
  //                 },
  //                 {
  //                     id: "210",
  //                     candidate: {
  //                         id: "f67759aa-7b72-464c-aab6-be8a6f49e811",
  //                         firstName: "Mahalakshmi",
  //                         lastName: "S",
  //                         mobile: "6384160270",
  //                         qualification: {
  //                             id: "3",
  //                             name: "Diploma"
  //                         },
  //                         experience: 5,
  //                         country: {
  //                             id: "1",
  //                             name: "India"
  //                         },
  //                         state: {
  //                             id: "17",
  //                             name: "Tamil Nadu"
  //                         },
  //                         city: {
  //                             id: "1151",
  //                             name: "Virudhunagar"
  //                         },
  //                         profile : "{\"fresher\": \"false\", \"currentSalary\": \"32000\"}"
  //                     },
  //                     "job": {
  //                         "__typename": "Job",
  //                         "id": "d136a171-b91d-4ae3-871c-d622cc2dd1a2",
  //                         "title": "Assembly Line Operator",
  //                         "country": {
  //                             "__typename": "Country",
  //                             "id": "1",
  //                             "name": "India"
  //                         },
  //                         "state": {
  //                             "__typename": "State",
  //                             "id": "17",
  //                             "name": "Tamil Nadu"
  //                         },
  //                         "city": {
  //                             "__typename": "City",
  //                             "id": "116",
  //                             "name": "Chennai"
  //                         },
  //                         "positions": 100
  //                     }
  //                 }
  //             ]
  //         }
  //     ]
  // }

  return (
    <div>
       {loading && <div className="d-flex justify-content-center">
        <div className="d-flex flex-column align-items-center">
          <p style={{ font: 15 }}>Loading... </p>
        <div
         class="spinner-border m-auto"
         role="status"
         aria-hidden="true"
      ></div>
      </div>
        </div>}
        {!loading &&
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div
              className="tab-pane fade show active"
              id="summary"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th
                            colSpan={3}
                            style={{
                              textAlign: "center",
                              fontSize: 17,
                              fontWeight: "bold",
                              background: "rgba(58, 34, 85, 0.1)",
                            }}
                          >
                            Employer Report (
                            {/* {moment(startDate).format("DD.MM.YYYY")} -{" "}
                            {moment(endDate).format("DD.MM.YYYY")}) */}
                          </th>
                        </tr>
                        <tr>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Employer
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Selection Revenue
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            No
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {summaryTableData &&
                        summaryTableData?.consolidatedReport.length > 0 ? (
                          summaryTableData?.consolidatedReport.map((sum, i) => (
                            <tr
                              style={{
                                borderBottom:
                                  i ===
                                  summaryTableData.consolidatedReport.length - 1
                                    ? "1px #D6D6D6 solid"
                                    : "",
                              }}
                            >
                              <td style={{ borderRight: "1px #D6D6D6 solid" }}>
                                {sum?.organization?.name || ""}
                              </td>
                              <td style={{ borderRight: "1px #D6D6D6 solid" }}>
                                {sum?.recruitmentRevenue > 0
                                  ? `₹${sum?.recruitmentRevenue.toLocaleString(
                                      "en-IN"
                                    )}`
                                  : "₹0"}
                              </td>
                              <td style={{ borderRight: "1px #D6D6D6 solid" }}>
                                {sum?.recruitmentCount || 0}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={10}
                              style={{ textAlign: "center", fontSize: 14 }}
                            >
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="transaction" role="tabpanel">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th
                            colSpan={8}
                            style={{
                              textAlign: "center",
                              fontSize: 17,
                              fontWeight: "bold",
                              background: "rgba(58, 34, 85, 0.1)",
                            }}
                          >
                            Transaction Report
                          </th>
                        </tr>
                        <tr>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Transaction Date &#38; Time
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Event Date
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Transaction Type
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Beneficiary Org Name
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Candidate Name
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Candidate Mobile
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Agreed Commission Rate
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Transaction Amount
                          </th>
                          <th style={{ fontWeight: 550 }}>Posted By</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactionData &&
                        transactionData?.transactions &&
                        JSON.parse(transactionData?.transactions?.transactions).length >
                          0 ? (
                          JSON.parse(transactionData?.transactions?.transactions).map(
                            (tran, i) => {
                              let commissionRate = 0;
                              // debugger
                              if (tran?.commissionType === "Fixed Rate") {
                                commissionRate =
                                  tran?.organization?.rate_placement || 0;
                              } else {
                                let profile = JSON.parse(tran?.profile);
                                commissionRate =
                                  profile?.percentage_recruitment || 0;
                              }
                              console.log({ commissionRate });
                              return (
                                <tr
                                  style={{
                                    borderBottom:
                                      i ===
                                      JSON.parse(transactionData.transactions.transactions)
                                        .length -
                                        1
                                        ? "1px #D6D6D6 solid"
                                        : "",
                                  }}
                                >
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {getPrettyDate(tran?.createdDate) || ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {getPrettyDate(tran?.commissionDate) || ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {tran?.transactionType
                                      ? capitalize(tran?.transactionType)
                                      : ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    Mafoi
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {tran?.user?.firstName || ""}{" "}
                                    {tran?.user?.lastName || ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {tran?.user?.mobile || ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {tran?.commissionType === "Fixed Rate"
                                      ? commissionRate > 0
                                        ? `₹${commissionRate.toLocaleString(
                                            "en-IN"
                                          )}`
                                        : "₹0"
                                      : `${commissionRate}%`}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {tran?.commission || ""}
                                  </td>
                                  <td>{tran?.createdBy?.firstName || ""}</td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td
                              colSpan={10}
                              style={{ textAlign: "center", fontSize: 14 }}
                            >
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="newCandidates" role="tabpanel">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover table-vcenter mb-0">
                      <thead>
                        <tr>
                          <th
                            colSpan={9}
                            style={{
                              textAlign: "center",
                              fontSize: 17,
                              fontWeight: "bold",
                              background: "rgba(58, 34, 85, 0.1)",
                            }}
                          >
                            Job-wise Shortlisted Candidates
                          </th>
                        </tr>
                        <tr>
                          <th
                            rowSpan={2}
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Job Title
                          </th>
                          <th
                            rowSpan={2}
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Job Location
                          </th>
                          <th
                            rowSpan={2}
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            No. of Openings
                          </th>
                          <th
                            colSpan={6}
                            style={{ fontWeight: 550, textAlign: "center" }}
                          >
                            Shortlisted Candidates
                          </th>
                        </tr>
                        <tr>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Name
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Mobile Number
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Qualification
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Current Location
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Experience
                          </th>
                          <th
                            style={{
                              fontWeight: 550,
                              borderRight: "1px #D6D6D6 solid",
                            }}
                          >
                            Current Salary
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobReport &&
                        jobReport?.jobReport &&
                        jobReport?.jobReport.length > 0 ? (
                          jobReport?.jobReport.map((user, i) => {
                            let salary = user?.candidates[0].candidate?.profile
                              ? JSON.parse(
                                  user?.candidates[0].candidate?.profile
                                )
                              : 0;
                            console.log({ salary });
                            if (salary?.currentSalary) {
                              salary = salary.currentSalary;
                            }
                            console.log({ salary });
                            return (
                              <>
                                <tr
                                  style={{
                                    borderBottom:
                                      i === jobReport.jobReport.length - 1
                                        ? "1px #D6D6D6 solid"
                                        : "",
                                  }}
                                >
                                  <td
                                    rowSpan={user?.candidates.length || 1}
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {user?.candidates[0]?.job?.title || "-"}
                                  </td>
                                  <td
                                    rowSpan={user?.candidates.length || 1}
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {user?.candidates[0]?.job?.city?.name ||
                                      "-"}
                                    ,{" "}
                                    {user?.candidates[0]?.job?.state?.name ||
                                      "-"}
                                    ,{" "}
                                    {user?.candidates[0]?.job?.country?.name ||
                                      "-"}
                                  </td>
                                  <td
                                    rowSpan={user?.candidates.length || 1}
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {user?.candidates[0]?.job?.positions || "-"}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {user?.candidates[0]?.candidate
                                      ?.firstName || ""}{" "}
                                    {user?.candidates[0]?.candidate?.lastName ||
                                      ""}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {user?.candidates[0]?.candidate?.mobile ||
                                      "-"}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {user?.candidates[0]?.candidate
                                      ?.qualification?.name || "-"}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {user?.candidates[0]?.candidate?.city
                                      ?.name || "-"}
                                    ,{" "}
                                    {user?.candidates[0]?.candidate?.state
                                      ?.name || "-"}
                                    ,{" "}
                                    {user?.candidates[0]?.candidate?.country
                                      ?.name || "-"}
                                  </td>
                                  <td
                                    style={{ borderRight: "1px #D6D6D6 solid" }}
                                  >
                                    {user?.candidates[0]?.candidate
                                      ?.experience === 0
                                      ? "Fresher"
                                      : `${user?.candidates[0]?.candidate?.experience} years`}
                                  </td>
                                  <td>
                                    {user?.candidates[0]?.candidate
                                      ?.experience === 0
                                      ? "NA"
                                      : `₹${parseFloat(salary).toLocaleString(
                                          "en-IN"
                                        )}`}
                                  </td>
                                </tr>
                                {user?.candidates.length > 1 &&
                                  user?.candidates
                                    .filter(
                                      (itm) => itm.id !== user.candidates[0].id
                                    )
                                    .map((candidate) => {
                                      let salary1 = candidate?.candidate
                                        ?.profile
                                        ? JSON.parse(
                                            candidate.candidate?.profile
                                          )
                                        : 0;
                                      console.log({ salary1 });
                                      if (salary1?.currentSalary) {
                                        salary1 = salary1.currentSalary;
                                      }
                                      console.log({ salary1 });
                                      return (
                                        <tr
                                          style={{
                                            borderBottom:
                                              i ===
                                              jobReport.jobReport.length - 1
                                                ? "1px #D6D6D6 solid"
                                                : "",
                                          }}
                                        >
                                          <td
                                            style={{
                                              borderRight: "1px #D6D6D6 solid",
                                            }}
                                          >
                                            {candidate?.candidate?.firstName ||
                                              ""}{" "}
                                            {candidate?.candidate?.lastName ||
                                              ""}
                                          </td>
                                          <td
                                            style={{
                                              borderRight: "1px #D6D6D6 solid",
                                            }}
                                          >
                                            {candidate?.candidate?.mobile || ""}
                                          </td>
                                          <td
                                            style={{
                                              borderRight: "1px #D6D6D6 solid",
                                            }}
                                          >
                                            {candidate?.candidate?.qualification
                                              ?.name || ""}
                                          </td>
                                          <td
                                            style={{
                                              borderRight: "1px #D6D6D6 solid",
                                            }}
                                          >
                                            {candidate?.candidate?.city?.name ||
                                              "-"}
                                            ,{" "}
                                            {candidate?.candidate?.state
                                              ?.name || "-"}
                                            ,{" "}
                                            {candidate?.candidate?.country
                                              ?.name || "-"}
                                          </td>
                                          <td
                                            style={{
                                              borderRight: "1px #D6D6D6 solid",
                                            }}
                                          >
                                            {candidate?.candidate
                                              ?.experience === 0
                                              ? "Fresher"
                                              : `${candidate?.candidate?.experience} years`}
                                          </td>
                                          <td>
                                            {candidate?.candidate
                                              ?.experience === 0
                                              ? "NA"
                                              : `₹${parseFloat(
                                                  salary1
                                                ).toLocaleString(
                                                  "en-IN"
                                                )}/ month`}
                                          </td>
                                        </tr>
                                      );
                                    })}
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan={10}
                              style={{ textAlign: "center", fontSize: 14 }}
                            >
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  );
};

export default Employer_Report;
