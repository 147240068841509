import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import Chip from "../../common/Chip";

import { useQuery } from "@apollo/client";
import { GET_COUNTRY, GET_STATES, GET_ALL_CITIES } from "../../graphql/meta";

const JobPReferenceDetails = (props) => {
  const { user } = props;

  const [profileData, setProfileData] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const { data: country } = useQuery(GET_COUNTRY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: statesData } = useQuery(GET_STATES, {
    variables: { country: profileData?.country },
    skip: !profileData?.country,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: citiesData } = useQuery(GET_ALL_CITIES, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (user && user?.profile) {
      const tempJSON = JSON.parse(user?.profile);
      setProfileData(tempJSON);
    }
  }, [user]);

  useEffect(() => {
    if (statesData && statesData?.states && profileData?.state) {
      let statesArr = [];
      statesArr = profileData?.state.map(
        (x) => statesData?.states?.find((y) => y.id === x)?.name
      );
      if (statesArr.length > 5) {
        statesArr = statesArr.splice(0, 5);
      }
      setStates(statesArr);
    }
  }, [profileData, statesData]);

  useEffect(() => {
    if (citiesData && citiesData?.allCities && profileData?.cities) {
      let citiesArr = [];
      citiesArr = profileData?.cities.map(
        (x) => citiesData?.allCities?.find((y) => y.id === x)?.name
      );
      if (citiesArr.length > 5) {
        citiesArr = citiesArr.splice(0, 5);
      }
      setCities(citiesArr);
    }
  }, [citiesData, profileData]);

  return (
    <Paper elevation={2} sx={{ px: 2, py: 3 }}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontWeight={600} fontSize="18px">
            Job Preference
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px">Country</Typography>
          <Typography fontWeight={600} fontSize="16px">
            {country?.countries && profileData?.country
              ? country?.countries?.find((x) => x.id === profileData?.country)
                  ?.name
              : "Not specified"}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px">States</Typography>
          {states.length > 0 ? (
            <Grid container spacing={0.5} sx={{ mt: 0.5 }}>
              {states.map((x) => (
                <Grid item>
                  <Chip label={x} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography fontWeight={600} fontSize="16px">
              Not specified
            </Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px">Cities</Typography>
          {cities.length > 0 ? (
            <Grid container spacing={0.5} sx={{ mt: 0.5 }}>
              {cities.map((x) => (
                <Grid item>
                  <Chip label={x} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography fontWeight={600} fontSize="16px">
              Not specified
            </Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="14px">Expected CTC per Month</Typography>
          {profileData?.ctc_range ? (
            <Typography fontWeight={600} fontSize="16px">
              &#8377;{profileData?.ctc_range} / Month
            </Typography>
          ) : (
            <Typography fontWeight={600} fontSize="16px">
              Not specified
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default JobPReferenceDetails;
