import React, { useState, useEffect } from "react";
import AddNewOrganization from "./OrganizationForm";
import {
  GET_ORGANIZATIONS,
  GET_ORGANIZATION,
  DELETE_ORGANIZATION,
} from "../../graphql/organizations";
import { useQuery, useMutation } from "@apollo/client";
import { Modal } from "react-bootstrap";

const organizationTypeMap = {
  1: "PIA",
  2: "Employer",
  3: "Placement Partner",
};

const Organizations = (props) => {
  const { fixNavbar } = props;
  const [addOrganizationView, setAddOrganizationView] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedOrganizationPayload, setSelectedOrganizationPayload] =
    useState({});
  const [organizations, setOrganizations] = useState([]);
  const [filter, setFilter] = useState({});
  //   const [timer, setTimer] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [qrCode, setQrCode] = useState(false)

  const { data, refetch,loading:organizationLoading } = useQuery(GET_ORGANIZATIONS, {
    variables: {
      filter: filter,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: organization } = useQuery(GET_ORGANIZATION, {
    variables: {
      id: selectedOrganization,
    },
    skip: !selectedOrganization,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [deleteOrganization] = useMutation(DELETE_ORGANIZATION, {
    onCompleted: (data) => {
      const { deleteOrganization } = data;
      const { status, error } = deleteOrganization;
      console.log("DELETED ORG", status, error);
      if (status) {
        setConfirmModal(false);
        refetch();
      }
    },
  });

  useEffect(() => {
    if (data && data.organizations) {
      // setRowCount(data.organizations.count);
      setOrganizations(data.organizations);
    }
  }, [data]);

  useEffect(() => {
    if (organization?.organization) {
      setSelectedOrganizationPayload(organization.organization);
    } else {
      setSelectedOrganizationPayload({});
    }
  }, [organization]);

  //   const changeDelay = (e) => {
  //     if (timer) {
  //       clearTimeout(timer);
  //       setTimer(null);
  //     }
  //     setTimer(
  //       setTimeout(() => {
  //         console.log(e);
  //         let { name__icontains, ...mutableFilter } = filter;
  //         if (e) {
  //           mutableFilter = { ...filter, name__icontains: e };
  //         }
  //         setFilter(mutableFilter);
  //       }, 1000)
  //     );
  //   };

  useEffect(() => {
    if (addOrganizationView === false) {
      setSelectedOrganization(null);
      setSelectedOrganizationPayload({});
      refetch();
    }
  }, [addOrganizationView, refetch]);

  const Organization = ({ o }) => {
    return (
      <div className="col-xl-4 col-lg-4 col-md-6">
        <div className="card">
          <div className="card-body text-center ribbon">
            <span
              className="avatar avatar-cyan"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Avatar Name"
            >
              {o.name ? o.name.substring(0, 1) : "-"}
            </span>
            <h6 className="mt-3 mb-0">{o.name}</h6>
            <span>{o.city?.name ? o.city.name : "-"}</span>
            <ul className="mt-3 list-unstyled d-flex justify-content-center align-items-center">
              <li>
                <button
                  type="button"
                  className="btn btn-icon"
                  title="Edit"
                  onClick={() => {
                    setAddOrganizationView(true);
                    setSelectedOrganization(o.id);
                  }}
                >
                  <i className="fa fa-edit" />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="btn btn-icon"
                  title="Delete"
                  onClick={() => {
                    setSelectedOrganization(o.id);
                    setConfirmModal(true);
                  }}
                >
                  <i className="fa fa-trash" />
                </button>
              </li>
            </ul>
            <div className="row text-center mt-4">
              <div className="col-lg-6 border-right">
                <label className="mb-0">Jobs</label>
                <h4 className="font-16">{o?.jobs || 0}</h4>
              </div>
              <div className="col-lg-6">
                <label className="mb-0">Type</label>
                <h4 className="font-16">
                  {organizationTypeMap[o.organizationType]}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {!addOrganizationView && 
        <div className={`section-body ${fixNavbar ? "marginTop" : ""}`}>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-flex-start align-items-center">
                      <>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Organization Name"
                              onChange={(e) => {
                                let { name__icontains, ...mutableFilter } =
                                  filter;
                                if (e) {
                                  mutableFilter = {
                                    ...filter,
                                    name__icontains: e.target.value,
                                  };
                                }
                                setFilter(mutableFilter);
                              }}
                              value={
                                Object.keys(filter).length === 0
                                  ? ""
                                  : filter && !("name__icontains" in filter)
                                  ? ""
                                  : filter.name__icontains
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                          <div className="input-group">
                            <select
                              className="custom-select"
                              // style={{ marginLeft: 5 }}
                              onChange={(e) => {
                                const { organizationType, ...mutableFilter } =
                                  filter;
                                const Ofilter =
                                  e.target.value !== "0"
                                    ? {
                                        ...mutableFilter,
                                        organizationType: parseInt(
                                          e.target.value
                                        ),
                                      }
                                    : mutableFilter;
                                setFilter(Ofilter);
                              }}
                            >
                              <option
                                selected={
                                  Object.keys(filter).length === 0 ||
                                  (filter && !("organizationType" in filter))
                                    ? true
                                    : false
                                }
                                disabled
                              >
                                {" "}
                                -- Select Organization Type --{" "}
                              </option>
                              <option value={"0"} key={"state0"}>
                                None
                              </option>
                              <option value="2">Employer</option>
                              <option value="3">Placement Partner</option>
                              <option value="1">PIA</option>
                            </select>
                          </div>
                        </div>
                      </>
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => setFilter({})}
                      >
                        <i className="fa fa-times mr-2" />
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="header-action float-right"
                  style={{ marginTop: -5, marginBottom: 10 }}
                >
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => setAddOrganizationView(true)}
                  >
                    <i className="fe fe-plus mr-2" />
                    Add Organization
                  </button>
                </div>
              </div>
              <div className="row clearfix row-deck" style={{width:100+'%'}}>
                {!organizationLoading && organizations && organizations.length > 0 &&
                  organizations.map((o) => <Organization o={o} key={o.id} />

                  
                )
                }
                {!organizationLoading && (!organizations || organizations.length > 0) &&
                  <div
                  className="card"
                  style={{ padding: 15, textAlign: "center" }}
                >
                  No organizations added
                </div>
                }
                 {organizationLoading && <div className="d-flex justify-content-center w-100">
                  <div className="d-flex flex-column align-items-center">
                    <p style={{ font: 15 }}>Loading... </p>
                  <div
                  class="spinner-border m-auto"
                  role="status"
                  aria-hidden="true"
                ></div>
                </div>
                  </div>}
              </div>
              <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
                <Modal.Header>
                  <Modal.Title>Confirm</Modal.Title>
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    onClick={() => setConfirmModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete the organization?
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    onClick={() =>
                      deleteOrganization({
                        variables: {
                          ids: [selectedOrganization],
                        },
                      })
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    onClick={() => setConfirmModal(false)}
                  >
                    No
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      }
      {addOrganizationView && (
        <AddNewOrganization
          setAddOrganizationView={setAddOrganizationView}
          selectedOrganizationPayload={selectedOrganizationPayload}
        />
      )}
    </>
  );
};

export default Organizations;
