import React, { useEffect, useState } from "react";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker
} from "react-simple-maps";
import { GET_CENTRES } from "../../graphql/meta";
import { useQuery } from '@apollo/client';

const geoUrl =
    "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";



const MapChart = () => {

    const { data: centres } = useQuery(GET_CENTRES, {
        variables: {},
    });


    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        if(centres?.centres){
            let tempMarkers = centres?.centres?.map((c) => ({ markerOffset: -30, name: c.name, coordinates: [c.long, c.lat] }))
            setMarkers(tempMarkers);
        }

    }, [centres])

    return (
        <ComposableMap
            // projection="geoAzimuthalEqualArea"
            projection="geoMercator"
            projectionConfig={{
                // rotate: [58, 20, 0],
                scale: 7000,
                // center: [77.0281, 21.9149],
                center: [78.1198, 11.9252]
            }}
        >
            <Geographies geography={geoUrl}>
                {({ geographies }) =>
                    geographies
                        .filter(d => d.properties.NAME === "India")
                        .map(geo => (
                            <Geography
                                key={geo.rsmKey}
                                geography={geo}
                                fill="#EAEAEC"
                                stroke="#D6D6DA"
                            />
                        ))
                }
            </Geographies>
            {markers.map(({ name, coordinates, markerOffset }) => (
                <Marker key={name} coordinates={coordinates}>
                    <g
                        fill="none"
                        stroke="#FF5533"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        transform="translate(-12, -24)"
                    >
                        <circle cx="12" cy="10" r="3" />
                        <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                    </g>
                    <text
                        textAnchor="middle"
                        y={markerOffset}
                        style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
                    >
                        {name}
                    </text>
                </Marker>
            ))}
        </ComposableMap>
    );
};

export default MapChart;