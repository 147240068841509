import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import colors from "../../styles/colors";

const Footer = (props) => {
  const { setScreeningQuestions, questions } = props;
  const history = useHistory();
  return (
    <Grid container spacing={1} justifyContent="flex-end">
      <Grid item>
        <Button
          size="small"
          sx={{ color: colors.lightGray }}
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <Button
          size="small"
          variant="contained"
          disableElevation
          onClick={() => {
            setScreeningQuestions([...questions]);
            history.goBack();
          }}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default Footer;
