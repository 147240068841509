import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import colors from "../../styles/colors";

const Metrics = (props) => {
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: colors.secondary,
          px: { xs: 3, sm: 10 },
          py: { xs: 5, sm: 7 },
        }}
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item lg={8} md={8} sm={8} xs={12}>
          <Typography fontSize="40px" fontWeight={700} color={colors.darkGray}>
              We use advanced technology to filter talent based on your job
              description and share the best few profiles with you to pick from
            </Typography>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={4}
            sx={{ display: { xs: "none", sm: "block" } }}
          />
          <Grid
            item
            lg={7}
            md={7}
            sm={7}
            sx={{ display: { xs: "none", sm: "block" } }}
          />
          <Grid item lg={3} md={3} sm={3} xs={6}>
            <Typography fontSize="36px" color={colors.darkGray}>Total Clients</Typography>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={6}
            display="flex"
            justifyContent="flex-end"
          >
            <Typography fontSize="50px" color={colors.primary} fontWeight={700}>
              725
            </Typography>
          </Grid>
          <Grid
            item
            lg={7}
            md={7}
            sm={7}
            sx={{ display: { xs: "none", sm: "block" } }}
          />
          <Grid item lg={3} md={3} sm={3} xs={6} color={colors.darkGray}>
            <Typography fontSize="36px">Today's Clients</Typography>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={6}
            display="flex"
            justifyContent="flex-end"
          >
            <Typography fontSize="50px" color={colors.primary} fontWeight={700}>
              64
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: colors.purple2,
          px: { xs: 3, sm: 10 },
          py: { xs: 5, sm: 10 },
        }}
      >
        <Typography
          fontSize="38px"
          color={`${colors.secondary}70`}
          fontWeight={700}
        >
          Our AI-based technology will ensure a smooth communication with
          candidates and makes it easy for you to keep a track of the applicants
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: colors.secondary,
          px: { xs: 3, sm: 10 },
          py: { xs: 5, sm: 7 },
        }}
      >
        <Grid container spacing={4} alignItems="center">
          <Grid
            item
            lg={3}
            md={3}
            sm={3}
            sx={{ display: { xs: "none", sm: "block" } }}
          />
          <Grid item lg={9} md={9} sm={9} xs={12}>
            <Typography fontSize="40px" fontWeight={700} textAlign="right" color={colors.darkGray}>
              With CIEL Jobs, you can cutdown the time required to shortlist the
              resumes and instead spend quality time to interview the candidates
              and find the right fit for your organisation
            </Typography>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={6}>
            <Typography fontSize="36px" color={colors.darkGray}>Total Hires</Typography>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={6}
            display="flex"
            justifyContent="flex-end"
          >
            <Typography fontSize="50px" color={colors.primary} fontWeight={700}>
              1,235
            </Typography>
          </Grid>
          <Grid
            item
            lg={7}
            md={7}
            sm={7}
            sx={{ display: { xs: "none", sm: "block" } }}
          />
          <Grid item lg={3} md={3} sm={3} xs={6}>
            <Typography fontSize="36px" color={colors.darkGray}>Today's Hires</Typography>
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={6}
            display="flex"
            justifyContent="flex-end"
          >
            <Typography fontSize="50px" color={colors.primary} fontWeight={700}>
              64
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Metrics;
