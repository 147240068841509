import React, { useState } from "react";
import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import Drawer from "../../common/RightSideDrawer";

const LoginDrawer = (props) => {
  const { open, setOpen } = props;

  const [forgotPasswordView, setForgotPasswordView] = useState(false);
  return (
    <Drawer
      open={open}
      onClose={() => {
        setOpen(false);
        setForgotPasswordView(false);
      }}
    >
      {!forgotPasswordView && (
        <LoginForm setForgotPasswordView={setForgotPasswordView} />
      )}
      {forgotPasswordView && (
        <ForgotPasswordForm setForgotPasswordView={setForgotPasswordView} />
      )}
    </Drawer>
  );
};

export default LoginDrawer;
