import React, { useState, useMemo, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Typography, Avatar } from "@mui/material";
import MetricCards from "./MetricCards";
import Filters from "./Filters";
import EmployerNavbar from "../../common/EmployerNavBar";
import NoBorderDropdown from "../../common/NoBorderDropdown";
import Table from "../../common/Table";
import ScreeningQuestionsModal from "../../common/ScreeningQuestionsModal";
import { getDateInFormat, loadScreeningAnswers } from "../../helpers";
import { statuses } from "../../constants";
import help_icon from "../../assets/imagesNew/help-icon.svg";
import colors from "../../styles/colors";

import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  GET_JOB_APPLICANTS,
  GET_JOB_DETAILS,
  UPDATE_JOB_CANDIDATE_STATUS,
} from "../../graphql/EmployerJobs";
import { GET_RESUME } from "../../graphql/users";
import { downloadAny } from "../../utils";
import getStore from "../../zustandStore";

const handleStatusChange = (status, id, updateJobCandidateStatus) => {
  updateJobCandidateStatus({
    variables: { payload: { id: [id], status: status } },
  });
};

function JobApplicants(props) {
  const setLoading = getStore((state) => state.setLoading);
  const setSelectedJob = getStore((state) => state.setSelectedJob);
  const selectedJob = getStore((state) => state.selectedJob);
  const uriParams = useParams();
  const history = useHistory();

  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [jobApplicants, setJobApplicants] = useState([]);
  const [jobMetricData, setJobMetricData] = useState({});
  const [jobApplicantFilter, setJobApplicantFilter] = useState({});
  const [totalApplicantCount, setTotalApplicantCount] = useState(0);
  const [screeningQuestionsModal, setScreeningQuestionsModal] = useState(false);
  const [screeningQuestionsResponses, setScreeningQuestionsResponses] =
    useState([]);
  const [
    screeningQuestionsSelectedCandidate,
    setScreeningQuestionsSelectedCandidate,
  ] = useState("");

  const { data: job, loading: jobTitleLoading } = useQuery(GET_JOB_DETAILS, {
    variables: {
      id: uriParams.jobId,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const {
    data: jobCandidates,
    loading,
    refetch,
  } = useQuery(GET_JOB_APPLICANTS, {
    variables: {
      filter: { ...jobApplicantFilter, jobId: uriParams.jobId },
      limit: 0,
      page: 1,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [updateJobCandidateStatus, { loading: updatingStatus }] = useMutation(
    UPDATE_JOB_CANDIDATE_STATUS,
    {
      onCompleted: (data) => {
        const { updateJobCandidate } = data || {};
        const { status, error } = updateJobCandidate;
        console.log({ status, error });
        if (status) {
          refetch();
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const [getResume, { downloadingResume, data }] = useLazyQuery(GET_RESUME, {
    nextFetchPolicy: "network-only",
    fetchPolicy: "network-only",
  });

  const downloadResume = async (userId) => {
    let temp = await getResume({
      variables: {
        userId: userId,
      },
    });
    let data = temp.data.getResume;
    downloadAny(data.file, data.fileName, data.fileType);
  };

  const columns = useMemo(
    () => [
      {
        field: "profile",
        headerName: "Profile",
        minWidth: 190,
        flex: 1,
        renderCell: (params) => (
          <Grid
            container
            spacing={0.25}
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={() =>
              history.push(`/candidate-details/${params.row.candidate.id}`)
            }
          >
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Avatar
                sx={{
                  mr: 1,
                  backgroundColor: colors.primaryFill,
                  color: colors.primary,
                  border: `1px solid ${colors.primary}`,
                }}
              >
                {params.row.candidate.firstName.charAt(0)}
              </Avatar>
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Typography fontSize="12px">
                {params.row.candidate.firstName || "-"}{" "}
                {params.row.candidate.lastName || ""}
              </Typography>
              <Typography
                color={colors.primary}
                fontSize="12px"
                fontWeight={700}
              >
                {params?.row?.score
                  ? `${Math.round(params.row?.score)}%`
                  : "0%"}
              </Typography>
            </Grid>
          </Grid>
        ),
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Typography fontSize="12px" noWrap>
            {params.row.candidate.email || "-"}
          </Typography>
        ),
      },
      {
        field: "mobile",
        headerName: "Phone Number",
        minWidth: 160,
        flex: 1,
        renderCell: (params) => (
          <Typography fontSize="12px">
            {params.row.candidate.mobile
              ? `+91 ${params.row.candidate.mobile}`
              : "-"}
          </Typography>
        ),
      },
      {
        field: "skills",
        headerName: "Skills",
        minWidth: 190,
        flex: 1,
        renderCell: (params) => (
          <Typography fontSize="12px" noWrap>
            {params.row.candidate.skills || "-"}
          </Typography>
        ),
      },
      {
        field: "createdDate",
        headerName: "Applied Date",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
          <Typography fontSize="12px">
            {getDateInFormat(params.row.createdDate, "DD MMM YYYY")}
          </Typography>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 130,
        flex: 1,
        renderCell: (params) => (
          <NoBorderDropdown
            value={params.row.status}
            onChange={(e) =>
              handleStatusChange(
                e.target.value,
                params.row.id,
                updateJobCandidateStatus
              )
            }
            bgColor={colors.secondary}
            textColor={colors.gray}
            dropdownArr={statuses}
          />
        ),
      },
      {
        field: "actions",
        headerName: "Candidate Responses",
        minWidth: 180,
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <img
            src={help_icon}
            alt="help"
            style={{ cursor: "pointer" }}
            onClick={() => {
              const name =
                params.row.candidate.firstName +
                  " " +
                  params.row.candidate.lastName || "";

              setScreeningQuestionsSelectedCandidate(name);
              loadScreeningAnswers(
                params.row.screeningAnswers,
                setScreeningQuestionsResponses
              );
              setScreeningQuestionsModal(true);
            }}
          />
        ),
      },
      {
        field: "resume",
        headerName: "Resume",
        minWidth: 20,
        flex: 1,
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderCell: (params) => (
          <button
            type="button"
            className="btn btn-icon"
            title="Resume"
            disabled={!params.row.candidate.resume}
            onClick={() => {
              downloadResume(params.row.candidate.id);
            }}
          >
            <i className="fa fa-download" />
          </button>
        ),
      },
    ],
    [updateJobCandidateStatus]
  );

  useEffect(() => {
    if (job && job.job) {
      setSelectedJob({ id: job.job.id, title: job.job.title });
    }
  }, [job, setSelectedJob]);

  useEffect(() => {
    if (jobCandidates && jobCandidates.jobCandidates) {
      setJobMetricData(jobCandidates.jobCandidates);
      setTotalApplicantCount(jobCandidates.jobCandidates.count);
    }
    if (
      jobCandidates &&
      jobCandidates.jobCandidates &&
      jobCandidates.jobCandidates.jobcandidates
    ) {
      setJobApplicants(jobCandidates.jobCandidates.jobcandidates);
    }
  }, [jobCandidates]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    setLoading(jobTitleLoading);
  }, [jobTitleLoading, setLoading]);

  useEffect(() => {
    setLoading(updatingStatus);
  }, [updatingStatus, setLoading]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="24px" color={colors.dark} fontWeight={500}>
          View Applicants ({totalApplicantCount}) - {selectedJob.title}
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <MetricCards jobMetricData={jobMetricData} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Filters
          jobApplicantFilter={jobApplicantFilter}
          setJobApplicantFilter={setJobApplicantFilter}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Table
          rows={jobApplicants}
          columns={columns}
          checkboxSelection
          selectedRows={selectedCandidates}
          setSelectedRows={setSelectedCandidates}
        />
      </Grid>
      <ScreeningQuestionsModal
        open={screeningQuestionsModal}
        setOpen={setScreeningQuestionsModal}
        data={screeningQuestionsResponses}
        name={screeningQuestionsSelectedCandidate}
        setData={setScreeningQuestionsResponses}
      />
    </Grid>
  );
}

const EmployerJobApplicants = (props) => {
  return <EmployerNavbar mainComponent={<JobApplicants />} />;
};

export default EmployerJobApplicants;
