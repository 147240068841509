import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import QuestionCard from "./QuestionCard";
import Footer from "./Footer";
import getStore from "../../zustandStore";
import EmployerNavbar from "../../common/EmployerNavBar";
import colors from "../../styles/colors";

import { useQuery } from "@apollo/client";
import { GET_SCREENING_QUESTIONS_JOB_TYPE_ID } from "../../graphql/users";

function ScreeningQuestions(props) {
  const screeningQuestions = getStore((state) => state.screeningQuestions);
  const setScreeningQuestions = getStore(
    (state) => state.setScreeningQuestions
  );
  const jobPayload = getStore((state) => state.jobPayload);
  const setLoading = getStore((State) => State.setLoading);

  const [questions, setQuestions] = useState([]);
  const [questionsJobTypeId, setQuestionsJobTypeId] = useState([]);

  const { data: screeningQuestionsJobId, loading } = useQuery(
    GET_SCREENING_QUESTIONS_JOB_TYPE_ID,
    {
      variables: { id: jobPayload?.jobTypeId },
      skip: !jobPayload?.jobTypeId,
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (screeningQuestions && screeningQuestions.length > 0) {
      setQuestions([...screeningQuestions]);
    } else {
      const temp = [];
      temp.push({ answerType: "YES_NO" });
      setQuestions(temp);
    }
  }, [screeningQuestions]);

  useEffect(() => {
    if (
      screeningQuestionsJobId &&
      screeningQuestionsJobId?.jobTypeScreeningQuestions
    ) {
      setQuestionsJobTypeId(screeningQuestionsJobId?.jobTypeScreeningQuestions);
    }
  }, [screeningQuestionsJobId]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="24px" color={colors.dark} fontWeight={500}>
          Screening Questions
        </Typography>
      </Grid>
      {questionsJobTypeId?.length > 0 && (
        <>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography fontSize="20px">Default Screening Questions</Typography>
          </Grid>
          {questionsJobTypeId?.map((ques) => (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  backgroundColor: colors.strokeGray,
                  p: 2,
                  borderRadius: 2,
                }}
              >
                <Typography>{ques?.question}</Typography>
              </Box>
            </Grid>
          ))}
        </>
      )}
      {questionsJobTypeId.length > 0 && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="20px">
            Add your own screening questions
          </Typography>
        </Grid>
      )}
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        {questions &&
          questions.map((ques, index) => (
            <div style={{ marginTop: index === 0 ? 0 : 15 }}>
              <QuestionCard
                questionNo={index}
                screeningQuestions={questions}
                setScreeningQuestions={setQuestions}
              />
            </div>
          ))}
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          size="small"
          startIcon={
            <AddCircleOutlineOutlined fontSize="small" color="primary" />
          }
          onClick={() => {
            const temp = [...questions];
            temp.push({ answerType: "YES_NO" });
            setQuestions(temp);
          }}
        >
          Add
        </Button>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 3 }}>
        <Footer
          setScreeningQuestions={setScreeningQuestions}
          questions={questions}
        />
      </Grid>
    </Grid>
  );
}

const EmployerScreeningQuestions = (props) => {
  return <EmployerNavbar mainComponent={<ScreeningQuestions />} />;
};

export default EmployerScreeningQuestions;
