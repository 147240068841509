import React, { useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import ceil_logo from "../../assets/images/CIELlogo-1.png";
import { withFormik } from "formik";
import * as Yup from "yup";
import queryString from "query-string";
import { useMutation } from "@apollo/client";
import { SET_PASSWORD } from "../../graphql/users";
const SetPasswordForm = (props) => {
  const { values, errors, handleChange, handleSubmit, touched } = props;

  console.log(">>>>>>>>values", values, errors);
  return (
    <div className="auth">
      <div className="auth_left">
        <div className="card">
          <div className="text-center mb-5">
            <Link className="header-brand" to="/">
              {/* <i className="fe fe-command brand-logo" /> */}
              <img src={ceil_logo} alt="LOGO" />
            </Link>
          </div>
          <div className="card-body">
            <div className="card-title">Set password</div>
            {/* <p className="text-muted">
								Enter your email address and your password will be reset and emailed to you.
							</p> */}
            <div className="form-group">
              <label className="form-label" htmlFor="exampleInputEmail1">
                New password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                aria-describedby="emailHelp"
                placeholder="Enter new password"
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="exampleInputEmail1">
                Confirm new password
              </label>
              <input
                type="password"
                className="form-control"
                id="confirmpassword"
                aria-describedby="emailHelp"
                placeholder="Confirm new password"
                onChange={handleChange}
              />
              {touched.confirmpassword && (
                <span style={{ fontSize: 12, color: "red" }}>
                  {errors.confirmpassword}
                </span>
              )}
            </div>
            <div className="form-footer">
              <button
                className="btn btn-primary btn-block"
                onClick={handleSubmit}
              >
                Reset Password
              </button>
            </div>
          </div>
          <div className="text-center text-muted">
            <Link to="/login">Sign In</Link>
          </div>
        </div>
      </div>
      <div className="auth_right">
        <div
          className="carousel slide"
          data-ride="carousel"
          data-interval={3000}
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="https://www.incimages.com/uploaded_files/image/1920x1080/getty_475352876_301971.jpg"
                className="img-fluid"
                alt="login page"
                style={{ width: "100%", height: 790 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SetPassword = (props) => {
  const [error, setError] = React.useState("");
  const [qs, setQs] = React.useState({});
  const location = useLocation();
  const history = useHistory();

  const [setPassword] = useMutation(SET_PASSWORD, {
    onCompleted: (data) => {
      const { setPassword } = data || {};
      const { error, status } = setPassword;

      if (status) {
        history.push("/");
      }
      console.log(">>>>>>>>>>>>>>>>status, error", status, error);
    },
  });

  useEffect(() => {
    const parsedQuery = queryString.parse(location.search);
    if (!parsedQuery.token || !parsedQuery.id) {
      setError("The link is invalid.");
    } else {
      setQs(parsedQuery);
    }
  }, []);

  const SetPasswordWrapped = withFormik({
    mapPropsToValues: (props) => {
      return {
        password: "",
        confirmpassword: "",
      };
    },

    validationSchema: Yup.object().shape({
      password: Yup.string().required("Enter your password"),
      // .matches(
      // 	/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      // 	'Passwords must be at least 8 letters long and must have a symbol (allowed symbols are ! * @ # & % $), upper and lower case letters and a number.'
      // ),
      confirmpassword: Yup.string()
        .required("Confirm your password")
        .oneOf([Yup.ref("password")], "Password does not match"),
    }),
    enableReinitialize: true,
    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props, qs);
      const { password } = values;
      setPassword({
        variables: {
          password,
          ...qs,
        },
      });
    },
  })(SetPasswordForm);

  return <SetPasswordWrapped></SetPasswordWrapped>;
};

export default SetPassword;
