import React, { useEffect, useState } from "react";
import CIELlogo_small from "../../assets/images/CIELlogo_small.png";
import {
  GET_COUNTRY,
  GET_STATES,
  GET_CITIES,
  GET_LOCATIONS,
  GET_QUALIFICATIONS,
} from "../../graphql/meta";
import { useQuery, useMutation } from "@apollo/client";
import { withFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { QR_ADD_APPLICANT } from "../../graphql/users";
import { Redirect } from "react-router-dom";
import { GET_CLUSTER_CENTRES } from "../../graphql/clusters";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal } from "react-bootstrap";
function QrCandidateForm(props) {
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    centreDetails,
    centreId,
    setFieldValue,
    centreName,
    state,
    setUserError,
    userError,
  } = props;

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [centreRetrive, setCentreRetrive] = useState([]);
  const [captcha, setCaptcha] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const { data: countriesDropDown } = useQuery(GET_COUNTRY, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: statesDropDown } = useQuery(GET_STATES, {
    variables: {
      country: selectedCountry,
    },
    skip: !selectedCountry,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: citiesDropDown } = useQuery(GET_CITIES, {
    variables: {
      state: selectedState,
    },
    skip: !selectedState,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: locationsDropDown } = useQuery(GET_LOCATIONS, {
    variables: {
      city: selectedCity,
    },
    skip: !selectedCity,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: qualificationsDropDown } = useQuery(GET_QUALIFICATIONS, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });
  console.log(qualificationsDropDown);
  const { cityId, stateId, countryId, profile, source } = values;

  useEffect(() => {
    if (cityId) {
      setSelectedCity(cityId);
      console.log(countriesDropDown);
    }
    if (profile && profile.preferredCityId) {
      setSelectedCity(profile.preferredCityId);
    }
  }, [cityId, profile]);

  useEffect(() => {
    if (countryId) {
      setSelectedCountry(countryId);
    }
    if (profile && profile.preferredCountryId) {
      setSelectedCountry(profile.preferredCountryId);
    }
  }, [countryId, profile]);

  useEffect(() => {
    if (stateId) {
      setSelectedState(stateId);
    }
    if (profile && profile.preferredStateId) {
      setSelectedState(profile.preferredStateId);
    }
  }, [stateId, profile]);
  // useEffect(() => {
  // //   for(let i=0;i<centres?.length;i++)
  // // {
  // //   console.log(centres[i].centre.id,centreId)
  // //   if(centres[i].centre.id===centreId)
  // //   {
  // //     console.log("i am here",centres[i])
  // //     setCentreRetrive(centres[i])
  // //     break
  // //   }
  // // }

  // }, [centres])
  console.log(centreRetrive);

  const captchaValue = (value) => {
    console.log(value);
    setCaptcha(true);
  };

  return (
    <div>
      <Modal show={errorModal} onHide={() => setErrorModal(false)}>
        <Modal.Header>
          <Modal.Title>Add Fail</Modal.Title>
          <button
            type="button"
            class="close"
            aria-label="Close"
            onClick={() => setErrorModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>{values.error}</Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-secondary"
            onClick={() => {
              setFieldValue("error", "");
              setErrorModal(false);
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <div
        className="container d-flex align-item-center"
        style={{ marginTop: 20 }}
      >
        <img src={CIELlogo_small} style={{ width: 40, height: 40 }} />
        <div className="ml-3">
          <h3>Candidate Form</h3>
          <hr />
        </div>
      </div>
      <div
        className="container p-20 mb-20"
        style={{ backgroundColor: "white" }}
      >
        <form action="">
          <div className="form-group d-flex">
            <label htmlFor="" className="col-form-label col-lg-3">
              First Name <span style={{ color: "red" }}>*</span>
            </label>
            <div className="col-lg-5">
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder="First Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
              />
              {touched.firstName && (
                <span style={{ fontSize: 12, color: "red" }}>
                  {errors.firstName}
                </span>
              )}
            </div>
          </div>

          <div className="form-group d-flex">
            <label htmlFor="" className="col-form-label col-lg-3">
              Last Name
            </label>
            <div className="col-lg-5">
              <input
                type="text"
                className="form-control"
                id="lastName"
                placeholder="Last Name"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group d-flex">
            <label htmlFor="" className="col-form-label col-lg-3">
              Mobile Number<span style={{ color: "red" }}>*</span>
            </label>
            <div className="col-lg-5">
              <input
                type="type"
                className="form-control"
                id="mobile"
                placeholder="Mobile Number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mobile}
              />
              {touched.mobile && (
                <span style={{ fontSize: 12, color: "red" }}>
                  {errors.mobile}
                </span>
              )}
            </div>
          </div>
          <div className="form-group d-flex">
            <label htmlFor="" className="col-form-label col-lg-3">
              Date Of Birth <span style={{ color: "red" }}>*</span>
            </label>
            <div className="col-lg-5">
              <input
                type="date"
                className="form-control"
                id="dob"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dob}
              />
              {touched.dob && (
                <span style={{ fontSize: 12, color: "red" }}>{errors.dob}</span>
              )}
            </div>
          </div>
          <div className="form-group d-flex">
            <label htmlFor="" className="col-form-label col-lg-3">
              Gender <span style={{ color: "red" }}>*</span>
            </label>
            <div className="col-lg-5">
              <select
                name="gender"
                id="gender"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.gender}
              >
                <option> -- Select Gender* -- </option>
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="O">Other</option>
                <option value="NA">NA</option>
              </select>
              {touched.gender && (
                <span style={{ fontSize: 12, color: "red" }}>
                  {errors.gender}
                </span>
              )}
            </div>
          </div>
          <div className="form-group d-flex">
            <label htmlFor="" className="col-form-label col-lg-3">
              Qualification <span style={{ color: "red" }}>*</span>
            </label>
            <div className="col-lg-5">
              <select
                name="qualificationId"
                className="form-control"
                id="qualificationId"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.qualificationId}
              >
                <option selected={values?.qualificationId ? true : false}>
                  {" "}
                  -- Select Qualifications* --{" "}
                </option>
                {qualificationsDropDown &&
                  qualificationsDropDown.qualifications &&
                  qualificationsDropDown?.qualifications.map(
                    (qualification) => (
                      <option
                        value={qualification.id}
                        selected={
                          values.qualificationId === qualification.id
                            ? true
                            : false
                        }
                      >
                        {qualification.name}
                      </option>
                    )
                  )}
              </select>
              {touched.qualificationId && (
                <span style={{ fontSize: 12, color: "red" }}>
                  {errors.qualificationId}
                </span>
              )}
            </div>
          </div>
          <div className="form-group d-flex">
            <label htmlFor="" className="col-form-label col-lg-3">
              <div className="font-15 d-flex flex-column align-item-center">
                <span>Upload Resume</span>
                <span className="font-12">(Maximum limit 5MB)</span>
              </div>
            </label>
            <div className="col-lg-5">
              <input
                type="file"
                placeholder="upload resume"
                onChange={(e) => {
                  let file = e.target.files[0];
                  if (file.size > 5242880) {
                    setFieldValue(
                      "error",
                      "File size cannot be more than 5mb."
                    );
                    // alert("File size cannot be more than 5mb.")
                    setErrorModal(true);
                    e.target.value = "";
                  } else {
                    setFieldValue("file", file);
                  }
                  setFieldValue("file", file);
                }}
              />
            </div>
          </div>

          <div className="form-group d-flex">
            <label htmlFor="" className="col-form-label col-lg-3">
              Centre
            </label>
            <div className="col-lg-5">
              <input
                type="text"
                className="form-control"
                placeholder="Centre"
                value={centreName}
                disabled
              />
            </div>
          </div>

          <div className="form-group d-flex">
            <label htmlFor="" className="col-form-label col-lg-3">
              Country
            </label>
            <div className="col-lg-5">
              <input
                type="text"
                className="form-control"
                placeholder="Country"
                value="India"
                disabled
              />
            </div>
          </div>

          <div className="form-group d-flex">
            <label htmlFor="" className="col-form-label col-lg-3">
              State
            </label>
            <div className="col-lg-5">
              <input
                type="text"
                className="form-control"
                value={state}
                disabled
              />
            </div>
          </div>

          <div className="form-group d-flex align-items-center">
            <label htmlFor="" className="col-form-label col-lg-3">
              Fresher?
            </label>
            <div className="col-lg-5 d-flex">
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="fresherRadios"
                  value="Y"
                />
                <label htmlFor="" className="form-check-label">
                  Yes
                </label>
              </div>
              <div className="form-check ml-3">
                <input
                  type="radio"
                  className="form-check-input"
                  name="fresherRadios"
                  value="N"
                />
                <label htmlFor="" className="form-check-label">
                  No
                </label>
              </div>
            </div>
          </div>
          <div className="container pl-10 mb-10">Location</div>

          <div className="d-flex flex-column flex-sm-row justify-content-between">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group form-group-lg">
                <select
                  className="form-control"
                  style={{ border: "1PX #7C7B7B solid" }}
                  id="countryId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option disabled selected={!values.countryId ? true : false}>
                    {" "}
                    -- Select Country* --{" "}
                  </option>
                  {countriesDropDown &&
                    countriesDropDown.countries &&
                    countriesDropDown?.countries.map((country) => (
                      <option
                        value={country.id}
                        selected={
                          values.countryId === country.id ? true : false
                        }
                      >
                        {country.name}
                      </option>
                    ))}
                </select>
                {touched.countryId && (
                  <span style={{ fontSize: 12, color: "red" }}>
                    {errors.countryId}
                  </span>
                )}
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group">
                <select
                  className="form-control"
                  style={{ border: "1PX #7C7B7B solid" }}
                  id="stateId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option disabled selected={!values.stateId ? true : false}>
                    {" "}
                    -- Select State* --{" "}
                  </option>
                  {statesDropDown &&
                    statesDropDown.states &&
                    statesDropDown?.states.map((state) => (
                      <option
                        value={state.id}
                        selected={values.stateId === state.id ? true : false}
                      >
                        {state.name}
                      </option>
                    ))}
                </select>
                {touched.stateId && (
                  <span style={{ fontSize: 12, color: "red" }}>
                    {errors.stateId}
                  </span>
                )}
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group">
                <select
                  className="form-control"
                  style={{ border: "1PX #7C7B7B solid" }}
                  id="cityId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option disabled selected={!values.cityId ? true : false}>
                    {" "}
                    -- Select City* --{" "}
                  </option>
                  {citiesDropDown &&
                    citiesDropDown.cities &&
                    citiesDropDown?.cities.map((city) => (
                      <option
                        value={city.id}
                        selected={values.cityId === city.id ? true : false}
                      >
                        {city.name}
                      </option>
                    ))}
                </select>
                {touched.cityId && (
                  <span style={{ fontSize: 12, color: "red" }}>
                    {errors.cityId}
                  </span>
                )}
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group">
                <select
                  className="form-control"
                  style={{ border: "1PX #7C7B7B solid" }}
                  id="locationId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option disabled selected={!values.locationId ? true : false}>
                    {" "}
                    -- Select Location* --{" "}
                  </option>
                  {locationsDropDown &&
                    locationsDropDown.locations &&
                    locationsDropDown?.locations.map((location) => (
                      <option
                        value={location.id}
                        selected={
                          values.locationId === location.id ? true : false
                        }
                      >
                        {location.name}
                      </option>
                    ))}
                </select>
                {touched.locationId && (
                  <span style={{ fontSize: 12, color: "red" }}>
                    {errors.locationId}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="container mb-10">
            <ReCAPTCHA
              sitekey="6Lf7n6EfAAAAAFf67IB2La6apPcPvvnGcFey4ks_"
              onChange={() => captchaValue()}
            />
          </div>
          <div className="container">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                handleSubmit();
                e.preventDefault();
              }}
              disabled={captcha ? false : true}
            >
              Apply
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const QrAddCandidate = (props) => {
  const urlParams = new URLSearchParams(props.location.search);
  const [centreId, setcentreId] = useState(urlParams.get("id"));
  const [centreName, setcentreName] = useState(urlParams.get("name"));
  const [state, setState] = useState(urlParams.get("state"));
  const [userError, setUserError] = useState(false);
  const [sucessFullyAdded, setSucessfullyAdded] = useState(false);
  // const {userError,setUserError}=props
  const [addApplicant] = useMutation(QR_ADD_APPLICANT, {
    onCompleted: (data) => {
      const { createApplicantCandidate } = data || {};
      const { status, error } = createApplicantCandidate;
      console.log(status, error);
      if (status) {
        console.log("Status", status);
        setSucessfullyAdded(true);
      } else {
        console.log("Error", error);
        if (error === "User Already Exists") {
          console.log("hello");
          setUserError("false");
        }
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });
  console.log(centreName);
  // const {data:centreDetails}=useQuery(GET_CLUSTER_CENTRES,{
  //   variables:{
  //     id:centreName
  //   },
  //   skip:!centreName,
  //   nextFetchPolicy:"network-only",
  //   fetchPolicy:"network-only"
  // })
  // console.log(centreDetails,centreId)

  const QrAddCandidateWrapped = withFormik({
    mapPropsToValues: () => {
      return {
        firstName: "",
        lastName: "",
        mobile: "",
        dob: "",
        locationId: "",
        stateId: "",
        countryId: "",
        cityId: "",
        qualificationId: "",
        gender: "",
        centreId: centreId || "",
        file: null,
      };
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Please enter the first name"),
      mobile: Yup.string()
        .required("Please enter mobile number")
        .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
      dob: Yup.date()
        .required("Please enter DOB")
        .max(moment().format("YYYY-MM-DD")),
      gender: Yup.string().required("Please select gender"),
      countryId: Yup.string().required("Please select country"),
      stateId: Yup.string().required("Please select state"),
      cityId: Yup.string().required("Please select city"),
      locationId: Yup.string().required("Please select location"),
      qualificationId: Yup.string().required("Please select qualification"),
    }),
    handleSubmit(values, { props, setStatus }) {
      console.log(">>>>>>>>>>>>>values121", values, props);
      addApplicant({
        variables: {
          payload: values,
        },
      });
    },
  })(QrCandidateForm);

  return (
    <div>
      {userError && (
        <Modal show={userError}>
          <Modal.Header>
            <Modal.Title>User Already Exist</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            User Already Exists with this phone number. Please verify the phone
            number.
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={() => {
                setUserError(false);
              }}
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {!userError && centreId && !sucessFullyAdded && (
        <QrAddCandidateWrapped
          centreId={centreId}
          centreName={centreName}
          state={state}
          userError={userError}
          setUserError={setUserError}
        />
      )}

      {!userError && centreId && sucessFullyAdded && (
        <div>
          <div
            className="container d-flex align-item-center"
            style={{ marginTop: 20 }}
          >
            <img src={CIELlogo_small} style={{ width: 40, height: 40 }} />
            <div className="ml-3">
              <h3>Candidate Form</h3>
              <hr />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 40,
              marginRight: 10,
              marginLeft: 10,
            }}
          >
            <div style={{ color: "green", width: "10%" }}>
              <i className="fa fa-check" style={{ fontSize: 38 }} />
            </div>
            <div
              class="ml-3"
              style={{ fontSize: 24, fontWeight: "bold", width: "90%" }}
            >
              Submitted successfully! To add a new candidate, please scan the QR
              Code again.
            </div>
          </div>
          {/* <div class='ml-3 mr-3'>To add a new candidate, please scan the QR Code again.</div> */}
        </div>
      )}
    </div>
  );
};
// const QrAddCandidate=()=>{
//   const [userError, setUserError] = useState(false)
// return(
//   <QrAddCandidate1 userError={userError} setUserError={setUserError}/>
// )
// }

export default QrAddCandidate;
//
