import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid, Typography, Button } from "@mui/material";
import client1 from "../../assets/imagesNew/client1.svg";
import client2 from "../../assets/imagesNew/client2.svg";
import client3 from "../../assets/imagesNew/client3.svg";
import client4 from "../../assets/imagesNew/client4.svg";
import client5 from "../../assets/imagesNew/client5.svg";
import client6 from "../../assets/imagesNew/client6.svg";
import client7 from "../../assets/imagesNew/client7.svg";
import client8 from "../../assets/imagesNew/client8.svg";
import client9 from "../../assets/imagesNew/client9.svg";
import client10 from "../../assets/imagesNew/client10.svg";
import client11 from "../../assets/imagesNew/client11.svg";
import colors from "../../styles/colors";

const Clients = (props) => {
  const history = useHistory();
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: colors.secondary,
          pl: { xs: 3, sm: 10 },
          py: { xs: 5, sm: 10 },
          pr: 3,
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <Typography
              fontSize="50px"
              fontWeight={700}
              color={colors.darkGray}
            >
              Our Clients
            </Typography>
            <Typography color={`${colors.gray}40`} fontSize="40px">
              Pleasure to work with
            </Typography>
          </Grid>
          <Grid item lg={7} md={7} sm={7} xs={12}>
            <Grid container spacing={1}>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <img src={client10} alt="1" height={50} />
                <Typography color="primary" fontSize="12px">
                  One of India's largest conglomerates based in Western India
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <img src={client3} alt="1" height={50} />
                <Typography color="primary" fontSize="12px">
                  One of world's largest oil manufacturing company founded in
                  the early 1900s
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <img src={client4} alt="1" height={50} />
                <Typography color="primary" fontSize="12px">
                  A global solar solutions provider across industrial,
                  commercial and utility segments
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <img src={client6} alt="1" height={50} />
                <Typography color="primary" fontSize="12px">
                  One of India's top oil refineries based in West region of
                  India encompassing a larger network of retail fuel outlets
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <img src={client2} alt="1" height={50} />
                <Typography color="primary" fontSize="12px">
                  One of India's largest Home Appliances company headquatered in
                  Mumbai
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <img src={client1} alt="1" height={50} />
                <Typography color="primary" fontSize="12px">
                  A leading mining and metals company based in Jharkhand
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <img src={client8} alt="1" height={50} />
                <Typography color="primary" fontSize="12px">
                  Top manufacturer of highly engineered industrial products
                  located in Western India
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <img src={client5} alt="1" height={50} />
                <Typography color="primary" fontSize="12px">
                  A leading multinational transportation provider headquatered
                  in France
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <img src={client11} alt="1" height={50} />
                <Typography color="primary" fontSize="12px">
                  India's leading multinational Infrastructure developer
                  headquatered in Mumbai
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <img src={client5} alt="1" height={50} />
                <Typography color="primary" fontSize="12px">
                  A global manufacturing and engineering company focused on
                  commercial vehicles, based in Germany
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <img src={client7} alt="1" height={50} />
                <Typography color="primary" fontSize="12px">
                  One of the world's leading component supplier in the wind
                  industry for blades, service and logistics support
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <img src={client9} alt="1" height={50} />
                <Typography color="primary" fontSize="12px">
                  A global energy services company with advanced technology
                  headquatered in Texas
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: colors.purple2,
          px: { xs: 3, sm: 10 },
          py: { xs: 5, sm: 15 },
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item lg={8} md={8} sm={8} xs={12}>
            <Typography color="secondary" fontSize="50px" fontWeight={700}>
              Explore all the things in CIEL Jobs
            </Typography>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={4}
            xs={12}
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              sx={{ color: colors.purple2 }}
              onClick={() => history.push("/employer-registration")}
            >
              Sign Up
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Clients;
