import { Redirect, Route, RouteProps } from "react-router-dom";
import { clearSession, isJwtTokenValid } from "../../utils";
import { useLocation } from "react-router-dom";
import React, { lazy } from "react";

const Forbidden = lazy(() => import("./404"));

export default function ProtectedRoute({
  roles,
  authenticationPath,
  verificationPath,
  ...routeProps
}) {
  const location = useLocation();
  const { hasExpired } = isJwtTokenValid();
  if (!hasExpired) {
    return <Route {...routeProps} />;
  } else {
    clearSession();
    return <Redirect to={{ pathname: authenticationPath }} />;
  }
}
