import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import DashboardCards from "./DashboardCards";
import EmployerNavbar from "../../common/EmployerNavBar";
import GraphSection from "./GraphSection";
import RecentApplicants from "./RecentApplicants";
import NoJobsView from "./NoJobsView";

import { useQuery } from "@apollo/client";
import { GET_ALL_JOBS_COUNT_AND_LIST } from "../../graphql/EmployerDashboard";
import getStore from "../../zustandStore";

function Dashboard() {
  const setLoading = getStore((state) => state.setLoading);
  const setJobPayload = getStore((state) => state.setJobPayload);
  const setScreeningQuestions = getStore(
    (state) => state.setScreeningQuestions
  );

  const [jobCount, setJobCount] = useState(0);

  const { data: jobsData, loading: loadingJobsData } = useQuery(
    GET_ALL_JOBS_COUNT_AND_LIST,
    {
      variables: {},
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (jobsData && jobsData.jobs && jobsData.jobs.count) {
      setJobCount(jobsData.jobs.count);
    }
  }, [jobsData]);

  useEffect(() => {
    setLoading(loadingJobsData);
  }, [loadingJobsData, setLoading]);

  useEffect(() => {
    setJobPayload({});
    setScreeningQuestions([]);
  }, [setJobPayload, setScreeningQuestions]);

  return (
    <Grid container spacing={2}>
      {/* <Grid item lg={12} md={12} sm={12} xs={12}>
        <WelcomeCard jobsData={jobsData} />
      </Grid> */}
      {jobCount > 0 ? (
        <>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <DashboardCards jobsData={jobsData} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <GraphSection jobsData={jobsData} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
            <RecentApplicants />
          </Grid>
        </>
      ) : (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <NoJobsView jobsData={jobsData} />
        </Grid>
      )}
    </Grid>
  );
}

const EmployerDashbord = (props) => {
  return <EmployerNavbar mainComponent={<Dashboard />} />;
};

export default EmployerDashbord;
