import React from "react";

const HorizontalDivider = (props) => {
  return (
    <div
      style={{
        height: 1,
        backgroundColor: "#E9E9E9",
        width: "100%",
      }}
    />
  );
};

export default HorizontalDivider;
