import React, { useState, useEffect } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import {
  GET_COUNTRY,
  GET_STATES,
  GET_CITIES,
  GET_INDUSTRIES,
  JOB_TYPES,
  GET_QUALIFICATIONS,
} from "../../graphql/meta";
import { useQuery, useMutation } from "@apollo/client";
import { ADD_JOB, UPDATE_JOB, GET_ACCOUNT_MANAGERS } from "../../graphql/jobs";
import { GET_ORGANIZATIONS } from "../../graphql/organizations";
import { getLoggedInUserData, isJwtTokenValid } from "../../utils";

const AddNewJobForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setAddJobsView,
    selectedJobPayload,
    setFieldValue,
    clone,
  } = props;

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const { roles } = isJwtTokenValid();

  const { data: countries } = useQuery(GET_COUNTRY, {
    variables: {},
  });

  const { data: organizations } = useQuery(GET_ORGANIZATIONS, {
    variables: {
      filter: {
        organizationType: 2,
      },
    },
  });

  const { data: states } = useQuery(GET_STATES, {
    variables: {
      country: selectedCountry,
    },
    skip: !selectedCountry,
  });

  const { data: cities } = useQuery(GET_CITIES, {
    variables: {
      state: selectedState,
    },
    skip: !selectedState,
  });


  const { data: industries } = useQuery(GET_INDUSTRIES, {
    variables: {},
  });

  const { data: jobTypes } = useQuery(JOB_TYPES, {
    variables: {},
  });

  const { data: qualifications } = useQuery(GET_QUALIFICATIONS, {
    variables: {},
  });

  const { data: accountManagers } = useQuery(GET_ACCOUNT_MANAGERS, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { cityId, stateId, countryId } = values;

  useEffect(() => {
    if (cityId) {
      setSelectedCity(cityId);
    }
  }, [cityId]);

  useEffect(() => {
    if (countryId) {
      setSelectedCountry(countryId);
    }
  }, [countryId]);

  useEffect(() => {
    if (stateId) {
      setSelectedState(stateId);
    }
  }, [stateId]);

  useEffect(() => {
    console.log({ values, errors });
  }, [values, errors]);

  return (
    <>
      <div>
        <div className="section-body mt-3">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a onClick={() => setAddJobsView(false)}>
                      <span style={{ fontSize: 14, cursor: "pointer" }}>
                        Jobs
                      </span>
                    </a>
                  </li>
                  <li class="breadcrumb-item">
                    <span style={{ fontSize: 14 }}>
                      {!clone && selectedJobPayload?.title
                        ? selectedJobPayload?.title
                        : "New"}
                    </span>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="form-group">
                    <label>Job Details</label>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: -20 }}
                >
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                      <input
                        id="title"
                        type="text"
                        className="form-control"
                        placeholder="Title *"
                        style={{ border: "1px #7C7B7B solid" }}
                        onChange={handleChange}
                        value={values?.title}
                        onBlur={handleBlur}
                      />
                      {touched.title && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.title}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                      <textarea
                        id="description"
                        type="text"
                        className="form-control"
                        placeholder="Description"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        value={values?.description}
                        rows={3}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        id="industryId"
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.industryId ? true : false}
                        >
                          {" "}
                          -- Select Industry* --{" "}
                        </option>
                        {industries?.industries?.map((c) => (
                          <option
                            key={c.id}
                            value={c.id}
                            selected={values.industryId === c.id ? true : false}
                          >
                            {" "}
                            {c.name}{" "}
                          </option>
                        ))}
                      </select>
                      {touched.industryId && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.industryId}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        id="jobTypeId"
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.jobTypeId ? true : false}
                        >
                          {" "}
                          -- Select Job Type* --{" "}
                        </option>
                        {jobTypes?.jobTypes?.map((c) => (
                          <option
                            key={c.id}
                            value={c.id}
                            selected={values.jobTypeId === c.id ? true : false}
                          >
                            {" "}
                            {c.name}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        id="qualificationId"
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.qualificationId ? true : false}
                        >
                          {" "}
                          -- Select Qualification* --{" "}
                        </option>
                        {qualifications?.qualifications?.map((c) => (
                          <option
                            key={c.id}
                            value={c.id}
                            selected={
                              values.qualificationId === c.id ? true : false
                            }
                          >
                            {" "}
                            {c.name}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Skills"
                        id="skills"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        value={values.skills}
                      />
                      {touched.skills && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.skills}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>Experience</label>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>Remuneration</label>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: -20 }}
                >
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Min experience *"
                        id="minimumExperience"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={(event) =>
                          setFieldValue(
                            "minimumExperience",
                            parseFloat(event.target.value)
                          )
                        }
                        value={
                          values?.minimumExperience === -1
                            ? ""
                            : values.minimumExperience
                        }
                        // value={values.minimumExperience}
                      />
                      {/* {touched.minimumExperience && <span style={{ fontSize: 12, color: 'red' }}>{errors.minimumExperience}</span>} */}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Max experience *"
                        id="maximumExperience"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={(event) =>
                          setFieldValue(
                            "maximumExperience",
                            parseFloat(event.target.value)
                          )
                        }
                        value={
                          values?.maximumExperience === 0
                            ? ""
                            : values.maximumExperience
                        }
                        // onBlur={handleBlur}
                      />
                      {touched.maximumExperience &&
                        values.maximumExperience !== 0 && (
                          <span style={{ fontSize: 12, color: "red" }}>
                            {errors.maximumExperience}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Min remuneration"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="minimumRemuneration"
                        onChange={(event) =>
                          setFieldValue(
                            "minimumRemuneration",
                            parseFloat(event.target.value)
                          )
                        }
                        value={
                          values?.minimumRemuneration === -1
                            ? ""
                            : values.minimumRemuneration
                        }
                      />
                      {touched.minimumRemuneration && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.minimumRemuneration}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Max remuneration"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="maximumRemuneration"
                        onChange={(event) =>
                          setFieldValue(
                            "maximumRemuneration",
                            parseFloat(event.target.value)
                          )
                        }
                        value={
                          values?.maximumRemuneration === 0
                            ? ""
                            : values.maximumRemuneration
                        }
                        // onBlur={handleBlur}
                      />
                      {touched.maximumRemuneration && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.maximumRemuneration}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        id="countryId"
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.countryId ? true : false}
                        >
                          {" "}
                          -- Select Country* --{" "}
                        </option>
                        {countries?.countries?.map((c) => (
                          <option
                            key={c.id}
                            value={c.id}
                            selected={values.countryId === c.id ? true : false}
                          >
                            {" "}
                            {c.name}{" "}
                          </option>
                        ))}
                      </select>
                      {touched.countryId && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.countryId}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        id="stateId"
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.stateId ? true : false}
                        >
                          {" "}
                          -- Select State* --{" "}
                        </option>
                        {states?.states?.map((c) => (
                          <option
                            key={c.id}
                            value={c.id}
                            selected={values.stateId === c.id ? true : false}
                          >
                            {" "}
                            {c.name}{" "}
                          </option>
                        ))}
                      </select>
                      {touched.stateId && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.stateId}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        id="cityId"
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.cityId ? true : false}
                        >
                          {" "}
                          -- Select City* --{" "}
                        </option>
                        {cities?.cities?.map((c) => (
                          <option
                            key={c.id}
                            value={c.id}
                            selected={values.cityId === c.id ? true : false}
                          >
                            {" "}
                            {c.name}{" "}
                          </option>
                        ))}
                      </select>
                      {touched.cityId && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.cityId}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        id="locationId"
                        type="text"
                        className="form-control"
                        placeholder="Location *"
                        style={{ border: "1px #7C7B7B solid" }}
                        onChange={handleChange}
                        value={values?.locationId}
                        onBlur={handleBlur}
                      />
                      {touched.locationId && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.locationId}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Positions"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="positions"
                        onChange={(event) => {
                          setFieldValue(
                            "positions",
                            parseInt(event.target.value)
                          );
                        }}
                        value={values?.positions === 0 ? "" : values?.positions}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Notice Period (in days)"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="noticePeriod"
                        onChange={(event) => {
                          setFieldValue(
                            "noticePeriod",
                            parseInt(event.target.value)
                          );
                        }}
                        value={
                          values?.noticePeriod === 0 ? "" : values?.noticePeriod
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Target Companies"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="targetCompanies"
                        onChange={handleChange}
                        value={values?.targetCompanies}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="No Poach Companies"
                        style={{ border: "1PX #7C7B7B solid" }}
                        id="nopoachCompanies"
                        onChange={handleChange}
                        value={values?.nopoachCompanies}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        id="genderPreference"
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.genderPreference ? true : false}
                        >
                          {" "}
                          -- Select Gender* --{" "}
                        </option>
                        <option
                          value="M"
                          selected={
                            values.genderPreference === "M" ? true : false
                          }
                        >
                          Male
                        </option>
                        <option
                          value="F"
                          selected={
                            values.genderPreference === "F" ? true : false
                          }
                        >
                          Female
                        </option>
                        <option
                          value="B"
                          selected={
                            values.genderPreference === "B" ? true : false
                          }
                        >
                          Both
                        </option>
                        <option
                          value="O"
                          selected={
                            values.genderPreference === "Other" ? true : false
                          }
                        >
                          Other
                        </option>
                        <option
                          value="NA"
                          selected={
                            values.genderPreference === "NA" ? true : false
                          }
                        >
                          NA
                        </option>
                      </select>
                      {touched.genderPreference && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.genderPreference}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        id="organizationId"
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={roles[0] === "employer_user" ? true : false}
                      >
                        <option
                          disabled
                          selected={!values.organizationId ? true : false}
                        >
                          {" "}
                          -- Select Employer --{" "}
                        </option>
                        {organizations?.organizations?.map((c) => (
                          <option
                            key={c.id}
                            value={c.id}
                            selected={
                              values.organizationId === c.id ? true : false
                            }
                          >
                            {" "}
                            {c.name}{" "}
                          </option>
                        ))}
                      </select>
                      {touched.organizationId && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.organizationId}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  {/* <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <select id="skillId"
                                                className="form-control"
                                                style={{ border: '1PX #7C7B7B solid' }}
                                                onChange={handleChange}
                                            >
                                                <option disabled selected={!values.skillId ? true : false}> -- Select Skill -- </option>
                                                {skills?.skills?.map((c) => <option key={c.id} value={c.id} selected={values.skillId === c.id ? true : false}> {c.name} </option>)}
                                            </select>
                                        </div>
                                    </div> */}
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        id="accountManagerId"
                        className="form-control"
                        style={{ border: "1PX #7C7B7B solid" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option
                          disabled
                          selected={!values.accountManagerId ? true : false}
                        >
                          -- Select Account Manager --
                        </option>
                        {accountManagers?.getAccountManager?.users?.map((c) => (
                          <option
                            key={c.id}
                            value={c.id}
                            selected={
                              values.accountManagerId === c.id ? true : false
                            }
                          >
                            {c?.firstName || ""} {c?.lastName || ""}
                          </option>
                        ))}
                      </select>
                      {touched.accountManagerId && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {errors.accountManagerId}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="confidentail"
                        name="confidential"
                        // value={true}
                        checked={values.confidential}
                        onChange={(e) =>
                          setFieldValue("confidential", e.target.checked)
                        }
                      />
                      <label for="confidential" style={{ marginLeft: 5 }}>
                        Hide Employer Name
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ marginTop: 15 }}
                    onClick={handleSubmit}
                  >
                    {!clone && selectedJobPayload?.id ? "Save" : "Add"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ marginTop: 15, marginLeft: 5 }}
                    onClick={() => setAddJobsView(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddNewJob = (props) => {
  const { setAddJobsView, selectedJobPayload, clone } = props;
  const me = getLoggedInUserData();
  const { roles } = isJwtTokenValid();
  console.log({ me });

  const [addJob] = useMutation(ADD_JOB, {
    onCompleted: (data) => {
      const { createJob } = data || {};
      const { status, error } = createJob;
      console.log({ status, error });
      setAddJobsView(false);
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [updateJob] = useMutation(UPDATE_JOB, {
    onCompleted: (data) => {
      const { updateJob } = data || {};
      const { status, error } = updateJob;
      console.log({ status, error });
      setAddJobsView(false);
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const AddNewJobWrapped = withFormik({
    mapPropsToValues: (props) => {
      const { selectedJobPayload } = props;
      return {
        title: selectedJobPayload?.title || "",
        description: selectedJobPayload?.description || "",
        minimumExperience:
          selectedJobPayload?.minimumExperience >= 0
            ? selectedJobPayload?.minimumExperience
            : -1,
        minimumRemuneration:
          selectedJobPayload?.minimumRemuneration >= 0
            ? selectedJobPayload?.minimumRemuneration
            : -1,
        maximumExperience: selectedJobPayload?.maximumExperience || 0,
        maximumRemuneration: selectedJobPayload?.maximumRemuneration || 0,
        locationId: selectedJobPayload?.locationId || "",
        stateId: selectedJobPayload?.state?.id || "",
        countryId: selectedJobPayload?.country?.id || "",
        cityId: selectedJobPayload?.city?.id || "",
        positions: selectedJobPayload?.positions || 0,
        noticePeriod: selectedJobPayload?.noticePeriod || 0,
        targetCompanies: selectedJobPayload?.targetCompanies || "",
        nopoachCompanies: selectedJobPayload?.nopoachCompanies || "",
        genderPreference: selectedJobPayload?.genderPreference || "",
        organizationId:
          roles[0] === "employer_user" &&
          Object.keys(selectedJobPayload).length === 0 &&
          me?.organization
            ? me.organization.id
            : selectedJobPayload?.organization?.id || "",
        skills: selectedJobPayload?.skills || "",
        industryId: selectedJobPayload?.industry?.id || "",
        jobTypeId: selectedJobPayload?.jobType?.id || "",
        qualificationId:
          selectedJobPayload?.qualification?.qualification?.id || "",
        confidential: selectedJobPayload?.confidential || false,
        accountManagerId: selectedJobPayload?.accountManager?.id || "",
      };
    },

    validationSchema: Yup.object().shape({
      title: Yup.string().required("Please enter job title"),
      locationId: Yup.string().required("Please select a location"),
      cityId: Yup.string().required("Please select a city"),
      stateId: Yup.string().required("Please select a state"),
      countryId: Yup.string().required("Please select a country"),
      genderPreference: Yup.string().required("Please select a gender"),
      organizationId: Yup.string().required("Please select an employer"),
      minimumRemuneration: Yup.number().test({
        name: "minus one test",
        message: "Please specify minimum remuneration",
        test: function (value) {
          return value >= 0;
        },
      }),
      maximumExperience: Yup.number().when("minimumExperience", {
        is: (val) => val !== -1,
        then: Yup.number()
          .required("Required")
          .test({
            name: "min-max",
            message:
              "Maximum experience must be greater than the minimum experience",
            test: function (value) {
              // You can access the price field with `this.parent`.
              return value > this.parent.minimumExperience;
            },
          }),
      }),
      maximumRemuneration: Yup.number()
        .test({
          name: "minus one test",
          message: "Please specify maximum remuneration",
          test: function (value) {
            return value > 0;
          },
        })
        .when("minimumRemuneration", {
          is: (val) => val !== -1,
          then: Yup.number()
            .required("Required")
            .test({
              name: "min-max",
              message:
                "Maximum remuneration must be greater than the minimum remuneration",
              test: function (value) {
                // You can access the price field with `this.parent`.
                return value > this.parent.minimumRemuneration;
              },
            }),
        }),
      skills: Yup.string().required("Please enter the skills"),
      industryId: Yup.string().when("confidential", {
        is: (confidential) => confidential === true,
        then: Yup.string().required("Please specify the industry"),
      }),
      accountManagerId: Yup.string().required(
        "Please select an account manager"
      ),
    }),

    enableReinitialize: true,
    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>values", values, props);

      const { selectedJobPayload } = props;
      

      if (selectedJobPayload?.id && !clone) {

        const payload = {
          ...values,
          id: selectedJobPayload.id,
          title: values.title.trim(),
          location: values.locationId
        };

        delete payload.locationId

        updateJob({
          variables: {
            payload: payload,
          },
        });
      } else {

        const payload = {
          ...values,
          closure: "2022-05-01",
          title: values.title.trim(),
          location: values.locationId
        };

        delete payload.locationId
        addJob({
          variables: {
            payload: payload,
          },
        });
      }
    },
  })(AddNewJobForm);

  return (
    <AddNewJobWrapped
      setAddJobsView={setAddJobsView}
      selectedJobPayload={selectedJobPayload}
      clone={clone}
    ></AddNewJobWrapped>
  );
};

export default AddNewJob;
