import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Button,
} from "@mui/material";
import Drawer from "../../common/RightSideDrawer";
import { gendersArr, jobModesArr } from "../../constants";
import { formArrayFromStringSkills } from "../../helpers";
import colors from "../../styles/colors";

import { useQuery } from "@apollo/client";
import { GET_SEARCH_META } from "../../graphql/meta";

const FilterDrawer = (props) => {
  const { open, setOpen, filters, setFilters, jobTitle } = props;

  const [dropDownData, setDropDownData] = useState({});
  const [tempFilters, setTempFilters] = useState({});
  const [skillsFilter, setSkillsFilter] = useState([]);

  const { data: dropDownDataRaw } = useQuery(GET_SEARCH_META, {
    variables: {},
  });

  const setFilter = (key, val) => {
    setTempFilters({ ...filters, [key]: val });
  };

  useEffect(() => {
    setDropDownData(dropDownDataRaw);
  }, [dropDownDataRaw]);

  useEffect(() => {
    if (filters && filters.skills) {
      setTempFilters({
        ...filters,
        skills: formArrayFromStringSkills(filters.skills),
      });
    } else {
      setTempFilters(filters);
    }
  }, [filters]);

  return (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <Grid container spacing={2} sx={{ p: { xs: 3, sm: 5 } }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography color={colors.dark} fontSize="24px" fontWeight={500}>
            Filter by
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <Typography fontSize="13px">Job Title</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
          <Typography color={colors.dark} fontSize="16px" fontWeight={500}>
            {jobTitle}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography fontSize="13px">Job Category</Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          display={{ xs: "none", md: "block" }}
        >
          <Typography fontSize="13px">Job Type</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -1 }}>
          <Autocomplete
            id="jobCategoryPref"
            autoComplete
            disableClearable
            onChange={(_, value) => setFilter("jobType", [value.name])}
            value={
              dropDownData?.jobTypes && tempFilters?.jobType
                ? dropDownData.jobTypes.find(
                    (x) => x.name === tempFilters["jobType"][0]
                  )
                : {
                    id: "",
                    name: "",
                  }
            }
            options={dropDownData?.jobTypes || []}
            getOptionLabel={(option) => option.name || ""}
            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose..." />
            )}
          />
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          display={{ xs: "block", md: "none" }}
        >
          <Typography fontSize="13px">Job Type</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -1 }}>
          <Autocomplete
            id="jobModePref"
            autoComplete
            disableClearable
            onChange={(_, value) => setFilter("jobMode", value.name)}
            value={
              jobModesArr.find((x) => x.name === tempFilters["jobMode"]) || {
                id: "",
                name: "",
              }
            }
            getOptionLabel={(option) => option.name || ""}
            options={jobModesArr}
            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose..." />
            )}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Typography fontSize="13px">Qualification</Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          display={{ xs: "none", md: "block" }}
        >
          <Typography fontSize="13px">Gender</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -1 }}>
          <Autocomplete
            id="qualificationPrefs"
            autoComplete
            disableClearable
            onChange={(_, value) => setFilter("qualification", value.name)}
            value={
              dropDownData?.qualifications
                ? dropDownData.qualifications.find(
                    (x) => x.name === tempFilters["qualification"]
                  )
                : {
                    id: "",
                    name: "",
                  }
            }
            options={dropDownData?.qualifications || []}
            getOptionLabel={(option) => option.name || ""}
            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose..." />
            )}
          />
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          display={{ xs: "block", md: "none" }}
        >
          <Typography fontSize="13px">Gender</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -1 }}>
          <Autocomplete
            id="genderPreference"
            autoComplete
            disableClearable
            onChange={(_, value) => setFilter("gender", value.name)}
            value={
              gendersArr.find((x) => x.name === tempFilters["gender"]) || {
                id: "",
                name: "",
              }
            }
            getOptionLabel={(option) => option.name || ""}
            options={gendersArr}
            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose..." />
            )}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">Work Location Preference</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <Autocomplete
            id="cityPrefs"
            autoComplete
            disableClearable
            options={dropDownData?.allCities || []}
            onChange={(_, value) => setFilter("city", value.name)}
            value={
              dropDownData?.allCities
                ? dropDownData.allCities.find(
                    (x) => x.name === tempFilters["city"]
                  )
                : {
                    id: "",
                    name: "",
                  }
            }
            getOptionLabel={(option) => option.name || ""}
            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose..." />
            )}
          />
        </Grid>
        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">Experience (in years)</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -2 }}>
          <Typography fontSize="11px">Min</Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          display={{ xs: "none", md: "block" }}
          sx={{ mt: -2 }}
        >
          <Typography fontSize="11px">Max</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -1 }}>
          <TextField size="small" fullWidth type="number" />
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          display={{ xs: "block", md: "none" }}
          sx={{ mt: -2 }}
        >
          <Typography fontSize="11px">Max</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -1 }}>
          <TextField size="small" fullWidth type="number" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">Expected CTC (Per Month)</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -2 }}>
          <Typography fontSize="11px">Min</Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          display={{ xs: "none", md: "block" }}
          sx={{ mt: -2 }}
        >
          <Typography fontSize="11px">Max</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -1 }}>
          <TextField size="small" fullWidth type="number" />
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          display={{ xs: "block", md: "none" }}
          sx={{ mt: -2 }}
        >
          <Typography fontSize="11px">Max</Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: -1 }}>
          <TextField size="small" fullWidth type="number" />
        </Grid> */}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">Key Skills</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          {/* <Autocomplete
            id="skills"
            autoComplete
            disableClearable
            onChange={(_, value) => setSkillsFilter(value)}
            value={skillsFilter}
            options={[]}
            freeSolo
            multiple
            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose..." />
            )}
          /> */}
          <Autocomplete
            id="skills"
            autoComplete
            disableClearable
            multiple
            freeSolo
            options={[]}
            value={skillsFilter}
            onChange={(event, newArr) => {
              setSkillsFilter(newArr);
              if (newArr.length > 0) {
                setFilter("skills", newArr.join(","));
              } else {
                const temp = { ...tempFilters };
                delete temp["skills"];
                setTempFilters(temp);
              }
            }}
            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Add here" />
            )}
            ChipProps={{
              sx: { backgroundColor: colors.primary, color: colors.secondary },
            }}
          />
        </Grid>
        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">Languages</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <Autocomplete
            id="jobCategoryPrefs"
            autoComplete
            disableClearable
            options={[]}
            freeSolo
            size="small"
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Choose..." />
            )}
          />
        </Grid> */}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setFilters(tempFilters);
              setOpen(false);
            }}
          >
            Apply Filter
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default FilterDrawer;
