import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Grid,
  Avatar,
  IconButton,
  Button,
} from "@mui/material";
import { DownloadOutlined } from "@mui/icons-material";
import ScreeningQuestionsModal from "../../common/ScreeningQuestionsModal";
import question_icon from "../../assets/imagesNew/help-icon.svg";
import "../../styles/statusDiv.css";

import { useQuery } from "@apollo/client";
import {
  GET_RESUME,
  GET_SCREENING_QUESTIONS_JOB_TYPE_ID,
} from "../../graphql/users";
import { downloadAny } from "../../utils";
import getStore from "../../zustandStore";

const GeneralDetails = (props) => {
  const { user } = props;

  const [resume, setResume] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [openScreeningQAModal, setOpenScreeningQAModal] = useState(false);
  const [screeningQA, setScreeningQA] = useState(null);

  const setLoading = getStore((state) => state.setLoading);

  const { data: resumeData, loading } = useQuery(GET_RESUME, {
    variables: { userId: user?.id },
    skip: !user?.id,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { data: screeningQuestionsData, loading: gettingQuestions } = useQuery(
    GET_SCREENING_QUESTIONS_JOB_TYPE_ID,
    {
      variables: {
        id: user?.jobType[0]?.jobType?.id,
      },
      skip: !user?.jobType || user?.jobType.length === 0,
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (resumeData && resumeData?.getResume) {
      setResume(resumeData?.getResume);
    }
  }, [resumeData]);

  useEffect(() => {
    if (user && user?.profile) {
      const tempJSON = JSON.parse(user?.profile);
      setProfileData(tempJSON);
    }
  }, [user]);

  useEffect(() => {
    if (
      screeningQuestionsData &&
      screeningQuestionsData?.jobTypeScreeningQuestions
    ) {
      if (profileData && profileData?.screening_answers) {
        let QnA = [];
        QnA = screeningQuestionsData?.jobTypeScreeningQuestions.map((ques) => ({
          question: ques.question,
          answer: profileData?.screening_answers.hasOwnProperty(ques.id)
            ? profileData?.screening_answers[ques?.id]
            : "Not answered",
        }));
        setScreeningQA(QnA);
      }
    }
  }, [profileData, screeningQuestionsData]);

  useEffect(() => {
    setLoading(loading || gettingQuestions);
  }, [gettingQuestions, loading, setLoading]);

  return (
    <Paper elevation={2} sx={{ px: 2, py: 3 }}>
      <Grid container spacing={2}>
        <Grid item lg={1} md={3} sm={6} xs={6}>
          <Avatar
            sx={{ height: { lg: 100, xs: 80 }, width: { lg: 100, xs: 80 } }}
          >
            {user?.firstName ? user?.firstName?.charAt(0) : ""}
          </Avatar>
        </Grid>
        <Grid item lg={10} md={6} sm={6} xs={6}>
          <Typography fontWeight={700} fontSize="24px">
            {user?.firstName || ""} {user?.lastName || ""}
          </Typography>
          {/* <div class="shortlist" style={{ maxWidth: 100 }}>
            Shortlist
          </div> */}
          {user?.resume && (
            <Button
              variant="outlined"
              sx={{ borderRadius: 1.5, mt: 1 }}
              size="small"
              startIcon={<DownloadOutlined color="primary" fontSize="small" />}
              onClick={() => {
                if (resume) {
                  downloadAny(resume.file, resume.fileName, resume.fileType);
                }
              }}
            >
              Resume
            </Button>
          )}
        </Grid>
        <Grid
          item
          lg={1}
          md={3}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "flex-start", md: "flex-end" },
            alignItems: "flex-start",
          }}
        >
          <IconButton
            size="large"
            onClick={() => {
              setOpenScreeningQAModal(true);
            }}
          >
            <img src={question_icon} width={30} />
          </IconButton>
        </Grid>
      </Grid>
      <ScreeningQuestionsModal
        open={openScreeningQAModal}
        setOpen={setOpenScreeningQAModal}
        data={screeningQA}
        setData={setScreeningQA}
        name={`${user?.firstName || ""} ${user?.lastName || ""}`}
      />
    </Paper>
  );
};

export default GeneralDetails;
