import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_EVENT } from "../../graphql/users";
import { GET_USER } from "../../graphql/users";
import { GET_ORGANIZATION } from "../../graphql/organizations";
import { withFormik } from "formik";
import * as Yup from "yup"
import moment from "moment";
import { GET_STAGES } from "../../graphql/meta";
const MobiliseModalForm=(props)=>{
const {mobiliseModal,
     setMobiliseModal,
      handleChange,
      handleSubmit,
      setFieldValue,
      handleBlur,
      errors,
      touched,
      selectedCandidateData,
      organizationData
    }=props;
    const [Update, setUpdate] = useState(false)
    const { data: stages } = useQuery(GET_STAGES, {
      variables: {},
      nextFetchPolicy: "network-only",
      fetchPolicy: "network-only",
    });
    return(
    < Modal show={mobiliseModal} hide={()=>setMobiliseModal(false)} >
        <Modal.Header>
            <Modal.Title>Mobilise Candidate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>Date</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <input
                type="date"
                id="eventDate"
                className="form-control"
                placeholder="Mobilise Date"
                format="YYYY-MM-DD"
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ border: "1PX #7C7B7B solid" }}
              />
              {touched.eventDate && (
                <span style={{ color: "red", fontSize: 13 }}>
                  {errors.eventDate}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>Candidate Stage</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <select
                className="custom-select form-control"
                style={{ border: "1PX #7C7B7B solid" }}
                id="eventId"
                onChange={handleChange}
                disabled
              >
                {stages &&
                  stages?.events &&
                  stages?.events?.map((e) => (
                    <>{e.name === "Selected for Training" && <option value={e.id}>{e.name}</option>}</>
                  ))}
              </select>
              {touched.eventId && (
                <span style={{ color: "red", fontSize: 13 }}>
                  {errors.eventId}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>Beneficiary PIA</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Beneficiary PIA"
                style={{ border: "1PX #7C7B7B solid" }}
                value={selectedCandidateData?.name}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>PIA Rate (Rs)</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <input
                type="number"
                className="form-control"
                placeholder="PIA Rate"
                style={{ border: "1PX #7C7B7B solid" }}
                value={organizationData?.organization?.rate_mobilization}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <span>Comments</span>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="form-group">
              <input
                type="text"
                id="comments"
                className="form-control"
                placeholder="Comments"
                style={{ border: "1PX #7C7B7B solid" }}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <hr />
        <p>Do you really want to book this candidate for mobilization?</p>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-primary" type="button"
            onClick={() => {
              if(stages && stages.events){
                stages.events.map((e) => {
                  if(e.name === "Selected for Training"){
                    setFieldValue("eventId", e.id)
                  }
                })
              }
              handleSubmit()
            }}
            >
              Book Now</button>
            <button className="btn btn-secondary" onClick={()=>setMobiliseModal(false)}>Cancel</button>
        </Modal.Footer>
    </Modal>
    )
}

const MobiliseModal=(props)=>{

  const {selectedCandidate,setMobiliseModal,mobiliseModal,refetchCandidates}=props
  const [organizationData, setOrganizationData] = useState("")
  const [addEvent] = useMutation(ADD_EVENT, {
    onCompleted: (data) => {
      const { createTimeline } = data || {};
      const { status, error } = createTimeline;
      console.log("EVENT ADDED", status, error);
      if (status) {
        console.log("Offered Succesfull")
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

//Fetching candidatedata for organization details
  const { data: candidatedata } = useQuery(GET_USER, {
    variables: {
      id: selectedCandidate,
    },
    skip: !selectedCandidate,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

 

  const selectedCandidateData=candidatedata?.user?.organization
  console.log(selectedCandidateData?.name,candidatedata)

  //getting pia rates
  const { data: organization } = useQuery(GET_ORGANIZATION, {
    variables: {
      id: selectedCandidateData?.id,
    },
    skip: !selectedCandidateData,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  console.log(organization?.organization?.rate_mobilization)

  useEffect(() => {
    setOrganizationData(organization)
  }, [organization])
  

  const MobiliseModalWrapped=withFormik({
    mapPropsToValues:()=>{
      return{
        eventDate:"",
        comments:"",
        eventId:""
      }
    },
    validationSchema: Yup.object().shape({
      eventDate: Yup.date()
        .required("Please enter the activity date")
        .min(moment().format("YYYY-MM-DD"))
    }),
    
    handleSubmit(values, { props }) {
      console.log(">>>>>>>>>>>>>valuesMobilise", values, props);

      addEvent({
        variables: {
          payload: {
            userId:selectedCandidate,
            ...values
          },
        },
      });
      setMobiliseModal(false)
      refetchCandidates()
    },
  })(MobiliseModalForm)

  
  return(
    <MobiliseModalWrapped
    mobiliseModal={mobiliseModal}
    setMobiliseModal={setMobiliseModal}
    selectedCandidate={selectedCandidate}
    selectedCandidateData={selectedCandidateData}
    organizationData={organizationData}
    />
  )
}
export default MobiliseModal

//18-selected for training