import React from "react";
import { Box, Grid, Typography, ListItem } from "@mui/material";
import colors from "../../styles/colors";

const JobDescription = (props) => {
  const { jobData } = props;
  return (
    <Box
      sx={{
        borderRadius: "4px",
        border: `1px solid ${colors.strokeGray}`,
        p: { xs: 2, sm: 3 },
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontWeight={500} fontSize="18px">
            Job Description
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="13px" sx={{ minHeight: "42vh" }}>
            {jobData?.description || "No description available"}
          </Typography>
          {/* <ListItem
            sx={{
              display: "list-item",
              fontSize: "13px",
              fontFamily: "DM Sans",
            }}
          >
            Believers: Wars are not won by mercenaries but by true believers.
            Believers who walk the talk
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              fontSize: "13px",
              fontFamily: "DM Sans",
            }}
          >
            Insatiable curiosity: You keep a learner's mindset. Undeterred by
            past credentials, you are perennially willing to grow as a person
            and professional
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              fontSize: "13px",
              fontFamily: "DM Sans",
            }}
          >
            Obsession for great products: You understand the difference between
            good and great products. You are obsessed with building great things
            and never settle.
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              fontSize: "13px",
              fontFamily: "DM Sans",
            }}
          >
            Problem solvers: You understand people. You understand
            problem-solving. You know how and when to marry the tw
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              fontSize: "13px",
              fontFamily: "DM Sans",
            }}
          >
            We are one global team ; 710K. Accenture employees worldwide ; 7K.
            Clients served throughout more than 120 countries ; 8200. Patents
            and patents pending worldwide.
          </ListItem>
          <ListItem
            sx={{
              display: "list-item",
              fontSize: "13px",
              fontFamily: "DM Sans",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue
            tincidunt tellus quis consequat feugiat
          </ListItem> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default JobDescription;
