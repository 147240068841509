import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import { formArrayFromStringSkills } from "../../helpers";
import Chip from "../../common/Chip";
import colors from "../../styles/colors";

function getYearOfPassing(jobData) {
  if (
    jobData.minimumYearOfPassing !== null &&
    jobData.maximumYearOfPassing !== null
  ) {
    return `${moment(jobData.minimumYearOfPassing).year()} - ${moment(
      jobData.maximumYearOfPassing
    ).year()}`;
  }
  return "Not specified";
}

function Item(props) {
  const { title, data } = props;
  return (
    <Box>
      <Typography fontSize="13px">{title}</Typography>
      <Typography color={colors.dark} fontSize="14px" fontWeight={700}>
        {data}
      </Typography>
    </Box>
  );
}

const QualificationDetails = (props) => {
  const { jobData } = props;

  const [skills, setSkills] = useState([]);

  useEffect(() => {
    if (jobData && jobData.skills) {
      setSkills(formArrayFromStringSkills(jobData.skills));
    }
  }, [jobData]);
  return (
    <Box
      sx={{
        borderRadius: "4px",
        border: `1px solid ${colors.strokeGray}`,
        p: { xs: 2, sm: 3 },
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontWeight={500} fontSize="18px">
            Qualification Details
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            title="Qualification"
            data={jobData?.qualification?.qualification?.name || "-"}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item title="Year of Passing" data={getYearOfPassing(jobData)} />
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sx={{ display: { xs: "none", md: "block" } }}
        ></Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography fontSize="18px" fontWeight={500}>
            Key Skills
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <Grid container spacing={1} alignItems="center">
            {skills.length > 0 ? (
              skills.map((skill) => (
                <Grid item key={skill}>
                  <Chip label={skill} />
                </Grid>
              ))
            ) : (
              <Typography color={colors.dark} fontSize="14px" fontWeight={700}>
                No skills specified
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QualificationDetails;
