import React from "react";
import { Grid, Typography } from "@mui/material";
import no_results_employer_search from "../../assets/imagesNew/no-results-employer-search.svg";

const NoResultView = (props) => {
  return (
    <Grid container spacing={2} sx={{ mt: { xs: 0, sm: 5 } }}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
      >
        <img src={no_results_employer_search} alt="no_results" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px" textAlign="center">
          Try adjusting your search or filter to
          <br />
          find what you&apos;re looking for
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoResultView;
