import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Checkbox,
  Paper,
  FormControlLabel,
  InputAdornment,
} from "@mui/material";
import colors from "../../styles/colors";

function CheckboxComp(props) {
  const { label, perksArr, setPerksArr } = props;
  return (
    <Grid item lg={6} md={12} sm={12} xs={12}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            value={label}
            checked={perksArr.findIndex((x) => x === label) !== -1}
            onChange={(e) => {
              let temp = [...perksArr];
              if (e.target.checked) {
                temp.push(e.target.value);
              } else {
                temp = perksArr.filter((x) => x !== e.target.value);
              }
              setPerksArr(temp);
            }}
          />
        }
        label={<Typography fontSize="13px">{label}</Typography>}
      />
    </Grid>
  );
}

const perks = [
  "All",
  "Transport Facility",
  "Free/Subisidized Lunch",
  "Free Snacks",
  "5 Days Work Week",
  "Others",
];

const SalaryDetails = (props) => {
  const { values, handleBlur, setFieldValue, touched, errors } = props;



  const setPerks = (perks) => {
    setFieldValue('perks',perks)
  }
 
 
  return (
    <Paper elevation={1} sx={{ py: { xs: 2, sm: 3 }, px: 4 }}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography color={colors.dark} fontSize="14px">
            Renumeration Range{" "}
            <span style={{ fontSize: "11px" }}>(Monthly)</span>
          </Typography>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Typography fontSize="13px">Minimum</Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: { xs: "none", lg: "block" } }}
        >
          <Typography fontSize="13px">Maximum</Typography>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <TextField
            id="minimumRemuneration"
            value={values.minimumRemuneration}
            onChange={(e) =>
              setFieldValue("minimumRemuneration", parseFloat(e.target.value))
            }
            onBlur={handleBlur}
            size="small"
            type="number"
            fullWidth
            placeholder="00.00"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₹</InputAdornment>
              ),
            }}
          />
          {touched.minimumRemuneration && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.minimumRemuneration}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: { xs: "block", lg: "none" } }}
        >
          <Typography fontSize="13px">Maximum</Typography>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
          <TextField
            id="maximumRemuneration"
            value={values.maximumRemuneration}
            onChange={(e) =>
              setFieldValue("maximumRemuneration", parseFloat(e.target.value))
            }
            onBlur={handleBlur}
            size="small"
            type="number"
            fullWidth
            placeholder="00.00"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₹</InputAdornment>
              ),
            }}
          />
          {touched.maximumRemuneration && (
            <Typography fontSize="11px" color={colors.danger}>
              {errors.maximumRemuneration}
            </Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
          <Typography color={colors.dark} fontSize="14px">
            Perks
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={2} alignItems="center">
            {perks &&
              perks.map((perk) => (
                <CheckboxComp
                  key={perk}
                  label={perk}
                  perksArr={values?.perks ? values.perks : []}
                  setPerksArr={setPerks}
                />
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SalaryDetails;
